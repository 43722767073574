import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage } from 'react-intl'

import Drawer from 'components/Drawer'
import Button from 'ui/Button'
import { getShareValuesColumns } from 'utils/functions/metrics'
import Dropdown from 'ui/Dropdown'
import BoxGroupsAndUsersChips from 'components/BoxGroupsAndUsersChips'
import Separator from 'ui/Separator'
import Table from 'components/ExpandableTable'
import DropdownEntity from 'components/GroupsUsersAddInput/DropdownEntity'
import useShareDataPoints from './useShareDataPoints'
import {
  Form,
  Title,
  VerifyValuesLegend,
  CTAWrapper,
} from './StyledShareDataPoints'
import { TableWrapper } from '../ShowMetric/ShowMetric.styles'

const ShareDataPoints = ({ closeDrawer, isOpen }) => {
  const {
    handleAddGroup,
    handleAddGroupByHandle,
    loadGroups,
    register,
    intl,
    currentGroups,
    handleRemoveGroup,
    onRemoveValue,
    dataPoints,
    metric,
    onSubmitForm,
    isValidForm,
    error,
    groupSearchText,
  } = useShareDataPoints(closeDrawer)

  return (
    <Drawer
      isOpen={isOpen}
      headerTitle={`${intl.formatMessage(
        { id: 'metrics.shareValues' },
        { count: dataPoints?.length, metricName: metric?.name }
      )}${metric.name}`}
      onCloseDrawer={closeDrawer}
    >
      <Form onSubmit={onSubmitForm}>
        <Drawer.Content contentPadding="2rem 2.4rem">
          <Dropdown
            error={error}
            name="companiesOrPortfolios"
            label={intl.messages['metrics.shareWith']}
            onSelectOption={handleAddGroup}
            onPressEnter={handleAddGroupByHandle}
            placeholder={intl.messages['metrics.addGroups']}
            id="companies-portfolios"
            loadOptions={loadGroups}
            ref={register}
            async
            type="input"
            icon={['far', 'plus']}
            clearOnSelect
            optionsTitle={
              !groupSearchText
                ? intl.messages['metrics.recentlyUsedGroups']
                : null
            }
            highlightEnabled
            maxListHeight="40rem"
            dropdownOptionPadding="0"
            showLoadingIndicator
            getOption={(entity) => ({
              value: {
                type: entity.type,
                name: entity.name,
                handle: entity.handle,
                logo: entity.logo?.url,
              },
              name: entity.name,
              id: entity.id,
            })}
            formatSelectedOptionComponent={(option) => (
              <DropdownEntity entity={option.value} />
            )}
          />

          <BoxGroupsAndUsersChips
            items={currentGroups?.map((item) => ({
              ...item,
              fromMetric: true,
              canDelete: item.canDelete,
            }))}
            handleDelete={handleRemoveGroup}
          />

          <Separator space="1.8rem" />

          <VerifyValuesLegend>
            <FormattedMessage id="metrics.verifyValues" />
          </VerifyValuesLegend>

          <TableWrapper maxHeight="66vh">
            <Table
              rowHeight={40}
              innerScroll
              maxHeight={560}
              keyPath="id"
              scrollElementId="drawer-container"
              columns={getShareValuesColumns({
                intl,
                onRemoveValue,
              })}
              data={dataPoints || []}
            />
          </TableWrapper>
        </Drawer.Content>

        <Drawer.Footer>
          <Button secondary type="button" onClick={closeDrawer}>
            <FormattedMessage id="general.cancel" />
          </Button>

          <Button primary type="submit" disabled={!isValidForm}>
            <FormattedMessage id="general.share" />
          </Button>
        </Drawer.Footer>
      </Form>
    </Drawer>
  )
}

ShareDataPoints.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
}

export default ShareDataPoints
