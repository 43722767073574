import { useState, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useQueryClient } from '@tanstack/react-query'
import { useHistory } from 'react-router-dom'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import MetricsService from 'api/MetricsService'
import Toast from 'components/Toast'
import { dispatchEvent } from 'utils/hooks/useEventListener'
import { DELETED_METRIC_EVENT } from 'utils/constants/events'
import {
  getCurrentGroupId,
  isActingAsFounder,
  isActingAsFundManager,
} from 'selectors/auth'
import { useMetricsRoute } from 'utils/hooks/useMetricsRoute'
import { Metric, Milestone } from 'utils/types/metrics'
import { metricsKeys } from 'utils/queries/metrics'
import { companyKeys } from 'utils/queries/companies'
import useGroupCompany from 'utils/hooks/useGroupCompany'

export const useMetrics = () => {
  const history = useHistory()
  const [removedMetricsIds, setRemovedMetricsIds] = useState<string[]>([])
  const intl = useIntl()
  const [loading, setLoading] = useState(true)
  const [firstLoad, setFirstLoad] = useState(true)
  const [currentPage, setCurrentPage] = useState(1)
  const [paginationEnabled, setPaginationEnabled] = useState(true)
  const isFounder = useAppSelector(isActingAsFounder)
  const isFundManager = useAppSelector(isActingAsFundManager)
  const currentGroupId = useAppSelector(getCurrentGroupId)
  const founderCompany = useGroupCompany()
  const queryClient = useQueryClient()

  const metricsRoute = useMetricsRoute()

  const onClickMetric = useCallback(
    (metricData: Metric) => {
      history.push(`${metricsRoute}/${metricData.id}`, {
        metricData,
      })
    },
    [history, metricsRoute]
  )

  const onEditMetric = useCallback(
    (metricData: Metric) => {
      history.push(`${metricsRoute}/${metricData.id}/edit`, {
        metricData,
      })
    },
    [history, metricsRoute]
  )

  const onAddNewValue = useCallback(
    (metricData: Metric) => {
      history.push(`${metricsRoute}/${metricData.id}/add-value`)
    },
    [history, metricsRoute]
  )

  const onGoToCompanyPage = useCallback(
    (metricData: Metric) => {
      history.push(`/companies/${metricData?.companyData?.id}`)
    },
    [history]
  )

  const onViewMetricDetail = (metricId: string) => {
    history.push(`${metricsRoute}/${metricId}`)
  }

  const deleteMetric = useCallback(
    (currentGroup: string, isFounderTeam: boolean) => async (metricData) => {
      try {
        if (isFounderTeam) {
          await MetricsService.deleteFounderMetric(metricData.id, currentGroup)
          queryClient.invalidateQueries(
            companyKeys.companyMetrics(metricData.businessCompanyDatum)
          )
        } else {
          await MetricsService.deleteInvestorMetric(metricData.id, currentGroup)
          queryClient.invalidateQueries(metricsKeys.getInvestorMetrics())

          if (
            isFundManager &&
            metricData.businessCompanyDatum === founderCompany?.id
          ) {
            queryClient.invalidateQueries(
              companyKeys.companyMetrics(metricData.businessCompanyDatum)
            )
          }
        }
      } catch (error) {
        Toast.displayIntl('metrics.deleteMetricError', 'error')
      }
    },
    [queryClient, isFundManager, founderCompany?.id]
  )

  const onSetMilestone = useCallback(
    (metricData: Metric) => {
      history.push(`${metricsRoute}/${metricData.id}/set-milestone`)
    },
    [history, metricsRoute]
  )

  const onEditMilestone = useCallback(
    (metricData: Metric, milestone: Milestone) => {
      history.push(
        `${metricsRoute}/${metricData.id}/edit-milestone/${milestone.id}`
      )
    },
    [history, metricsRoute]
  )

  const onRemoveMetric = (metricData: Metric) => {
    dispatchEvent(DELETED_METRIC_EVENT, {
      id: metricData.id,
      hidden: true,
      metric: metricData,
    })
    const deleteMetricFunc = deleteMetric(currentGroupId, isFounder)

    Toast.displayAction({
      message: intl.formatMessage(
        { id: 'metrics.toasts.metricDeleted' },
        { metricName: metricData.name }
      ),
      action: () => {
        dispatchEvent(DELETED_METRIC_EVENT, {
          id: metricData.id,
          hidden: false,
          metric: metricData,
        })
      },
      afterClose: () => deleteMetricFunc(metricData),
      label: intl.formatMessage({ id: 'common.undoDelete' }),
    })
  }

  const exportAllMetrics = useCallback(async () => {
    try {
      await MetricsService.exportAllMetrics()
    } catch (err) {
      Toast.displayIntl('metrics.exportMetricsError', 'error')
    }
  }, [])

  const exportMetric = async (metric: Metric) => {
    try {
      await MetricsService.exportMetricData(metric.id, metric.name)
    } catch (err) {
      Toast.display(intl.messages['metrics.exportMetricsError'], 'error')
    }
  }

  return {
    loading,
    setLoading,
    onClickMetric,
    onEditMetric,
    onGoToCompanyPage,
    onRemoveMetric,
    onAddNewValue,
    firstLoad,
    setFirstLoad,
    paginationEnabled,
    setPaginationEnabled,
    currentPage,
    setCurrentPage,
    onSetMilestone,
    onEditMilestone,
    currentGroupId,
    onViewMetricDetail,
    removedMetricsIds,
    setRemovedMetricsIds,
    exportMetric,
    exportAllMetrics,
  }
}
