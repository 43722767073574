import React from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { Formik } from 'formik'

import Form from 'components/Form'
import Button from 'ui/Button'
import Drawer from 'components/Drawer'

import { AddMetricContainer, Form as StyledForm } from '../CreateMetric.styles'
import CreateInvestorMetricForm from './CreateInvestorMetricForm'
import {
  useCreateInvestorMetric,
  CreateInvestorMetricFormType,
} from './useCreateInvestorMetric'

interface CreateInvestorMetricProps {
  isOpen: boolean
  closeDrawer: () => void
}

const CreateInvestorMetric: React.FC<CreateInvestorMetricProps> = ({
  closeDrawer,
  isOpen,
}) => {
  const {
    initialValues,
    validationSchema,
    createInvestorMetric,
    isCreatingMetric,
  } = useCreateInvestorMetric(closeDrawer)
  const intl = useIntl()

  return (
    <AddMetricContainer>
      <Drawer
        isOpen={isOpen}
        headerTitle={intl.formatMessage({ id: 'metrics.addMetric' })}
        onCloseDrawer={closeDrawer}
      >
        <Formik<CreateInvestorMetricFormType>
          initialValues={initialValues}
          onSubmit={(values) => createInvestorMetric(values)}
          validationSchema={validationSchema}
          validateOnMount
        >
          {({ submitForm, isValid }) => (
            <Form<CreateInvestorMetricFormType> {...initialValues}>
              <StyledForm autoComplete="off">
                <Drawer.Content contentPadding="2rem 2.4rem">
                  <CreateInvestorMetricForm />
                </Drawer.Content>

                <Drawer.Footer>
                  <Button
                    disabled={isCreatingMetric}
                    type="button"
                    onClick={closeDrawer}
                  >
                    <FormattedMessage id="metrics.cancel" />
                  </Button>

                  <Button
                    id="metric-save"
                    disabled={!isValid || isCreatingMetric}
                    primary
                    type="button"
                    onClick={submitForm}
                  >
                    <FormattedMessage id="metrics.save" />
                  </Button>
                </Drawer.Footer>
              </StyledForm>
            </Form>
          )}
        </Formik>
      </Drawer>
    </AddMetricContainer>
  )
}

export default CreateInvestorMetric
