import styled from 'styled-components/macro'

import Button from 'components/Button'
import SelectDatePicker from 'components/TimeSelect/SelectDatePicker'
import Input from 'components/Input'

export const DataPointHistoryContainer = styled.div`
  h1[class*='noResultsFoundTitle'] {
    margin: 2rem;
  }
`

export const AddButton = styled(Button)`
  background: transparent;
  color: #2a2a2f;
  font-size: 1.4rem;
  font-weight: 600;
  padding: 0;
  margin-bottom: 2.6rem;
  text-transform: capitalize;

  &:hover {
    box-shadow: none;
  }
`

export const AddButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const DataPointCell = styled.div`
  color: #787e8c;
  font-size: 1.4rem;
  font-weight: 600;
`

export const DataPOintValue = styled.div`
  display: flex;
  align-items: center;
  span[class*='GrowthIndicator_growthIndicator'] {
    margin-left: 0.8rem;
  }
`

export const Title = styled.div`
  font-family: Raleway;
  font-size: 1.6rem;
  font-weight: 500;
  color: #444444;
  margin: 0rem 0rem 3rem 1rem;
`

export const TableContainer = styled.div`
  border-radius: 1rem;
  border: solid 0.1rem #e9e9e9;
`

export const AddDataPointContainer = styled.div`
  background-color: #f3f5f9;
  padding: 13px 17px 13px 18px;
`
export const AddDataPointRow = styled.div`
  display: flex;
`

export const DateContainer = styled.div`
  flex: 2;
`

export const ValueContainer = styled.div`
  flex: 2;
`

export const DataPointDatePicker = styled(SelectDatePicker)`
  border-radius: 0.4rem;
  border: solid 0.1rem #e9e9e9;
  background-color: white;
  font-size: 1.4rem;
  font-weight: 600;
  height: 3.2rem;
  padding: 0.7rem 0.5rem;
  color: #787e8c;

  input {
    font-size: 1.4rem;
  }
`

export const DataPointInput = styled(Input)`
  border-radius: 0.4rem;
  border: solid 0.1rem #e9e9e9;
  background-color: white;
  border-bottom: none;
  margin-left: 0.2rem;
  font-size: 1.4rem;
  font-weight: 600;
  height: 3.2rem;
  color: #787e8c;

  div[class*='Input_inputContainer'] {
    border-bottom: none;
    height: 100%;
    input {
      font-size: 1.4rem;
    }
  }
  div[class*='Input_prefixContainer'] {
    margin-left: 0.4rem;
    color: #787e8c;
  }
  span[class*='Input_fieldErrorMessage'] {
    display: none;
  }
`

export const ButtonsContainer = styled.div`
  justify-content: flex-end;
  display: flex;

  button {
    height: 3.2rem;
    width: 7rem;
    font-size: 1.4rem;
    text-transform: capitalize;
  }

  button:first-child {
    width: 6.6.rem;
    margin-right: 0.7rem;
    background-color: white;
  }
`
