/* eslint-disable react/style-prop-object */
import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage, useIntl } from 'react-intl'

import Chip from 'components/Chip'
import Button from 'components/Button'
import Dropdown from 'components/Dropdown/Dropdown'
import Input from 'components/Input'
import { getFrequencyNames } from 'utils/functions/metrics'
import { OptionalSpan } from 'containers/Metrics/CreateMetric/CreateMetric.styles'
import SelectDatePicker from 'components/TimeSelect/SelectDatePicker'

import useApproveMetric from './useApproveMetric'
import {
  Title,
  Label,
  Value,
  Form,
  CTAWrapper,
  Separator,
  SubmitButtons,
  CancelButton,
} from './ShowPendingMetric.styles'

const ShowPendingClientMetric = ({ metric, closeDrawer }) => {
  const {
    onApproveMetric,
    onDenyMetric,
    errors,
    register,
    setValue,
    frequency,
  } = useApproveMetric(metric, closeDrawer)
  const intl = useIntl()

  return (
    <>
      <Title>
        <FontAwesomeIcon icon={['fal', 'analytics']} />
        {metric?.name}
      </Title>

      <Form>
        <Label>
          <FormattedMessage id="metrics.metric" />
        </Label>
        <Value>{metric?.name}</Value>

        <Label>
          <FormattedMessage id="metrics.frequency" />
        </Label>
        <Value>{intl.messages[`metrics.${metric?.frequency}`]}</Value>

        <Label>
          <FormattedMessage id="metrics.message" />
        </Label>
        <Value>{metric?.message}</Value>

        <Label>
          <FormattedMessage id="metrics.requestedBy" />
        </Label>
        <Separator margin="0.5rem 0 0 0" />
        <Chip
          image={metric.groupRequester?.logo?.smallLogo?.url}
          text={metric.groupRequester?.name}
          canDelete={false}
        />

        <Separator margin="3.6rem 0 0 0" />
        <Dropdown
          name="frequency"
          register={register}
          label={intl.messages['metrics.frequency']}
          placeholder={intl.messages['metrics.frequencyPlaceholder']}
          onSelectOption={(dropdownName, value) => {
            setValue(dropdownName, value, { shouldValidate: true })
          }}
          showBorder
          options={getFrequencyNames(intl)}
          error={errors?.frequency}
          value={frequency}
        />
        <Separator margin="2.8rem 0 0 0" />

        <Label marginBottom="0.4rem">
          <FormattedMessage id="metrics.metricValue" />
          <OptionalSpan>(optional)</OptionalSpan>
        </Label>
        <Input
          id="value"
          name="value"
          type="number"
          step="1000"
          ref={register}
          placeholder={intl.messages['metrics.metricValue']}
          error={errors.value}
        />

        <Separator margin="2.8rem 0 0 0" />

        <Label marginBottom="0.8rem">
          <FormattedMessage id="metrics.metricDate" />
          <OptionalSpan>
            <FormattedMessage id="general.optional" />
          </OptionalSpan>
        </Label>
        <SelectDatePicker
          onlyMonthYearPicker={false}
          placeholder={intl.messages['metrics.selectDate']}
          dateFormat="MM/dd/yyyy"
          name="date"
          error={errors?.date}
          register={register}
          // TODO: Why we are using setValue instead of handleChange? probably we can improve it
          setValue={setValue}
          initialValue={null}
          maxDate={null}
        />
        <Separator margin="2.8rem 0 0 0" />
        <CTAWrapper>
          <CancelButton type="button" onClick={closeDrawer}>
            <FormattedMessage id="metrics.cancel" />
          </CancelButton>

          <SubmitButtons>
            <Button type="button" danger onClick={onDenyMetric}>
              <FormattedMessage id="metrics.denyMetric" />
            </Button>
            <Button type="button" blue onClick={onApproveMetric}>
              <FormattedMessage id="metrics.approveMetric" />
            </Button>
          </SubmitButtons>
        </CTAWrapper>
      </Form>
    </>
  )
}

ShowPendingClientMetric.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  metric: PropTypes.shape({
    name: PropTypes.string.isRequired,
    frequency: PropTypes.string.isRequired,
    message: PropTypes.string,
    groupRequester: PropTypes.object,
  }).isRequired,
}

export default ShowPendingClientMetric
