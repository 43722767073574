import React from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'
import dayjs from 'dayjs'

import { LoggingType } from 'utils/constants/eventLogs'

import styles from './LogItem.module.scss'

const LogItem = ({ log }) => {
  const intl = useIntl()

  const getOperationTypeIntl = ({ key, targetObjectType, text, group }) => {
    switch (key) {
      case 'create':
        if (
          targetObjectType === LoggingType.ATTACHMENT ||
          targetObjectType === LoggingType.METRIC_MILESTONE ||
          targetObjectType === LoggingType.TAG
        ) {
          return intl.messages['logs.added']
        }

        if (targetObjectType === LoggingType.PORTFOLIO) {
          return intl.formatMessage(
            { id: 'logs.addedToPortfolio' },
            { companyName: text }
          )
        }

        if (targetObjectType === LoggingType.METRIC_DATAPOINT) {
          return intl.formatMessage({ id: 'logs.addedDataPoint' })
        }

        if (targetObjectType === LoggingType.METRIC_LINKED) {
          const logData = JSON.parse(text)
          if (logData.state === 'share_sent') {
            return intl.formatMessage(
              { id: 'logs.sharedMetric' },
              { groupName: <b>{logData.receiver_group}</b> }
            )
          }

          if (logData.state === 'request_sent') {
            return intl.formatMessage(
              { id: 'logs.sharedMetric' },
              { groupName: <b>{logData.sender_group}</b> }
            )
          }
        }

        return intl.messages['logs.created']

      case 'delete':
        if (targetObjectType === LoggingType.PORTFOLIO) {
          return intl.formatMessage(
            { id: 'logs.removedFromPortfolio' },
            { companyName: text }
          )
        }

        if (targetObjectType === LoggingType.TAG) {
          return intl.messages['logs.removed']
        }

        if (targetObjectType === LoggingType.METRIC_DATAPOINT) {
          return intl.formatMessage(
            { id: 'logs.deleteDataPoint' },
            { groupName: <b>{group}</b> }
          )
        }

        return intl.messages['logs.deleted']

      case 'update':
        if (targetObjectType === LoggingType.PORTFOLIO) {
          return intl.formatMessage(
            { id: 'logs.updatedCompany' },
            { companyName: text }
          )
        }

        if (targetObjectType === LoggingType.METRIC_LINKED) {
          const logData = JSON.parse(text)
          if (logData.state === 'unshared') {
            return intl.formatMessage(
              { id: 'logs.unsharedMetric' },
              { groupName: <b>{logData.receiver_group}</b> }
            )
          }
          if (
            logData.state === 'share_accepted' ||
            logData.state === 'request_accepted'
          ) {
            return intl.formatMessage(
              { id: 'logs.metricAccepted' },
              {
                groupName: (
                  <b>{logData.receiver_group || logData.sender_group}</b>
                ),
              }
            )
          }

          if (logData.state === 'share_denied') {
            return intl.formatMessage(
              { id: 'logs.deniedMetric' },
              { groupName: <b>{logData.receiver_group}</b> }
            )
          }

          if (logData.state === 'request_denied') {
            return intl.formatMessage(
              { id: 'logs.deniedMetric' },
              { groupName: <b>{logData.sender_group}</b> }
            )
          }

          if (logData.state === 'unshared') {
            return intl.formatMessage(
              { id: 'logs.unsharedMetric' },
              { groupName: <b>{logData.receiver_group}</b> }
            )
          }
        }

        if (targetObjectType === LoggingType.METRIC_DATAPOINT) {
          return intl.formatMessage({ id: 'logs.editedDataPoint' })
        }

        return intl.messages['logs.updated']
      case 'export_transactions_csv':
        return intl.formatMessage({ id: 'logs.exportCSV' })
      default:
        return ''
    }
  }

  const getLogTypeIntl = ({ targetObjectType, text = '' }) => {
    switch (targetObjectType) {
      case LoggingType.NOTES:
        return intl.messages['logs.note']
      case LoggingType.DOCUMENTS:
        return intl.messages['logs.document']
      case LoggingType.ANNOUNCEMENTS:
        return intl.messages['logs.announcement']
      case LoggingType.ATTACHMENT:
        return intl.messages['logs.attachment']
      case LoggingType.TAG:
        return intl.formatMessage({ id: 'logs.tag' }, { tagName: text })
      case LoggingType.XERO_REPORTS:
      case LoggingType.QUICKBOOKS_REPORTS:
        return `${intl.messages['logs.accounting']} ${intl.messages['logs.update']}`
      case LoggingType.PORTFOLIO:
        return ''
      case LoggingType.TRANSACTION:
        return intl.messages['logs.transaction']
      case LoggingType.METRIC_MILESTONE:
        return intl.messages['logs.milestone']
      case LoggingType.METRIC_ENTITY:
        return intl.messages['logs.metric']

      default:
        return ''
    }
  }

  return (
    <div className={styles.logItemContainer}>
      <div className={styles.title}>
        <span className={styles.user}>{log.user?.name}</span>{' '}
        {getOperationTypeIntl(log)} {getLogTypeIntl(log)}
        {log.targetObjectType === LoggingType.ATTACHMENT &&
          log.text &&
          ` "${log.text}"`}
      </div>
      <div className={styles.subTitle}>
        <div className={styles.date}>
          {dayjs(log.createdAt).format('DD MMM. YYYY - HH:mm A ')}
        </div>
      </div>
    </div>
  )
}

LogItem.propTypes = {
  log: PropTypes.shape({
    key: PropTypes.oneOf(['create', 'update', 'delete']).isRequired,
    text: PropTypes.string,
    createdAt: PropTypes.string.isRequired,
    targetObjectType: PropTypes.oneOf(Object.values(LoggingType)),
    user: PropTypes.shape({
      name: PropTypes.string,
    }).isRequired,
  }).isRequired,
}

export default LogItem
