/* eslint-disable react-hooks/exhaustive-deps, no-unused-vars */
import { useEffect, useCallback } from 'react'
import { useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'

import MetricService from 'api/MetricsService'
import Toast from 'components/Toast'
import { dispatchEvent } from 'utils/hooks/useEventListener'
import { getApproveMetricSchema } from 'utils/schemas/metrics'
import { MetricStatus } from 'utils/constants/metrics'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getCurrentGroupId } from 'selectors/auth'

const useApproveMetric = (metric, closeDrawer) => {
  const intl = useIntl()
  const currentGroupId = useAppSelector(getCurrentGroupId)
  const { register, setValue, errors, handleSubmit, watch, reset } = useForm({
    validationSchema: getApproveMetricSchema(intl.messages),
  })

  const frequency = watch('frequency')

  useEffect(() => {
    setValue('frequency', metric.frequency)
  }, [metric])

  const updateMetricStatus = useCallback(
    (currentGroup) => async (metricId, data) => {
      try {
        const metricResponse = await MetricService.updateRequestedMetric(
          metricId,
          data,
          currentGroup
        )
      } catch (error) {
        Toast.display(intl.messages['editMetric.error'], 'error')
      }
    },
    []
  )

  const onSubmitForm = (isApproved) => async (data) => {
    closeDrawer()
    const prevFrequency = metric.frequency
    const updateMetricStatusFunc = updateMetricStatus(currentGroupId)
    Toast.displayAction({
      message: intl.formatMessage(
        { id: isApproved ? 'metrics.metricApproved' : 'metrics.metricDenied' },
        { metricName: metric.name }
      ),

      type: isApproved ? 'success' : 'action',

      afterClose: () =>
        updateMetricStatusFunc(metric.id, {
          metricGroupId: metric.metricGroup.id,
          frequency: data.frequency,
          status: isApproved ? MetricStatus.SHARED : MetricStatus.DENIED,
          date: data.date,
          value: data.value,
        }),
    })
  }

  return {
    metric,
    onApproveMetric: handleSubmit(onSubmitForm(true)),
    onDenyMetric: handleSubmit(onSubmitForm(false)),
    register,
    frequency,
    setValue,
    errors,
  }
}

export default useApproveMetric
