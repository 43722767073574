import dayjs from 'dayjs'
import GroupTooltips from 'components/GroupTooltips'
import MilestoneIndicator from 'components/Milestone/MilestoneIndicator'
import ReadOnlyTooltip from 'components/ReadOnlyTooltip/ReadOnlyTooltip'
import IconButton from 'ui/IconButton'
import { useIntl } from 'react-intl'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useHistory } from 'react-router-dom'
import { isActingAsFounder } from 'selectors/auth'
import { displayThousands } from 'utils/functions/number'
import { useMetricsRoute } from 'utils/hooks/useMetricsRoute'
import { useRandomId } from 'utils/hooks/useRandomId'
import { getPortfolioIconByType } from 'utils/functions/renderers/renderPortfolioHelper'
import { Color } from 'utils/theme'
import {
  Metric,
  MetricSources,
  Milestone as MilestoneType,
  InvestorMilestone,
  isInvestorMilestone,
} from 'utils/types/metrics'
import { CreatorLogoContainer } from './Milestone.styles'

interface MilestoneProps {
  milestone: MilestoneType | InvestorMilestone
  metric: Metric
  isEditable: boolean
  mode?: 'dark' | 'light'
  progressColor?: Color
}

const Milestone = ({
  milestone,
  metric,
  isEditable = false,
  mode = 'light',
  progressColor = 'primaryBlue',
}: MilestoneProps) => {
  const intl = useIntl()
  const tooltipId = useRandomId()
  const isFounder = useAppSelector(isActingAsFounder)
  const history = useHistory()
  const metricsRoute = useMetricsRoute()
  const isCalculatedMetric = metric.metricSource === MetricSources.System

  return (
    <MilestoneIndicator>
      <MilestoneIndicator.Title mode={mode}>
        {displayThousands(milestone.value)}
        {isEditable && (
          <ReadOnlyTooltip
            id={`editMilestoneDropdown_${tooltipId}`}
            text={intl.messages['readOnlyMode.editMilestone']}
            place="left"
            leftOffset={-10}
            maxWidth="25.4rem"
          >
            <IconButton
              icon="pen"
              border={false}
              onClick={() =>
                history.push(
                  `${metricsRoute}/${metric.id}/edit-milestone/${milestone.id}`
                )
              }
            />
          </ReadOnlyTooltip>
        )}
        {isInvestorMilestone(milestone) && (
          <CreatorLogoContainer>
            <GroupTooltips groups={[milestone.group]} direction="left" />
          </CreatorLogoContainer>
        )}
      </MilestoneIndicator.Title>
      <MilestoneIndicator.Legend>
        {intl.formatMessage(
          { id: 'metrics.before' },
          {
            value: dayjs(milestone.date).format('DD MMMM YYYY'),
          }
        )}
      </MilestoneIndicator.Legend>

      <MilestoneIndicator.Progress
        color={progressColor}
        value={milestone.percentage}
      />

      {!isFounder && (
        <MilestoneIndicator.Groups
          shared={milestone.shared}
          groups={[
            isCalculatedMetric
              ? {
                  id: metric.id,
                  name: metric.metrizableName,
                  logo: getPortfolioIconByType(metric.metrizableType!, '1x'),
                }
              : {
                  id: metric.companyData?.id,
                  name: metric.companyData?.name,
                  logo: metric.companyData?.primaryLogo,
                },
          ]}
        />
      )}
    </MilestoneIndicator>
  )
}

export default Milestone
