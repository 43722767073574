import React, { useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import useOutsideClick from 'utils/hooks/useOutsideClick'

import styles from './OptionsDropdown.module.scss'

const OptionsDropdown = ({ children, title, icon }) => {
  const [dropdownClosed, setDropdownClosed] = useState(true)
  const wrapperRef = useRef(null)

  useOutsideClick(wrapperRef, () => {
    if (!dropdownClosed) {
      setDropdownClosed(true)
    }
  })

  const toggleDropdown = useCallback((event) => {
    event.preventDefault()
    event.stopPropagation()
    setDropdownClosed((open) => !open)
  }, [])

  return (
    <div ref={wrapperRef} className={styles.dropdown}>
      {title ? (
        <div
          className={styles.title}
          role="button"
          tabIndex={0}
          onKeyDown={toggleDropdown}
          onClick={toggleDropdown}
        >
          {title}
        </div>
      ) : (
        <div
          className={classNames(styles.dots, {
            [styles.dotsActive]: !dropdownClosed,
          })}
          tabIndex={0}
          role="button"
          onClick={toggleDropdown}
          onKeyDown={toggleDropdown}
        >
          <FontAwesomeIcon icon={icon} />
        </div>
      )}
      <div
        className={classNames(styles.dropdownContent, {
          [styles.dropdownClosed]: dropdownClosed,
          [styles.dropdownRight]: !title,
        })}
      >
        {React.Children.map(
          children,
          (child) =>
            child &&
            React.cloneElement(child, {
              closeDropdown: () => setDropdownClosed(true),
            })
        )}
      </div>
    </div>
  )
}

const Item = ({
  label,
  closeDropdown,
  onSelectOption,
  icon,
  color,
  ...rest
}) => {
  const onSelect = useCallback(
    (event) => {
      event.preventDefault()
      event.stopPropagation()
      closeDropdown()
      onSelectOption()
    },
    [closeDropdown, onSelectOption]
  )

  return (
    <div
      role="button"
      tabIndex={0}
      className={styles.dropdownItem}
      style={{ color }}
      onKeyDown={onSelect}
      onClick={onSelect}
      {...rest}
    >
      {icon && (
        <FontAwesomeIcon
          size="lg"
          className={styles.itemIcon}
          style={{ color }}
          icon={icon}
        />
      )}
      <span className={styles.label}>{label}</span>
    </div>
  )
}
Item.propTypes = {
  label: PropTypes.oneOfType([PropTypes.element, PropTypes.string]).isRequired,
  closeDropdown: PropTypes.func,
  onSelectOption: PropTypes.func.isRequired,
  icon: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  color: PropTypes.string,
}

Item.defaultProps = {
  closeDropdown: () => {},
  icon: null,
  color: null,
}

OptionsDropdown.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  title: PropTypes.node,
  icon: PropTypes.array,
}

OptionsDropdown.defaultProps = {
  title: null,
  icon: ['far', 'ellipsis-v-alt'],
}

OptionsDropdown.Item = Item

export default OptionsDropdown
