/* eslint-disable @typescript-eslint/no-use-before-define, react-hooks/exhaustive-deps */
import { useEffect, useMemo } from 'react'
import { useIntl } from 'react-intl'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useParams } from 'react-router-dom'

import { getCurrentGroupId, isActingAsFounder } from 'selectors/auth'
import MetricService from 'api/MetricsService'
import Toast from 'components/Toast'
import { getSetMilestoneSchema } from 'utils/schemas/metrics'
import { useMetricMilestone } from 'utils/hooks/useMetricMilestone'
import { useQueryClient } from '@tanstack/react-query'
import { deleteMilestone, undoDeleteMilestone } from 'utils/functions/metrics'
import dayjs from 'dayjs'
import { useFormik } from 'formik'
import { dispatchEvent } from 'utils/hooks/useEventListener'
import useMetricQuery from 'utils/hooks/useMetricQuery'
import { MetricsMode, MetricSources } from 'utils/types/metrics'
import { useMetricsMode } from 'utils/hooks/useMetricsMode'
import { metricsKeys } from 'utils/queries/metrics'

const useSetMetricMilestone = (closeDrawer, isEditingMilestone) => {
  const intl = useIntl()
  const { id: metricId, milestoneId } = useParams()
  const { metric, isLoading } = useMetricQuery({
    metricId,
  })

  const metricsMode = useMetricsMode()
  const isFounder = metricsMode === MetricsMode.FOUNDER
  const isSystemMetric = useMemo(
    () => metric?.metricSource === MetricSources.System,
    [metric]
  )

  const queryClient = useQueryClient()
  const currentGroupId = useAppSelector(getCurrentGroupId)
  const initialValues = {
    notifyValueReached: false,
    notifyPercentageReached: false,
    shared: !isFounder,
    date: undefined,
    value: undefined,
  }

  const formik = useFormik({
    validationSchema: getSetMilestoneSchema(intl.messages),
    initialValues,
    onSubmit: (val) => onSubmit(val),
  })

  const { milestone } = useMetricMilestone(metricId, milestoneId, {
    enabled: !!isEditingMilestone,
  })

  useEffect(() => {
    if (isEditingMilestone && milestone) {
      formik.setValues({
        notifyValueReached: milestone.notifyValueReached,
        notifyPercentageReached: milestone.notifyPercentageReached,
        date: milestone.date,
        value: milestone.value,
        shared: milestone.shared || false,
      })
    }
  }, [milestone, isEditingMilestone])

  const editMilestone = async (data) => {
    if (isFounder) {
      await MetricService.editFounderMilestone(milestone.id, data)
    } else {
      await MetricService.editInvestorMilestone(milestone.id, data)
    }
    Toast.display(intl.messages['editMetric.milestoneUpdatedSuccess'])
  }

  const createMilestone = async (data) => {
    if (isFounder) {
      await MetricService.setFounderMilestone(metricId, data)
    } else {
      await MetricService.setInvestorMilestone(metricId, data)
    }
    Toast.display(intl.messages['editMetric.milestoneCreatedSuccess'])
  }

  const onSubmit = async (data) => {
    try {
      const milestoneData = {
        notifyValueReached: data.notifyValueReached,
        notifyPercentageReached: data.notifyPercentageReached,
        value: data.value !== null ? Number(data.value) : null,
        date: dayjs(data.date).endOf('day').toDate(),
        shared: data.shared,
      }

      if (milestoneData.value === 0) {
        formik.setFieldError(
          'value',
          intl.messages['metrics.milestoneCannotBeZero']
        )
        return
      }

      if (isEditingMilestone) {
        await editMilestone(milestoneData)
      } else {
        await createMilestone(milestoneData)
      }

      queryClient.invalidateQueries(metricsKeys.getMetric(metricId))

      closeDrawer()
      formik.resetForm(initialValues)
    } catch (error) {
      Toast.display(intl.messages['editMetric.error'], 'error')
    }
  }

  const createDeleteMilestoneFunction =
    (currentGroup, isFounderTeam) => async (milestoneIdToDelete) => {
      try {
        if (isFounderTeam) {
          await MetricService.deleteFounderMilestone(
            milestoneIdToDelete,
            currentGroup
          )
        } else {
          await MetricService.deleteInvestorMilestone(
            milestoneIdToDelete,
            currentGroup
          )
        }
      } catch (error) {
        Toast.display(
          intl.messages['editMetric.milestoneDeletedError'],
          'error'
        )
      }
    }

  const onDeleteMilestone = async () => {
    deleteMilestone({
      queryClient,
      metricId,
      milestone,
    })
    const deleteMilestoneFunc = createDeleteMilestoneFunction(
      currentGroupId,
      isFounder
    )

    Toast.displayAction({
      message: intl.messages['editMetric.milestoneDeletedSuccess'],
      action: () => {
        undoDeleteMilestone({
          queryClient,
          metricId,
          milestone,
        })
      },
      afterClose: () => deleteMilestoneFunc(milestone.id),
    })

    closeDrawer()
  }

  return {
    formik,
    metric,
    isSystemMetric,
    milestone,
    isEditingMilestone,
    onDeleteMilestone,
    isLoadingMetric: isLoading,
    isFounder,
  }
}

export default useSetMetricMilestone
