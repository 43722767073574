import { METRICS_PAGE_SIZE } from 'utils/constants/metrics'
import InfiniteScroll from 'components/InfiniteScrollRef/InfiniteScroll'
import { ZeroState, ZeroStateType } from 'components/ZeroStateV2'
import SkeletonSelectedMetricsCard from 'components/Skeletons/Metrics/SkeletonSelectedMetricsCard'
import { useProfileMetrics } from 'components/ProfileMetrics/useProfileMetrics'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getFounderCompany } from 'selectors/company'

import MetricsZeroState from '../MetricsZeroState'
import MetricsSelector from './components/MetricsSelector'
import { useMetricsSandbox } from './useMetricsSandbox'
import { SelectedMetricCard } from './components/MetricCards/SelectedMetricCard'

import * as Styles from './SelectedMetrics.styles'

interface SelectedMetricsProps {
  metrizableId?: string
}

const SelectedMetrics = ({ metrizableId }: SelectedMetricsProps) => {
  const currentCompany = useAppSelector(getFounderCompany)
  const {
    hasMetrics,
    hasSelectedMetrics,
    isLoading,
    metricsCards,
    fetchNextMetricsPage,
  } = useMetricsSandbox()

  const { removedMetricsIds } = useProfileMetrics({
    companyInfo: currentCompany,
  })

  if (isLoading) {
    return (
      <>
        <MetricsSelector disabled={isLoading} />
        <Styles.Container>
          <SkeletonSelectedMetricsCard />
          <SkeletonSelectedMetricsCard />
          <SkeletonSelectedMetricsCard />
        </Styles.Container>
      </>
    )
  }

  if (!hasMetrics) {
    return (
      <Styles.ZeroStateContainer>
        <MetricsZeroState />
      </Styles.ZeroStateContainer>
    )
  }

  const metrics = metricsCards
    .map((card) => card.metrics)
    .flat()
    .filter((metric) => !removedMetricsIds.includes(metric.id))

  return (
    <>
      <MetricsSelector filters={{ metrizableId }} />
      {hasSelectedMetrics ? (
        <>
          <Styles.Container>
            {metrics.map((metric) => {
              return <SelectedMetricCard metric={metric} />
            })}
          </Styles.Container>
          <InfiniteScroll
            enabled
            fetchNextPage={fetchNextMetricsPage}
            pageSize={METRICS_PAGE_SIZE}
          />
        </>
      ) : (
        <Styles.ZeroStateContainer>
          <ZeroState
            type={ZeroStateType.SELECTED_METRICS}
            maxWidth="54rem"
            infoMarginTop="-0.6rem"
          />
        </Styles.ZeroStateContainer>
      )}
    </>
  )
}

export default SelectedMetrics
