import React, { useEffect } from 'react'
import { Nullable } from 'utils/types/common'

function usePortal(id: string) {
  const rootElemRef = React.useRef<HTMLDivElement>(
    document.createElement('div')
  )

  useEffect(
    function setupElement() {
      const parentElem: Nullable<HTMLElement> = document.querySelector(`#${id}`)

      parentElem?.appendChild(rootElemRef.current)

      const currentRootElemRef = rootElemRef.current
      return function removeElement() {
        currentRootElemRef.remove()
      }
    },
    [id]
  )

  return rootElemRef.current
}

export default usePortal
