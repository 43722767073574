import { useEffect, useState, useRef } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { cloneDeep } from 'lodash'
import { useIntl } from 'react-intl'
import { useForm } from 'react-hook-form'
import { useQueryClient } from '@tanstack/react-query'

import MetricService from 'api/MetricsService'
import GroupService from 'api/GroupService'
import Toast from 'components/Toast'
import {
  getEditValueMetricSchema,
  getSetNewValueMetricSchema,
} from 'utils/schemas/metrics'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { isActingAsFounder } from 'selectors/auth'
import { useFetchUniversity } from 'utils/hooks/useFetchUniversity'
import useMetricGroups from 'utils/hooks/useMetricGroups'
import useUserData from 'utils/hooks/useUserData'
import useMetricQuery from 'utils/hooks/useMetricQuery'
import { searchMatchesGroup } from 'utils/functions/updates'
import { MetricsMode } from 'utils/types/metrics'
import { useMetricsMode } from 'utils/hooks/useMetricsMode'
import { QUERIES, metricsKeys } from 'utils/queries/metrics'
import { dispatchEvent } from 'utils/hooks/useEventListener'
import { EDIT_DP_METRIC_EVENT } from 'utils/constants/events'

const useAddMetricValue = (closeDrawer) => {
  const intl = useIntl()
  const [groups, setGroups] = useState([])
  const dropdownRef = useRef(null)
  const queryClient = useQueryClient()

  const { id: metricId } = useParams()
  const { metric, refetchMetric } = useMetricQuery({
    metricId,
  })
  const { fetchUniversity } = useFetchUniversity()
  const location = useLocation()
  const isEditing = location.state?.isEditing

  const { register, handleSubmit, errors, setValue, reset, formState, watch } =
    useForm({
      validationSchema: isEditing
        ? getEditValueMetricSchema(intl.messages)
        : getSetNewValueMetricSchema(intl.messages),
      mode: 'all',
    })

  const { isCurrentUserActingAsFounder, isFounderVerified } = useUserData()
  const founderUnverified = !isFounderVerified && isCurrentUserActingAsFounder

  const { handleAddGroup, handleRemoveGroup, currentGroups, loadGroups } =
    useMetricGroups(
      !isEditing && !founderUnverified ? metric?.receiverGroups : undefined
    )

  const metricsMode = useMetricsMode()
  const isFounder = metricsMode === MetricsMode.FOUNDER
  const isEditingMetricAsFounder = useAppSelector(isActingAsFounder)

  const date = watch('date')

  const getGroups = async () => {
    try {
      const groupsResponse = await GroupService.fetchUserGroups()

      setGroups(groupsResponse)
    } catch (error) {
      Toast.display(intl.messages['metrics.errorFetchingGroupss'], 'error')
    }
  }

  useEffect(() => {
    if (!location.state?.dataPoint) {
      setTimeout(() => {
        setValue('date', new Date(), { shouldValidate: true })
      }, 2)
    }
    getGroups()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const dataPoint = location.state?.dataPoint

    if (dataPoint) {
      reset({
        metricValue: dataPoint.value,
        date: dataPoint.date,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const createDataPoint = async (dataPoint) => {
    let addedDatapoint

    if (isFounder) {
      addedDatapoint = await MetricService.addDataPointFounder({
        metricId,
        dataPoint,
        sharedGroupsIds: currentGroups?.map((group) => group.id),
      })
    } else {
      addedDatapoint = await MetricService.addDataPointInvestor({
        metricId,
        dataPoint,
      })
    }

    if (addedDatapoint) {
      const allQueryKeys = queryClient
        .getQueryCache()
        .getAll()
        .map((query) => query.queryKey)

      const allMetricsKeys = isEditingMetricAsFounder
        ? allQueryKeys.filter(
            (key) => key.indexOf(QUERIES.FOUNDER_METRICS) !== -1
          )
        : allQueryKeys.filter(
            (key) => key.indexOf(QUERIES.INVESTOR_METRICS) !== -1
          )

      allMetricsKeys.forEach((key) => {
        queryClient.setQueryData(key, (metrics) => {
          const updatedMetrics = cloneDeep(metrics)

          updatedMetrics?.pages?.forEach((page) => {
            const metricIndex = page.data.findIndex(
              (currentMetric) => currentMetric.id === metricId
            )
            if (metricIndex !== -1) {
              page.data[metricIndex].dataPoints.push(addedDatapoint)
            }
          })

          return updatedMetrics
        })
      })
    }

    Toast.displayIntl('metrics.toasts.successNewMetricValue', 'success')
  }

  const editDataPoint = async (dataPoint) => {
    if (isFounder) {
      await MetricService.editDataPointFounder({
        ...dataPoint,
        sharedGroupsIds: currentGroups?.map((group) => group.id),
      })
    } else {
      await MetricService.editDataPointInvestor({
        ...dataPoint,
        sharedGroupsIds: currentGroups?.map((group) => group.id),
      })
    }

    dispatchEvent(EDIT_DP_METRIC_EVENT, {
      isFounderMetric: isFounder,
    })
  }

  const onSubmit = async (data) => {
    const normalizedMetricValue = data.metricValue?.replace(/,/g, '')

    try {
      let dataPoint = { date: data.date, value: normalizedMetricValue }
      if (location?.state?.dataPoint) {
        dataPoint = {
          ...location?.state?.dataPoint,
          date: data.date,
          value: normalizedMetricValue ?? location?.state?.dataPoint?.value,
        }
      }

      if (isEditing) {
        await editDataPoint(dataPoint)
      } else {
        await createDataPoint(dataPoint)
      }

      refetchMetric()

      fetchUniversity()
      closeDrawer()
      reset('')
    } catch (error) {
      Toast.display(intl.messages['editMetric.error'], 'error')
    }
  }

  const onDateChange = (currDate) => {
    setValue('date', currDate, { shouldValidate: true })
  }

  const { isValid } = formState

  const clearDropdown = () => {
    dropdownRef.current?.clear()
    dropdownRef.current?.close()
  }

  const onSelectGroup = (...params) => {
    handleAddGroup(...params)
    setTimeout(clearDropdown)
  }

  const handlePressEnter = async (inputValue, dropdownOptions) => {
    if (
      dropdownOptions.length === 1 &&
      searchMatchesGroup(dropdownOptions[0], inputValue)
    ) {
      onSelectGroup(null, null, dropdownOptions[0])
    }
  }

  return {
    metric,
    onSubmit,
    register,
    handleSubmit: handleSubmit(onSubmit),
    errors,
    setValue,
    onDateChange,
    isValid,
    date,
    isFounder,
    groups,
    handleAddGroup,
    handlePressEnter,
    handleRemoveGroup,
    currentGroups,
    readOnlyMode: location.state?.readOnlyMode,
    dataPoint: location.state?.dataPoint,
    isEditing: location.state?.isEditing,
    loadGroups,
    founderUnverified,
    dropdownRef,
  }
}

export default useAddMetricValue
