import { useMemo } from 'react'
import dayjs from 'dayjs'
import { DataPoint } from 'utils/types/metrics'

interface Props {
  dataPoints: DataPoint[]
}

export const useDataPoints = ({ dataPoints: dataPointsProp }: Props) => {
  const dataPoints = useMemo(() => {
    const newDataPoints = dataPointsProp.reduce((acc, dataPoint) => {
      const date = dayjs(dataPoint.date)
      const updatedAt = dayjs(dataPoint.updatedAt)

      if (!acc[date.date()]) {
        acc[date.date()] = dataPoint
      } else {
        const currentDataPoint = acc[date.date()]
        if (updatedAt.isAfter(dayjs(currentDataPoint.updatedAt))) {
          acc[date.date()] = dataPoint
        }
      }

      return acc
    }, {} as Record<string, DataPoint>)

    return Object.values(newDataPoints).sort(
      (a, b) => dayjs(a.date).valueOf() - dayjs(b.date).valueOf()
    )
  }, [dataPointsProp])

  return {
    dataPoints,
  }
}
