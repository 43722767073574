import styled, { css } from 'styled-components'
import { Link } from 'react-router-dom'
import { color } from 'utils/functions/colors'
import { Color } from 'utils/theme'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  padding: 1.3rem 1.6rem 1rem 1.4rem;
  border-radius: 0.8rem;
  border: 0.1rem solid #e9e9e9;
`

export const Title = styled.h3<{ mode?: string }>`
  display: flex;
  align-items: center;
  color: ${(props) =>
    props.mode === 'light'
      ? color('lightGray')(props)
      : color('darkBlue')(props)};
  font-size: 1.8rem;
  font-weight: 400;

  span {
    margin-left: 0.5rem;
  }
`

export const Legend = styled.p`
  color: #4a4a68;
  font-size: 1.4rem;
  margin: 0.9rem 0 0.6rem 0;
`

export const Progress = styled.progress<{
  mode: 'dark' | 'light'
  color: Color
}>`
  width: 100%;
  border-radius: 1rem;
  height: 0.8rem;

  &[value]::-webkit-progress-value {
    background: ${(props) =>
      color(props.mode === 'dark' ? 'white' : props.color || 'primaryBlue')(
        props
      )};
    border-radius: 1rem;
  }
  &::-webkit-progress-bar {
    background: ${(props) =>
      props.mode === 'dark' ? color('white', 0.3)(props) : '#e9e9e9'};
    border-radius: 1rem;
  }
`

export const ProgressValue = styled.span<{
  mode: 'dark' | 'light'
}>`
  font-weight: 700;
  font-size: 1.2rem;
  color: ${(props) => (props.mode === 'dark' ? 'white' : '#101527')};
`

export const ProgressWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: 1.2rem;
    font-weight: bold;
    margin-left: 1rem;
  }
`

export const MilestonePercentage = styled.span`
  margin-left: 0.5rem;
`

export const TooltipWrapper = styled.div`
  border-radius: 0.8rem;
  background-color: #194590;
  padding: 0.8rem;
`

export const TooltipHeader = styled.header`
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 1.7rem;
`

export const TooltipContent = styled.main`
  font-size: 1.2rem;
`

export const StyledLink = styled(Link)<{ disabled?: boolean }>`
  display: block;
  color: #194590;
  font-size: 1.4rem;
  font-weight: 700;

  &:hover {
    text-decoration: underline;
  }

  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;

      &:hover {
        text-decoration: none;
      }
    `}
`

export const MilestoneSmallIndicatorContainer = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
`

export const MilestoneGroupsContainer = styled.div`
  display: flex;
  padding: 1.5rem 0 0.6rem 0;
`

export const SharedWithLabel = styled.span`
  font-weight: 400;
  font-size: 1rem;
  text-transform: uppercase;
  margin-right: 0.8rem;
`

export const TitleChildrenWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
  margin-left: 0.4rem;
`
