import dayjs from 'dayjs'
import { useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Dropdown from 'ui/Dropdown'
import Separator from 'ui/Separator'
import Chip from 'components/Chip/Chip'
import Timeline from 'components/Timeline/Timeline'
import { buildFormError } from 'utils/functions/forms'
import { getFrequencyNames } from 'utils/functions/metrics'
import { MAX_COUNTER_CHARACTERS } from 'utils/constants/common'
import { ErrorType } from 'utils/types/common'
import { Metric } from 'utils/types/metrics'
import { Input } from 'containers/UpdatesV2/components/commons'
import {
  ChipWrapper,
  Icon,
  Message,
  MessageTitle,
  TextLabel,
} from '../EditMetric.styles'
import useEditInvestorMetricForm from './useEditInvestorMetricForm'

interface Props {
  showTimeline: boolean
  showFounderTimelineItem: boolean
  showTimelineMessage: boolean
  metric: Metric
}

const EditInvestorMetricForm = ({
  showTimeline,
  showFounderTimelineItem,
  showTimelineMessage,
  metric,
}: Props) => {
  const intl = useIntl()

  const {
    nameField,
    frequencyField,
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
  } = useEditInvestorMetricForm()

  return (
    <>
      <Input
        id={nameField.name}
        name={nameField.name}
        error={buildFormError(errors.name, ErrorType.ERROR, true)}
        onBlur={handleBlur}
        title={intl.formatMessage({ id: 'metrics.name' })}
        label={intl.formatMessage({ id: 'metrics.name' })}
        placeholder={intl.formatMessage({ id: 'metrics.namePlaceholder' })}
        value={nameField.value}
        onChange={handleChange}
        withCounter
        customMaxCounterCharacters={MAX_COUNTER_CHARACTERS}
        counterSubtractNumber={nameField.value?.length}
        simulateIconSpace
        counterStyle={{
          right: '2rem',
          top: '1.2rem',
          opacity: 0.5,
        }}
        extraPaddingRight={1.5}
      />

      <Separator space="1rem" />

      <Dropdown
        id={frequencyField.name}
        name={frequencyField.name}
        label={intl.formatMessage({ id: 'metrics.frequency' })}
        placeholder={intl.formatMessage({ id: 'metrics.frequencyPlaceholder' })}
        onSelectOption={(_, value) => {
          setFieldValue(frequencyField.name, value)
        }}
        showBorder
        options={getFrequencyNames(intl)}
        error={buildFormError(errors.frequency, ErrorType.ERROR, true)}
        value={frequencyField.value}
      />

      <Separator space="1rem" />

      <TextLabel>{intl.formatMessage({ id: 'metrics.from' })}</TextLabel>

      <ChipWrapper>
        <Chip
          image={metric.companyData?.primaryLogo?.url}
          text={metric.companyData?.name}
          canDelete={false}
        />
      </ChipWrapper>

      <Separator space="2.3rem" />

      {showTimeline && (
        <TextLabel>
          {intl.formatMessage({ id: 'metrics.founderInformation' })}
        </TextLabel>
      )}

      <Timeline renderChildrenDuration={0.1}>
        {showFounderTimelineItem && (
          <Timeline.Item
            key="data"
            icon={
              <Icon>
                <FontAwesomeIcon icon={['far', 'analytics']} />
              </Icon>
            }
            updateType="log"
            left="1.6rem"
            padding="0 0 1.8rem 1.4rem"
          >
            <MessageTitle>
              {intl.formatMessage({ id: 'metrics.receiveDataFrom' })}
            </MessageTitle>

            <Message>
              {metric.name}{' '}
              {metric.frequency
                ? intl.formatMessage(
                    { id: 'metrics.frequencyValue' },
                    { frequency: metric.frequency }
                  )
                : ''}
            </Message>
            <Message>
              {intl.formatMessage({ id: 'metrics.since' })}{' '}
              {dayjs(metric.trackingSince).format('DD MMM, YYYY - HH:MM a')}
            </Message>
          </Timeline.Item>
        )}
        {showTimelineMessage && (
          <Timeline.Item
            key="message"
            icon={
              <Icon>
                <FontAwesomeIcon icon={['far', 'envelope']} />
              </Icon>
            }
            left="1.6rem"
            padding="0 0 0 1.4rem"
          >
            <MessageTitle>
              {intl.formatMessage(
                { id: 'metrics.messageSent' },
                { date: dayjs(metric.createdAt).format('DD MMM YYYY - HH:MM') }
              )}
            </MessageTitle>
            <Message>{metric.senderLinkedMetric?.message}</Message>
          </Timeline.Item>
        )}
      </Timeline>

      <Separator space="3.6rem" />
    </>
  )
}

export default EditInvestorMetricForm
