import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import { ZeroState, ZeroStateType } from 'components/ZeroStateV2'
import Milestone from 'components/Milestone'
import useMetricQuery from 'utils/hooks/useMetricQuery'
import { useMetricsRoute } from 'utils/hooks/useMetricsRoute'
import { Subtitle } from '../ShowMetric/ShowMetric.styles'
import {
  ShowMetricMilestoneWrapper,
  MilestoneWrapper,
  TitleWrapper,
  MilestoneZeroStateWrapper,
} from './ShowMetricMilestones.styles'

const ShowMetricMilestones = ({ metric }) => {
  const intl = useIntl()
  const history = useHistory()
  const metricsRoute = useMetricsRoute()

  const {
    groupedMilestones: {
      investorsMilestones,
      activeMilestones,
      reachedMilestones,
      expiredMilestones,
    },
    isLoading,
    hasMilestones,
  } = useMetricQuery({ metricId: metric.id, metricData: metric })

  const showZeroState = !isLoading && !hasMilestones

  if (showZeroState) {
    return (
      <ZeroState
        callToActionText={intl.messages['metrics.addMilestone']}
        type={ZeroStateType.MILESTONES}
        callToAction={() =>
          history.push(`${metricsRoute}/${metric.id}/set-milestone`)
        }
        isCallToActionPrimaryStyle
        padding="3rem 6rem"
        disableActionIfReadOnly
        readOnlyTooltipText={intl.messages['readOnlyMode.setMilestone']}
        readOnlyTooltipPlace="bottom"
        readOnlyTooltipMaxWidth="25.2rem"
      />
    )
  }

  const renderMilestoneGroup = ({
    title,
    titleMode = 'dark',
    contentMode = 'dark',
    milestones,
    isEditable,
    displayZeroState,
    zeroStateType,
    progressColor,
  }) => {
    if (!milestones.length) {
      return displayZeroState ? (
        <MilestoneZeroStateWrapper>
          <MilestoneWrapper>
            <TitleWrapper>
              <Subtitle mode={titleMode}>{title}</Subtitle>
            </TitleWrapper>
            <ZeroState
              callToActionText={intl.messages['metrics.addMilestone']}
              type={zeroStateType}
              callToAction={() =>
                history.push(`${metricsRoute}/${metric.id}/set-milestone`)
              }
              isCallToActionPrimaryStyle
              padding="0rem 4.8rem"
            />
          </MilestoneWrapper>
        </MilestoneZeroStateWrapper>
      ) : null
    }

    return (
      <ShowMetricMilestoneWrapper>
        <TitleWrapper>
          <Subtitle mode={titleMode}>{title}</Subtitle>
        </TitleWrapper>
        {milestones.map((milestone) => (
          <MilestoneWrapper key={milestone.id}>
            <Milestone
              milestone={milestone}
              metric={metric}
              isEditable={isEditable}
              mode={contentMode}
              progressColor={progressColor}
            />
          </MilestoneWrapper>
        ))}
      </ShowMetricMilestoneWrapper>
    )
  }

  return (
    <>
      {renderMilestoneGroup({
        title: <FormattedMessage id="metrics.activeMilestones" />,
        milestones: activeMilestones,
        isEditable: true,
        displayZeroState: true,
        zeroStateType: ZeroStateType.ACTIVE_MILESTONES,
      })}
      {renderMilestoneGroup({
        title: <FormattedMessage id="metrics.createdByInvestorMilestones" />,
        titleMode: 'light',
        contentMode: 'light',
        milestones: investorsMilestones,
        progressColor: 'purple',
      })}
      {renderMilestoneGroup({
        title: <FormattedMessage id="metrics.reachedMilestones" />,
        contentMode: 'light',
        milestones: reachedMilestones,
        progressColor: 'green',
      })}
      {renderMilestoneGroup({
        title: <FormattedMessage id="metrics.expiredMilestones" />,
        contentMode: 'light',
        milestones: expiredMilestones,
        progressColor: 'lightGray',
      })}
    </>
  )
}

ShowMetricMilestones.propTypes = {
  metric: PropTypes.object.isRequired,
}

export default ShowMetricMilestones
