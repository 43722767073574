import { useParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { MetricFrequency } from 'utils/constants/metrics'
import useMetricQuery from 'utils/hooks/useMetricQuery'
import { useEditInvestorMetricQuery } from 'utils/hooks/queries/useMetrics'
import { IFormContext } from 'components/Form/hooks'
import { getEditInvestorMetricSchema } from 'utils/schemas/metrics'

export interface EditInvestorMetricFormType extends IFormContext {
  name: string
  frequency: string
}

interface Props {
  closeDrawer: () => void
}

interface GetInitialValuesProps {
  metric: any
}

const getInitialValues = ({
  metric,
}: GetInitialValuesProps): EditInvestorMetricFormType => {
  const defaultValues = {
    name: '',
    frequency: MetricFrequency.NONE,
  }

  if (metric) {
    defaultValues.name = metric.name
    defaultValues.frequency = !metric.frequency
      ? MetricFrequency.NONE
      : metric.frequency
  }

  return defaultValues
}

const useEditInvestorMetric = ({ closeDrawer }: Props) => {
  const intl = useIntl()
  const { id: metricId } = useParams<{ id: string }>()
  const { metric, isLoading } = useMetricQuery({
    metricId,
  })

  const initialValues = getInitialValues({ metric })
  const validationSchema = getEditInvestorMetricSchema(intl)

  const { mutate: editMetricMutation, isLoading: isEditingMetric } =
    useEditInvestorMetricQuery({
      closeDrawer,
      metricId,
    })

  return {
    initialValues,
    isLoading,
    metric,
    validationSchema,
    editMetricMutation,
    isEditingMetric,
  }
}

export default useEditInvestorMetric
