import Card from 'components/Card'
import useInvestorDetails from 'containers/InvestorManagement/InvestorDetails/useInvestorDetails'
import { FormattedMessage, useIntl } from 'react-intl'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useHistory } from 'react-router-dom'
import { isActingAsFundManager } from 'selectors/auth'
import { InvestmentVehicle } from 'utils/types/investors'
import {
  EditButton,
  HeaderInfoWrapper,
  SideCardWrapper,
  SideInformationWrapper,
} from './CompanyInformation.styles'
import FinancialInfo from './FinancialInformation/FinancialInfo'
import HeaderBasicInformation from './InvestmentVehicleHeader/HeaderBasicInformation'
import HeaderAdditionalInformation from './InvestorHeader/HeaderAdditionalInformation'

interface Props {
  investmentVehicle: InvestmentVehicle
}

const InvestmentVehicleInformation = ({ investmentVehicle }: Props) => {
  const intl = useIntl()
  const history = useHistory()
  const { investor } = useInvestorDetails(investmentVehicle.investorGroup.id)
  const isFundManager = useAppSelector(isActingAsFundManager)

  const onEditInvestmentVehicleClickHandler = () => {
    const editInvestmentVehicleUrl = isFundManager
      ? `/investor-management/vehicles/${investmentVehicle.id}/profile/edit`
      : `/investment-vehicles/${investmentVehicle.id}/edit`
    history.push(editInvestmentVehicleUrl, {
      investmentVehicle,
    })
  }

  return (
    <SideInformationWrapper>
      <HeaderInfoWrapper>
        <HeaderBasicInformation investmentVehicle={investmentVehicle} />

        {!!investor && (
          <HeaderAdditionalInformation
            isShowingInvestmentVehicle
            investor={investor}
          />
        )}

        <EditButton secondary onClick={onEditInvestmentVehicleClickHandler}>
          <FormattedMessage id="investorManagement.profiles.edit" />
        </EditButton>
      </HeaderInfoWrapper>

      {investmentVehicle.financialInformation && (
        <SideCardWrapper>
          <Card isExpandable padding="1.4rem 2.4rem">
            <Card.Header>
              {intl.formatMessage({
                id: 'investorManagement.list.extended.financialInformation',
              })}
            </Card.Header>
            <Card.Body>
              <FinancialInfo
                financialInformation={investmentVehicle.financialInformation}
              />
            </Card.Body>
          </Card>
        </SideCardWrapper>
      )}
    </SideInformationWrapper>
  )
}

export default InvestmentVehicleInformation
