import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts'

import { displayThousands } from 'utils/functions/number'
import { DataPoint } from 'utils/types/metrics'
import { BreakPointName } from 'utils/constants/breakpoint'
import { useCurrentBreakpoint } from 'utils/hooks/useMediaQuery'
import { useDataPoints } from 'utils/hooks/useDataPoints'
import theme, { Color } from 'utils/theme'

import CustomTooltip from './CustomTooltip'

import useMetricChart, { XAxisPadding, LineChartMargin } from './useMetricChart'

import { ChartPagination } from './MetricChart.styles'
import CustomTick from './CustomTick'

interface MetricChartProps {
  dataPoints: DataPoint[]
  breakpoint?: BreakPointName
  xAxisPadding?: XAxisPadding
  lineChartMargin?: LineChartMargin
  visibleMetricsLimit?: Record<BreakPointName, number>
  calculateMarginLeft?: boolean
  color?: Color
}

const MetricChart: React.FC<MetricChartProps> = ({
  dataPoints: dataPointsProp,
  breakpoint: breakpointProp,
  xAxisPadding: xAxisPaddingProp,
  lineChartMargin: lineChartMarginProp,
  visibleMetricsLimit,
  calculateMarginLeft = false,
  color,
}) => {
  const currentBreakpoint = useCurrentBreakpoint() as BreakPointName
  const breakpoint = breakpointProp || currentBreakpoint
  const { dataPoints } = useDataPoints({ dataPoints: dataPointsProp })

  const {
    visibleMetrics,
    hasNext,
    hasPrevious,
    lineChartMargin,
    xAxisPadding: defaultXAxisPadding,
    yAxisDomain,
    yTicks,
    chartOpacity,
    onPreviousPage,
    onNextPage,
    setIsChartHovered,
  } = useMetricChart({
    dataPoints,
    breakpoint,
    visibleMetricsLimit,
    lineChartMargin: lineChartMarginProp,
    calculateMarginLeft,
  })

  const xAxisPadding = xAxisPaddingProp || defaultXAxisPadding
  const strokeColor = theme.colors[color ?? 'brightBlue']

  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={visibleMetrics}
          margin={{ ...lineChartMargin }}
          onMouseMove={({ isTooltipActive }) => {
            setIsChartHovered(!!isTooltipActive)
          }}
        >
          <CartesianGrid stroke={theme.colors.veryLightGray} vertical={false} />

          <Line
            dataKey="value"
            dot={false}
            stroke={strokeColor}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            type="linear"
            strokeOpacity={chartOpacity}
          />

          <XAxis
            tickMargin={8}
            axisLine={false}
            dataKey="date"
            tick={CustomTick}
            tickLine={false}
            padding={{ ...xAxisPadding }}
            height={40}
            interval={0}
          />

          <YAxis
            dataKey="value"
            tickLine={false}
            axisLine={false}
            tickFormatter={displayThousands}
            domain={yAxisDomain}
            ticks={yTicks}
            width={50}
          />

          <Tooltip
            offset={-50}
            allowEscapeViewBox={{ x: true, y: true }}
            content={<CustomTooltip />}
            wrapperStyle={{
              borderRadius: '0.8rem',
              outline: 'none',
            }}
          />
        </LineChart>
      </ResponsiveContainer>
      <ChartPagination
        hideButtonsInsteadOfDisabling
        isNextDisabled={!hasNext}
        isPreviousDisabled={!hasPrevious}
        isVisible={hasNext || hasPrevious}
        isButtonRounded={false}
        onNextClick={onNextPage}
        onPreviousClick={onPreviousPage}
      />
    </>
  )
}

export default MetricChart
