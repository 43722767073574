import dayjs from 'dayjs'
import { QueryClient } from '@tanstack/react-query'
import type { DateCell, NumberCell } from '@silevis/reactgrid'
import type { IntlShape } from 'react-intl'
import {
  MetricCell,
  UNKNOWN_METRIC_ID,
} from 'components/Spreadsheet/CellTemplates/MetricCellTemplate'
import MetricsService, {
  CreateMetricResponse,
  DataPointDTO,
} from 'api/MetricsService'
import { metricsKeys } from 'utils/queries/metrics'
import { Nullable } from 'utils/types/common'
import { companyKeys } from 'utils/queries/companies'
import { dispatchEvent } from 'utils/hooks/useEventListener'
import {
  HoldingCell,
  UNKNOWN_HOLDING_ID,
} from 'components/TransactionsSpeadsheetImportModal/components/CustomHoldingCellTemplate'
import { getNumberCellValue } from 'components/Spreadsheet/utils'
import { RowNumberCell } from 'components/Spreadsheet/types'
import { GridType, MetricsSpreadsheetEvents } from './useUpdateMetricsGrid'
import { MetricsSpreadsheetMode } from './MetricsSpreadsheetLogic'

const METRIC_REQUIRED_ERROR = 'spreadsheet.metrics.errors.metricRequired'

export const createMetric = async (
  metricName: string,
  isFounder: boolean,
  companyId?: string
): Promise<CreateMetricResponse> => {
  if (isFounder) {
    return MetricsService.createFounderMetric({
      name: metricName,
      frequency: undefined,
      sharedGroups: undefined,
    })
  }

  const { metrics: metricsCreated } = await MetricsService.createInvestorMetric(
    {
      name: metricName,
      companiesOrPortfolios: [{ id: companyId }],
    }
  )

  return metricsCreated[0]
}

interface BulkCreate {
  metricId: string
  metricName: string
  dataPoints: DataPointDTO[]
  companyId?: string
}

export class MetricsSpreadsheetService {
  private mode: MetricsSpreadsheetMode

  private metricColumnIndex: number

  private holdingColumnIndex: number

  private companyId?: string

  private isFounder: boolean

  private founderCompanyId: Nullable<string>

  private intl: IntlShape

  private queryClient: QueryClient

  private constructor(
    mode: MetricsSpreadsheetMode,
    isFounder: boolean,
    metricColumnIndex: number,
    holdingColumnIndex: number,
    founderCompanyId: Nullable<string>,
    intl: IntlShape,
    queryClient: QueryClient
  ) {
    this.mode = mode
    this.metricColumnIndex = metricColumnIndex
    this.holdingColumnIndex = holdingColumnIndex
    this.isFounder = isFounder
    this.founderCompanyId = founderCompanyId
    this.intl = intl
    this.queryClient = queryClient
  }

  static forMultipleHoldings = (
    isFounder: boolean,
    metricColumnIndex: number,
    holdingColumnIndex: number,
    founderCompanyId: Nullable<string>,
    intl: IntlShape,
    queryClient: QueryClient
  ) => {
    return new MetricsSpreadsheetService(
      MetricsSpreadsheetMode.MULTIPLE_HOLDINGS,
      isFounder,
      metricColumnIndex,
      holdingColumnIndex,
      founderCompanyId,
      intl,
      queryClient
    )
  }

  static forSingleHolding = (
    companyId: string,
    isFounder: boolean,
    metricColumnIndex: number,
    holdingColumnIndex: number,
    founderCompanyId: Nullable<string>,
    intl: IntlShape,
    queryClient: QueryClient
  ) => {
    const service = new MetricsSpreadsheetService(
      MetricsSpreadsheetMode.SINGLE_HOLDING,
      isFounder,
      metricColumnIndex,
      holdingColumnIndex,
      founderCompanyId,
      intl,
      queryClient
    )
    service.companyId = companyId
    return service
  }

  createMissingMetrics = async (bulks: BulkCreate[]) => {
    let newMetricsCreated = false

    // eslint-disable-next-line no-restricted-syntax
    for (const bulk of bulks) {
      if (bulk.metricId === UNKNOWN_METRIC_ID) {
        newMetricsCreated = true
        // eslint-disable-next-line no-await-in-loop
        const { id } = await createMetric(
          bulk.metricName,
          this.isFounder,
          bulk.companyId
        )
        bulk.metricId = id
      }
    }

    return newMetricsCreated
  }

  validateSpreadsheet = (spreadsheet: GridType) => {
    let valid = true

    for (let i = 1; i < spreadsheet.length; i++) {
      if (!this.validateRow(spreadsheet, i)) {
        valid = false
      }
    }

    return valid
  }

  validateRow = (spreadsheet: GridType, rowIndex: number): boolean => {
    const metricCell = spreadsheet[rowIndex][
      this.metricColumnIndex
    ] as MetricCell
    const hasMetricValues = spreadsheet[rowIndex]
      .slice(this.metricColumnIndex + 1)
      .some((_cell, columnIndex) =>
        getNumberCellValue(spreadsheet, rowIndex, columnIndex)
      )

    if (this.mode === MetricsSpreadsheetMode.MULTIPLE_HOLDINGS) {
      const holdingCell = spreadsheet[rowIndex][
        this.holdingColumnIndex
      ] as HoldingCell

      const validMetric = !!metricCell.metric
      const validHolding =
        !!holdingCell.holding && holdingCell.holding.id !== UNKNOWN_HOLDING_ID
      const hasHoldingAndMetric = validMetric && validHolding
      const isEmpty =
        !hasMetricValues && !metricCell.metric && !holdingCell.holding

      const isValid = hasHoldingAndMetric || isEmpty

      if (!isValid) {
        const rowNumberCell = spreadsheet[rowIndex][0] as RowNumberCell

        if (!holdingCell.holding) {
          holdingCell.error = METRIC_REQUIRED_ERROR
          rowNumberCell.errors.add(this.holdingColumnIndex)
        }

        if (!metricCell.metric) {
          metricCell.error = 'spreadsheet.metrics.errors.metricRequired'
          rowNumberCell.errors.add(this.metricColumnIndex)
        }
      }

      return isValid
    }

    const isEmpty = !hasMetricValues && !metricCell.metric
    const isValid = !!metricCell.metric || isEmpty

    if (!isValid) {
      const rowNumberCell = spreadsheet[rowIndex][0] as RowNumberCell

      if (!metricCell.metric) {
        metricCell.error = METRIC_REQUIRED_ERROR
        rowNumberCell.errors.add(this.metricColumnIndex)
      }
    }

    return isValid
  }

  uploadValues = async (spreadsheet: GridType): Promise<boolean> => {
    if (spreadsheet) {
      const bulks: BulkCreate[] = []

      const isValidSpreadsheet = this.validateSpreadsheet(spreadsheet)

      if (!isValidSpreadsheet) {
        dispatchEvent(MetricsSpreadsheetEvents.SPREADSHEET_WITH_ERRORS, {
          spreadsheet,
        })
        throw new Error(
          this.intl.formatMessage({
            id: 'spreadsheet.metrics.errors.youHaveErrors',
          })
        )
      }

      for (let i = 1; i < spreadsheet.length; i++) {
        const { metric } = spreadsheet[i][this.metricColumnIndex] as MetricCell

        if (metric) {
          const metricId = metric.id
          const metricName = metric.name
          const dataPoints: DataPointDTO[] = []

          for (let j = 1; j < spreadsheet[i].length; j++) {
            const { value } = spreadsheet[i][j] as NumberCell
            const { date } = spreadsheet[0][j] as DateCell

            if (!Number.isNaN(value) && date) {
              if (dayjs(date).isAfter(new Date(), 'day')) {
                throw new Error(
                  this.intl.formatMessage({
                    id: 'metrics.update.noFutureDatesAllowed',
                  })
                )
              }

              dataPoints.push({
                date,
                value: value.toString(),
                sharedGroups: metric.receiverGroups?.map((group) => group.id),
              })
            }
          }

          if (dataPoints.length > 0 || metricId === UNKNOWN_METRIC_ID) {
            const bulk: BulkCreate = {
              metricId,
              metricName,
              dataPoints,
            }

            if (this.mode === MetricsSpreadsheetMode.MULTIPLE_HOLDINGS) {
              const { holding } = spreadsheet[i][
                this.holdingColumnIndex
              ] as HoldingCell
              bulk.companyId = holding?.id
            }

            if (this.mode === MetricsSpreadsheetMode.SINGLE_HOLDING) {
              bulk.companyId = this.companyId
            }

            bulks.push(bulk)
          }
        }
      }

      const newMetricsCreated = await this.createMissingMetrics(bulks)

      // eslint-disable-next-line no-restricted-syntax
      for (const bulk of bulks) {
        if (bulk.dataPoints.length > 0) {
          // eslint-disable-next-line no-await-in-loop
          await MetricsService.bulkCreateDataPoints(
            bulk.metricId,
            bulk.dataPoints
          )

          this.queryClient.invalidateQueries(
            metricsKeys.getMetric(bulk.metricId)
          )
        }
      }

      if (newMetricsCreated) {
        this.queryClient.invalidateQueries(metricsKeys.getInvestorMetrics())

        if (this.founderCompanyId) {
          this.queryClient.invalidateQueries(
            companyKeys.companyMetrics(this.founderCompanyId)
          )
        }
      }
    }

    return true
  }
}
