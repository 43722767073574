import { useIntl } from 'react-intl'
import { Formik } from 'formik'

import Form from 'components/Form'
import Button from 'ui/Button'
import Drawer from 'components/Drawer'
import CWLoader from 'components/CWLoader/CWLoader'

import { LoaderWrapper } from 'containers/Metrics/EditMetric/EditMetric.styles'
import CreateFounderMetricForm from './CreateFounderMetricForm'
import useCreateFounderMetric, {
  CreateFounderMetricFormType,
} from './useCreateFounderMetric'

interface Props {
  isOpen: boolean
  onCancel: () => void
}

const CreateFounderMetric = ({ onCancel, isOpen }: Props) => {
  const intl = useIntl()

  const {
    initialValues,
    validationSchema,
    isCreatingMetric,
    createMetricMutation,

    currentGroups,
    groupSearchText,
    onSelectGroup,
    handleRemoveGroup,
    loadGroups,

    itemLists,
    handleAddItemList,
    handleDeleteItemList,
    handleEditItemList,

    getSubOptionById,
  } = useCreateFounderMetric({
    closeDrawer: onCancel,
  })

  if (!initialValues) {
    return (
      <LoaderWrapper>
        <CWLoader />
      </LoaderWrapper>
    )
  }

  return (
    <Drawer
      isOpen={isOpen}
      onCloseDrawer={onCancel}
      headerTitle={intl.formatMessage({ id: 'metrics.addMetric' })}
    >
      <Formik<CreateFounderMetricFormType>
        initialValues={initialValues}
        onSubmit={(values) => createMetricMutation(values)}
        validationSchema={validationSchema}
        validateOnMount
      >
        {({ submitForm, isValid }) => (
          <Form<CreateFounderMetricFormType> {...initialValues}>
            <Drawer.Content contentPadding="2rem 2.4rem">
              <CreateFounderMetricForm
                onSelectGroup={onSelectGroup}
                currentGroups={currentGroups}
                handleRemoveGroup={handleRemoveGroup}
                loadGroups={loadGroups}
                groupSearchText={groupSearchText}
                itemLists={itemLists}
                handleAddItemList={handleAddItemList}
                handleDeleteItemList={handleDeleteItemList}
                handleEditItemList={handleEditItemList}
                getSubOptionById={getSubOptionById}
              />
            </Drawer.Content>

            <Drawer.Footer>
              <Button
                secondary
                type="button"
                onClick={onCancel}
                disabled={isCreatingMetric}
              >
                {intl.formatMessage({ id: 'metrics.cancel' })}
              </Button>

              <Button
                primary
                type="submit"
                disabled={!isValid || isCreatingMetric}
                onClick={submitForm}
              >
                {intl.formatMessage({ id: 'metrics.save' })}
              </Button>
            </Drawer.Footer>
          </Form>
        )}
      </Formik>
    </Drawer>
  )
}

export default CreateFounderMetric
