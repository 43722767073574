import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import IconButton from 'ui/IconButton'
import { Metric, Milestone } from 'utils/types/metrics'
import ShowInChart from 'containers/Metrics/ShowInChart'
import { DisplayMode } from 'utils/constants/metrics'
import { getDropdownOptionsForStatus } from 'utils/functions/metrics'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import { maxSize } from 'utils/constants/breakpoint'
import { useMetricsSandbox } from '../../useMetricsSandbox'

import * as Styles from './MetricCards.styles'

interface Props {
  metric: Metric
  displayMode: DisplayMode
  activeMilestones: string[]
  redirectToLogs: (metricId: string) => void
  onRemoveMetric: (metric: Metric) => void
  onAddNewValue: (metric: Metric) => void
  onEditMetric: (metric: Metric) => void
  onSetMilestone: (metric: Metric) => void
  onViewMetricDetail: (metricId: string) => void
  onImportCsv: (metric: Metric) => void
  exportMetric: (metric: Metric) => Promise<void>
  onEditMilestone: (metric: Metric, milestone: Milestone) => void
  onSelectDisplayMode: (displayMode: DisplayMode) => void
}

const SelectedMetricActions = ({
  metric,
  displayMode,
  activeMilestones,
  redirectToLogs,
  onRemoveMetric,
  onEditMetric,
  onAddNewValue,
  onSetMilestone,
  onEditMilestone,
  onViewMetricDetail,
  onImportCsv,
  exportMetric,
  onSelectDisplayMode,
}: Props) => {
  const intl = useIntl()
  const { matches: isMobile } = useMediaQuery(maxSize.xsm)
  const { changeMetricVisibility, toggleMetricInSandbox } = useMetricsSandbox()

  const isEmpty = useMemo(
    () => (metric?.dataPoints.filter((dp) => !dp.archive) || []).length === 0,
    [metric?.dataPoints]
  )

  return (
    <Styles.ActionsWrapper>
      {!isEmpty && (
        <>
          <ShowInChart
            metric={metric}
            onClick={() => changeMetricVisibility(metric.id)}
          />

          <Styles.VerticalSeparator isMobile={isMobile} />

          <IconButton
            border={false}
            isActive={displayMode === DisplayMode.CHART}
            buttonSize="3.2rem"
            minSize="3.2rem"
            icon="chart-mixed"
            iconFamily="fal"
            buttonType="square"
            onClick={() => onSelectDisplayMode(DisplayMode.CHART)}
          />

          <IconButton
            border={false}
            isActive={displayMode === DisplayMode.VALUE}
            buttonSize="3.2rem"
            minSize="3.2rem"
            icon="list-ul"
            iconFamily="fal"
            buttonType="square"
            onClick={() => onSelectDisplayMode(DisplayMode.VALUE)}
          />

          <Styles.OptionsDropdown
            buttonType="square"
            alignSelf="flex-start"
            buttonSize="3.2rem"
          >
            {getDropdownOptionsForStatus({
              intl,
              metricData: metric,
              activeMilestones,
              onRemoveMetric,
              onAddNewValue,
              onEditMetric,
              onSetMilestone,
              onEditMilestone,
              onViewMetricDetail,
              redirectToLogs,
              onImportCsv,
              onExportCsv: exportMetric,
            })}
          </Styles.OptionsDropdown>
        </>
      )}

      <IconButton
        border={false}
        buttonSize="3.2rem"
        minSize="3.2rem"
        icon="close"
        iconFamily="fal"
        buttonType="square"
        onClick={() => toggleMetricInSandbox(metric)}
      />
    </Styles.ActionsWrapper>
  )
}

export default SelectedMetricActions
