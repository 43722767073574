import { SortDirection } from 'utils/constants'
import { MetricSortBy } from 'utils/constants/metrics'

export const QUERIES = {
  METRIC: 'metric',
  PERFORMANCE_METRICS: 'performance_metrics',
  PENDING_INVITATIONS: 'pending_invitations',
  INVITATIONS_HISTORY: 'invitations_history',
  INVESTOR_METRICS: 'investor_metrics',
  FOUNDER_METRICS: 'founder_metrics',
} as const

export type MetricsFilters = {
  metricName?: string
  sortBy?: MetricSortBy
  sortDirection?: SortDirection
  companyDatumIds?: string[]
  companyDatumIdsToExclude?: string[]
  selected?: boolean
  selectedUserPortfolioId?: string
}

export const metricsKeys = {
  getMetric: (metricId?: string) =>
    metricId
      ? ([QUERIES.METRIC, metricId] as const)
      : ([QUERIES.METRIC] as const),
  getPerformanceMetrics: (portfolioId: string) =>
    [QUERIES.PERFORMANCE_METRICS, portfolioId] as const,
  getPendingInvitations: () => [QUERIES.PENDING_INVITATIONS] as const,
  getInvitationsHistory: () => [QUERIES.INVITATIONS_HISTORY] as const,
  getInvestorMetrics: (filters?: MetricsFilters) =>
    filters
      ? ([QUERIES.INVESTOR_METRICS, filters] as const)
      : ([QUERIES.INVESTOR_METRICS] as const),
  getFounderMetrics: (filters?: MetricsFilters) =>
    [QUERIES.FOUNDER_METRICS, filters] as const,
}
