import { useLocation } from 'react-router-dom'
import { MetricFrequency } from 'utils/constants/metrics'
import { getInvestorMetricsSchema } from 'utils/schemas/metrics'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getCurrentGroupId } from 'selectors/auth'
import { CompanyHoldingData } from 'utils/types/company'
import { getSharedMetricCompany } from 'utils/functions/metrics'
import { EntityType } from 'utils/constants/groups'
import { IFormContext } from 'components/Form/hooks'
import { useIntl } from 'react-intl'
import { useCreateInvestorMetricQuery } from 'utils/hooks/queries/useMetrics'

export interface CreateInvestorMetricFormType extends IFormContext {
  name: string
  frequency: string
  companiesOrPortfolios: {
    id: string
    name: string
  }[]
  requestDataFromCompanies: boolean
  message?: string
}

const getInitialValues = (
  companyToAdd?: CompanyHoldingData,
  linkedMetric?: any
) => {
  const defaultValues = {
    name: '',
    frequency: MetricFrequency.NONE,
    companiesOrPortfolios: companyToAdd ? [companyToAdd] : [],
    requestDataFromCompanies: true,
    message: '',
  }

  if (linkedMetric) {
    const groupRequestingMetric = linkedMetric?.receiverGroup
    defaultValues.name =
      linkedMetric?.receiverMetric?.name ||
      linkedMetric?.senderMetric?.name ||
      ''
    defaultValues.frequency =
      linkedMetric?.receiverMetric?.frequency ||
      linkedMetric?.senderMetric?.frequency ||
      MetricFrequency.NONE

    const metricCompany = getSharedMetricCompany(linkedMetric)

    defaultValues.companiesOrPortfolios = [
      {
        id: groupRequestingMetric?.id || metricCompany?.id,
        name: groupRequestingMetric?.name || metricCompany?.name,
        type: EntityType.COMPANY,
        primaryLogo: {
          url:
            groupRequestingMetric?.logo?.url || metricCompany?.primaryLogo?.url,
        } as any,
      } as any,
    ]
  }

  return defaultValues
}

export const useCreateInvestorMetric = (closeDrawer: () => void) => {
  const intl = useIntl()
  const { state } = useLocation<{
    linkedMetric?: any
    companyToAdd?: CompanyHoldingData
  }>()

  const initialValues = getInitialValues(
    state?.companyToAdd,
    state?.linkedMetric
  )

  const validationSchema = getInvestorMetricsSchema(intl)
  const currentGroupId = useAppSelector(getCurrentGroupId)
  const isCreatingMetricFromSharedMetric = !!state?.linkedMetric

  const { mutate: createInvestorMetric, isLoading: isCreatingMetric } =
    useCreateInvestorMetricQuery({
      closeDrawer,
      isCreatingMetricFromSharedMetric,
      linkedMetric: state?.linkedMetric,
      currentGroupId,
    })

  return {
    initialValues,
    validationSchema,
    createInvestorMetric,
    isCreatingMetric,
  }
}
