import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getInitials } from 'utils/functions/user'
import { LoggingType } from 'utils/constants/eventLogs'

import { StyledLogAvatar, LogIcon } from './Logs.styles'

const getIcon = (logOperationType, targetObjectType) => {
  switch (logOperationType) {
    case 'create':
      if (targetObjectType === LoggingType.METRIC_LINKED) {
        return ['fal', 'share']
      }
      return ['fal', 'plus']

    case 'delete':
      return ['fal', 'trash-alt']

    case 'update':
      if (targetObjectType === LoggingType.METRIC_LINKED) {
        return ['fal', 'share']
      }
      return ['fal', 'arrow-alt-up']

    default:
      return ['fal', 'arrow-alt-up']
  }
}

const LogAvatar = ({
  logOperationType,
  targetObjectType,
  userName,
  color,
  text,
}) => {
  const redIcon =
    targetObjectType === LoggingType.METRIC_LINKED &&
    (text?.includes('share_denied') ||
      text?.includes('request_denied') ||
      text?.includes('unshared'))

  const blueIcon =
    targetObjectType === LoggingType.METRIC_LINKED &&
    (text?.includes('share_sent') || text?.includes('request_sent'))

  return (
    <StyledLogAvatar
      backgroundColor={color}
      blueIcon={blueIcon}
      redIcon={redIcon}
    >
      {getInitials(userName)}
      <LogIcon type={logOperationType}>
        <FontAwesomeIcon
          icon={getIcon(logOperationType, targetObjectType, text)}
        />
      </LogIcon>
    </StyledLogAvatar>
  )
}

LogAvatar.defaultProps = {
  text: '',
}

LogAvatar.propTypes = {
  color: PropTypes.string.isRequired,
  logOperationType: PropTypes.string.isRequired,
  targetObjectType: PropTypes.string.isRequired,
  text: PropTypes.string,
  userName: PropTypes.string.isRequired,
}

export default LogAvatar
