import { ChangeEvent, useCallback, useMemo, useRef, useState } from 'react'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'

import useOutsideClick from 'utils/hooks/useOutsideClick'
import { useMetricsQuery } from 'utils/hooks/queries/useMetrics'
import { useDebouncedState } from 'utils/hooks/useDebouncedState'
import { MetricsSelectorFilters } from './MetricsSelector'

enum MetricsSelectorMode {
  FOCUSED = 'focused',
  IDLE = 'idle',
  OPEN = 'open',
}

interface UseMetricsSelectorProps {
  filters?: MetricsSelectorFilters
}

export const useMetricsSelector = ({ filters }: UseMetricsSelectorProps) => {
  const [search, debouncedSearch, setSearch] = useDebouncedState('')
  const {
    metrics: metricPages,
    isLoading,
    fetchNextMetricsPage,
  } = useMetricsQuery({
    metricName: debouncedSearch,
    ...filters,
  })
  const [selectorMode, setSelectorMode] = useState(MetricsSelectorMode.IDLE)
  const inputRef = useRef<HTMLInputElement>(null)
  const containerRef = useRef<HTMLDivElement>(null)

  const onClickOutside = useCallback(() => {
    setSelectorMode(MetricsSelectorMode.IDLE)
    setSearch('')
    inputRef.current?.blur()
  }, [setSearch])

  const iconConfig: { icon: IconProp; size?: string } = useMemo(() => {
    switch (selectorMode) {
      case MetricsSelectorMode.FOCUSED:
        return { icon: ['fal', 'search'] }
      case MetricsSelectorMode.OPEN:
        if (search.length > 0) {
          return { icon: ['fal', 'times'] }
        }
        return { icon: ['fal', 'search'] }
      case MetricsSelectorMode.IDLE:
      default:
        return { icon: ['fal', 'plus'], size: '1.8rem' }
    }
  }, [search.length, selectorMode])

  const metrics = useMemo(() => {
    return metricPages?.pages.flatMap((page) => page.data) || []
  }, [metricPages])

  const onIconClick = useCallback(() => {
    if (search.length > 0) {
      setSearch('')
      inputRef.current?.focus()
    }
  }, [search.length, setSearch])

  const onFocus = useCallback(() => {
    setSelectorMode(MetricsSelectorMode.FOCUSED)
  }, [])

  const onChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setSearch(e.target.value)
      setSelectorMode(MetricsSelectorMode.OPEN)
    },
    [setSearch]
  )

  useOutsideClick(containerRef, onClickOutside)

  return {
    metrics,
    iconConfig,
    search,
    containerRef,
    inputRef,
    showMenu: selectorMode === MetricsSelectorMode.OPEN,
    isLoading,
    onFocus,
    onChange,
    onClickOutside,
    fetchNextMetricsPage,
    onIconClick,
  }
}
