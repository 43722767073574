/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useInvalidateMetricsInvitationsQueries } from 'utils/hooks/metrics/useMetricsInvitationsQueries'
import { FormattedMessage, useIntl } from 'react-intl'
import { useParams, useLocation } from 'react-router-dom'
import Drawer from 'components/Drawer'

import Toast from 'components/Toast'
import Button from 'ui/Button'
import MetricService from 'api/MetricsService'
import Dropdown from 'ui/Dropdown'
import Checkbox from 'components/Checkbox'
import Separator from 'ui/Separator'
import { dispatchEvent } from 'utils/hooks/useEventListener'
import { metricsKeys } from 'utils/queries/metrics'

import { CTA, CheckboxWrapper } from './AddGroupToExistingMetric.styles'

const AddGroupToExistingMetric = ({ closeDrawer, isOpen }) => {
  const queryClient = useQueryClient()
  const { invalidateInvitationsQueries } =
    useInvalidateMetricsInvitationsQueries()
  const [form, setForm] = useState({
    metric: {
      label: '',
      value: '',
    },
    includeHistory: true,
  })
  const intl = useIntl()
  const { linkedMetricId } = useParams()
  const { state } = useLocation()

  const loadMetrics = useCallback(
    async (inputSearch = '') => {
      const metrics = await MetricService.getFounderMetrics({
        params: {
          not_linked_to_investor: state.linkedMetric?.receiverGroupId,
          'q[name_cont]': inputSearch,
        },
      })

      return metrics.map((metric) => ({
        id: metric.id,
        label: metric.name,
      }))
    },
    [state.linkedMetric]
  )

  const onChangeCheckbox = (event) => {
    setForm((currForm) => ({
      ...currForm,
      includeHistory: event?.target?.checked,
    }))
  }

  const onChangeMetric = (_, __, value) => {
    setForm((currForm) => ({ ...currForm, metric: value }))
  }

  const onConfirm = async () => {
    try {
      await MetricService.addGroupToExistingMetric({
        linkedMetricId,
        founderMetricId: form.metric.id,
        includeHistory: form.includeHistory,
      })

      queryClient.invalidateQueries(metricsKeys.getMetric(form.metric.id))
      invalidateInvitationsQueries()
      closeDrawer()
    } catch (error) {
      Toast.display(
        intl.formatMessage({ id: 'metrics.errorLinkingMetric' }),
        'error'
      )
    }
  }

  const disableForm = !form.metric?.id

  return (
    <Drawer
      isOpen={isOpen}
      headerTitle={intl.formatMessage(
        { id: 'metrics.addGroupNameTo' },
        { groupName: state?.linkedMetric?.receiverGroup?.name }
      )}
      onCloseDrawer={closeDrawer}
    >
      <Drawer.Content contentPadding="2rem 2.4rem">
        <Dropdown
          loadOptions={loadMetrics}
          onSelectOption={onChangeMetric}
          label={intl.formatMessage({ id: 'metrics.selectMetric' })}
          placeholder={intl.formatMessage({ id: 'general.select' })}
          async
          type="input"
          getOption={(entity) => ({ label: entity.label, id: entity.id })}
        />
        <Separator space="1.8rem" />

        <CheckboxWrapper>
          <Checkbox
            name="includeHistory"
            onChange={onChangeCheckbox}
            label={intl.formatMessage({ id: 'metrics.includeHistoryData' })}
            checked={form.includeHistory}
          />
        </CheckboxWrapper>
      </Drawer.Content>
      <Drawer.Footer>
        <CTA>
          <Button onClick={closeDrawer}>
            {intl.formatMessage({ id: 'common.cancel' })}
          </Button>

          <Button
            autoWidth
            primary
            blue
            onClick={onConfirm}
            disabled={disableForm}
          >
            {intl.formatMessage({ id: 'common.save' })}
          </Button>
        </CTA>
      </Drawer.Footer>
    </Drawer>
  )
}

AddGroupToExistingMetric.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
}

export default AddGroupToExistingMetric
