import { useCallback, useMemo, useRef } from 'react'
import { useInfiniteQuery, useQueryClient } from '@tanstack/react-query'
import type { InfiniteData } from '@tanstack/react-query'
import MetricsService from 'api/MetricsService'
import Toast from 'components/Toast'
import { METRICS_PAGE_SIZE, MetricStates } from 'utils/constants/metrics'
import { metricsKeys } from 'utils/queries/metrics'
import { LinkedMetric } from 'utils/types/metrics'
import {
  getCurrentGroupId,
  isActingAsClient,
  isActingAsFounder,
  isActingAsFundManager,
} from 'selectors/auth'
import { useAppSelector } from '../reduxToolkit'

type LinkedMetricsResponse = {
  data: LinkedMetric[]
  page: number
}

const fetchLinkedMetrics = async ({
  page,
  status,
  receiverGroupId,
  senderGroupId,
}: {
  page: number
  status: MetricStates[]
  receiverGroupId?: string
  senderGroupId?: string
}): Promise<LinkedMetricsResponse> => {
  const reqMetrics = await MetricsService.getLinkedMetrics({
    page,
    pageSize: METRICS_PAGE_SIZE,
    status,
    receiverGroupId,
    senderGroupId,
  })

  return { data: reqMetrics as LinkedMetric[], page }
}

const mergeAndSortInvitations = (
  requestedMetrics: LinkedMetricsResponse,
  sharedMetricInvitations: LinkedMetricsResponse
): LinkedMetricsResponse => {
  const result = {
    data: [
      ...(requestedMetrics.data || []),
      ...(sharedMetricInvitations.data || []),
    ],
    page: requestedMetrics.page,
  }

  result.data.sort(
    (a, b) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
  )

  return result
}

export const usePendingMetricsInvitationsQuery = (
  setIsPaginationEnabled?: (enabled: boolean) => void
) => {
  const currentGroupId = useAppSelector(getCurrentGroupId)
  const isFounder = useAppSelector(isActingAsFounder)
  const isFundManager = useAppSelector(isActingAsFundManager)
  const isClient = useAppSelector(isActingAsClient)

  const {
    data: pendingInvitations,
    fetchNextPage: fetchNextPendingInvitationsPage,
    refetch: refetchPendingInvitations,
    isLoading,
  } = useInfiniteQuery(
    metricsKeys.getPendingInvitations(),
    async ({ pageParam = 1 }) => {
      let requestedMetrics: LinkedMetricsResponse = {
        data: [],
        page: 1,
      }
      let sharedMetricInvitations: LinkedMetricsResponse = {
        data: [],
        page: 1,
      }

      if (isFounder || isFundManager) {
        requestedMetrics = await fetchLinkedMetrics({
          page: pageParam,
          status: [MetricStates.REQUEST_SENT],
          senderGroupId: currentGroupId,
        })
      }

      if (isClient || isFundManager) {
        sharedMetricInvitations = await fetchLinkedMetrics({
          page: pageParam,
          status: [MetricStates.SHARE_SENT],
          receiverGroupId: currentGroupId,
        })
      }

      const result = mergeAndSortInvitations(
        requestedMetrics,
        sharedMetricInvitations
      )

      const requestedMetricsHasMorePages =
        requestedMetrics.data.length === METRICS_PAGE_SIZE
      const sharedMetricInvitationsHasMorePages =
        sharedMetricInvitations.data.length === METRICS_PAGE_SIZE
      setIsPaginationEnabled?.(
        requestedMetricsHasMorePages || sharedMetricInvitationsHasMorePages
      )

      return result
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage.page + 1
      },
      onError: () => {
        Toast.displayIntl('metrics.requestMetricsError', 'error')
      },
    }
  )

  return {
    isLoading,
    pendingInvitations,
    fetchNextPendingInvitationsPage,
    refetchPendingInvitations,
  }
}

export const usePendingMetricsInvitationsCount = () => {
  const { pendingInvitations } = usePendingMetricsInvitationsQuery()
  const invitationsCount = useMemo(
    () => pendingInvitations?.pages?.flatMap((page) => page.data).length,
    [pendingInvitations]
  )

  return invitationsCount
}

export const useMetricsInvitationsHistoryQuery = (
  setIsPaginationEnabled: (enabled: boolean) => void
) => {
  const currentGroupId = useAppSelector(getCurrentGroupId)
  const isFounder = useAppSelector(isActingAsFounder)
  const isFundManager = useAppSelector(isActingAsFundManager)
  const isClient = useAppSelector(isActingAsClient)

  const {
    data: invitationsHistory,
    fetchNextPage: fetchNextInvitationsHistoryPage,
    refetch: refetchInvitationsHistory,
    isLoading,
  } = useInfiniteQuery(
    metricsKeys.getInvitationsHistory(),
    async ({ pageParam = 1 }) => {
      let requestedMetrics: LinkedMetricsResponse = {
        data: [],
        page: 1,
      }

      let sharedMetricInvitations: LinkedMetricsResponse = {
        data: [],
        page: 1,
      }

      if (isFounder || isFundManager) {
        requestedMetrics = await fetchLinkedMetrics({
          page: pageParam,
          status: [MetricStates.REQUEST_ACCEPTED, MetricStates.REQUEST_DENIED],
          senderGroupId: currentGroupId,
        })
      }

      if (isClient || isFundManager) {
        sharedMetricInvitations = await fetchLinkedMetrics({
          page: pageParam,
          status: [MetricStates.SHARE_ACCEPTED],
          receiverGroupId: currentGroupId,
        })
      }

      const result = mergeAndSortInvitations(
        requestedMetrics,
        sharedMetricInvitations
      )

      const requestedMetricsHasMorePages =
        requestedMetrics.data.length === METRICS_PAGE_SIZE
      const sharedMetricInvitationsHasMorePages =
        sharedMetricInvitations.data.length === METRICS_PAGE_SIZE
      setIsPaginationEnabled(
        requestedMetricsHasMorePages || sharedMetricInvitationsHasMorePages
      )

      return result
    },
    {
      getNextPageParam: (lastPage) => {
        return lastPage.page + 1
      },
      onError: () => {
        Toast.displayIntl('metrics.requestMetricsError', 'error')
      },
    }
  )

  return {
    isLoading,
    pendingInvitations: invitationsHistory,
    fetchNextInvitationsHistoryPage,
    refetchInvitationsHistory,
  }
}

export const useInvalidateMetricsInvitationsQueries = () => {
  const queryClient = useQueryClient()

  const invalidateInvitationsQueries = useCallback(() => {
    queryClient.invalidateQueries({
      queryKey: metricsKeys.getPendingInvitations(),
    })
    queryClient.invalidateQueries({
      queryKey: metricsKeys.getInvitationsHistory(),
    })
  }, [queryClient])

  return { invalidateInvitationsQueries }
}

export const useHideMetricInvitation = () => {
  const queryClient = useQueryClient()
  const hiddenInvitations = useRef<Record<string, any>>({})

  const hideMetricInvitation = useCallback(
    (invitationId: string) => {
      queryClient.setQueryData<
        InfiniteData<{
          data: LinkedMetric[]
          page: number
        }>
      >(metricsKeys.getPendingInvitations(), (oldData) => {
        if (!oldData) {
          return oldData
        }

        return {
          ...oldData,
          pages: oldData?.pages?.map((page) => {
            return {
              ...page,
              data: page.data.filter((invitation, index) => {
                if (invitation.id === invitationId) {
                  hiddenInvitations.current = {
                    ...hiddenInvitations.current,
                    [invitationId]: {
                      invitation,
                      page: page.page,
                      index,
                    },
                  }

                  return false
                }

                return true
              }),
            }
          }),
        }
      })
    },
    [queryClient]
  )

  const unhideMetricInvitation = useCallback(
    (invitationId: string) => {
      queryClient.setQueryData<
        InfiniteData<{
          data: LinkedMetric[]
          page: number
        }>
      >(metricsKeys.getPendingInvitations(), (oldData) => {
        if (!oldData) {
          return oldData
        }

        return {
          ...oldData,
          pages: oldData?.pages?.map((page) => {
            if (hiddenInvitations.current[invitationId]?.page === page.page) {
              const data = [...page.data]
              data.splice(
                hiddenInvitations.current[invitationId].index,
                0,
                hiddenInvitations.current[invitationId].invitation
              )
              return {
                ...page,
                data,
              }
            }

            return page
          }),
        }
      })
    },
    [queryClient]
  )

  return { hideMetricInvitation, unhideMetricInvitation }
}
