import type { SizeProp } from '@fortawesome/fontawesome-svg-core'

import { PortfolioTypes } from 'utils/constants/portfolio'
import { getPortfolioIconByType } from 'utils/functions/renderers/renderPortfolioHelper'
import { getInitials } from 'utils/functions/user'
import { Image } from 'utils/types/common'
import { MetricSources } from 'utils/types/metrics'
import * as Styles from './MetricHoldingAvatar.styles'

const SizeConfig = {
  default: {
    size: '2.8rem',
    iconSize: '1x' as SizeProp,
  },
  small: {
    size: '2.1rem',
    iconSize: 'sm' as SizeProp,
  },
}

type MetricHoldingAvatarProps = {
  companyInfo?: {
    primaryLogo?: Image
    name: string
  }
  size?: keyof typeof SizeConfig
  hasPortfolioIconBorder?: boolean
  metricSource?: MetricSources
  portfolioType?: PortfolioTypes
}

const MetricHoldingAvatar = ({
  companyInfo,
  size = 'default',
  metricSource,
  portfolioType,
  hasPortfolioIconBorder = true,
}: MetricHoldingAvatarProps) => {
  const isCalculatedMetric = metricSource === MetricSources.System
  const sizeConfig = SizeConfig[size]

  return isCalculatedMetric ? (
    <Styles.PortfolioIconWrapper
      size={sizeConfig.size}
      hasPortfolioIconBorder={hasPortfolioIconBorder}
    >
      {getPortfolioIconByType(portfolioType!, sizeConfig.iconSize)}
    </Styles.PortfolioIconWrapper>
  ) : (
    <Styles.Avatar
      size={sizeConfig.size}
      image={companyInfo?.primaryLogo?.url}
      initials={getInitials(companyInfo?.name)}
      avatarStyle="avatarCircleLogs"
    />
  )
}

export default MetricHoldingAvatar
