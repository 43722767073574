import { useMemo } from 'react'
import { useIntl } from 'react-intl'
import humps from 'humps'
import { Metric, MetricSources } from 'utils/types/metrics'

export const useMetricName = (metric?: Metric) => {
  const intl = useIntl()
  const metricName = useMemo(() => {
    const isCalculatedMetric = metric?.metricSource === MetricSources.System

    return isCalculatedMetric
      ? intl.formatMessage({
          id: `portfolios.${humps.camelize(metric.name)}`,
        })
      : metric?.name
  }, [intl, metric?.metricSource, metric?.name])

  return { metricName }
}
