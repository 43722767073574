import { useCallback } from 'react'

import {
  SkeletonAvatar,
  SkeletonItem,
  SkeletonItemWrapper,
  SkeletonTableRow,
} from 'components/Skeletons/Skeletons.styles'

import { MetricsTableColumn } from 'containers/Metrics/AllMetrics/useAllMetricsTable'

interface SkeletonTableColumn {
  id: MetricsTableColumn
  size: string
  component: React.ReactNode
}

interface SkeletonAllMetricsTableProps {
  hasHeader: boolean
  visibleColumns: Record<MetricsTableColumn, boolean>
}

const SkeletonAllMetricsTable: React.FC<SkeletonAllMetricsTableProps> = ({
  hasHeader,
  visibleColumns,
}) => {
  const getSkeletonTableColumns = useCallback(
    (isHeader: boolean): SkeletonTableColumn[] =>
      [
        {
          id: 'name' as MetricsTableColumn,
          size: '28.5rem',
          component: (
            <SkeletonItemWrapper
              borderBottom
              height="4.5rem"
              justifyContent="start"
            >
              <SkeletonItem
                width={isHeader ? '8rem' : '1.6rem'}
                height={isHeader ? '1rem' : '1.6rem'}
                borderRadius={isHeader ? '0.9rem' : '0.3rem'}
                margin={
                  isHeader ? 'auto 0 auto 6.5rem' : 'auto 1rem auto 3.5rem'
                }
              />
              {!isHeader && <SkeletonItem width="10rem" height="1rem" />}
            </SkeletonItemWrapper>
          ),
        },
        {
          id: 'portfolioHolding' as MetricsTableColumn,
          size: '23rem',
          component: (
            <SkeletonItemWrapper borderBottom height="4.5rem">
              {!isHeader && (
                <SkeletonAvatar backgroundColor="#F0F2F5" marginLeft="2rem" />
              )}
              <SkeletonItem
                width={isHeader ? '13rem' : '10rem'}
                height="1rem"
                marginLeft={isHeader}
              />
            </SkeletonItemWrapper>
          ),
        },
        {
          id: 'source' as MetricsTableColumn,
          size: '20.8rem',
          component: (
            <SkeletonItemWrapper
              borderBottom
              height="4.5rem"
              justifyContent="start"
            >
              <SkeletonItem
                width={isHeader ? '8rem' : '10rem'}
                height="1rem"
                margin="auto 0 auto 4.6rem"
              />
            </SkeletonItemWrapper>
          ),
        },
        {
          id: 'currentValue' as MetricsTableColumn,
          size: '13.5rem',
          component: (
            <SkeletonItemWrapper
              borderBottom
              height="4.5rem"
              justifyContent="start"
            >
              <SkeletonItem
                width={isHeader ? '8.5rem' : '2.4rem'}
                height="1rem"
                margin="auto 0 auto 5.5rem"
              />
            </SkeletonItemWrapper>
          ),
        },
        {
          id: 'previousValue' as MetricsTableColumn,
          size: 'auto',
          component: (
            <SkeletonItemWrapper
              borderBottom
              height="4.5rem"
              justifyContent="start"
            >
              <SkeletonItem
                width={isHeader ? '8.5rem' : '2.4rem'}
                height="1rem"
                margin="auto 0 auto 4.6rem"
              />
            </SkeletonItemWrapper>
          ),
        },
      ].filter((column) => visibleColumns[column.id]),
    [visibleColumns]
  )

  const columnSizes = getSkeletonTableColumns(true)
    .filter((column) => visibleColumns[column.id])
    .map((column) => column.size)

  return (
    <>
      {hasHeader && (
        <SkeletonTableRow columnSizes={columnSizes}>
          {getSkeletonTableColumns(true).map((column) => column.component)}
        </SkeletonTableRow>
      )}

      {[1, 2, 3].map((rowIndex) => (
        <SkeletonTableRow columnSizes={columnSizes} key={rowIndex}>
          {getSkeletonTableColumns(false).map((column) => column.component)}
        </SkeletonTableRow>
      ))}
    </>
  )
}

export default SkeletonAllMetricsTable
