import { useState } from 'react'
import {
  Route,
  Switch,
  useHistory,
  Redirect,
  useParams,
} from 'react-router-dom'
import CreateMetric from 'containers/Metrics/CreateMetric'
import ShowMetric from 'containers/Metrics/ShowMetric'
import EditMetric from 'containers/Metrics/EditMetric'
import AddMetricValue from 'containers/Metrics/AddMetricValue'
import SetMetricMilestone from 'containers/Metrics/SetMetricMilestone'
import ApproveMetric from 'containers/Metrics/ApproveMetric'
import AddGroupToExistingMetric from 'containers/Metrics/AddGroupToExistingMetric'
import AddMetricToExistingMetric from 'containers/Metrics/AddMetricToExistingMetric'
import ShareDataPoints from 'containers/Metrics/ShareDataPoints'

interface Props {
  urlPrefix?: string
}

const SidebarMetricsRoutes = ({ urlPrefix = '' }: Props) => {
  const [drawerIsOpen, setDrawerIsOpen] = useState(true)
  const history = useHistory()
  const { metricId, type, id } = useParams<{
    metricId?: string
    type?: string
    id?: string
  }>()

  const isInInvestmentsView = urlPrefix.includes('investments')
  const hasPortfolioSelected = metricId && type && id

  const closeDrawer = () => {
    setDrawerIsOpen(false)

    if (isInInvestmentsView && hasPortfolioSelected) {
      history.replace(`/investments/${type}/${id}/metrics`)
      return
    }

    if (urlPrefix.length > 0) {
      history.replace(urlPrefix)
      return
    }

    history.goBack()
  }

  if (history.location.pathname.includes('update-all')) return null
  if (history.location.pathname.endsWith('/metrics/edit')) return null

  return (
    <Switch>
      <Route exact path={`${urlPrefix}/new`}>
        <CreateMetric isOpen={drawerIsOpen} closeDrawer={closeDrawer} />
      </Route>

      <Route exact path={`${urlPrefix}/:id`}>
        <ShowMetric
          isOpen={drawerIsOpen}
          closeDrawer={closeDrawer}
          urlPrefix={urlPrefix}
        />
      </Route>

      <Route exact path={`${urlPrefix}/:id/edit`}>
        <EditMetric isOpen={drawerIsOpen} closeDrawer={closeDrawer} />
      </Route>

      <Route exact path={`${urlPrefix}/:id/add-value`}>
        <AddMetricValue isOpen={drawerIsOpen} closeDrawer={closeDrawer} />
      </Route>

      <Route exact path={`${urlPrefix}/:id/edit-value`}>
        <AddMetricValue isOpen={drawerIsOpen} closeDrawer={closeDrawer} />
      </Route>

      <Route exact path={`${urlPrefix}/:id/set-milestone`}>
        <SetMetricMilestone isOpen={drawerIsOpen} closeDrawer={closeDrawer} />
      </Route>

      <Route exact path={`${urlPrefix}/:id/edit-milestone/:milestoneId`}>
        <SetMetricMilestone
          isOpen={drawerIsOpen}
          closeDrawer={closeDrawer}
          isEditingMilestone
        />
      </Route>

      <Route exact path={`${urlPrefix}/:linkedMetricId/approve-metric`}>
        <ApproveMetric isOpen={drawerIsOpen} closeDrawer={closeDrawer} />
      </Route>

      <Route exact path={`${urlPrefix}/:linkedMetricId/add-group-to-metric`}>
        <AddGroupToExistingMetric
          isOpen={drawerIsOpen}
          closeDrawer={closeDrawer}
        />
      </Route>

      <Route
        exact
        path={`${urlPrefix}/:metricId/add-metric-data-to-existing-metric`}
      >
        <AddMetricToExistingMetric
          isOpen={drawerIsOpen}
          closeDrawer={closeDrawer}
        />
      </Route>

      <Route exact path={`${urlPrefix}/:metricId/share-values`}>
        <ShareDataPoints isOpen={drawerIsOpen} closeDrawer={closeDrawer} />
      </Route>

      <Redirect to="/investments" />
    </Switch>
  )
}

export default SidebarMetricsRoutes
