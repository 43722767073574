import { useCallback, useEffect, useMemo, useRef } from 'react'
import { useQuery, useQueryClient } from '@tanstack/react-query'

import MetricService from 'api/MetricsService'
import Toast from 'components/Toast'
import { checkMetricPermissionError } from 'utils/functions/permissions'
import { Metric, MetricsMode } from 'utils/types/metrics'
import { groupMilestonesByState } from 'utils/functions/metrics'
import { metricsKeys } from 'utils/queries/metrics'
import { useMetricsMode } from './useMetricsMode'
import useGroupCompany from './useGroupCompany'

interface Props {
  metricId: string
  metricData?: Metric
  onError?: (error: { status: number }) => void
  refetchOnMount?: boolean
}

const useMetricQuery = ({
  metricId,
  metricData,
  onError,
  refetchOnMount,
}: Props) => {
  const queryClient = useQueryClient()
  const metricsMode = useMetricsMode(metricData)
  const founderCompany = useGroupCompany()
  const isFounder = useRef(metricsMode === MetricsMode.FOUNDER)

  useEffect(() => {
    isFounder.current = metricsMode === MetricsMode.FOUNDER
  }, [metricsMode])

  const getMetricById = useCallback(async () => {
    try {
      let metricResponse: Metric | undefined
      if (isFounder.current) {
        metricResponse = await MetricService.getFounderMetricById(metricId)
      } else {
        metricResponse = await MetricService.getInvestorMetricById(metricId)
      }

      if (
        !isFounder.current &&
        metricResponse.companyData &&
        metricResponse.companyData?.id === founderCompany?.id
      ) {
        // It was actually a founder metric requested as investor metric, due to metricsMode not knowing it from the start
        isFounder.current = true
        metricResponse = await MetricService.getFounderMetricById(metricId)
      }

      return metricResponse
    } catch (error) {
      if (!checkMetricPermissionError(error)) {
        Toast.displayIntl('metrics.errorGettingMetric', 'error')
      }

      onError?.(error)

      throw error
    }
  }, [metricId, onError, founderCompany])

  const {
    data: metric,
    refetch,
    isLoading,
    isFetching,
  } = useQuery<Metric>(
    metricsKeys.getMetric(metricId),
    () => {
      return getMetricById()
    },
    {
      enabled: !!metricId,
      initialData: metricData!, // It can be undefined, but RQ doesn't allow it
      staleTime: 1000,
      refetchOnMount: refetchOnMount ?? true,
    }
  )

  const setMetric = (data) =>
    queryClient.setQueryData(metricsKeys.getMetric(metricId), data)

  const milestones = metric?.milestones
  const investorsMilestones = metric?.investorsMilestones

  const groupedMilestones = useMemo(() => {
    return groupMilestonesByState({ milestones, investorsMilestones })
  }, [milestones, investorsMilestones])

  const hasMilestones =
    groupedMilestones.investorsMilestones?.length > 0 ||
    groupedMilestones.expiredMilestones?.length > 0 ||
    groupedMilestones.reachedMilestones?.length > 0 ||
    groupedMilestones.activeMilestones?.length > 0

  return {
    metric,
    refetchMetric: refetch,
    isRefetching: isFetching,
    isLoading,
    groupedMilestones,
    hasMilestones,
    setMetric,
  }
}

export default useMetricQuery
