import styled from 'styled-components'

export const Title = styled.h1`
  color: #444444;
  font-size: 1.6rem;
  font-weight: 500;
  svg {
    font-size: 1.7rem;
    margin-right: 0.5rem;
  }
`

export const Label = styled.label`
  color: #214b7d;
  font-size: 1.4rem;
`

export const Value = styled.p`
  color: #444444;
  font-size: 1.6rem;
  margin-bottom: 2.9rem;
  text-transform: ${(props) => props.textTransform || 'none'};
`

export const Form = styled.form`
  margin-top: 3.1rem;
`

export const Separator = styled.div`
  margin: ${(props) => props.margin || 0};
`

export const CancelButton = styled.button`
  padding: 0.8rem 1.5rem;
  background: white;
  margin-right: 0.5rem;
  border: 1px solid #e9e9e9;
  color: #101527;
`

export const CTAWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  button {
    font-size: 1.4rem;
    text-transform: capitalize;
    border-radius: 0.4rem;
    padding: 0.8rem 1.5rem;
  }
  ${CancelButton} {
    background: white;
    margin-right: 0.5rem;
    border: 0.1rem solid #e9e9e9;
    color: #101527;
  }
`

export const SubmitButtons = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button:nth-child(1) {
    margin-right: 0.6rem;
  }
`
