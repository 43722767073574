import { useCallback } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import MetricService from 'api/MetricsService'
import { getSharedMetricCompany } from 'utils/functions/metrics'
import { Nullable } from 'utils/types/common'
import { useAddToExistingMetricQuery } from 'utils/hooks/queries/useMetrics'

export interface AddToExistingMetricFormValues {
  id: Nullable<string>
  includeHistory: boolean
}

interface Props {
  closeDrawer: () => void
}

const useAddToExistingMetric = ({ closeDrawer }: Props) => {
  const { metricId: linkedMetricId } = useParams<{ metricId: string }>()
  const location = useLocation<{ metric: any }>()
  const metric = location?.state?.metric

  const initialValues: AddToExistingMetricFormValues = {
    id: null,
    includeHistory: true,
  }

  const loadMetrics = useCallback(
    async (metricName: string) => {
      const metricCompany = getSharedMetricCompany(metric)
      const metrics =
        await MetricService.getMetricsByCompanyIdThatAreNotReceivingData(
          metricCompany.id,
          metricName
        )

      return metrics?.map((currMetric) => ({
        id: currMetric.id,
        label: currMetric.name,
      }))
    },
    [metric]
  )

  const { mutate: addToExistingMetricMutation } = useAddToExistingMetricQuery({
    closeDrawer,
    linkedMetricId,
  })

  return {
    metric,
    initialValues,
    loadMetrics,
    addToExistingMetricMutation,
  }
}

export default useAddToExistingMetric
