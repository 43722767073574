import { useCallback, useRef } from 'react'
import { useLocation } from 'react-router-dom'
import compact from 'lodash/compact'

import { useField, useFormikContext } from 'components/Form/hooks'
import {
  getMetricCompaniesPortfoliosFormat,
  shouldCompanySupportMetrics,
} from 'utils/functions/metrics'
import CompanyService from 'api/CompanyService'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getCurrentGroupId } from 'selectors/auth'
import PortfolioService from 'api/PortfolioService'
import { PortfolioTypes } from 'utils/constants/portfolio'
import { CreateInvestorMetricFormType } from './useCreateInvestorMetric'

const INITIAL_PAGE = 0
const PAGE_SIZE = 20

export const useCreateInvestorMetricForm = () => {
  const currentGroupId = useAppSelector(getCurrentGroupId)

  const { setFieldValue, handleBlur } =
    useFormikContext<CreateInvestorMetricFormType>()
  const [nameField] = useField<string>('name')
  const [frequencyField] = useField<string>('frequency')
  const [companiesOrPortfoliosField, _, companiesOrPortfoliosHelpers] =
    useField<any[]>('companiesOrPortfolios')
  const [requestDataFromCompaniesField] = useField<boolean>(
    'requestDataFromCompanies'
  )
  const [messageField] = useField<string>('message')

  const { state } = useLocation<{ linkedMetric: any }>()
  const isCreatingMetricFromSharedMetric = !!state?.linkedMetric

  const dropdownRef = useRef<{ clear: () => {}; close: () => {} }>(null)

  const clearDropdown = () => {
    dropdownRef.current?.clear()
    dropdownRef.current?.close()
  }

  const getSubOptionById = (id, options) =>
    compact<{ id: string; label: string }>(
      options.map((option) => {
        return option.subOptions.find((subOption) => subOption.id === id)
      })
    )?.[0]

  const loadCompaniesOrPortfolios = useCallback(
    async (searchValue) => {
      const selectedItemsId =
        companiesOrPortfoliosField.value?.map(({ id }) => id) ?? []
      const params = {
        'q[name_cont]': searchValue,
        'q[id_not_in]': selectedItemsId,
        order_by: 'name',
        direction: 'asc',
        // ToDo: Remove scope: 'public' filter once private profiles are supported in metrics
        scope: 'public',
      }

      if (!searchValue) {
        params['q[holdings_in_portfolios]'] = true
      }
      const {
        data: {
          entities: { companyData = {} },
        },
      } = await CompanyService.getCompanies(INITIAL_PAGE, params, PAGE_SIZE)
      const companies = Object.values(companyData).filter((company) =>
        shouldCompanySupportMetrics(company, currentGroupId)
      )

      const portfolios = await PortfolioService.fetchPortfolios({
        portfolioType: [
          PortfolioTypes.INVEST,
          PortfolioTypes.FUND,
          PortfolioTypes.DEAL,
        ],
        name: searchValue,
        idNotIn: selectedItemsId,
        withCompanies: true,
      })

      return getMetricCompaniesPortfoliosFormat(companies, portfolios)
    },
    [companiesOrPortfoliosField.value, currentGroupId]
  )

  const handleDeleteItem = (id) => {
    companiesOrPortfoliosHelpers.setTouched(true)
    companiesOrPortfoliosHelpers.setValue(
      companiesOrPortfoliosField.value.filter((item) => item.id !== id),
      true
    )
  }

  return {
    nameField,
    frequencyField,
    companiesOrPortfoliosField,
    requestDataFromCompaniesField,
    messageField,
    isCreatingMetricFromSharedMetric,
    dropdownRef,
    clearDropdown,
    getSubOptionById,
    loadCompaniesOrPortfolios,
    handleDeleteItem,
    setFieldValue,
    handleBlur,
  }
}
