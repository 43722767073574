import { useMemo } from 'react'

import { SortDirection } from 'utils/constants'
import { MetricSortBy } from 'utils/constants/metrics'
import { getMetricsGroupByMetricCards } from 'utils/functions/metricsCard'
import { useMetricsQuery } from 'utils/hooks/queries/useMetrics'

export const useSelectedMetrics = () => {
  const { metrics: allMetrics, isLoading: isLoadingAllMetrics } =
    useMetricsQuery({})
  const { metrics, isLoading, fetchNextMetricsPage } = useMetricsQuery({
    selected: true,
  })

  const hasMetrics = useMemo(() => {
    return (
      isLoadingAllMetrics ||
      (allMetrics?.pages?.flatMap((page) => page.data) ?? []).length > 0
    )
  }, [allMetrics, isLoadingAllMetrics])

  const hasSelectedMetrics = useMemo(() => {
    return (
      isLoading ||
      (metrics?.pages?.flatMap((page) => page.data) ?? []).length > 0
    )
  }, [metrics, isLoading])

  const metricsCards = useMemo(
    () =>
      metrics
        ? getMetricsGroupByMetricCards(metrics, {
            sortBy: MetricSortBy.NAME,
            direction: SortDirection.ASC,
          }).sort((a, b) => a.name.localeCompare(b.name))
        : [],
    [metrics]
  )

  return {
    metricsCards,
    hasMetrics,
    isLoading: isLoading || isLoadingAllMetrics,
    hasSelectedMetrics,
    fetchNextMetricsPage,
  }
}
