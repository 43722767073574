import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const Form = styled.form`
  height: 100%;
`

export const Title = styled.h1`
  color: #101527;
  font-size: 1.8rem;
  display: flex;
  align-items: center;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  > svg {
    font-size: 1.5rem;
    margin-right: 0.8rem;
    margin-bottom: 0.2rem;
  }
`

export const VerifyValuesLegend = styled.p`
  font-weight: 700;
  font-size: 1.2rem;
  margin-left: 0.8rem;
`

export const TimesIconWrapper = styled.div`
  svg {
    font-size: 2rem;
    color: ${color('darkBlue')};
  }
`

export const ValueWrapper = styled.div`
  padding-left: 0.8rem;
`

export const CTAWrapper = styled.div`
  gap: 0.8rem;
  display: flex;
  justify-content: flex-end;
`
