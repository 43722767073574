import React, { useEffect, useState, useCallback, useMemo } from 'react'
import { useIntl, FormattedMessage } from 'react-intl'
import {
  useHistory,
  useLocation,
  useParams,
  useRouteMatch,
} from 'react-router-dom'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useQueryClient } from '@tanstack/react-query'

import OptionsDropdown from 'components/OptionsDropdown'
import Toast from 'components/Toast'
import { isActingAsFounder, getCurrentGroupId } from 'selectors/auth'
import MetricsService from 'api/MetricsService'
import { orderArrayByObjectPropertyAndDirection } from 'utils/functions/array'
import { dispatchEvent } from 'utils/hooks/useEventListener'
import { useGeneratingMetricsQuery } from 'utils/hooks/metrics/useGeneratingMetricsQuery'
import { PortfolioMetricsActions } from 'utils/types/portfolios'
import { metricsKeys } from 'utils/queries/metrics'
import { companyKeys } from 'utils/queries/companies'

import {
  populateMetricGrowth,
  metricIsReceivingDataFromFounder,
} from 'utils/functions/metrics'
import { MetricStates } from 'utils/constants/metrics'
import {
  ARCHIVE_DP_METRIC_EVENT,
  DELETED_METRIC_EVENT,
  UN_ARCHIVE_DP_METRIC_EVENT,
} from 'utils/constants/events'
import useMetricQuery from 'utils/hooks/useMetricQuery'
import { useMetricsMode } from 'utils/hooks/useMetricsMode'
import { MetricsMode, MetricSources } from 'utils/types/metrics'
import { useGeneratingHoldingsMetricsQuery } from 'utils/hooks/metrics/useGeneratingHoldingsMetricsQuery'

export const POLLING_METRICS_STATUS_INTERVAL = 9000

const useMetric = (urlPrefix, closeDrawer) => {
  const [dataPoints, setDataPoints] = useState([])
  const intl = useIntl()
  const history = useHistory()
  const metricsMode = useMetricsMode()
  const isFounder = metricsMode === MetricsMode.FOUNDER
  const isClient = metricsMode === MetricsMode.CLIENT
  const [dataPoint, setDataPoint] = useState()
  const [editDataPointIndex, setEditDataPointIndex] = useState(null)
  const currentGroupId = useAppSelector(getCurrentGroupId)
  const isActingAsFounderValue = useAppSelector(isActingAsFounder)
  const [isFounderDataToggled, setIsFounderDataToggled] = useState(false)
  const queryClient = useQueryClient()
  const [showBulkImport, setShowBulkImport] = useState(false)
  const [initialTabIndex, setInitialTabIndex] = useState(0)
  const location = useLocation()
  const routeMatch = useRouteMatch()

  const [nonArchivedSort, setNonArchivedSort] = useState({
    sortId: 'date',
    sortDirection: 'desc',
  })
  const [archivedSort, setArchivedSort] = useState({
    sortId: 'date',
    sortDirection: 'desc',
  })

  useEffect(() => {
    if (location.state?.activeTab) {
      setInitialTabIndex(location.state?.activeTab)
    } else {
      setInitialTabIndex(0)
    }
  }, [location.state])

  const { id: metricId } = useParams()

  const onMetricNotFoundError = useCallback(
    (error) => {
      if (error.status === 404) {
        history.push('/metrics')
      }
    },
    [history]
  )

  const { metric, groupedMilestones, isLoading, setMetric, refetchMetric } =
    useMetricQuery({
      metricId,
      onError: onMetricNotFoundError,
    })

  const { data: idsStatusMap } = useGeneratingHoldingsMetricsQuery(
    [metric?.metrizable],
    {
      enabled: !!metric && !isActingAsFounderValue,
      refetchInterval: POLLING_METRICS_STATUS_INTERVAL,
      onSuccess: (data) => {
        refetchMetric()
      },
    }
  )

  const isGeneratingMetrics =
    idsStatusMap?.[metric?.metrizable] === PortfolioMetricsActions.PENDING

  const isSystemMetric = useMemo(
    () => metric?.metricSource === MetricSources.System,
    [metric]
  )

  useEffect(() => {
    if (metric) {
      setDataPoints([...(metric.dataPoints ?? [])].reverse())
      setIsFounderDataToggled(metricIsReceivingDataFromFounder(metric))
    }
  }, [metric])

  const onCancelDataPoint = useCallback(() => {
    setDataPoint(null)
    setEditDataPointIndex(null)
  }, [])

  const onSaveDataPoint = async () => {
    if (editDataPointIndex !== null) {
      if (isFounder) {
        await MetricsService.editDataPointFounder(dataPoint)
      } else {
        await MetricsService.editDataPointInvestor(dataPoint)
      }

      setEditDataPointIndex(null)
      setDataPoint(null)

      refetchMetric()
    }
  }

  const onDeleteDataPoint = async (dataPointParam) => {
    if (isFounder) {
      await MetricsService.deleteFounderDataPoint(dataPointParam)
    } else {
      await MetricsService.deleteInvestorDataPoint(dataPointParam)
    }
    setEditDataPointIndex(null)
    setDataPoint(null)

    refetchMetric()
  }

  const onDeleteMilestone = async () => {
    if (isFounder) {
      await MetricsService.deleteFounderMilestone(metric.id)
    } else {
      await MetricsService.deleteInvestorMilestone(metric.id)
    }
    history.replace(routeMatch.url)
  }

  const onChangeDataPointIndex = (dataPointToEdit, dataPointIndex) => {
    setDataPoint({ ...dataPointToEdit })
    setEditDataPointIndex(dataPointIndex)
  }

  const onSortByColumn =
    (isArchivedTableSort) =>
    ({ sortId, sortDirection }) => {
      if (isArchivedTableSort) {
        setArchivedSort({ sortId, sortDirection })
      } else {
        setNonArchivedSort({ sortId, sortDirection })
      }
    }

  const notArchivedDataPointsWithGrowth = useMemo(() => {
    const sortedArr = dataPoints.filter((dp) => !dp.archive)
    return populateMetricGrowth(sortedArr)
  }, [dataPoints])

  const notArchivedDataPoints = useMemo(() => {
    return orderArrayByObjectPropertyAndDirection(
      notArchivedDataPointsWithGrowth,
      nonArchivedSort.sortId,
      nonArchivedSort.sortDirection
    )
  }, [
    nonArchivedSort.sortDirection,
    nonArchivedSort.sortId,
    notArchivedDataPointsWithGrowth,
  ])

  const archivedDataPoints = useMemo(() => {
    return orderArrayByObjectPropertyAndDirection(
      dataPoints.filter((dp) => dp.archive),
      archivedSort.sortId,
      archivedSort.sortDirection
    )
  }, [dataPoints, archivedSort.sortDirection, archivedSort.sortId])

  const onEditMetric = useCallback(() => {
    history.replace(`${routeMatch.url}/edit`)
  }, [history, routeMatch.url])

  const onAddNewValue = useCallback(() => {
    history.replace(`${routeMatch.url}/add-value`)
  }, [history, routeMatch.url])

  const onSetMilestone = useCallback(() => {
    history.replace(`${routeMatch.url}/set-milestone`)
  }, [history, routeMatch.url])

  const deleteMetric = useCallback(
    (currentGroup, isFounderTeam) => async (metricData) => {
      try {
        if (isFounderTeam) {
          await MetricsService.deleteFounderMetric(metricData.id, currentGroup)
          queryClient.refetchQueries(
            companyKeys.companyMetrics(metricData.companyData.id)
          )
        } else {
          await MetricsService.deleteInvestorMetric(metricData.id, currentGroup)
          queryClient.refetchQueries(metricsKeys.getInvestorMetrics())
        }
      } catch (error) {
        Toast.display(intl.messages['metrics.deleteMetricError'], 'error')
      }
    },
    [intl.messages, queryClient]
  )

  const onRemoveMetric = (metricData) => {
    dispatchEvent(DELETED_METRIC_EVENT, {
      id: metricData.id,
      hidden: true,
    })
    const deleteMetricFunc = deleteMetric(currentGroupId, isFounder)

    Toast.displayAction({
      message: intl.formatMessage(
        { id: 'metrics.toasts.metricDeleted' },
        { metricName: metricData.name }
      ),
      action: () => {
        dispatchEvent(DELETED_METRIC_EVENT, {
          id: metricData.id,
          hidden: false,
        })
      },
      afterClose: () => deleteMetricFunc(metricData),
      label: intl.formatMessage({ id: 'common.undoDelete' }),
    })
    closeDrawer()
  }

  const onGoToCompanyPage = useCallback(
    (metricData) => {
      history.push(`/companies/${metricData?.businessCompanyDatum}`)
    },
    [history]
  )

  const redirectToShareDataPoints = (currMetric, dataPointsToShare) => {
    history.replace(`${routeMatch.url}/share-values`, {
      dataPointsToShare,
      metric,
    })
  }

  const redirectToSetMilestone = () => {
    history.push(`${routeMatch.url}/set-milestone`)
  }

  const redirectToEdit = () => {
    history.push(`${routeMatch.url}/edit`)
  }

  const getDropdownOptions = () => {
    const options = [
      <OptionsDropdown.Item
        key="addNewValue"
        label={<FormattedMessage id="metrics.addValue" />}
        icon={['far', 'plus-square']}
        onSelectOption={() => onAddNewValue(metric)}
      />,
    ]
    if (isFounder) {
      if (!metric?.requestedByGroup) {
        options.push(
          <OptionsDropdown.Item
            key="editMetric"
            label={<FormattedMessage id="metrics.editMetric" />}
            icon={['far', 'pen']}
            onSelectOption={() => onEditMetric(metric)}
          />
        )
      }
    } else {
      options.push(
        <OptionsDropdown.Item
          key="goToCompanyPage"
          label={<FormattedMessage id="metrics.goToCompanyPage" />}
          icon={['far', 'file-user']}
          onSelectOption={() => onGoToCompanyPage(metric)}
        />,
        <OptionsDropdown.Item
          key="removeMetric"
          label={<FormattedMessage id="metrics.removeMetric" />}
          color="#f94863"
          icon={['far', 'trash-alt']}
          onSelectOption={() => onRemoveMetric(metric)}
        />
      )
    }
    return options
  }

  const getMilestoneDropdownOptions = () => {
    return [
      <OptionsDropdown.Item
        key="setGroupMilestone"
        label={<FormattedMessage id="metrics.editGroupMilestone" />}
        icon={['far', 'trophy-alt']}
        onSelectOption={() => {
          onSetMilestone(metric)
        }}
      />,
      <OptionsDropdown.Item
        key="removeMetric"
        label={<FormattedMessage id="metrics.deleteGroupMilestone" />}
        color="#f94863"
        icon={['far', 'trash-alt']}
        onSelectOption={onDeleteMilestone}
      />,
    ]
  }

  const onChangeFounderDataToggleValue = async (value) => {
    setIsFounderDataToggled(value)
    try {
      await MetricsService.toggleReceiveDataFromFounder(
        value,
        metric.senderLinkedMetric?.id
      )
      refetchMetric()
    } catch (error) {
      Toast.display(
        intl.messages['metrics.errorChangingReceivingData'],
        'error'
      )
    }
  }

  const archiveDataPoints = async (selectedDataPoints) => {
    try {
      setDataPoints((currDataPoints) => {
        return currDataPoints.map((dp) => {
          if (selectedDataPoints.map((currDp) => currDp.id).includes(dp.id)) {
            return { ...dp, archive: true, isSelected: false }
          }
          return dp
        })
      })
      await MetricsService.archiveDataPoints(
        metric.id,
        selectedDataPoints,
        isClient
      )

      refetchMetric()

      dispatchEvent(ARCHIVE_DP_METRIC_EVENT, {
        isFounderMetric: isFounder,
      })

      Toast.displayIntl(
        selectedDataPoints.length === 1
          ? 'metrics.toasts.successArchivingDataPoint'
          : [
              'metrics.toasts.successArchivingDataPoints',
              { count: selectedDataPoints.length },
            ],
        'info'
      )
    } catch (error) {
      Toast.displayIntl('metrics.toasts.errorArchivingDataPoint', 'error')
    }
  }

  const unArchiveDataPoints = async (selectedDataPoints) => {
    try {
      setDataPoints((currDataPoints) => {
        return currDataPoints.map((dp) => {
          if (selectedDataPoints.map((currDp) => currDp.id).includes(dp.id)) {
            return { ...dp, archive: false, isSelected: false }
          }
          return dp
        })
      })

      await MetricsService.unArchiveDataPoints(
        metric.id,
        selectedDataPoints,
        isClient
      )

      dispatchEvent(UN_ARCHIVE_DP_METRIC_EVENT, {
        isFounderMetric: isFounder,
      })

      refetchMetric()

      Toast.displayIntl(
        selectedDataPoints.length === 1
          ? 'metrics.toasts.successUnarchivingDataPoint'
          : [
              'metrics.toasts.successUnarchivingDataPoints',
              { count: selectedDataPoints.length },
            ],
        'info'
      )
    } catch (error) {
      Toast.displayIntl('metrics.toasts.errorUnarchivingDataPoint', 'error')
    }
  }

  const deleteDataPoints = async (selectedDataPoints) => {
    try {
      setDataPoints((currDataPoints) => {
        return currDataPoints.filter((dp) => {
          const isDeletedDataPoint = selectedDataPoints
            .map((currDp) => currDp.id)
            .includes(dp.id)
          return !isDeletedDataPoint
        })
      })

      await MetricsService.deleteDataPoints(selectedDataPoints, isFounder)

      dispatchEvent(ARCHIVE_DP_METRIC_EVENT, {
        isFounderMetric: isFounder,
      })

      refetchMetric()
    } catch (error) {
      Toast.display(intl.messages['metrics.errorDeletingDataPoints'], 'error')
    }
  }

  const editDataPoint = ({ readOnlyMode, dataPoint: currDataPoint }) => {
    history.push(`${routeMatch.url}/edit-value`, {
      dataPoint: currDataPoint,
      readOnlyMode,
      isEditing: true,
    })
  }

  const sendRequestToFounder = async () => {
    try {
      await MetricsService.sendRequestToFounder(metric.id)
      const senderMetric = { ...metric?.senderLinkedMetric }
      const updatedMetric = {
        ...metric,
        senderLinkedMetric: {
          ...senderMetric,
          state: MetricStates.REQUEST_SENT,
        },
      }
      setMetric(updatedMetric)
    } catch (error) {
      Toast.display(intl.messages['metrics.requestToFounderFailed'], 'error')
    }
  }

  const exportMetric = async () => {
    try {
      await MetricsService.exportMetricData(metric.id, metric.name)
    } catch (err) {
      Toast.display(intl.messages['metrics.exportMetricsError'], 'error')
    }
  }

  return {
    isFounder,
    metric,
    groupedMilestones,
    dataPoints,
    isLoadingMetric: isLoading,
    onSortByColumn,
    onEditMetric,
    onAddNewValue,
    onRemoveMetric,
    onGoToCompanyPage,
    onChangeDataPointIndex,
    onCancelDataPoint,
    onSaveDataPoint,
    currentGroupId,
    dataPoint,
    setDataPoint,
    editDataPointIndex,
    onDeleteDataPoint,
    onSetMilestone,
    getDropdownOptions,
    getMilestoneDropdownOptions,
    isFounderDataToggled,
    onChangeFounderDataToggleValue,
    redirectToSetMilestone,
    setDataPoints,
    archiveDataPoints,
    unArchiveDataPoints,
    deleteDataPoints,
    editDataPoint,
    sendRequestToFounder,
    redirectToEdit,
    redirectToShareDataPoints,
    showBulkImport,
    setShowBulkImport,
    initialTabIndex,
    notArchivedDataPoints,
    archivedDataPoints,
    exportMetric,
    isGeneratingMetrics,
    isSystemMetric,
  }
}

export default useMetric
