/* eslint-disable no-unused-expressions */
import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useIntl } from 'react-intl'

import Button from 'components/Button'
import {
  DateContainer,
  ValueContainer,
  AddDataPointRow,
  DataPointDatePicker,
  DataPointInput,
  ButtonsContainer,
  AddDataPointContainer,
} from './DataPointRow.styles'

const DataPointRow = ({
  dataPointDate,
  setDataPointDate,
  dataPointValue,
  setDataPointValue,
  onCancel,
  onSave,
  editDataPoint,
  loading,
}) => {
  const ref = useRef()
  const intl = useIntl()

  useEffect(() => {
    ref.current?.focus()
  }, [])

  return (
    <AddDataPointContainer>
      <AddDataPointRow>
        <DateContainer>
          <DataPointDatePicker
            filled
            initialValue={null}
            value={dataPointDate}
            maxDate={new Date()}
            dateFormat="MMM dd, yyy"
            handleChange={setDataPointDate}
            label=""
            onlyMonthYearPicker={false}
          />
        </DateContainer>

        <ValueContainer>
          <DataPointInput
            ref={ref}
            prefix="$"
            type="number"
            value={dataPointValue}
            step="1000"
            onChange={({ value }) => setDataPointValue(value)}
          />
        </ValueContainer>
      </AddDataPointRow>
      <ButtonsContainer>
        <Button onClick={onCancel} disabled={loading}>
          {intl.messages['buttons.cancel']}
        </Button>
        <Button
          autoWidth
          primary
          blue
          onClick={onSave}
          disabled={loading || !dataPointDate || !dataPointValue}
        >
          {editDataPoint
            ? intl.messages['buttons.save']
            : intl.messages['buttons.add']}
        </Button>
      </ButtonsContainer>
    </AddDataPointContainer>
  )
}

DataPointRow.propTypes = {
  dataPointDate: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  setDataPointDate: PropTypes.func.isRequired,
  dataPointValue: PropTypes.number,
  setDataPointValue: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  editDataPoint: PropTypes.bool,
  loading: PropTypes.bool,
}

DataPointRow.defaultProps = {
  editDataPoint: false,
  loading: false,
  dataPointDate: null,
  dataPointValue: null,
}
export default DataPointRow
