import type { UseInfiniteQueryOptions } from '@tanstack/react-query'
import { useInfiniteQuery } from '@tanstack/react-query'
import { setCurrentUpdates } from 'actions/companies'
import MixedUpdatesService, {
  UpdateEntityParams,
} from 'api/MixedUpdatesService'
import { useEffect, useMemo, useRef } from 'react'
import { parseUpdatesForNavigation } from 'utils/functions/updates'
import { useAppDispatch } from 'utils/hooks/reduxToolkit'
import useInfiniteScroll from 'utils/hooks/useInfiniteScroll'
import {
  FiltersDataSet,
  StatusFilters,
} from 'utils/hooks/useUpdatesProfilesFilter'
import { mixedUpdatesKeys } from 'utils/queries/mixedUpdates'
import { MixedUpdate } from 'utils/types/update'
import { UpdateEntityType } from 'components/UpdatesFeedV2'
import { InvestmentsFiltersObject } from 'containers/Investments/InvestmentsFilters/types'
import { isIndexUpdate } from '../../functions/suggestedUpdates'

export interface UseMixedUpdatesInfiniteQuery extends UpdateEntityParams {
  scrollRootElement?: React.RefObject<HTMLElement>
  queryOptions?: UseInfiniteQueryOptions<{
    data: MixedUpdate[]
    page: number
  }>
  isInitiallyInfiniteScrollEnabled?: boolean
  investmentsFilterObject?: InvestmentsFiltersObject
  isShowingUpdatesForFilters?: boolean
  filters: FiltersDataSet
  onlyTransactionUpdates?: boolean
  updatesForInvestorTab?: boolean
}

export const useMixedUpdatesQuery = ({
  entityType,
  entityId,
  filters,
  queryOptions = {},
  onlyTransactionUpdates,
  isShowingUpdatesForFilters,
  updatesForInvestorTab,
  investmentsFilterObject,
}: UseMixedUpdatesInfiniteQuery) => {
  return useInfiniteQuery(
    mixedUpdatesKeys.all(
      entityType,
      entityId || '',
      filters,
      investmentsFilterObject
    ),
    async ({ pageParam = 1 }) => {
      if (isShowingUpdatesForFilters && investmentsFilterObject) {
        let data: MixedUpdate[] = []
        data = await MixedUpdatesService.fetchMixedUpdatesForInvestmentsFilters(
          {
            params: {
              page: pageParam,
              entityType,
              types: filters?.types?.map((type) => type.value).flat(),
              text: filters?.searchText,
              tags: filters?.tags?.map((type) => type.label),
            },
            investmentsFilterObject,
          }
        )
        return {
          data,
          page: pageParam,
        }
      }

      const filtersParams = {
        page: pageParam,
        types: onlyTransactionUpdates
          ? ['transaction']
          : filters?.types?.map((type) => type.value).flat(),
        tags: filters?.tags?.map((type) => type.label),
        suggested: filters?.[StatusFilters.SUGGESTED],
        draft: filters?.[StatusFilters.DRAFT],
        published: filters?.[StatusFilters.PUBLISHED],
        text: filters?.searchText,
        typesNotIncluded: filters?.typesNotIncluded,
      }

      let data: MixedUpdate[] = []

      if (entityType === UpdateEntityType.ALL_PORTFOLIOS) {
        data = await MixedUpdatesService.fetchAllPortfolioMixedUpdates({
          entityType,
          entityId,
          ...filtersParams,
        })
      } else {
        data = await MixedUpdatesService.fetchMixedUpdates({
          entityType,
          entityId,
          updatesForInvestorTab,
          ...filtersParams,
        })
      }
      return {
        data,
        page: pageParam,
      }
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage?.data.length === 0) return lastPage.page
        return (lastPage?.page ?? 0) + 1
      },
      ...queryOptions,
    }
  )
}

export const useMixedUpdatesInfiniteQuery = ({
  entityType,
  entityId,
  filters,
  queryOptions = {},
  onlyTransactionUpdates,
  isShowingUpdatesForFilters,
  updatesForInvestorTab,
  investmentsFilterObject,
}: UseMixedUpdatesInfiniteQuery) => {
  const dispatch = useAppDispatch()
  const scrollRef = useRef<HTMLDivElement>(null)
  const { ref: infiniteScrollRef, onScreen } = useInfiniteScroll()

  const query = useMixedUpdatesQuery({
    entityType,
    entityId,
    filters,
    queryOptions,
    onlyTransactionUpdates,
    isShowingUpdatesForFilters,
    investmentsFilterObject,
    updatesForInvestorTab,
  })

  useEffect(() => {
    let currentUpdates = {}
    const updates =
      query.data?.pages.flatMap((page) => page.data).filter(isIndexUpdate) ?? []
    if (updates) {
      currentUpdates = {
        page: query.data?.pages.length ?? 1,
        updates: parseUpdatesForNavigation(updates),
      }
      dispatch(setCurrentUpdates(currentUpdates))
    }
  }, [dispatch, query.data])

  useEffect(() => {
    if (onScreen && !query.isFetching) {
      query.fetchNextPage()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onScreen])

  const mixedUpdates = useMemo(
    () => query.data?.pages.flatMap((page) => page.data) ?? [],
    [query.data]
  )

  return {
    ...query,
    mixedUpdates,
    scrollRef,
    infiniteScrollRef,
  }
}
