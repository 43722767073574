import React from 'react'
import PropTypes from 'prop-types'
import SelectDatePicker from 'components/TimeSelect/SelectDatePicker'
import IconButton from 'ui/IconButton'
import Input from 'ui/Input'
import { buildFormError } from 'utils/functions/forms'
import {
  TableCol,
  TableColIndex,
  TableRow,
} from './BulkImportMetricValuesModal.styles'

const ImportMetricValueRow = ({
  index,
  name,
  remove,
  values,
  errors,
  touched,
  showDelete,
  handleChange,
  handleBlur,
  setFieldValue,
}) => {
  return (
    <TableRow>
      <TableColIndex flex={0}>{index + 1}.</TableColIndex>
      <TableCol flex={1}>
        <Input
          id={`${name}.dataPointValue`}
          name={`${name}.dataPointValue`}
          type="number"
          onChange={handleChange}
          value={values.dataPointValue}
          error={buildFormError(
            errors?.dataPointValue,
            'error',
            touched?.dataPointValue
          )}
          onBlur={handleBlur}
          showErrorMessage={false}
        />
      </TableCol>
      <TableCol flex={0.6} minWidth="16rem">
        <SelectDatePicker
          id={`${name}.dataPointDate`}
          name={`${name}.dataPointDate`}
          handleChange={(date) => setFieldValue(`${name}.dataPointDate`, date)}
          onlyMonthYearPicker={false}
          value={values.dataPointDate}
          error={buildFormError(
            errors?.dataPointDate,
            'error',
            touched?.dataPointDate
          )}
          dateFormat="dd MMM, yyyy"
          maxDate={new Date()}
          onBlur={handleBlur}
          showErrorMessage={false}
          closeOnScroll
          popperModifiers={[
            {
              name: 'offset',
              options: {
                offset: [0, 8],
              },
            },
          ]}
        />
      </TableCol>
      <TableCol flex={0}>
        {showDelete && (
          <IconButton
            type="button"
            icon="times"
            onClick={() => remove(index)}
            border={false}
            buttonSize="2.4rem"
          />
        )}
      </TableCol>
    </TableRow>
  )
}

ImportMetricValueRow.propTypes = {
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  remove: PropTypes.func.isRequired,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  showDelete: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
}

export default React.memo(ImportMetricValueRow, (prevProps, nextProps) => {
  return (
    prevProps.showDelete === nextProps.showDelete &&
    prevProps.index === nextProps.index &&
    prevProps.values.dataPointDate === nextProps.values.dataPointDate &&
    prevProps.values.dataPointValue === nextProps.values.dataPointValue &&
    prevProps.errors?.dataPointDate === nextProps.errors?.dataPointDate &&
    prevProps.errors?.dataPointValue === nextProps.errors?.dataPointValue &&
    prevProps.touched?.dataPointDate === nextProps.touched?.dataPointDate &&
    prevProps.touched?.dataPointValue === nextProps.touched?.dataPointValue
  )
})
