export const SHOW_CLAIM_COMPANY = 'SHOW_CLAIM_COMPANY'
export const HIDE_CLAIM_COMPANY = 'HIDE_CLAIM_COMPANY'

export const CLOSE_GROUP_SETTINGS_EVENT = 'CLOSE_GROUP_SETTINGS_EVENT'
export const OPEN_CHAT_POPUP_EVENT = 'OPEN_CHAT_POPUP_EVENT'

export const CREATED_METRIC_EVENT = 'CREATED_METRIC_EVENT'
export const DELETED_METRIC_EVENT = 'DELETED_METRIC_EVENT'
export const UPDATE_ALL_METRICS_EVENT = 'UPDATE_ALL_METRICS_EVENT'
export const IMPORT_METRIC_EVENT = 'IMPORT_METRIC_EVENT'
export const ARCHIVE_DP_METRIC_EVENT = 'ARCHIVE_DP_METRIC_EVENT'
export const UN_ARCHIVE_DP_METRIC_EVENT = 'UN_ARCHIVE_DP_METRIC_EVENT'
export const EDIT_DP_METRIC_EVENT = 'EDIT_DP_METRIC_EVENT'
