import styled from 'styled-components/macro'
import { color } from 'utils/functions/colors'

export const Title = styled.h1`
  color: ${color('darkBlue')};
  font-size: 1.8rem;
  margin-top: 2rem;
  font-weight: 400;
  svg {
    font-size: 1.7rem;
    margin-right: 0.8rem;
  }
`

export const Label = styled.label`
  color: ${color('mainBlue')};
  font-size: 1.4rem;
`

export const Legend = styled.p<{ fontSize: string }>`
  font-size: ${(props) => props.fontSize || '1.4rem'};
  line-height: 1.7rem;
  color: ${color('lightGray')};
  margin: 2rem 0;
  svg {
    font-size: 1.4rem;
    color: ${color('darkGray')};
    margin-right: 0.5rem;
  }
`

export const Value = styled.p`
  color: #444444;
  font-size: 1.6rem;
  margin-bottom: 2.9rem;
`

export const Form = styled.form`
  height: 100%;
`

export const Separator = styled.div<{ margin: string }>`
  margin: ${(props) => props.margin || 0};
`

export const CTAWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 1rem;

  button {
    text-transform: capitalize;
  }
`

export const LabelValue = styled.div`
  color: ${color('darkGray')};
  margin: 0.7rem 0;
  font-weight: 400;
  font-size: 1.4rem;
`

export const LoaderWrapper = styled.div`
  height: 50vh;
`

export const TextLabel = styled.label`
  margin-left: 0.8rem;
  font-size: 1.2rem;
  font-weight: 700;
  color: ${color('darkGray')};
`

export const ChipWrapper = styled.div`
  > div {
    margin-top: 0.8rem;
    width: fit-content;
  }
`

export const MessageTitle = styled.p`
  font-size: 1.4rem;
  color: ${color('darkGray')};
  margin-top: 0.8rem;
  margin-left: 0.8rem;
  font-weight: 700;
`

export const Message = styled.p`
  font-size: 1.4rem;
  color: #4a4a68;
  margin-top: 0.8rem;
  margin-left: 1rem;
`

export const ShareWithWrapper = styled.div`
  margin-bottom: 1rem;
`

export const Icon = styled.div`
  border: 0.1rem solid #c9cfe4;
  border-radius: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.6rem;
  height: 3.6rem;
  background: ${color('white')};

  > svg {
    font-size: 1.5rem;
    color: ${color('lightGray')};
  }
`
