import { useIntl } from 'react-intl'
import InfiniteScrollRef from 'components/InfiniteScrollRef'
import Card from 'components/Card'
import Table from 'ui/Table'
import { Metric } from 'utils/types/metrics'
import { getMetricsColumns } from 'utils/functions/renderers/renderAllMetricsHelper'
import { ZeroState, ZeroStateType } from 'components/ZeroStateV2'
import SkeletonAllMetricsTable from 'components/Skeletons/Metrics/SkeletonAllMetricsTable'
import { Media, useMediaQuery } from 'utils/hooks/useMediaQuery'

import useAllMetricsTable from './useAllMetricsTable'
import BulkImportMetricValuesModal from '../BulkImportMetricValuesModal'
import MetricActions from './components/MetricActions'
import MetricsZeroState from '../MetricsZeroState'
import { ScrollWrapper, Cell } from './AllMetrics.styles'

interface AllMetricsTableProps {}

const AllMetricsTable: React.FC<AllMetricsTableProps> = () => {
  const { matches: isSmallScreen } = useMediaQuery(Media.MAX_XXXL)
  const intl = useIntl()

  const {
    allMetrics,
    isLoading,
    isFetching,
    metricActions,
    sortCriteria,
    debouncedSearch,
    metricBulkImportModal,
    scrollRef,
    visibleColumns,
    infiniteScrollRef,
    isMetricInSandbox,
    toggleMetricInSandbox,
    isAddMetricToSandboxDisabled,
    onChangeSortBy,
    onHideImportCsvModal,
  } = useAllMetricsTable()

  if (!isLoading && !allMetrics.length && !!debouncedSearch) {
    return (
      <ZeroState
        type={ZeroStateType.NO_SEARCH_RESULTS}
        padding="5rem 0"
        maxWidth="50rem"
        intlValues={{ searchText: debouncedSearch }}
      />
    )
  }

  if (!isLoading && !allMetrics.length && !debouncedSearch) {
    return <MetricsZeroState isAllMetricsTabSelected />
  }

  return (
    <ScrollWrapper ref={scrollRef}>
      <Card padding="0">
        {!isLoading && allMetrics.length && (
          <Table<Metric>
            data={allMetrics}
            loading={false}
            rowHeight={45}
            keyPath="id"
            initialSortId={sortCriteria?.field}
            initialSortDirection={sortCriteria?.direction}
            columns={getMetricsColumns({
              intl,
              Cell,
              visibleColumns,
              isMetricInSandbox,
              toggleMetricInSandbox,
              isAddMetricToSandboxDisabled,
              metricActions,
            })}
            withFloatingScroll={isSmallScreen}
            onChangeSortBy={onChangeSortBy}
            rowContextMenu={({
              rowData,
              isOpen,
              position,
              onOpen,
              onClose,
            }) => {
              return (
                <MetricActions
                  metric={rowData}
                  {...metricActions}
                  isContentMenuOpen={isOpen}
                  contextMenuPosition={position}
                  onOpenContentMenu={onOpen}
                  onCloseContentMenu={onClose}
                />
              )
            }}
            onClickRow={(metric) => metricActions.onViewMetricDetail(metric.id)}
          />
        )}
        {(isLoading || isFetching) && (
          <SkeletonAllMetricsTable
            hasHeader={!isFetching}
            visibleColumns={visibleColumns}
          />
        )}

        {metricBulkImportModal.metric && (
          <BulkImportMetricValuesModal
            show={metricBulkImportModal.show}
            metric={metricBulkImportModal.metric}
            onHide={onHideImportCsvModal}
          />
        )}
      </Card>
      <InfiniteScrollRef ref={infiniteScrollRef} />
    </ScrollWrapper>
  )
}

export default AllMetricsTable
