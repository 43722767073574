import { useCallback } from 'react'

import { useMetricName } from 'utils/hooks/metrics/useMetricName'
import { Metric } from 'utils/types/metrics'
import { useMetricsSandbox } from 'containers/Metrics/SelectedMetrics/useMetricsSandbox'

export const useMetricOption = (metric: Metric) => {
  const { metricName } = useMetricName(metric)
  const {
    isMetricInSandbox,
    toggleMetricInSandbox,
    isAddMetricToSandboxDisabled,
  } = useMetricsSandbox()

  const onClick = useCallback(() => {
    if (isAddMetricToSandboxDisabled() && !isMetricInSandbox(metric)) return

    toggleMetricInSandbox(metric)
  }, [
    metric,
    isAddMetricToSandboxDisabled,
    isMetricInSandbox,
    toggleMetricInSandbox,
  ])

  return {
    metricName,
    checked: isMetricInSandbox(metric),
    onClick,
  }
}
