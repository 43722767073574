import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import GroupTooltips from 'components/GroupTooltips'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import { Color } from 'utils/theme'
import {
  Container,
  Legend,
  MilestoneGroupsContainer,
  Progress,
  ProgressValue,
  ProgressWrapper,
  SharedWithLabel,
  Title,
  TitleChildrenWrapper,
} from './MilestoneIndicator.styles'

const MAX_PROGRESS = 100

const MilestoneIndicator = ({ children }: { children: React.ReactNode }) => {
  return <Container>{children}</Container>
}

const MilestoneTitle = ({
  children,
  mode = 'light',
}: {
  children: React.ReactNode
  mode?: 'light' | 'dark'
}) => (
  <Title mode={mode}>
    <FontAwesomeIcon icon={['far', 'trophy-alt']} />
    <TitleChildrenWrapper>{children}</TitleChildrenWrapper>
  </Title>
)

const MilestoneProgress = ({
  value,
  legend,
  mode = 'light',
  color = 'primaryBlue',
}: {
  value: number
  legend?: string
  mode?: 'light' | 'dark'
  color?: Color
}) => (
  <ProgressWrapper>
    <Progress
      color={color}
      mode={mode}
      value={Math.round(value)}
      max={MAX_PROGRESS}
    />
    <ProgressValue mode={mode}>{`${Math.round(value)}%`}</ProgressValue>
    {legend && <span>{legend}</span>}
  </ProgressWrapper>
)

const MilestoneGroups = ({
  shared,
  groups,
}: {
  shared?: boolean
  groups: any[]
}) => {
  if (!shared) {
    return (
      <MilestoneGroupsContainer>
        <SharedWithLabel>
          <FormattedMessage id="showMetrics.yourGroupOnly" />
        </SharedWithLabel>
      </MilestoneGroupsContainer>
    )
  }

  return (
    <MilestoneGroupsContainer>
      <SharedWithLabel>
        <FormattedMessage id="showMetrics.sharedWith" />
      </SharedWithLabel>
      <GroupTooltips groups={groups} direction="left" />
    </MilestoneGroupsContainer>
  )
}

MilestoneIndicator.Title = MilestoneTitle
MilestoneIndicator.Legend = Legend
MilestoneIndicator.Progress = MilestoneProgress
MilestoneIndicator.Groups = MilestoneGroups

export default MilestoneIndicator
