import {
  MetricStatus,
  METRICS_PAGE_SIZE,
  MetricStates,
} from 'utils/constants/metrics'
import uniq from 'lodash/uniq'
import { store } from 'store'
import compact from 'lodash/compact'
import dayjs from 'dayjs'

import {
  getCurrentGroupType,
  getIsCurrentCompanyFund,
  isActingAsFounder,
} from 'selectors/auth'
import {
  getMetricTrackingSince,
  shouldHoldingSupportMetrics,
  sortMetricDataPoints,
} from 'utils/functions/metrics'
import { download } from 'utils/functions/files'
import { GroupTypes } from 'utils/constants/groups'
import { SortDirection } from 'utils/constants'
import {
  DataPoint,
  InvestorMilestone,
  LinkedMetric,
  Metric,
  MetricSources,
  Milestone,
} from 'utils/types/metrics'
import axiosClient from './httpClient'

type ApiMetric = {
  id: string
  name: string
  frequency: string
  metricSource: MetricSources
  businessCompanyDatum: string
  metricGroups: string[]
  dataPoints: string[]
  senderLinkedMetric?: string
  receiverLinkedMetrics?: string[]
  milestones?: string[]
  investorsMilestones?: string[]
  metrizable?: string
  createdAt: string
}

export type CreateMetricResponse = {
  id: string
  name: string
  frequency?: string
  metricSource: MetricSources
  createdAt: string
}

const METRIZABLE_ID_IN = 'q[metrizable_id_in]'
const METRIZABLE_ID_NOT_IN = 'q[metrizable_id_not_in]'
const NAME_IN = 'q[name_in]'
const NAME_CONT = 'q[name_cont]'
const BUSINESS_COMPANY_DATUM_NAME_CONT =
  'q[metrizable_of_Business::CompanyDatum_type_name_or_name_cont]'
const METRIZABLE_NAME_CONT = 'q[metrizable_name_cont]'
const TEXT_IN = 'text_in'
const BELONGING_COMPANY_NOT_RECEIVING_DATA =
  'q[belonging_to_company_and_not_receiving_data]'
const STATE_IN = 'q[state_in]'
const METRIC_SOURCE_EQ = 'q[metric_source_eq]'
const RECEIVER_GROUP_ID = 'q[receiver_group_id_eq]'
const SENDER_GROUP_ID = 'q[sender_group_id_eq]'
const SELECTED_METRICS = 'user_group_selected'
const USER_PORTFOLIO_SELECTED = 'user_portfolio_selected'

export type MetricsFilters = {
  [METRIZABLE_ID_IN]?: string[]
  [METRIZABLE_ID_NOT_IN]?: string[]
  [NAME_IN]?: string[]
  [NAME_CONT]?: string
  [BUSINESS_COMPANY_DATUM_NAME_CONT]?: string
  [METRIZABLE_NAME_CONT]?: string
  [BELONGING_COMPANY_NOT_RECEIVING_DATA]?: string
  [STATE_IN]?: string[]
  [METRIC_SOURCE_EQ]?: string
  [SELECTED_METRICS]?: boolean
  order_by?: string
  direction?: string
  rendering?: 'render'
  data_points_by_quarter?: boolean
}

type LinkedMetricsFilters = {
  [STATE_IN]?: string[]
  order_by?: string
  direction?: string
}

type CreateInvestorMetricData = {
  name: string
  frequency?: string
  requestDataFromCompanies?: boolean
  message?: string
  companiesOrPortfolios?: any[]
}

type CreateInvestorMetricFromSharedData = CreateInvestorMetricData & {
  sharedMetric: {
    id: string
  }
}

type CreateMetricDTO = {
  metric: {
    name: string
    frequency?: string
    senderLinkedMetricAttributes?: {
      id?: string
      message?: string
    }
  }
  metrizableIds?: string[]
}

export type DataPointDTO = {
  value: string
  date: Date
  sharedGroups?: string[]
}

const getUrlToArchivedDataPoints = (isClient: boolean, metricId: string) => {
  return isClient
    ? `metrics/investor_metrics/${metricId}/investor_data_points/bulk_update`
    : `metrics/founder_metrics/${metricId}/founder_data_points/bulk_update`
}

export default class MetricService {
  static get isFounder() {
    return isActingAsFounder(store.getState())
  }

  static normalizeMetric(
    metric: ApiMetric,
    {
      businessCompanyData,
      dataPoints,
      receiverLinkedMetrics,
      milestones,
      senderLinkedMetrics,
      metrizables,
      investorsMilestones,
    }
  ): Metric {
    const companyData = businessCompanyData?.[metric.businessCompanyDatum]

    const metricDataPoints = sortMetricDataPoints(
      metric.dataPoints.map((dataPointId) => dataPoints[dataPointId])
    )

    const trackingSince = getMetricTrackingSince(metric, metricDataPoints)

    const receiverGroups = compact(
      metric.receiverLinkedMetrics?.map((receiverLinkedMetricId) => {
        const group = receiverLinkedMetrics[receiverLinkedMetricId]
        if (group.state !== MetricStates.UNSHARED) {
          return group?.receiverGroup
        }
        return null
      })
    )

    const receiverLinkedMetricsNormalized = metric.receiverLinkedMetrics?.map(
      (receiverLinkedMetricId) => {
        return receiverLinkedMetrics[receiverLinkedMetricId]
      }
    )

    const senderLinkedMetric =
      metric?.senderLinkedMetric &&
      senderLinkedMetrics?.[metric?.senderLinkedMetric]

    return {
      ...metric,
      companyData,
      dataPoints: metricDataPoints,
      trackingSince,
      milestones:
        metric.milestones?.map((milestoneId) => milestones[milestoneId]) ?? [],
      receiverGroups,
      receiverLinkedMetrics: receiverLinkedMetricsNormalized,
      senderLinkedMetric,
      metrizableType:
        metric?.metrizable && metrizables?.[metric?.metrizable]?.type,
      metrizableName:
        metric?.metrizable && metrizables?.[metric?.metrizable]?.name,
      investorsMilestones:
        metric.investorsMilestones?.map(
          (milestoneId) => investorsMilestones[milestoneId]
        ) ?? [],
    }
  }

  static normalizeLinkedMetric(
    linkedMetric,
    { receiverGroups, receiverMetrics, senderGroups, senderMetrics }
  ): LinkedMetric {
    return {
      ...linkedMetric,
      receiverGroup: receiverGroups?.[linkedMetric.receiverGroupId],
      receiverMetric: receiverMetrics?.[linkedMetric.receiverMetricId],
      senderGroup: senderGroups?.[linkedMetric.senderGroupId],
      senderMetric: senderMetrics?.[linkedMetric.senderMetricId],
    }
  }

  static normalizeInvestorMilestone(
    milestone,
    { groups = {} }
  ): InvestorMilestone {
    return {
      ...milestone,
      group: groups[milestone.group],
    }
  }

  static async editDataPointInvestor(dataPoint: DataPoint) {
    return axiosClient().patch(
      `/metrics/investor_data_points/${dataPoint.id}`,
      {
        dataPoint: {
          date: dataPoint.date,
          value: dataPoint.value,
        },
      }
    )
  }

  static async editDataPointFounder(
    dataPoint: DataPoint & {
      sharedGroupsIds: string[]
    }
  ) {
    const editedDataPoint = {
      date: dataPoint.date,
      value: dataPoint.value,
      sharedGroups: (dataPoint.sharedGroups || [])
        ?.map((group) => group.id)
        .concat(dataPoint.sharedGroupsIds),
    }
    const response = await axiosClient().patch(
      `/metrics/founder_data_points/${dataPoint.id}`,
      {
        dataPoint: editedDataPoint,
      }
    )

    const dataPoints = response.data?.entities?.dataPoints
    const { result } = response.data
    return dataPoints[result]
  }

  static async addDataPointFounder({
    metricId,
    dataPoint,
    sharedGroupsIds,
  }: {
    metricId: string
    dataPoint: DataPoint
    sharedGroupsIds: string[]
  }) {
    const {
      data: {
        entities: { dataPoints },
        result,
      },
    } = await axiosClient().post(
      `/metrics/founder_metrics/${metricId}/founder_data_points`,
      {
        dataPoint: {
          date: dataPoint.date,
          value: dataPoint.value,
          sharedGroups: sharedGroupsIds,
        },
      }
    )

    return Array.isArray(result) ? dataPoints[result[0]] : dataPoints[result]
  }

  static async addDataPointInvestor({
    metricId,
    dataPoint,
  }: {
    metricId: string
    dataPoint: DataPoint
  }) {
    const {
      data: {
        entities: { dataPoints },
        result,
      },
    } = await axiosClient().post(
      `/metrics/investor_metrics/${metricId}/investor_data_points`,
      {
        dataPoint: {
          date: dataPoint.date,
          value: dataPoint.value,
        },
      }
    )
    return Array.isArray(result) ? dataPoints[result[0]] : dataPoints[result]
  }

  static async deleteInvestorDataPoint(dataPoint: DataPoint) {
    return axiosClient().delete(`/metrics/investor_data_points/${dataPoint.id}`)
  }

  static async deleteFounderDataPoint(dataPoint: DataPoint) {
    return axiosClient().delete(`/metrics/founder_data_points/${dataPoint.id}`)
  }

  static async editInvestorMetricById(id: string, metric: Partial<Metric>) {
    const {
      data: { entities, result },
    } = await axiosClient().patch(`/metrics/investor_metrics/${id}`, {
      metric,
    })
    return this.normalizeMetric(entities.metrics[result], entities)
  }

  static async editFounderMetricById(id: string, metric: Partial<Metric>) {
    const {
      data: { entities, result },
    } = await axiosClient().patch(`/metrics/founder_metrics/${id}`, {
      metric,
    })
    return this.normalizeMetric(entities.metrics[result], entities)
  }

  static async getInvestorMetricById(metricId: string) {
    const {
      data: { entities },
    } = await axiosClient().get(`metrics/investor_metrics/${metricId}`)
    const metric = entities.metrics[metricId]
    return this.normalizeMetric(metric, entities)
  }

  static async getFounderMetricById(metricId: string) {
    const {
      data: { entities },
    } = await axiosClient().get(`metrics/founder_metrics/${metricId}`)

    const metric = entities.metrics[metricId]
    return this.normalizeMetric(metric, entities)
  }

  static async getInvestorMetrics({
    paginate = true,
    page = 0,
    pageSize = METRICS_PAGE_SIZE,
    metricName,
    companyDatumIds = [],
    companyDatumIdsToExclude = [],
    sortBy: sortByKey,
    sortDirection,
    onlyCustomMetrics,
    selectedMetrics = false,
    metrizableId,
    selectedUserPortfolioId,
  }: {
    paginate?: boolean
    page?: number
    pageSize?: number
    metricName?: string
    companyDatumIds?: string[]
    companyDatumIdsToExclude?: string[]
    sortBy?: string
    sortDirection?: string
    onlyCustomMetrics?: boolean
    selectedMetrics?: boolean
    metrizableId?: string
    selectedUserPortfolioId?: string
  }): Promise<Metric[]> {
    const params: MetricsFilters = {}

    if (metricName) {
      params[TEXT_IN] = metricName
    }

    if (companyDatumIds?.length) {
      params[METRIZABLE_ID_IN] = companyDatumIds
    }

    if (companyDatumIdsToExclude?.length) {
      params[METRIZABLE_ID_NOT_IN] = companyDatumIdsToExclude
    }

    if (onlyCustomMetrics) {
      params[METRIC_SOURCE_EQ] = MetricSources.Custom
    }

    if (selectedMetrics) {
      params[SELECTED_METRICS] = true
    }

    if (metrizableId) {
      params[METRIZABLE_ID_IN] = [metrizableId]
    }

    if (selectedUserPortfolioId) {
      params[USER_PORTFOLIO_SELECTED] = selectedUserPortfolioId
    }

    if (sortByKey) {
      params.order_by = sortByKey
      params.direction = sortDirection
    }

    let queryString = ''
    if (paginate) {
      queryString = `?page=${page}&per_page=${pageSize}`
    } else {
      params.rendering = 'render'
    }
    const {
      data: { entities, result },
    } = await axiosClient().get(`/metrics/investor_metrics${queryString}`, {
      params,
    })

    return result.map((metricId) => {
      const metric = entities.metrics[metricId]
      return this.normalizeMetric(metric, entities)
    })
  }

  static async getPerformanceMetrics({
    paginate = true,
    page = 0,
    pageSize = METRICS_PAGE_SIZE,
    metricsName = [],
    portfoliosIds,
    sortBy,
    sortDirection,
  }: {
    paginate?: boolean
    page?: number
    pageSize?: number
    metricsName?: string[]
    portfoliosIds: string[]
    sortBy?: string
    sortDirection?: string
  }) {
    let queryString = ''
    const params: MetricsFilters = {}

    params[METRIZABLE_ID_IN] = portfoliosIds

    if (metricsName.length) {
      params[NAME_IN] = metricsName
    }

    if (sortBy) {
      params.order_by = sortBy
      params.direction = sortDirection
    }

    if (paginate) {
      queryString = `?page=${page}&per_page=${pageSize}`
    } else {
      params.rendering = 'render'
    }

    params.data_points_by_quarter = true

    const {
      data: { entities, result },
    } = await axiosClient().get(`/metrics/investor_metrics${queryString}`, {
      params,
    })

    return result.map((metricId) => {
      const metric = entities.metrics[metricId]
      return this.normalizeMetric(metric, entities)
    })
  }

  static async getFounderMetrics({
    page = 0,
    pageSize = METRICS_PAGE_SIZE,
    sortBy: sortByKey,
    sortDirection,
    selectedMetrics = false,
    params: currParams,
  }: {
    page: number
    pageSize: number
    sortBy?: string
    sortDirection?: string
    selectedMetrics?: boolean
    params?: MetricsFilters
  }): Promise<Metric[]> {
    const params: MetricsFilters = {
      [METRIC_SOURCE_EQ]: MetricSources.Custom,
    }

    if (selectedMetrics) {
      params[SELECTED_METRICS] = true
    }

    if (sortByKey) {
      params.order_by = sortByKey
      params.direction = sortDirection
    }

    const response = await axiosClient().get(
      `/metrics/founder_metrics?page=${page}&per_page=${pageSize}`,
      { params: { ...params, ...currParams } }
    )

    const { result, entities } = response.data

    return result.map((metricId) => {
      const metric = entities.metrics[metricId]
      return this.normalizeMetric(metric, entities)
    })
  }

  static async createInvestorMetric(
    data: CreateInvestorMetricData,
    currentGroupId?: string
  ) {
    const metricData: CreateMetricDTO = {
      metric: {
        name: data.name,
        frequency: data.frequency?.toLowerCase(),
      },
    }

    if (data.requestDataFromCompanies) {
      metricData.metric.senderLinkedMetricAttributes = { message: data.message }
    }

    if (data.companiesOrPortfolios) {
      metricData.metrizableIds = uniq(
        data.companiesOrPortfolios
          ?.map((element) => {
            if (element?.portfolioCompanies) {
              return element.portfolioCompanies
                .filter((portfolioCompany) =>
                  shouldHoldingSupportMetrics(
                    portfolioCompany.holding,
                    currentGroupId
                  )
                )
                .map((portfolioCompany) => portfolioCompany.holding?.id)
            }
            return element?.id
          })
          .flat()
      )
    }

    const response = await axiosClient().post(
      'metrics/investor_metrics',
      metricData
    )

    const { entities } = response.data

    return {
      metrics: Object.values(entities.metrics) as CreateMetricResponse[],
      metrizableIds: metricData.metrizableIds,
    }
  }

  static async createInvestorMetricFromSharedMetric(
    data: CreateInvestorMetricFromSharedData
  ) {
    const metricData: CreateMetricDTO = {
      metric: {
        name: data.name,
        frequency: data.frequency?.toLowerCase(),
        senderLinkedMetricAttributes: {
          id: data.sharedMetric.id,
        },
      },
    }

    const response = await axiosClient().post(
      '/metrics/investor_metrics/create_from_shared',
      metricData
    )
    const { entities } = response.data

    return Object.values(entities.metrics).map((metric: ApiMetric) =>
      this.normalizeMetric(metric, entities)
    )
  }

  static deleteInvestorMetric(metricId, currentGroupId) {
    return axiosClient(false, { 'group-id': currentGroupId }).delete(
      `metrics/investor_metrics/${metricId}`
    )
  }

  static deleteFounderMetric(metricId, currentGroupId) {
    return axiosClient(false, { 'group-id': currentGroupId }).delete(
      `metrics/founder_metrics/${metricId}`
    )
  }

  static async createFounderMetric({
    name,
    frequency,
    sharedGroups,
  }: {
    name: string
    frequency?: string
    sharedGroups?: any[]
  }) {
    const receiverLinkedMetricsAttributes = sharedGroups
      ?.filter((group) => group.type !== GroupTypes.INVESTOR_GROUP)
      .map((group) => {
        if (group.linkedMetricId) {
          // If accepting a metric request and creating new metric
          return {
            id: group.linkedMetricId,
            state: 'request_accepted',
          }
        }
        return {
          receiverGroupId: group.id,
          state: 'share_sent',
        }
      })
    const frequencyBody =
      typeof frequency !== 'undefined'
        ? { frequency: frequency?.toLowerCase() }
        : {}
    const response = await axiosClient().post('/metrics/founder_metrics', {
      metric: {
        name,
        receiverLinkedMetricsAttributes,
        ...frequencyBody,
      },
    })
    const { result, entities } = response.data
    const metric = entities.metrics[result]
    return this.normalizeMetric(metric, entities)
  }

  static async updateRequestedMetric(
    linkedMetricId,
    { status },
    currentGroupId
  ) {
    const path =
      status === MetricStatus.DENIED ? 'deny_request' : 'join_metric_request'
    await axiosClient(
      false,
      currentGroupId
        ? {
            'group-id': currentGroupId,
          }
        : undefined
    ).patch(`/metrics/linked_metrics/${linkedMetricId}/${path}`)
  }

  static async getMetricsByCompanyIdThatAreNotReceivingData(
    companyId: string,
    metricName?: string
  ): Promise<Metric[]> {
    const params: MetricsFilters = {
      [NAME_CONT]: metricName,
      [BELONGING_COMPANY_NOT_RECEIVING_DATA]: companyId,
    }
    const {
      data: { entities, result },
    } = await axiosClient().get(`/metrics/investor_metrics`, {
      params,
    })

    return result.map((metricId) => {
      return this.normalizeMetric(entities.metrics[metricId], entities)
    })
  }

  static async getMetricsByCompanyId(
    id: string,
    {
      page,
      text,
      sortBy,
      sortDirection,
      requestFounderMetrics,
    }: {
      page: number
      text?: string
      sortBy?: string
      sortDirection?: SortDirection
      requestFounderMetrics?: boolean
    }
  ) {
    const currentGroup = getCurrentGroupType(store.getState())
    const isCurrentCompanyFund = getIsCurrentCompanyFund(store.getState())
    let url = ''
    const params: MetricsFilters = {
      [METRIZABLE_ID_IN]: [id],
    }

    if (
      currentGroup === GroupTypes.FOUNDER ||
      isCurrentCompanyFund ||
      requestFounderMetrics
    ) {
      url = `/metrics/founder_metrics?page=${page}&per_page=${METRICS_PAGE_SIZE}`
    } else {
      url = `/metrics/investor_metrics?page=${page}&per_page=${METRICS_PAGE_SIZE}`
    }

    if (text) {
      params[NAME_CONT] = text
    }

    if (sortBy) {
      params.order_by = sortBy
      params.direction = sortDirection
    }

    const {
      data: { entities, result },
    } = await axiosClient().get(url, { params })

    return result.map((metricId) => {
      if (currentGroup === GroupTypes.FOUNDER) {
        const metric = entities.metrics[metricId]
        return this.normalizeMetric(metric, entities)
      }
      return this.normalizeMetric(entities.metrics[metricId], entities)
    })
  }

  static async getLinkedMetrics({
    page,
    pageSize,
    status,
    receiverGroupId,
    senderGroupId,
  }: {
    page: number
    pageSize: number
    status?: MetricStates[]
    receiverGroupId?: string
    senderGroupId?: string
  }) {
    const params: LinkedMetricsFilters = {}

    if (status) {
      params[STATE_IN] = status
    }

    if (receiverGroupId) {
      params[RECEIVER_GROUP_ID] = receiverGroupId
    }

    if (senderGroupId) {
      params[SENDER_GROUP_ID] = senderGroupId
    }

    const {
      data: { entities },
    } = await axiosClient().get(
      `/metrics/linked_metrics?page=${page}&per_page=${pageSize}`,
      {
        params,
      }
    )

    return Object.values(entities?.linkedMetrics || {}).map((linkedMetric) =>
      this.normalizeLinkedMetric(linkedMetric, entities)
    )
  }

  static async archiveDataPoints(
    metricId: string,
    dataPoints: DataPoint[],
    isClient = false
  ) {
    const url = getUrlToArchivedDataPoints(isClient, metricId)

    return axiosClient().patch(url, {
      dataPoints: dataPoints.map((dataPoint) => ({
        id: dataPoint.id,
        archive: true,
      })),
    })
  }

  static async unArchiveDataPoints(
    metricId: string,
    dataPoints: DataPoint[],
    isClient = false
  ) {
    const url = getUrlToArchivedDataPoints(isClient, metricId)

    return axiosClient().patch(url, {
      dataPoints: dataPoints.map((dataPoint) => ({
        id: dataPoint.id,
        archive: false,
      })),
    })
  }

  static async deleteDataPoints(dataPoints: DataPoint[], isFounder: boolean) {
    const dataPointsIds = dataPoints.map((dp) => dp.id)
    const url = isFounder
      ? `/metrics/founder_data_points/`
      : `/metrics/investor_data_points/`

    const promises = dataPointsIds.map((dpId) => {
      return axiosClient().delete(`${url}${dpId}`)
    })

    const response = await Promise.all(promises)
    return response
  }

  static async addGroupToExistingMetric({
    linkedMetricId,
    founderMetricId,
    includeHistory,
  }: {
    linkedMetricId: string
    founderMetricId: string
    includeHistory: boolean
  }) {
    await axiosClient().patch(
      `/metrics/linked_metrics/${linkedMetricId}/join_metric_request`,
      {
        linkedMetric: {
          senderMetricId: founderMetricId,
        },
        historicalData: includeHistory,
      }
    )
  }

  static async addFounderMetricToClientMetric({
    linkedMetricId,
    receiverMetricId,
  }: {
    linkedMetricId: string
    receiverMetricId: string
  }) {
    const response = await axiosClient().patch(
      `/metrics/linked_metrics/${linkedMetricId}/join_metric_share`,
      {
        linkedMetric: {
          receiverMetricId,
        },
      }
    )

    return response.data.entities.linkedMetrics[response.data.result]
  }

  static async toggleReceiveDataFromFounder(
    receiveData: boolean,
    metricLinkedId: string
  ) {
    await axiosClient().patch(
      `/metrics/linked_metrics/${metricLinkedId}/update_receive_data`,
      { receiveData }
    )
  }

  static async sendRequestToFounder(metricId: string) {
    await axiosClient().post(`/metrics/linked_metrics/send_request`, {
      metricId,
    })
  }

  static async bulkShareDataPoints({
    dataPoints,
    metric,
    currentGroups,
  }: {
    dataPoints: DataPoint[]
    metric: Metric
    currentGroups: any[]
  }) {
    await axiosClient().patch(
      `metrics/founder_metrics/${metric.id}/founder_data_points/bulk_update`,
      {
        dataPoints: dataPoints.map((dp) => ({
          id: dp.id,
          sharedGroups: currentGroups.map((currGroup) => currGroup.id),
        })),
      }
    )
  }

  static getFounderMilestones = async (metricId: string) => {
    const {
      data: { entities, result },
    } = await axiosClient().get(
      `/metrics/founder_metrics/${metricId}/founder_milestones`
    )
    return result.map((milestoneId) =>
      this.normalizeInvestorMilestone(
        entities.milestones[milestoneId],
        entities
      )
    )
  }

  static getFounderMilestone = async (milestoneId: string) => {
    const {
      data: { entities, result },
    } = await axiosClient().get(`/metrics/founder_milestones/${milestoneId}`)
    return this.normalizeInvestorMilestone(
      entities.milestones[result],
      entities
    )
  }

  static setFounderMilestone = async (
    metricId: string,
    milestone: Milestone
  ) => {
    const {
      data: { entities, result },
    } = await axiosClient().post(
      `/metrics/founder_metrics/${metricId}/founder_milestones`,
      { milestone }
    )

    return this.normalizeInvestorMilestone(
      entities.milestones[result],
      entities
    )
  }

  static editFounderMilestone = async (
    milestoneId: string,
    milestone: Milestone
  ) => {
    const {
      data: { entities, result },
    } = await axiosClient().patch(
      `/metrics/founder_milestones/${milestoneId}`,
      {
        milestone,
      }
    )
    return this.normalizeInvestorMilestone(
      entities.milestones[result],
      entities
    )
  }

  static deleteFounderMilestone = (milestoneId: string) => {
    return axiosClient().delete(`/metrics/founder_milestones/${milestoneId}`)
  }

  static getInvestorMilestones = async (metricId: string) => {
    const {
      data: { entities, result },
    } = await axiosClient().get(
      `/metrics/investor_metrics/${metricId}/investor_milestones`
    )
    return result.map((milestoneId) =>
      this.normalizeInvestorMilestone(
        entities.milestones[milestoneId],
        entities
      )
    )
  }

  static getInvestorMilestone = async (milestoneId: string) => {
    const {
      data: { entities, result },
    } = await axiosClient().get(`/metrics/investor_milestones/${milestoneId}`)
    return this.normalizeInvestorMilestone(
      entities.milestones[result],
      entities
    )
  }

  static setInvestorMilestone = async (
    metricId: string,
    milestone: Milestone
  ) => {
    const {
      data: { entities, result },
    } = await axiosClient().post(
      `/metrics/investor_metrics/${metricId}/investor_milestones`,
      { milestone }
    )

    return this.normalizeInvestorMilestone(
      entities.milestones[result],
      entities
    )
  }

  static editInvestorMilestone = async (
    milestoneId: string,
    milestone: Milestone
  ) => {
    const {
      data: { entities, result },
    } = await axiosClient().patch(
      `/metrics/investor_milestones/${milestoneId}`,
      {
        milestone,
      }
    )
    return this.normalizeInvestorMilestone(
      entities.milestones[result],
      entities
    )
  }

  static deleteInvestorMilestone = (milestoneId: string) => {
    return axiosClient().delete(`/metrics/investor_milestones/${milestoneId}`)
  }

  static approveAllMetrics = (linkedMetrics: LinkedMetric[]) => {
    return axiosClient().post('/metrics/linked_metrics/bulk_approve_requests', {
      linkedMetrics,
    })
  }

  static denyAllMetrics = (linkedMetricsIds: string[]) => {
    return axiosClient().post('/metrics/linked_metrics/bulk_deny_requests', {
      linkedMetricsIds,
    })
  }

  static exportMetricData = async (metricId: string, metricName: string) => {
    const response = await axiosClient().get(
      `/metrics/${
        MetricService.isFounder ? 'founder_metrics' : 'investor_metrics'
      }/${metricId}/export`
    )

    download(
      `metric-${metricName}-${dayjs().format('HH:MM:SS')}`,
      response.data
    )
  }

  static exportAllMetrics = async () => {
    const response = await axiosClient().get(
      `/metrics/${
        MetricService.isFounder ? 'founder_metrics' : 'investor_metrics'
      }/export`
    )

    download(`metrics-${dayjs().format('HH:MM:SS')}`, response.data)
  }

  static parseDataPointsCsvFile = async (file: File) => {
    const formData = new FormData()
    formData.append('[metrics_file]', file)
    const response = await axiosClient(true).post(
      `/metrics/${
        MetricService.isFounder ? 'founder_metrics' : 'investor_metrics'
      }/csv_parse`,
      formData
    )
    return response.data
  }

  static bulkCreateDataPoints = async (
    metricId: string,
    dataPoints: DataPointDTO[]
  ) => {
    const response = await axiosClient().post(
      `/metrics/${
        MetricService.isFounder ? 'founder_metrics' : 'investor_metrics'
      }/${metricId}/${
        MetricService.isFounder ? 'founder_data_points' : 'investor_data_points'
      }/bulk_create`,
      { dataPoints }
    )
    return response.data
  }
}
