/* eslint-disable react/style-prop-object */
import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage, useIntl } from 'react-intl'

import Input from 'ui/Input'
import Button from 'ui/Button'
import Separator from 'ui/Separator'
import SelectDatePicker from 'components/TimeSelect/SelectDatePicker'
import Dropdown from 'ui/Dropdown'
import BoxGroupsAndUsersChips from 'components/BoxGroupsAndUsersChips'
import ReadOnlyTooltip from 'components/ReadOnlyTooltip/ReadOnlyTooltip'
import DropdownEntity from 'components/GroupsUsersAddInput/DropdownEntity'

import Drawer from 'components/Drawer'
import { MetricTitle } from '../ShowMetric/ShowMetric.styles'
import useAddMetricValue from './useAddMetricValue'
import {
  ShareWithWrapper,
  Form,
  CTAWrapper,
} from '../EditMetric/EditMetric.styles'

const AddMetricValue = ({ closeDrawer, isOpen }) => {
  const {
    metric,
    handleSubmit,
    register,
    errors,
    onDateChange,
    isValid,
    date,
    isFounder,
    handleAddGroup,
    handlePressEnter,
    dropdownRef,
    handleRemoveGroup,
    currentGroups = [],
    readOnlyMode,
    dataPoint = {},
    isEditing,
    loadGroups,
    founderUnverified,
  } = useAddMetricValue(closeDrawer)

  const intl = useIntl()

  let sharedGroups = []
  if (dataPoint?.sharedGroups?.length) {
    sharedGroups = dataPoint?.sharedGroups?.map((group) => ({
      ...group,
      canDelete: false,
    }))
  }

  const displayGroups = currentGroups
    ? [...sharedGroups, ...currentGroups]
    : [...sharedGroups]

  return (
    <Drawer
      isOpen={isOpen}
      headerTitle={`${
        isEditing
          ? intl.formatMessage({ id: 'metrics.editValue' })
          : intl.formatMessage({ id: 'metrics.addValue' })
      } - ${metric?.name}`}
      onCloseDrawer={closeDrawer}
    >
      <Form onSubmit={handleSubmit}>
        <Drawer.Content contentPadding="1.5rem 2.4rem">
          <Input
            type="number"
            name="metricValue"
            ref={register}
            error={errors.metricValue}
            label={intl.messages['metrics.metricValue']}
            placeholder={intl.messages['metrics.addValue']}
            readOnlyMode={readOnlyMode}
            readOnlyValue={dataPoint.value}
          />

          <Separator space="1.8rem" />

          <SelectDatePicker
            name="date"
            handleChange={onDateChange}
            placeholder={intl.messages['metrics.metricDate']}
            label={intl.messages['metrics.metricValueDate']}
            onlyMonthYearPicker={false}
            register={register}
            value={date}
            error={errors.date}
            dateFormat="dd MMM, yyyy"
            maxDate={new Date()}
            readOnlyMode={readOnlyMode}
          />

          <Separator space="1.8rem" />

          {isFounder && (
            <ShareWithWrapper>
              <ReadOnlyTooltip
                place="left"
                text={intl.messages['updates.readOnlyMetrics']}
                disabled={founderUnverified}
                topOffset={-10}
              >
                {({ disabled }) => (
                  <Dropdown
                    async
                    ref={dropdownRef}
                    onPressEnter={handlePressEnter}
                    type="input"
                    icon={['far', 'plus']}
                    iconFontSize="1.8rem"
                    clearOnSelect
                    dropdownOptionPadding="0"
                    maxListHeight="30rem"
                    label={intl.messages['metrics.sharedWith']}
                    formatSelectedOptionComponent={(option) => (
                      <DropdownEntity entity={option.value} />
                    )}
                    getOption={(entity) => ({
                      value: {
                        type: entity.type,
                        name: entity.name,
                        handle: entity.handle,
                        logo: entity.logo?.url,
                      },
                      name: entity.name,
                      id: entity.id,
                    })}
                    placeholder={intl.messages['metrics.sharedWithPlaceholder']}
                    loadOptions={loadGroups}
                    onSelectOption={handleAddGroup}
                    disabled={disabled}
                  />
                )}
              </ReadOnlyTooltip>
              <BoxGroupsAndUsersChips
                items={displayGroups?.map((item) => ({
                  ...item,
                  fromMetric: true,
                }))}
                handleDelete={handleRemoveGroup}
              />
            </ShareWithWrapper>
          )}
        </Drawer.Content>

        <Drawer.Footer>
          <Button secondary type="button" onClick={closeDrawer}>
            <FormattedMessage id="general.cancel" />
          </Button>

          <Button primary type="submit">
            <FormattedMessage id="general.save" />
          </Button>
        </Drawer.Footer>
      </Form>
    </Drawer>
  )
}

AddMetricValue.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
}

export default AddMetricValue
