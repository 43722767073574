import { useRef, useState, useMemo, useLayoutEffect, useEffect } from 'react'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useHistory } from 'react-router-dom'

import { getCurrentGroupId } from 'selectors/auth'
import { maxSize } from 'utils/constants/breakpoint'
import { DisplayMode, MetricStates } from 'utils/constants/metrics'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import useMetricQuery from 'utils/hooks/useMetricQuery'
import { useWindowResize } from 'utils/hooks/windowResize'
import { Metric } from 'utils/types/metrics'
import { ValueOf } from 'utils/types/common'

export const useProfileMetric = (
  initialMetricData: Metric,
  linkTo: string,
  defaultDisplayMode?: ValueOf<typeof DisplayMode>
) => {
  const history = useHistory()
  const currentGroupId = useAppSelector(getCurrentGroupId)
  const { matches: isMobile } = useMediaQuery(maxSize.sm)
  const dataPointsContainerRef = useRef<HTMLDivElement>(null)
  const [width, setWidth] = useState(0)
  const [displayMode, setDisplayMode] = useState<ValueOf<typeof DisplayMode>>(
    defaultDisplayMode || DisplayMode.VALUE
  )

  const {
    metric,
    isLoading,
    groupedMilestones: { activeMilestones },
  } = useMetricQuery({
    metricId: initialMetricData.id,
    metricData: initialMetricData,
    refetchOnMount: false,
  })

  const dataPoints = useMemo(
    () => metric?.dataPoints.filter((dp) => !dp.archive) || [],
    [metric?.dataPoints]
  )

  const showFounderDataPulse =
    metric?.senderLinkedMetric?.receiveData &&
    (metric?.senderLinkedMetric?.state === MetricStates.SHARE_ACCEPTED ||
      metric?.senderLinkedMetric?.state === MetricStates.REQUEST_ACCEPTED)

  const sharedGroups = useMemo(() => {
    return (metric?.receiverGroups || []).filter(
      (metricGroup) => metricGroup?.id !== currentGroupId
    )
  }, [currentGroupId, metric])

  const onToggleDisplayMode = () => {
    setDisplayMode(
      displayMode === DisplayMode.VALUE ? DisplayMode.CHART : DisplayMode.VALUE
    )
  }

  // **** Another way to shift the display mode besides the onToggleDisplayMode ****
  const onSelectDisplayMode = (mode: ValueOf<typeof DisplayMode>) => {
    setDisplayMode(mode)
  }

  useLayoutEffect(() => {
    setWidth(dataPointsContainerRef.current?.clientWidth || 0)
  }, [])

  useWindowResize(() => {
    setWidth(0)
    setTimeout(() => {
      setWidth(
        (currentWidth) =>
          dataPointsContainerRef.current?.clientWidth || currentWidth
      )
    }, 0)
  })

  useEffect(() => {
    if (dataPointsContainerRef.current?.clientWidth) {
      setWidth(dataPointsContainerRef.current.clientWidth)
    }
  }, [dataPointsContainerRef.current?.clientWidth])

  const redirectToLogs = () => {
    history.push(linkTo, { activeTab: 2 })
  }

  return {
    metric,
    isLoading,
    showFounderDataPulse,
    sharedGroups,
    isMobile,
    dataPoints,
    dataPointsContainerRef,
    width,
    activeMilestones,
    displayMode,
    redirectToLogs,
    onSelectDisplayMode,
    onToggleDisplayMode,
  }
}
