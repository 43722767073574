import { useCallback, useMemo, useState } from 'react'
import { DataVisualizationColor } from 'utils/theme'
import { Metric } from 'utils/types/metrics'

const CHART_COLORS: DataVisualizationColor[] = [
  'blue',
  'brightBlue',
  'skyBlue',
  'aquaGreen',
  'darkGreen',
  'green',
  'yellowGreen',
  'yellow',
  'darkYellow',
  'orange',
  'darkOrange',
  'red',
  'darkRed',
  'darkPink',
  'purple',
  'bluePurple',
]

interface MetricsColorProps {
  metrics: Metric[]
}

export interface SelectedMetrics {
  metric: Metric
  color: DataVisualizationColor
  isVisible: boolean
}

const useMetricsColor = ({ metrics }: MetricsColorProps) => {
  const [hiddenMetrics, setHiddenMetrics] = useState<Record<string, true>>({})

  const selectedMetrics = useMemo(
    () =>
      metrics.map((metric, index) => ({
        metric,
        color: CHART_COLORS[
          index % CHART_COLORS.length
        ] as DataVisualizationColor,
        isVisible: hiddenMetrics[metric.id] !== true,
      })),
    [hiddenMetrics, metrics]
  )

  const selectedMetricsMap = useMemo(() => {
    return selectedMetrics.reduce<Record<string, SelectedMetrics>>(
      (acc, { metric, color, isVisible }) => {
        acc[metric.id] = { metric, color, isVisible }
        return acc
      },
      {}
    )
  }, [selectedMetrics])

  const isMetricInSandbox = useCallback(
    (metric: Metric) => {
      return !!selectedMetricsMap[metric.id]
    },
    [selectedMetricsMap]
  )

  const getMetricColor = useCallback(
    (metric: Metric) => {
      if (isMetricInSandbox(metric)) {
        return selectedMetricsMap[metric.id].color
      }

      return (CHART_COLORS.find(
        (color) => !selectedMetrics.some((m) => m.color === color)
      ) ||
        CHART_COLORS[
          selectedMetrics.length % CHART_COLORS.length
        ]) as DataVisualizationColor
    },
    [isMetricInSandbox, selectedMetrics, selectedMetricsMap]
  )

  const changeMetricVisibility = useCallback(
    (metricId: string, isVisible?: boolean) => {
      setHiddenMetrics((prev) => {
        if (isVisible) {
          const { [metricId]: _, ...rest } = prev
          return rest
        }

        if (isVisible === false) {
          return { ...prev, [metricId]: true }
        }

        if (prev[metricId]) {
          const { [metricId]: _, ...rest } = prev
          return rest
        }

        return { ...prev, [metricId]: true }
      })
    },
    []
  )

  const isMetricVisible = useCallback(
    (metricId: string) => {
      return !hiddenMetrics[metricId]
    },
    [hiddenMetrics]
  )

  return {
    selectedMetrics,
    getMetricColor,
    isMetricInSandbox,
    changeMetricVisibility,
    isMetricVisible,
  }
}

export default useMetricsColor
