import { Dispatch, SetStateAction, useCallback, useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { OrderByProps } from 'api/PortfolioService'
import { SortDirection, SortProperties } from 'utils/constants'
import { PortfolioTypes } from 'utils/constants/portfolio'
import usePortfoliosQuery from 'utils/hooks/usePortfolioQuery'
import { Portfolio, PortfolioCompanyWithFmv } from 'utils/types/portfolios'
import { orderArrayByObjectPropertyAndDirection } from 'utils/functions/array'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { isActingAsInvestorGroup } from 'selectors/auth'

import {
  useChangePortfolioNameMutation,
  useDeletePortfolioMutation,
  usePinnedPortfolioMutation,
} from 'utils/hooks/queries/usePortfolioQuery'
import {
  ALL_ENTITIES_FILTER_OPTION,
  BaseOption,
  InvestmentsNavigationOption,
} from './types'
import { normalizePortfolio } from './helpers'

interface Props {
  setCreatePortfolioModalOpen: Dispatch<SetStateAction<boolean>>
  setDisplayBigPortfolioNavigation: Dispatch<SetStateAction<boolean>>
  setPortfolioTypeFilterOptions: Dispatch<SetStateAction<BaseOption[]>>
}

const usePortfolioInvestments = ({
  setCreatePortfolioModalOpen,
  setDisplayBigPortfolioNavigation,
  setPortfolioTypeFilterOptions,
}: Props) => {
  const history = useHistory()
  const isActingAsInvestor = useAppSelector(isActingAsInvestorGroup)

  const {
    data: portfolios,
    isLoading: isLoadingPortfolios,
    refetch: refetchPortfolios,
  } = usePortfoliosQuery({
    direction: SortDirection.DESC,
    orderBy: OrderByProps.TYPE,
    perPage: 500,
  })

  const { mutate: changePortfolioNameMutation } =
    useChangePortfolioNameMutation()

  const { mutate: deletePortfolioMutation } = useDeletePortfolioMutation()

  const { mutate: togglePinPortfolio, isLoading: isMakingPinnedReq } =
    usePinnedPortfolioMutation()

  const normalizedPortfolios = useMemo(() => {
    const portfolioOptions = portfolios?.pages
      .map((page) => page.portfolios)
      .flat()
      .map((portfolio) => normalizePortfolio(portfolio, isActingAsInvestor))

    return portfolioOptions
  }, [portfolios?.pages, isActingAsInvestor])

  const fetchedPortfolios = useMemo(() => {
    const portfolioOptions = portfolios?.pages
      .map((page) => page.portfolios)
      .flat()
      .map((portfolio) => portfolio)
      .filter((portfolio) => portfolio.type !== PortfolioTypes.TRACK)

    return portfolioOptions
  }, [portfolios])

  const changePortfolioName = useCallback(
    async ({
      portfolio,
      value,
    }: {
      portfolio: Portfolio<PortfolioCompanyWithFmv>
      value: string
    }) => {
      changePortfolioNameMutation({
        portfolio,
        value,
      })
    },
    [changePortfolioNameMutation]
  )

  const onPortfolioCreated = async (
    createdPortfolio: Portfolio<PortfolioCompanyWithFmv>
  ) => {
    setCreatePortfolioModalOpen(false)
    setDisplayBigPortfolioNavigation(false)
    setPortfolioTypeFilterOptions(ALL_ENTITIES_FILTER_OPTION)

    await refetchPortfolios()

    const newNormalizedPortfolio = normalizePortfolio(
      createdPortfolio,
      isActingAsInvestor
    )
    history.push(newNormalizedPortfolio.url)
  }

  const onPortfolioDeleted = (
    portfolio: Portfolio<PortfolioCompanyWithFmv>
  ) => {
    deletePortfolioMutation({
      portfolioId: portfolio.id,
      portfolioType: portfolio.type,
    })
  }

  const portfolioNavigationOptions = useMemo(() => {
    if (!normalizedPortfolios) return []

    let sortedOptions: InvestmentsNavigationOption[] = []

    sortedOptions = orderArrayByObjectPropertyAndDirection(
      normalizedPortfolios,
      SortProperties.TYPE
    )

    const hasPinnedPortfolios = sortedOptions.some(
      (portfolio) => portfolio.pinned
    )

    if (hasPinnedPortfolios) {
      const pinnedPortfolios = sortedOptions
        .filter((portfolio) => portfolio.pinned)
        .sort((a, b) => (a.label as string).localeCompare(b.label as string))

      const nonPinnedPortfolios = sortedOptions.filter(
        (portfolio) => !portfolio.pinned
      )

      const orderedNonPinnedPortfolios = orderArrayByObjectPropertyAndDirection(
        nonPinnedPortfolios,
        SortProperties.TYPE
      )

      sortedOptions = [...pinnedPortfolios, ...orderedNonPinnedPortfolios]
    }

    return sortedOptions
  }, [normalizedPortfolios])

  const portfolioTypes = useMemo<PortfolioTypes[]>(() => {
    if (!normalizedPortfolios) return []

    const types = normalizedPortfolios.map((portfolio) => portfolio.type)
    const uniqueTypes = Array.from(new Set(types))

    return uniqueTypes as PortfolioTypes[]
  }, [normalizedPortfolios])

  const hasOneTrackPortfolio = useMemo(() => {
    if (!normalizedPortfolios) return false

    const trackPortfolios = normalizedPortfolios.filter(
      (portfolio) => portfolio.type === PortfolioTypes.TRACK
    )

    return trackPortfolios.length === 1
  }, [normalizedPortfolios])

  return {
    fetchedPortfolios,
    isLoadingPortfolios,
    isMakingPinnedReq,
    portfolioNavigationOptions,
    portfolioTypes,
    hasOneTrackPortfolio,
    changePortfolioName,
    onPortfolioDeleted,
    togglePinPortfolio,
    onPortfolioCreated,
  }
}

export default usePortfolioInvestments
