import {
  createContext,
  FC,
  ReactElement,
  useContext,
  useMemo,
  useState,
} from 'react'

import { useDebouncedState } from 'utils/hooks/useDebouncedState'
import { SortDirection } from 'utils/constants/sortDirection'
import { MetricSortBy } from 'utils/constants/metrics'
import { useEventListener } from 'utils/hooks/useEventListener'
import { metricsKeys } from 'utils/queries/metrics'
import { useQueryClient } from '@tanstack/react-query'
import {
  ARCHIVE_DP_METRIC_EVENT,
  CREATED_METRIC_EVENT,
  EDIT_DP_METRIC_EVENT,
  IMPORT_METRIC_EVENT,
  UN_ARCHIVE_DP_METRIC_EVENT,
  UPDATE_ALL_METRICS_EVENT,
} from 'utils/constants/events'

interface MetricsContextProps {
  search: string
  debouncedSearch: string
  sortCriteria: SortCriteria
  setSortCriteria: (sort: SortCriteria) => void
  onChange: (value: string) => void
}

interface SortCriteria {
  field: MetricSortBy
  direction: SortDirection
}

interface MetricsContextProviderProps {
  children: ReactElement | ReactElement[]
}

export const MetricsContext = createContext({} as MetricsContextProps)

export const MetricsContextProvider: FC<MetricsContextProviderProps> = ({
  children,
}) => {
  const [search, debouncedSearch, setSearch] = useDebouncedState('')
  const [sortCriteria, setSortCriteria] = useState<SortCriteria>({
    field: MetricSortBy.NAME,
    direction: SortDirection.ASC,
  })

  const queryClient = useQueryClient()

  const value = useMemo(
    () => ({
      search,
      debouncedSearch,
      sortCriteria,
      setSortCriteria,
      onChange: setSearch,
    }),
    [search, debouncedSearch, sortCriteria, setSearch]
  )

  const invalidateQueries = (isFounderMetric: boolean) => {
    const filters = {
      metricName: search,
      sortBy: sortCriteria.field,
      sortDirection: sortCriteria.direction,
    }

    if (isFounderMetric) {
      queryClient.invalidateQueries(metricsKeys.getFounderMetrics(filters))
      queryClient.invalidateQueries(
        metricsKeys.getFounderMetrics({
          selected: true,
        })
      )
    }

    queryClient.invalidateQueries(metricsKeys.getInvestorMetrics(filters))
    queryClient.invalidateQueries(
      metricsKeys.getInvestorMetrics({
        selected: true,
      })
    )
  }

  useEventListener(
    CREATED_METRIC_EVENT,
    ({ isFounderMetric }: { isFounderMetric: boolean }) => {
      invalidateQueries(isFounderMetric)
    }
  )

  useEventListener(
    UPDATE_ALL_METRICS_EVENT,
    ({ isFounderMetric }: { isFounderMetric: boolean }) => {
      invalidateQueries(isFounderMetric)
    }
  )

  useEventListener(
    IMPORT_METRIC_EVENT,
    ({ isFounderMetric }: { isFounderMetric: boolean }) => {
      invalidateQueries(isFounderMetric)
    }
  )

  useEventListener(
    ARCHIVE_DP_METRIC_EVENT,
    ({ isFounderMetric }: { isFounderMetric: boolean }) => {
      invalidateQueries(isFounderMetric)
    }
  )

  useEventListener(
    EDIT_DP_METRIC_EVENT,
    ({ isFounderMetric }: { isFounderMetric: boolean }) => {
      invalidateQueries(isFounderMetric)
    }
  )

  useEventListener(
    UN_ARCHIVE_DP_METRIC_EVENT,
    ({ isFounderMetric }: { isFounderMetric: boolean }) => {
      invalidateQueries(isFounderMetric)
    }
  )

  return (
    <MetricsContext.Provider value={value}>{children}</MetricsContext.Provider>
  )
}

export const useMetricsContext = () => useContext(MetricsContext)
