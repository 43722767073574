export enum MetricStatus {
  SHARED = 'shared',
  PENDING = 'pending',
  DENIED = 'denied',
}

export enum MetricStates {
  REQUEST_SENT = 'request_sent',
  REQUEST_DENIED = 'request_denied',
  REQUEST_ACCEPTED = 'request_accepted',
  SHARE_SENT = 'share_sent',
  SHARE_ACCEPTED = 'share_accepted',
  UNSHARED = 'unshared',
  SHARE_DENIED = 'share_denied',
}

export const METRIC_NAMES = {
  CUSTOMER_ACQUISITION_COST: 'CUSTOMER_ACQUISITION_COST',
  CUSTOMER_CHURN_RATE: 'CUSTOMER_CHURN_RATE',
  CUSTOMER_LIFETIME_VALUE: 'CUSTOMER_LIFETIME_VALUE',
  CUSTOMER_SATISFACTION_RETENTION: 'CUSTOMER_SATISFACTION_RETENTION',
  NUMBER_OF_CUSTOMERS: 'NUMBER_OF_CUSTOMERS',
  REVENUE: 'REVENUE',
  EXPENSES: 'EXPENSES',
  OPERATING_CASH_FLOW: 'OPERATING_CASH_FLOW',
  NET_INCOME: 'NET_INCOME',
  NET_PROFIT: 'NET_PROFIT',
  SALES: 'SALES',
  SALES_GROWTH: 'SALES_GROWTH',
  NEW_LEADS_PROSPECTS: 'NEW_LEADS_PROSPECTS',
  SALE_CONVERSION_RATE: 'SALE_CONVERSION_RATE',
}

export const MetricFrequency = {
  ANNUALLY: 'annual',
  MONTHLY: 'monthly',
  QUARTERLY: 'quarterly',
  WEEKLY: 'weekly',
  NONE: 'none',
}

export const METRICS_PAGE_SIZE = 20

export enum MetricSortBy {
  METRIZABLE_NAME = 'metrizable_name',
  NAME = 'name',
  UPDATE = 'updated_at',
}

export enum DisplayMode {
  VALUE = 'VALUE',
  CHART = 'CHART',
}
