// Slide animation logic based on: https://codesandbox.io/s/v1vkm8mol0

import { useEffect, useRef, useState } from 'react'
import { Grid } from 'react-virtualized'
import type { RenderedSection } from 'react-virtualized/dist/es/Grid'
import { maxSize } from 'utils/constants/breakpoint'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import { DataPoint } from 'utils/types/metrics'
import { METRIC_VALUE_WIDTH } from './MetricDataPoints.styles'

/**
 * Percentage of the metric value that needs to be visible for it to be considered visible
 */
const VISIBLE_METRIC_THRESHOLD = 0.75

const SLIDE_ANIMATION_DURATION = 500

const easeInOutQuint = (x: number): number => {
  return x < 0.5 ? 16 * x * x * x * x * x : 1 - (-2 * x + 2) ** 5 / 2
}

const calculateAmountOfMetricsVisible = (
  width: number,
  isMobile: boolean
): number => {
  const amountOfMetricsVisibleWithDecimals =
    (width - (isMobile ? 40 : 100)) / METRIC_VALUE_WIDTH
  let amountOfMetricsVisible = Math.floor(amountOfMetricsVisibleWithDecimals)

  if (amountOfMetricsVisibleWithDecimals % 1 > VISIBLE_METRIC_THRESHOLD) {
    amountOfMetricsVisible = Math.ceil(amountOfMetricsVisibleWithDecimals)
  }

  return amountOfMetricsVisible
}

const calculateScrollLeft = (
  dataPointsLength: number,
  amountOfMetricsVisible: number
): number => {
  return METRIC_VALUE_WIDTH * (dataPointsLength - amountOfMetricsVisible)
}

export interface MetricDataPointsProps {
  dataPoints: DataPoint[]
  width: number
}

export const useMetricDataPoints = ({
  dataPoints,
  width,
}: MetricDataPointsProps) => {
  const { matches: isMobile } = useMediaQuery(maxSize.sm)
  const amountOfMetricsVisible = calculateAmountOfMetricsVisible(
    width,
    isMobile
  )

  const [scrollLeft, setScrollLeft] = useState(
    calculateScrollLeft(dataPoints.length, amountOfMetricsVisible)
  )

  const [lastValueVisible, setLastValueVisible] = useState(true)
  const [firstValueVisible, setFirstValueVisible] = useState(false)
  const gridRef = useRef<Grid>(null)
  const animationStartTime = useRef<number>()
  const scrollRightFinal = useRef(0)
  const scrollRightInitial = useRef(0)
  // Could be the rightmost column or the leftmost column depending on which arrow was pressed last
  const currentColumnToDisplayIndex = useRef(dataPoints.length - 1)

  useEffect(() => {
    setScrollLeft(
      calculateScrollLeft(dataPoints.length, amountOfMetricsVisible)
    )
  }, [width, amountOfMetricsVisible, dataPoints.length])

  const animate = () => {
    requestAnimationFrame(() => {
      const now = performance.now()
      const ellapsed = now - (animationStartTime.current || 0)
      const scrollDelta = scrollRightFinal.current - scrollRightInitial.current
      const easedTime = easeInOutQuint(
        Math.min(1, ellapsed / SLIDE_ANIMATION_DURATION)
      )
      const scrollTop = scrollRightInitial.current + scrollDelta * easedTime

      setScrollLeft(scrollTop)

      if (ellapsed < SLIDE_ANIMATION_DURATION) {
        animate()
      } else {
        animationStartTime.current = undefined
        scrollRightInitial.current = scrollRightFinal.current
      }
    })
  }

  const initAnimation = (direction: 'left' | 'right') => {
    if (animationStartTime.current) {
      return
    }

    const cellsToScroll = amountOfMetricsVisible * 2 - 1
    let scrollToColumn =
      currentColumnToDisplayIndex.current +
      (direction === 'left' ? -cellsToScroll : cellsToScroll)

    if (scrollToColumn < 0) {
      scrollToColumn = 0
    } else if (scrollToColumn > dataPoints.length - 1) {
      scrollToColumn = dataPoints.length - 1
    }

    currentColumnToDisplayIndex.current = scrollToColumn

    animationStartTime.current = performance.now()

    scrollRightFinal.current = gridRef.current!.getOffsetForCell({
      columnIndex: scrollToColumn,
      rowIndex: 0,
    }).scrollLeft

    animate()
  }

  const goLeft = () => {
    initAnimation('left')
  }

  const goRight = () => {
    initAnimation('right')
  }

  const onScroll = (props) => {
    if (!animationStartTime.current) {
      scrollRightInitial.current = props.scrollLeft
    }
  }

  const onSectionRendered = ({
    columnStartIndex,
    columnStopIndex,
  }: RenderedSection) => {
    const firstColumnVisible = columnStartIndex === 0
    const lastColumnVisible = columnStopIndex === dataPoints.length - 1
    setFirstValueVisible(firstColumnVisible)
    setLastValueVisible(lastColumnVisible)
  }

  const getListWidth = () => {
    return Math.min(
      METRIC_VALUE_WIDTH * amountOfMetricsVisible,
      METRIC_VALUE_WIDTH * dataPoints.length
    )
  }

  return {
    gridRef,
    getListWidth,
    onScroll,
    scrollLeft,
    onSectionRendered,
    goLeft,
    goRight,
    firstValueVisible,
    lastValueVisible,
  }
}
