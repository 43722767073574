import React from 'react'
import { Nullable, IError } from 'utils/types/common'

export enum IconPosition {
  LEFT = 'left',
  RIGHT = 'right',
}

type SetValueOptions = { shouldValidate: boolean }

export interface SelectDatePickerProps {
  dateFormat?: string
  disabled?: boolean
  error?: IError
  filled?: boolean
  handleChange?: (value: any, shouldValidate?: boolean | undefined) => void
  iconPosition?: IconPosition
  id: string
  initialValue?: Nullable<Date>
  label?: Nullable<string>
  maxDate?: Nullable<Date>
  maxTime?: Nullable<Date>
  minDate?: Nullable<Date>
  minTime?: Nullable<Date>
  name: string
  onBlur?: {
    (e: React.FocusEvent<any, Element>): void
    <T = any>(fieldOrEvent: T): T extends string ? (e: any) => void : void
  }
  onFocus?: () => {}
  onlyMonthYearPicker?: boolean
  optional?: boolean
  placeholder?: string
  readOnlyMode?: boolean
  // TODO: Add correct type to register?
  register?: (arg: any) => {}
  // TODO: setValue is only used in ShowPendingFounderMetric, we should evaluate if we can remove this prop and use only handleChange
  setValue?: (
    name?: string,
    date?: Date,
    setValueOptions?: SetValueOptions
  ) => {}
  shouldCloseOnSelect?: boolean
  showErrorMessage?: boolean
  showTimeSelect?: boolean
  value?: Date
  isPastDateAllowed?: boolean
}
