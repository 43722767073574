import { useMetricName } from 'utils/hooks/metrics/useMetricName'
import { Metric } from 'utils/types/metrics'

import * as Styles from './MetricName.styles'

interface MetricNameProps {
  metric: Metric
  onNameClick?: () => void
  size?: string
  customStyle?: React.CSSProperties
}

const MetricName: React.FC<MetricNameProps> = ({
  metric,
  size,
  customStyle,
  onNameClick,
}) => {
  const { metricName } = useMetricName(metric)

  return metricName ? (
    <Styles.MetricName size={size} onClick={onNameClick} style={customStyle}>
      {metricName} ({metric.metrizableName ?? metric.companyData?.name})
    </Styles.MetricName>
  ) : null
}

export default MetricName
