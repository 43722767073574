import { SortDirection } from 'utils/constants'
import { MetricSortBy } from 'utils/constants/metrics'
import { MetricsCard, Metric } from 'utils/types/metrics'
import { InfiniteQueryData } from 'api/types'

type Sort = {
  sortBy?: MetricSortBy
  direction?: SortDirection
}

const getSortBy = (sortBy: MetricSortBy) => {
  if (sortBy === MetricSortBy.METRIZABLE_NAME) {
    return MetricSortBy.NAME
  }

  return sortBy
}

export const sortMetrics = (
  metrics: Metric[],
  { sortBy, direction }: Sort
): Metric[] => {
  if (sortBy) {
    return metrics.sort((a, b) => {
      const sortByField = getSortBy(sortBy)

      if (direction === SortDirection.ASC) {
        return a[sortByField] > b[sortByField] ? 1 : -1
      }

      return a[sortByField] < b[sortByField] ? 1 : -1
    })
  }

  return metrics
}

export const getMetricsGroupByMetricCards = (
  paginatedMetrics: InfiniteQueryData<Metric>,
  { sortBy, direction }: Sort
): MetricsCard[] => {
  const groupedMetrics =
    paginatedMetrics?.pages.reduce<Record<string, MetricsCard>>(
      (acc, currPage) => {
        currPage?.data.forEach((metric) => {
          const key = metric.companyData?.id || metric.metrizable

          if (key) {
            if (!acc[key]) {
              const name = metric.companyData?.name || metric.metrizableName
              acc[key] = {
                id: key,
                name: name!,
                holdingId: key,
                companyData: metric.companyData,
                metricSource: metric.metricSource,
                metrizableType: metric.metrizableType,
                metrics: [metric],
              }
            } else {
              acc[key].metrics.push(metric)
            }
          }
        })
        return acc
      },
      {}
    ) ?? {}

  if (sortBy) {
    return Object.values(groupedMetrics).map<MetricsCard>(
      ({ metrics, ...rest }) => {
        const sortedMetrics = metrics.sort((a, b) => {
          const sortByField = getSortBy(sortBy)

          if (direction === SortDirection.ASC) {
            return a[sortByField] > b[sortByField] ? 1 : -1
          }

          return a[sortByField] < b[sortByField] ? 1 : -1
        })

        return { ...rest, metrics: sortedMetrics }
      }
    )
  }

  return Object.values(groupedMetrics)
}
