import { useEffect, useState } from 'react'
import { useQueryClient } from '@tanstack/react-query'
import Toast from 'components/Toast'
import { useLocation } from 'react-router-dom'
import useMetricGroups from 'utils/hooks/useMetricGroups'
import { useIntl } from 'react-intl'
import useEffectSkipFirstRender from 'utils/hooks/useEffectSkipFirstRender'
import MetricService from 'api/MetricsService'
import { dispatchEvent } from 'utils/hooks/useEventListener'
import { metricsKeys } from 'utils/queries/metrics'

const useShareDataPoints = (closeDrawer) => {
  const queryClient = useQueryClient()
  const intl = useIntl()
  const location = useLocation()
  const dataPointsToShare = location?.state?.dataPointsToShare
  const metric = location?.state?.metric
  const [dataPoints, setDataPoints] = useState(dataPointsToShare)
  const [isValidForm, setIsValidForm] = useState(false)
  const [error, setError] = useState({})

  const {
    handleAddGroup,
    handleAddGroupByHandle,
    handleRemoveGroup,
    loadGroups,
    currentGroups,
    clear,
    searchText: groupSearchText,
  } = useMetricGroups()

  useEffect(() => {
    setDataPoints(dataPointsToShare)
  }, [dataPointsToShare])

  useEffectSkipFirstRender(() => {
    if (currentGroups?.length > 0) {
      setIsValidForm(true)
      setError({ message: '' })
    } else {
      setError({ message: intl.messages['metrics.enterAtLeastOneGroup'] })
      setIsValidForm(false)
    }
  }, [currentGroups, setIsValidForm, setError, intl])

  const onSubmitForm = async (event) => {
    event.preventDefault()
    try {
      await MetricService.bulkShareDataPoints({
        dataPoints,
        currentGroups,
        metric,
      })

      queryClient.invalidateQueries(metricsKeys.getMetric(metric.id))

      Toast.display(intl.messages['metrics.valuesSharedSuccessfully'])
      closeDrawer()
    } catch {
      Toast.display(intl.messages['editMetric.error'], 'error')
    }
  }

  const onRemoveValue = (dataPoint) => {
    setDataPoints((currDataPoints) =>
      currDataPoints.filter((dp) => dp.id !== dataPoint.id)
    )
  }

  return {
    metric,
    onSubmitForm,
    handleAddGroup,
    handleAddGroupByHandle,
    handleRemoveGroup,
    loadGroups,
    currentGroups,
    clear,
    intl,
    dataPoints,
    onRemoveValue,
    isValidForm,
    error,
    groupSearchText,
  }
}

export default useShareDataPoints
