import type { CellChange, EventHandlers } from '@silevis/reactgrid'
import { QueryClient, useQueryClient } from '@tanstack/react-query'
import MetricsService from 'api/MetricsService'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { isActingAsFounder } from 'selectors/auth'
import { SortDirection } from 'utils/constants'
import cloneDeep from 'lodash/cloneDeep'
import { METRICS_PAGE_SIZE, MetricSortBy } from 'utils/constants/metrics'
import { dispatchEvent, useEventListener } from 'utils/hooks/useEventListener'
import { MetricsMode } from 'utils/types/metrics'
import { MetricsFilters, metricsKeys } from 'utils/queries/metrics'
import {
  HOLDING_FETCHED_EVENT,
  HoldingCell,
} from 'components/TransactionsSpeadsheetImportModal/components/CustomHoldingCellTemplate'
import { INITIAL_HOLDINGS_FETCHED } from 'components/TransactionsSpeadsheetImportModal/components/useTransactionsSpreadsheet'
import { Holding } from 'utils/types/company'
import {
  METRIC_FETCHED_EVENT,
  Metric,
  MetricCell,
  UNKNOWN_METRIC_ID,
} from '../Spreadsheet/CellTemplates/MetricCellTemplate'
import {
  CustomDateCell,
  CustomNumberCell,
  CustomTextCell,
  RowNumberCell,
} from '../Spreadsheet/types'
import { MetricsSpreadsheetBuilder } from './MetricsSpreadsheetBuilder'
import {
  MetricsSpreadsheetMode,
  getFetchCompanyFunction,
} from './MetricsSpreadsheetLogic'

/**
 * Fixed cell (and column) width for every type of cell.
 */
export const CELL_WIDTH = 165

/**
 * Vertical padding of metric cells. Means (top + bottom) or (left + right).
 */
export const CELL_PADDING = 16

/**
 * Width of the arrow icon of the dropdown cell.
 */
export const ARROW_WIDTH = 17

export const MetricsSpreadsheetEvents = {
  INITIAL_METRICS_FETCHED: 'MSS_INITIAL_METRICS_FETCHED',
  METRIC_FOR_HOLDING_FETCHED: 'MSS_METRIC_FOR_HOLDING_FETCHED',
  SPREADSHEET_WITH_ERRORS: 'MSS_SPREADSHEET_WITH_ERRORS',
  PRELOADED_HOLDING_FETCHED_EVENT: 'MSS_PRELOADED_HOLDING_FETCHED_EVENT',
} as const

export type CellType =
  | RowNumberCell
  | CustomTextCell
  | CustomNumberCell
  | CustomDateCell
  | MetricCell
  | HoldingCell
export type GridType = CellType[][]

export interface UpdateMetricsGridProps {
  companyId?: string
  disableAddingColumns?: boolean
  isInDrawer?: boolean
  onPasteOutsideGrid?: (metrics: GridType) => void
  onGridChange?: (metrics: GridType) => void
  initialGrid?: GridType
  mode?: MetricsMode
  spreadsheetBuilder: MetricsSpreadsheetBuilder
  shouldDisplayDisclaimer?: boolean
}

const getFetchFounderMetrics =
  (queryClient: QueryClient) =>
  async (metricName: string): Promise<Metric[]> => {
    const metricsData = await queryClient.fetchQuery({
      queryKey: metricsKeys.getFounderMetrics({ metricName }),
      queryFn: ({ queryKey }) => {
        const name = queryKey[1]?.metricName
        return MetricsService.getFounderMetrics({
          page: 0,
          pageSize: METRICS_PAGE_SIZE,
          params: {
            'q[name_cont]': name,
          },
          sortBy: MetricSortBy.NAME,
          sortDirection: SortDirection.ASC,
        })
      },
      staleTime: 10000,
    })

    return metricsData
  }

const getFetchInvestorMetrics =
  (queryClient: QueryClient) =>
  async (metricName: string, companyId: string): Promise<Metric[]> => {
    const filters: MetricsFilters = {
      metricName,
      sortBy: MetricSortBy.NAME,
      sortDirection: SortDirection.ASC,
      companyDatumIds: [companyId],
    }

    const metricsData = await queryClient.fetchQuery({
      queryKey: metricsKeys.getInvestorMetrics(filters),
      queryFn: ({ queryKey }) => {
        const { metricName: name, sortBy, sortDirection } = queryKey[1] || {}
        return MetricsService.getInvestorMetrics({
          page: 0,
          pageSize: METRICS_PAGE_SIZE,
          metricName: name,
          sortBy,
          sortDirection,
          companyDatumIds: [companyId],
          onlyCustomMetrics: true,
        })
      },
      staleTime: 10000,
    })

    return metricsData
  }

const updateGridsInitialMetrics = (
  metrics: Metric[],
  grid: GridType,
  metricCellIndex: number
) => {
  for (let rowIdx = 1; rowIdx < grid.length; rowIdx++) {
    const dropdownCell = grid[rowIdx][metricCellIndex] as MetricCell
    dropdownCell.initialMetrics = metrics
    dropdownCell.loading = false
  }
}

const updateGridsInitialHoldings = (
  holdings: Holding[],
  grid: GridType,
  holdingColumnIndex: number
) => {
  for (let rowIdx = 1; rowIdx < grid.length; rowIdx++) {
    const dropdownCell = grid[rowIdx][holdingColumnIndex] as HoldingCell
    dropdownCell.initialHoldings = holdings
    dropdownCell.loading = false
  }
}
const getFiltersForInitialMetrics = (companyId: string): MetricsFilters => {
  return {
    metricName: '',
    sortBy: MetricSortBy.NAME,
    sortDirection: SortDirection.ASC,
    companyDatumIds: [companyId],
  }
}

export const useUpdateMetricsGrid = ({
  companyId,
  disableAddingColumns,
  onPasteOutsideGrid,
  onGridChange,
  initialGrid,
  mode,
  spreadsheetBuilder,
}: UpdateMetricsGridProps) => {
  const queryClient = useQueryClient()
  const eventHandler = useRef<EventHandlers>()
  const [grid, setGrid] = useState<GridType>(
    cloneDeep(initialGrid) ||
      spreadsheetBuilder.getSpreadsheet(
        spreadsheetBuilder.getMode() === MetricsSpreadsheetMode.SINGLE_HOLDING
      )
  )
  const isActingAsFounderValue = useAppSelector(isActingAsFounder)
  const isFounder = mode === MetricsMode.FOUNDER || isActingAsFounderValue

  const fetchInvestorMetrics = useCallback(
    (metricName: string) => {
      return getFetchInvestorMetrics(queryClient)(metricName, companyId!)
    },
    [queryClient, companyId]
  )

  const fetchMetricsForCompany = useMemo(
    () => getFetchInvestorMetrics(queryClient),
    [queryClient]
  )

  const updateInitialMetricsForHolding = useCallback(
    (holdingId: string, filters: MetricsFilters) => {
      fetchMetricsForCompany('', holdingId).then((fetchedMetrics) => {
        queryClient.setQueryData(
          metricsKeys.getInvestorMetrics(filters),
          fetchedMetrics
        )
        setGrid((currentGrid) => {
          const newGrid = [...currentGrid]

          for (let i = 1; i < newGrid.length; i++) {
            const holdingCell = newGrid[i][
              spreadsheetBuilder.getHoldingColumnIndex()
            ] as HoldingCell

            if (holdingCell.holding?.id === holdingId) {
              const metricCell = newGrid[i][
                spreadsheetBuilder.getMetricColumnIndex()
              ] as MetricCell
              metricCell.initialMetrics = fetchedMetrics
            }
          }

          return newGrid
        })
      })
    },
    [fetchMetricsForCompany, spreadsheetBuilder, queryClient]
  )

  const getInitialMetricsForHolding = useCallback(
    (holdingId: string) => {
      const filters = getFiltersForInitialMetrics(holdingId)
      const initialMetrics = queryClient.getQueryData<Metric[]>(
        metricsKeys.getInvestorMetrics(filters)
      )

      if (!initialMetrics) {
        updateInitialMetricsForHolding(holdingId, filters)
      }

      return initialMetrics || []
    },
    [queryClient, updateInitialMetricsForHolding]
  )

  const fetchMetrics = useMemo(
    () =>
      isFounder ? getFetchFounderMetrics(queryClient) : fetchInvestorMetrics,
    [isFounder, queryClient, fetchInvestorMetrics]
  )

  useEffect(() => {
    if (
      spreadsheetBuilder.getMode() === MetricsSpreadsheetMode.SINGLE_HOLDING
    ) {
      fetchMetrics('').then((fetchedMetrics) => {
        dispatchEvent(
          MetricsSpreadsheetEvents.INITIAL_METRICS_FETCHED,
          fetchedMetrics
        )
        setGrid((currentGrid) => {
          const newGrid = [...currentGrid]
          updateGridsInitialMetrics(
            fetchedMetrics,
            newGrid,
            spreadsheetBuilder.getMetricColumnIndex()
          )
          return newGrid
        })
      })
    }

    if (
      spreadsheetBuilder.getMode() ===
        MetricsSpreadsheetMode.MULTIPLE_HOLDINGS &&
      companyId
    ) {
      updateInitialMetricsForHolding(
        companyId,
        getFiltersForInitialMetrics(companyId)
      )
    }
  }, [
    fetchMetrics,
    spreadsheetBuilder,
    updateInitialMetricsForHolding,
    companyId,
  ])

  useEffect(() => {
    if (!isFounder) {
      getFetchCompanyFunction(queryClient)('').then((fetchedHoldings) => {
        dispatchEvent(INITIAL_HOLDINGS_FETCHED, fetchedHoldings)
        setGrid((currentGrid) => {
          const newGrid = [...currentGrid]
          updateGridsInitialHoldings(
            fetchedHoldings,
            newGrid,
            spreadsheetBuilder.getHoldingColumnIndex()
          )
          return newGrid
        })
      })
    }
  }, [queryClient, spreadsheetBuilder, isFounder])

  const rows = useMemo(
    () => spreadsheetBuilder.getRows(grid),
    [grid, spreadsheetBuilder]
  )
  const columns = useMemo(
    () => spreadsheetBuilder.getColumns(grid),
    [grid, spreadsheetBuilder]
  )

  const handleChanges = useCallback(
    (changes: CellChange<CellType>[]) => {
      setGrid((currentGrid) => {
        const hasChangesOutsideGrid = changes.some((change) => {
          return (change.columnId as number) >= currentGrid[0].length
        })

        if (disableAddingColumns && hasChangesOutsideGrid) {
          onPasteOutsideGrid?.(
            spreadsheetBuilder.applyChangesToGrid(
              changes,
              currentGrid,
              getFetchInvestorMetrics(queryClient),
              getInitialMetricsForHolding
            )
          )
          return currentGrid
        }

        const newMetrics = spreadsheetBuilder.applyChangesToGrid(
          changes,
          currentGrid,
          getFetchInvestorMetrics(queryClient),
          getInitialMetricsForHolding
        )
        onGridChange?.(newMetrics)
        return newMetrics
      })
    },
    [
      onGridChange,
      disableAddingColumns,
      onPasteOutsideGrid,
      spreadsheetBuilder,
      queryClient,
      getInitialMetricsForHolding,
    ]
  )

  const addRow = useCallback(() => {
    setGrid((currentMetrics) => [
      ...currentMetrics,
      spreadsheetBuilder.createRow(currentMetrics),
    ])
  }, [spreadsheetBuilder])

  const addColumn = useCallback(() => {
    setGrid((currentMetrics) => {
      const newMetrics = [...currentMetrics]
      currentMetrics.forEach((row, index) => {
        const cell = spreadsheetBuilder.createColumnCell(index)
        const newRow = [...row, cell]
        newMetrics[index] = newRow
      })
      return newMetrics
    })
  }, [spreadsheetBuilder])

  const getEventHandler = useCallback((gridEventHandler) => {
    eventHandler.current = gridEventHandler
  }, [])

  useEventListener(
    METRIC_FETCHED_EVENT,
    ({ metric, metricName }: { metric?: Metric; metricName: string }) => {
      if (metric) {
        const newMetrics = [...grid]

        // Go through all of the metrics because it might be repeated
        for (let i = 1; i < newMetrics.length; i++) {
          const dropdownCell = newMetrics[i][
            spreadsheetBuilder.getMetricColumnIndex()
          ] as MetricCell

          if (dropdownCell.metric?.name === metric.name) {
            dropdownCell.metric = metric
          }
        }

        setGrid(newMetrics)
      } else {
        const newMetrics = [...grid]

        for (let i = 1; i < newMetrics.length; i++) {
          const dropdownCell = newMetrics[i][
            spreadsheetBuilder.getMetricColumnIndex()
          ] as MetricCell

          if (dropdownCell.metric?.name === metricName) {
            dropdownCell.metric = {
              id: UNKNOWN_METRIC_ID,
              name: metricName,
              toCreate: true,
            }
          }
        }

        setGrid(newMetrics)
      }
    }
  )

  useEventListener(
    HOLDING_FETCHED_EVENT,
    ({
      holding,
      holdingName,
      rowIndex,
    }: {
      holding?: Holding
      holdingName: string
      rowIndex: number
    }) => {
      const newSpreadsheet = [...grid]

      if (holding) {
        const holdingDropdownCell = newSpreadsheet[rowIndex][
          spreadsheetBuilder.getHoldingColumnIndex()
        ] as HoldingCell

        if (holdingDropdownCell.holding?.name === holding.name) {
          holdingDropdownCell.holding = holding
        }

        const metricCell = newSpreadsheet[rowIndex][
          spreadsheetBuilder.getMetricColumnIndex()
        ] as MetricCell

        if (metricCell.metric?.name) {
          const metricName = metricCell.metric.name
          fetchMetricsForCompany(metricName, holding.id).then((metrics) => {
            const metric = metrics.find((m) => m.name === metricName)
            dispatchEvent(MetricsSpreadsheetEvents.METRIC_FOR_HOLDING_FETCHED, {
              metric,
              metricName,
              rowIndex,
            })
          })
        }
      } else {
        for (let i = 1; i < newSpreadsheet.length; i++) {
          const holdingDropdownCell = newSpreadsheet[i][
            spreadsheetBuilder.getHoldingColumnIndex()
          ] as HoldingCell
          if (holdingDropdownCell.holding?.name === holdingName) {
            const rowNumberCell = newSpreadsheet[i][0] as RowNumberCell
            rowNumberCell.errors.add(spreadsheetBuilder.getHoldingColumnIndex())
            holdingDropdownCell.error =
              'spreadsheet.metrics.errors.invalidHolding'
            holdingDropdownCell.holding = {
              id: 'invalid',
              name: holdingName,
              invalid: true,
            }
          }
        }
      }

      setGrid(newSpreadsheet)
      onGridChange?.(newSpreadsheet)
    }
  )

  useEventListener(
    MetricsSpreadsheetEvents.METRIC_FOR_HOLDING_FETCHED,
    ({
      metric,
      metricName,
      rowIndex,
    }: {
      metric?: Metric
      metricName: string
      rowIndex: number
    }) => {
      const newSpreadsheet = [...grid]
      const metricCell = newSpreadsheet[rowIndex][
        spreadsheetBuilder.getMetricColumnIndex()
      ] as MetricCell
      metricCell.error = undefined

      if (metric) {
        metricCell.metric = metric
      } else {
        metricCell.metric = {
          id: UNKNOWN_METRIC_ID,
          name: metricName,
          toCreate: true,
        }
      }

      setGrid(newSpreadsheet)
      onGridChange?.(newSpreadsheet)
    }
  )

  useEventListener(
    MetricsSpreadsheetEvents.PRELOADED_HOLDING_FETCHED_EVENT,
    ({ company }: { company: Holding }) => {
      const newSpreadsheet = [...grid]

      for (let i = 1; i < newSpreadsheet.length; i++) {
        const dropdownCell = newSpreadsheet[i][
          spreadsheetBuilder.getHoldingColumnIndex()
        ] as HoldingCell
        dropdownCell.holding = company
      }

      setGrid(newSpreadsheet)
      onGridChange?.(newSpreadsheet)
    }
  )

  useEventListener(
    MetricsSpreadsheetEvents.SPREADSHEET_WITH_ERRORS,
    ({ spreadsheet }: { spreadsheet: GridType }) => {
      const newSpreadsheet = [...spreadsheet]
      setGrid(newSpreadsheet)
      onGridChange?.(newSpreadsheet)
    }
  )

  return {
    handleChanges,
    addRow,
    addColumn,
    rows,
    columns,
    fetchMetrics,
    getEventHandler,
    eventHandler,
    fetchMetricsForCompany,
  }
}
