import { useLocation, useRouteMatch } from 'react-router-dom'

export const useMetricsRoute = () => {
  const defaultRouteMatch = useRouteMatch<{ tab: string }>('/metrics/:tab')
  const companyProfileRouteMatch = useRouteMatch<{ id: string }>(
    '/companies/:id'
  )
  const groupProfileRouteMatch = useRouteMatch('/group-profile')
  const dashboardRouteMatch = useRouteMatch<{ id: string }>('/dashboard')
  const fundProfileRouteMatch = useRouteMatch('/settings/group_management/fund')
  const privateProfileRouteMatch = useRouteMatch(
    '/settings/group_management/profile'
  )
  const investmentsRouteMatch = useRouteMatch<{ id: string }>('/investments')

  const route = useLocation()

  let value = '/metrics'

  if (defaultRouteMatch) {
    value = defaultRouteMatch!.url
  } else if (companyProfileRouteMatch?.params.id) {
    value = `/companies/${companyProfileRouteMatch.params.id}/metrics`
  } else if (groupProfileRouteMatch) {
    value = `/group-profile/metrics`
  } else if (dashboardRouteMatch) {
    value = `/dashboard/metrics`
  } else if (fundProfileRouteMatch) {
    value = `/settings/group_management/fund/metrics`
  } else if (privateProfileRouteMatch) {
    value = `/settings/group_management/profile/metrics`
  } else if (investmentsRouteMatch) {
    value = route.pathname
  }

  return value
}
