import { useMemo } from 'react'
import {
  useInfiniteQuery,
  useMutation,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query'
import MetricsService from 'api/MetricsService'
import { METRICS_PAGE_SIZE, MetricSortBy } from 'utils/constants/metrics'
import { Metric } from 'utils/types/metrics'
import { MetricsFilters, metricsKeys } from 'utils/queries/metrics'
import { companyKeys } from 'utils/queries/companies'
import { SortDirection } from 'utils/constants'
import { CREATED_METRIC_EVENT } from 'utils/constants/events'
import { dispatchEvent } from 'utils/hooks/useEventListener'
import Toast from 'components/Toast'
import { AddToExistingMetricFormValues } from 'containers/Metrics/AddMetricToExistingMetric/useAddToExistingMetric'
import { CreateFounderMetricFormType } from 'containers/Metrics/CreateMetric/CreateFounderMetric/useCreateFounderMetric'
import { EditFounderMetricFormType } from 'containers/Metrics/EditMetric/EditFounderMetric/useEditFounderMetric'
import { EditInvestorMetricFormType } from 'containers/Metrics/EditMetric/EditInvestorMetric/useEditInvestorMetric'
import { CreateInvestorMetricFormType } from 'containers/Metrics/CreateMetric/CreateInvestorMetric/useCreateInvestorMetric'
import { isActingAsFounder } from 'selectors/auth'
import { useFetchUniversity } from '../useFetchUniversity'
import { useInvalidateMetricsInvitationsQueries } from '../metrics/useMetricsInvitationsQueries'
import { useAppSelector } from '../reduxToolkit'

const getFounderMetricErrorMessage = (error: any, type: 'create' | 'edit') => {
  const defaultErrorMessage =
    type === 'create' ? 'metrics.errorCreatingMetric' : 'editMetric.error'
  try {
    const parsed = JSON.parse(error.message)

    if (
      parsed['receiver_linked_metrics.sender_metric']?.[0] ===
      'company_datum.not_verified'
    ) {
      return 'metrics.toasts.errorMetricCompanyNotVerified'
    }

    return defaultErrorMessage
  } catch {
    return defaultErrorMessage
  }
}

interface CreateFounderMetricProps {
  closeDrawer: () => void
}

export const useCreateFounderMetricQuery = ({
  closeDrawer,
}: CreateFounderMetricProps) => {
  const { invalidateInvitationsQueries } =
    useInvalidateMetricsInvitationsQueries()
  const queryClient = useQueryClient()

  return useMutation(
    async (values: CreateFounderMetricFormType) => {
      const founderMetric = await MetricsService.createFounderMetric({
        ...values,
      })
      return founderMetric
    },
    {
      onSuccess: (founderMetric) => {
        closeDrawer()
        invalidateInvitationsQueries()
        queryClient.invalidateQueries(
          companyKeys.companyMetrics(founderMetric.companyData?.id!)
        )

        dispatchEvent(CREATED_METRIC_EVENT, {
          isFounderMetric: !!founderMetric.companyData?.id,
        })

        Toast.displayIntl([
          'metrics.toasts.successCreatingMetric',
          { name: founderMetric.name },
        ])
      },
      onError: (error) => {
        Toast.displayIntl(
          getFounderMetricErrorMessage(error, 'create'),
          'error'
        )
      },
    }
  )
}

interface EditFounderMetricProps {
  metric: any
  groupIdsToAdd: string[]
  groupIdsToRemove: string[]
  closeDrawer: () => void
}

export const useEditFounderMetricQuery = ({
  metric,
  groupIdsToAdd,
  groupIdsToRemove,
  closeDrawer,
}: EditFounderMetricProps) => {
  const queryClient = useQueryClient()

  return useMutation(
    async (values: EditFounderMetricFormType) => {
      const newMetric = {
        name: values.name,
        frequency: values.frequency,
        receiverLinkedMetricsAttributes: [
          ...groupIdsToAdd.map((groupId) => ({
            receiver_group_id: groupId,
            state: 'share_sent',
          })),
          ...groupIdsToRemove.map((groupId) => ({
            id: metric.receiverLinkedMetrics?.find(
              (receiverLinkedMetric) =>
                receiverLinkedMetric.receiverGroupId === groupId &&
                receiverLinkedMetric.state !== 'unshared'
            )?.id,
            state: 'unshared',
          })),
        ],
      }

      const result = await MetricsService.editFounderMetricById(
        metric.id,
        newMetric
      )
      return result
    },
    {
      onSuccess: (editedMetric) => {
        queryClient.invalidateQueries(metricsKeys.getMetric(metric.id))
        closeDrawer()
        Toast.displayIntl(
          ['metrics.toasts.successEditingMetric', { name: editedMetric.name }],
          'success'
        )
      },
      onError: (error) => {
        Toast.displayIntl(getFounderMetricErrorMessage(error, 'edit'), 'error')
      },
    }
  )
}

export const useCreateInvestorMetricQuery = ({
  closeDrawer,
  isCreatingMetricFromSharedMetric,
  linkedMetric,
  currentGroupId,
}: {
  closeDrawer: () => void
  isCreatingMetricFromSharedMetric: boolean
  linkedMetric?: any
  currentGroupId: string
}) => {
  const queryClient = useQueryClient()
  const { fetchUniversity } = useFetchUniversity()

  return useMutation(
    async (values: CreateInvestorMetricFormType) => {
      if (isCreatingMetricFromSharedMetric) {
        const result =
          await MetricsService.createInvestorMetricFromSharedMetric({
            ...values,
            sharedMetric: linkedMetric,
            frequency: values.frequency,
          })

        return {
          name: values.name,
          metrizableIds: result.map((metric) => metric.companyData?.id!),
        }
      }

      const result = await MetricsService.createInvestorMetric(
        {
          ...values,
          frequency: values.frequency,
        },
        currentGroupId
      )

      return {
        name: values.name,
        metrizableIds: result.metrizableIds || [],
      }
    },
    {
      onSuccess: (metricData) => {
        metricData.metrizableIds.forEach((metrizableId) => {
          queryClient.invalidateQueries(
            companyKeys.companyMetrics(metrizableId)
          )
        })

        queryClient.invalidateQueries(metricsKeys.getInvestorMetrics())

        if (isCreatingMetricFromSharedMetric) {
          queryClient.invalidateQueries(metricsKeys.getPendingInvitations())
          queryClient.invalidateQueries(metricsKeys.getInvitationsHistory())
        }

        closeDrawer()
        fetchUniversity()
        Toast.displayIntl(
          [
            'metrics.toasts.successCreatingMetric',
            {
              name: metricData.name,
            },
          ],
          'success'
        )
      },
      onError: () => {
        Toast.displayIntl('metrics.errorCreatingMetric', 'error')
      },
    }
  )
}

export const useEditInvestorMetricQuery = ({
  closeDrawer,
  metricId,
}: {
  closeDrawer: () => void
  metricId: string
}) => {
  const queryClient = useQueryClient()

  return useMutation(
    async (values: EditInvestorMetricFormType) => {
      const metricEdited = {
        name: values.name,
        frequency: values.frequency,
      }

      const result = await MetricsService.editInvestorMetricById(
        metricId,
        metricEdited
      )
      return result
    },
    {
      onSuccess: (editedMetric) => {
        queryClient.invalidateQueries(metricsKeys.getMetric(metricId))
        closeDrawer()
        Toast.displayIntl(
          ['metrics.toasts.successEditingMetric', { name: editedMetric.name }],
          'success'
        )
      },
      onError: () => {
        Toast.displayIntl('editMetric.error', 'error')
      },
    }
  )
}

export const useAddToExistingMetricQuery = ({
  closeDrawer,
  linkedMetricId,
}: {
  closeDrawer: () => void
  linkedMetricId: string
}) => {
  const queryClient = useQueryClient()

  return useMutation(
    async (values: AddToExistingMetricFormValues) => {
      const { receiverMetricId } =
        await MetricsService.addFounderMetricToClientMetric({
          linkedMetricId,
          receiverMetricId: values.id!,
        })

      return receiverMetricId
    },
    {
      onSuccess: (receiverMetricId) => {
        queryClient.invalidateQueries(metricsKeys.getMetric(receiverMetricId))
        queryClient.invalidateQueries(metricsKeys.getPendingInvitations())
        queryClient.invalidateQueries(metricsKeys.getInvitationsHistory())

        closeDrawer()
        Toast.displayIntl('metrics.metricLinkedSuccess', 'success')
      },
      onError: () => {
        Toast.displayIntl('metrics.errorLinkingMetric', 'error')
      },
    }
  )
}

export function usePerformanceMetricsQuery(
  portfolioId: string,
  metricsName: string[] = [],
  queryOptions = {}
) {
  const fetchPerformanceMetrics = async (): Promise<Metric[]> => {
    return MetricsService.getPerformanceMetrics({
      page: 0,
      portfoliosIds: [portfolioId],
      metricsName,
      sortDirection: SortDirection.ASC,
      sortBy: MetricSortBy.NAME,
    })
  }

  return useQuery(
    metricsKeys.getPerformanceMetrics(portfolioId),
    fetchPerformanceMetrics,
    queryOptions
  )
}

export const useMetricsQuery = (
  filters: MetricsFilters = {},
  queryOptions: any = {}
) => {
  const isFounder = useAppSelector(isActingAsFounder)

  const key = isFounder
    ? metricsKeys.getFounderMetrics(filters)
    : metricsKeys.getInvestorMetrics(filters)

  const {
    data: metrics,
    fetchNextPage: fetchNextMetricsPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery(
    key,
    async ({ pageParam = 1, queryKey }) => {
      const {
        metricName,
        sortBy,
        sortDirection,
        companyDatumIdsToExclude,
      }: MetricsFilters = (queryKey[1] || {}) as MetricsFilters

      const metricsData: Metric[] = isFounder
        ? await MetricsService.getFounderMetrics({
            page: pageParam,
            pageSize: METRICS_PAGE_SIZE,
            metricName,
            sortBy: sortBy || MetricSortBy.METRIZABLE_NAME,
            sortDirection: sortDirection || SortDirection.ASC,
            companyDatumIdsToExclude,
            selectedMetrics: filters.selected,
            ...filters,
          })
        : await MetricsService.getInvestorMetrics({
            page: pageParam,
            pageSize: METRICS_PAGE_SIZE,
            metricName,
            sortBy: sortBy || MetricSortBy.METRIZABLE_NAME,
            sortDirection: sortDirection || SortDirection.ASC,
            companyDatumIdsToExclude,
            selectedMetrics: filters.selected,
            selectedUserPortfolioId: filters.selectedUserPortfolioId,
          })

      return { data: metricsData, page: pageParam }
    },
    {
      getNextPageParam: (lastPage) => {
        return (lastPage?.page ?? 0) + 1
      },
      ...queryOptions,
    }
  )

  return {
    isLoading,
    metrics,
    fetchNextMetricsPage,
    isFetchingNextPage,
  }
}

export const useSelectedMetricsCount = (queryOptions) => {
  const { metrics } = useMetricsQuery(
    {
      selected: true,
    },
    queryOptions
  )

  const selectedMetricsCount = useMemo(
    () => metrics?.pages?.flatMap((page) => page.data).length,
    [metrics]
  )

  return selectedMetricsCount
}
