import dayjs from 'dayjs'

import { Nullable } from 'utils/types/common'
import { SortDirection } from 'utils/constants/sortDirection'
import { Metric } from 'utils/types/metrics'

import {
  ChartCriteriaType,
  ChartMetric,
  DataPointsByDate,
  DataPointsByQuarter,
  DataPointsByYear,
  GroupedDatapoints,
  MetricData,
  MetricDataPoint,
  VISIBLE_METRICS_LIMIT,
} from '../types'

import { groupDataPointsByDate, sortByDate } from './index'

const initializePeriods = (initialYear, endYear) => {
  const dataPointsByYear = {}
  const dataPointsByQuarter = {}
  const dataPointsByDate: { days: MetricDataPoint[] } = { days: [] }

  // every years and quarters must be displayed in the chart even if they don't have data
  // so we need to initialize the dataPointsByYear and dataPointsByQuarter with empty arrays
  // it doesnt apply to dates. They should be visible in the chart when they have data only
  for (let year = initialYear; year <= endYear; year++) {
    dataPointsByYear[year] = []
    dataPointsByQuarter[year] = {
      1: [],
      2: [],
      3: [],
      4: [],
    }
  }

  return {
    dataPointsByYear,
    dataPointsByQuarter,
    dataPointsByDate,
  }
}

const getFirstQuarterDate = (quarter: string, year: string) => {
  const month = (Number(quarter) - 1) * 3 // Convert quarter to month (0, 3, 6, 9)
  const firstDate = new Date(Number(year), month, 1)

  return firstDate
}

export const getInitialMetricsQuantity = (
  isMobile: boolean,
  chartCriteria: ChartCriteriaType
): number => {
  if (isMobile) {
    return VISIBLE_METRICS_LIMIT[`${chartCriteria}`].MOBILE
  }

  return VISIBLE_METRICS_LIMIT[`${chartCriteria}`].DESKTOP
}

const getChartMetricData = (
  dataPoints: Nullable<MetricDataPoint[]>,
  year: string,
  quarter: string,
  onlyLastPeriodValue: boolean = true // this is true when we want to display only the last value of the period (quarter or year)
): ChartMetric => {
  let chartMetric: ChartMetric = {
    date: null,
    quarter: '',
    year: '',
    metrics: [],
  }

  let metrics: MetricData[] = []

  if (onlyLastPeriodValue) {
    const firstDayOfYear = new Date(Number(year), 0, 1)

    const date: Nullable<Date> =
      quarter !== ''
        ? getFirstQuarterDate(quarter, year) // needed to display the quarter in the chart
        : firstDayOfYear || null

    // it was defined to just take the last datapoint of the period for each metric
    // be sure the datapoints are sorted by date DESC (updatedAt)
    const lastMetricsValue =
      dataPoints?.reduce((acc, obj) => {
        const { metricId, metricName, value, metricSource } = obj
        acc[metricId] = acc[metricId]
          ? acc[metricId]
          : { value: (acc[metricId] || 0) + value, metricName, metricSource }
        return acc
      }, {}) || {}

    metrics = Object.keys(lastMetricsValue).map((id) => {
      return {
        metricId: id,
        name: lastMetricsValue[id].metricName,
        value: lastMetricsValue[id].value,
        metricSource: lastMetricsValue[id].metricSource,
      }
    })

    chartMetric = {
      date,
      quarter,
      year,
      metrics,
    }
  } else {
    metrics = (dataPoints || [])?.map((point) => {
      const { metricId, value, metricName, metricSource } = point

      return {
        metricId,
        name: metricName,
        value,
        metricSource,
      }
    })

    chartMetric = {
      date: dataPoints?.[0]?.date ? new Date(dataPoints?.[0]?.date) : null,
      quarter: '',
      year: '',
      metrics,
    }
  }

  return chartMetric
}

export const getGroupedDatapoints = (metrics: Metric[]): GroupedDatapoints => {
  const allDataPoints = metrics
    .flatMap((metric) => metric.dataPoints.filter((point) => !point.archive))
    .sort((pointA, pointB) => sortByDate(pointA, pointB, SortDirection.ASC))

  if (allDataPoints.length === 0)
    return {
      dataPointsByYear: {},
      dataPointsByQuarter: {},
      dataPointsByDate: { days: [] },
    }

  const initialYear = new Date(allDataPoints[0].date).getFullYear()
  const endYear = new Date(
    allDataPoints[allDataPoints.length - 1].date
  ).getFullYear()

  const { dataPointsByYear, dataPointsByQuarter, dataPointsByDate } =
    initializePeriods(initialYear, endYear)

  metrics.forEach(({ id, name, metricSource, dataPoints }) => {
    dataPoints.forEach((point) => {
      if (point.archive) return

      const year = new Date(point.date).getFullYear()
      const quarter = Math.floor(new Date(point.date).getMonth() / 3) + 1

      const dataPoint: MetricDataPoint = {
        ...point,
        metricName: name,
        metricId: id,
        metricSource,
      }

      dataPointsByYear[year].push(dataPoint)
      dataPointsByQuarter[year][quarter].push(dataPoint)
      dataPointsByDate.days.push(dataPoint)
    })
  })

  return {
    dataPointsByYear,
    dataPointsByQuarter,
    dataPointsByDate,
  }
}

export const getDateChartMetrics = (
  groupedDataPointsByDate: DataPointsByDate
): ChartMetric[] => {
  const groupedDataPoints: Nullable<MetricDataPoint[]> = groupDataPointsByDate(
    groupedDataPointsByDate.days
  )

  // dates which will be displayed in the chart
  const sortedDates = groupedDataPoints
    .sort((dp1, dp2) => sortByDate(dp1, dp2, SortDirection.ASC))
    .map((point) => dayjs(point.date).format('YYYY-MM-DD'))

  const dateDataPointMap: Record<string, MetricDataPoint[]> = {}

  sortedDates.forEach((currentDate, index) => {
    const previousMetricDate = sortedDates[index - 1]

    dateDataPointMap[currentDate] = groupedDataPoints.filter(
      (point) => dayjs(point.date).format('YYYY-MM-DD') === currentDate
    )

    if (previousMetricDate) {
      const previousDataPoints = dateDataPointMap[previousMetricDate]

      previousDataPoints.forEach((previousDataPoint) => {
        const hasMetricDataPoint = dateDataPointMap[currentDate].some(
          (currentDataPoint) =>
            currentDataPoint.metricId === previousDataPoint.metricId
        )

        if (!hasMetricDataPoint) {
          dateDataPointMap[currentDate].push(previousDataPoint)
        }
      })
    }
  })

  const dateChartMetrics: ChartMetric[] = []

  Object.keys(dateDataPointMap).forEach((date) => {
    const chartMetric = getChartMetricData(
      dateDataPointMap[date],
      '',
      '',
      false
    )
    dateChartMetrics.push(chartMetric)
  })

  return dateChartMetrics
}

export const getQuarterlyChartMetrics = (
  groupedDataPointsByQuarter: DataPointsByQuarter
): ChartMetric[] => {
  const quarterChartMetrics: ChartMetric[] = []
  const dataPointsByQuarter = {}

  Object.keys(groupedDataPointsByQuarter).forEach((year) => {
    Object.keys(groupedDataPointsByQuarter[year]).forEach((quarter) => {
      dataPointsByQuarter[year] = dataPointsByQuarter[year] || {}
      dataPointsByQuarter[year][quarter] =
        groupedDataPointsByQuarter[year][quarter] || []

      let quarterYear = Number(year)
      let previousQuarter = Number(quarter) - 1

      if (previousQuarter === 0) {
        previousQuarter = 4
        quarterYear = Number(year) - 1
      }

      const previousQuarterMetrics = (
        groupedDataPointsByQuarter[quarterYear]?.[previousQuarter] || []
      ).map((metric) => metric.id)

      previousQuarterMetrics.forEach((metricId) => {
        // if in the current quarter the metric doesn't have data, we need to add the
        // datapoints of the previous quarter to the current quarter
        if (
          !groupedDataPointsByQuarter[year][quarter].some(
            (metric) => metric.id === metricId
          )
        ) {
          dataPointsByQuarter[year][quarter].push(
            ...groupedDataPointsByQuarter[quarterYear][previousQuarter].filter(
              (metric) => metric.id === metricId
            )
          )
        }
      })

      const sortedDataPoints: Nullable<MetricDataPoint[]> =
        dataPointsByQuarter[year][quarter].sort(sortByDate)

      const quarterChartMetric: ChartMetric = getChartMetricData(
        sortedDataPoints,
        year,
        quarter
      )

      quarterChartMetrics.push(quarterChartMetric)
    })
  })

  return quarterChartMetrics
}

export const getAnnualChartMetrics = (
  groupedDataPointsByYear: DataPointsByYear
) => {
  // when a metric doesn't have data for a year, it should display the previous year data for that metric
  const years = {}
  const dataPointsByYear = {}

  Object.keys(groupedDataPointsByYear).forEach((year) => {
    dataPointsByYear[year] = groupedDataPointsByYear[year]
    const previousYear = Number(year) - 1

    const previousYearMetrics = (
      groupedDataPointsByYear[previousYear] || []
    ).map((metric) => metric.id)

    previousYearMetrics.forEach((metricId) => {
      // if in the current year the metric doesn't have data, we need to add the
      // datapoints of the previous year to the current year
      if (
        !groupedDataPointsByYear[year].some((metric) => metric.id === metricId)
      ) {
        if (!dataPointsByYear[year]) dataPointsByYear[year] = []

        dataPointsByYear[year].push(
          ...groupedDataPointsByYear[previousYear].filter(
            (metric) => metric.id === metricId
          )
        )
      }
    })

    years[year] = dataPointsByYear[year]
  })

  const annualChartMetrics = Object.keys(years).map((year) => {
    const sortedDataPoints: Nullable<MetricDataPoint[]> =
      years[year].sort(sortByDate)

    const annualChartMetric: ChartMetric = getChartMetricData(
      sortedDataPoints,
      year,
      ''
    )

    return annualChartMetric
  })

  const nonNullableChartMetrics: ChartMetric[] = annualChartMetrics.filter(
    (metric) => metric !== null
  ) as ChartMetric[]

  return nonNullableChartMetrics
}
