/* eslint-disable no-param-reassign */
/* eslint-disable react/style-prop-object */
/* eslint-disable react/prop-types, array-callback-return, consistent-return */
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import dayjs from 'dayjs'
import { chain, sortBy, groupBy } from 'lodash'
import {
  MetricFrequency,
  METRIC_NAMES,
  MetricStatus,
  MetricStates,
  MetricSortBy,
} from 'utils/constants/metrics'
import { displayThousands, round, withDecimals } from 'utils/functions/number'
import { MetricSources } from 'utils/types/metrics'

import Tooltip from 'components/Tooltip'
import ReadOnlyTooltip from 'components/ReadOnlyTooltip/ReadOnlyTooltip'

import {
  Triangle,
  DateContainer,
  GroupCell,
  DateWrapper,
  TooltipGlobalStyles,
} from 'containers/Metrics/MetricsList/Metrics.styles'
import OptionsDropdown from 'ui/OptionsDropdown'
import FloatColumnWrapper from 'components/ExpandableTable/FloatButtonColumn/FloatColumnWrapper'
import { FMVCell } from 'components/FairMarketValueHistory/FairMarketValueHistory.styles'
import GrowthIndicator from 'components/Dashboard/GrowthIndicator/GrowthIndicator'
import GrowthValue from 'components/Dashboard/GrowthIndicator/GrowthValue'
import GroupTooltips from 'components/GroupTooltips/GroupTooltips'
import {
  TimesIconWrapper,
  ValueWrapper,
} from 'containers/Metrics/ShareDataPoints/StyledShareDataPoints'
import {
  GrowthIndicatorWrapper,
  MetricValue,
} from 'containers/Metrics/ShowMetric/ShowMetric.styles'
import { SortDirection } from 'utils/constants/sortDirection'
import { HoldingType } from 'utils/types/company'
import { color } from 'utils/functions/colors'
import theme from 'utils/theme'
import { GroupTypes } from '../constants/groups'

export const getDropdownOptionsForStatus = ({
  metricData,
  onRemoveMetric,
  onAddNewValue,
  onEditMetric,
  activeMilestones,
  onEditMilestone,
  onSetMilestone,
  intl,
  onViewMetricDetail,
  redirectToLogs,
  onImportCsv,
  onExportCsv,
}) => {
  const isCalculatedMetric = metricData.metricSource === MetricSources.System
  const hasDataPoints = metricData.dataPoints?.length > 0 // has value

  return [
    <OptionsDropdown.Item
      key="viewMetricDetails"
      label={<FormattedMessage id="metrics.viewMetricDetails" />}
      icon={['fal', 'analytics']}
      onSelectOption={() => onViewMetricDetail(metricData?.id)}
    />,
    !isCalculatedMetric && (
      <ReadOnlyTooltip
        key="addDataPoint"
        id={`addDataPointDropdown_${metricData.id}`}
        text={intl.formatMessage({ id: 'readOnlyMode.addMetricValues' })}
        place="left"
        leftOffset={-10}
        maxWidth="38.5rem"
      >
        <OptionsDropdown.Item
          key="addNewValue"
          label={<FormattedMessage id="metrics.addValue" />}
          icon={['fal', 'plus-square']}
          onSelectOption={() => onAddNewValue(metricData)}
        />
      </ReadOnlyTooltip>
    ),

    hasDataPoints && (
      <ReadOnlyTooltip
        key="exportCsv"
        id={`exportCsvDropdown_${metricData.id}`}
        text={intl.messages['readOnlyMode.exportMetricCsv']}
        place="left"
        leftOffset={-10}
        maxWidth="38.5rem"
      >
        <OptionsDropdown.Item
          label={intl.messages['metrics.exportCsv']}
          icon={['fal', 'file-export']}
          onSelectOption={() => onExportCsv(metricData)}
        />
      </ReadOnlyTooltip>
    ),
    !isCalculatedMetric && (
      <ReadOnlyTooltip
        key="importCsv"
        id={`importCsvDropdown_${metricData.id}`}
        text={intl.formatMessage({ id: 'readOnlyMode.importMetricCsv' })}
        place="left"
        leftOffset={-10}
        maxWidth="38.5rem"
      >
        <OptionsDropdown.Item
          label={intl.formatMessage({
            id: 'metrics.importCsv',
          })}
          icon={['fal', 'file-import']}
          onSelectOption={onImportCsv}
        />
      </ReadOnlyTooltip>
    ),
    hasDataPoints &&
      (activeMilestones?.length ? (
        <ReadOnlyTooltip
          key="editMilestone"
          id={`editMilestoneDropdown_${metricData.id}`}
          text={intl.messages['readOnlyMode.editMilestone']}
          place="left"
          leftOffset={-10}
          maxWidth="38.5rem"
        >
          <OptionsDropdown.Item
            label={<FormattedMessage id="metrics.editMilestone" />}
            icon={['fal', 'trophy-alt']}
            onSelectOption={() => {
              onEditMilestone(metricData, activeMilestones[0])
            }}
          />
        </ReadOnlyTooltip>
      ) : (
        <ReadOnlyTooltip
          key="setMilestone"
          id={`setMilestoneDropdown_${metricData.id}`}
          text={intl.messages['readOnlyMode.setMilestone']}
          place="left"
          leftOffset={-10}
          maxWidth="38.5rem"
        >
          <OptionsDropdown.Item
            label={
              <FormattedMessage
                id={
                  isCalculatedMetric
                    ? 'metrics.setGroupMilestone'
                    : 'metrics.setMilestone'
                }
              />
            }
            icon={['fal', 'trophy-alt']}
            onSelectOption={() => onSetMilestone(metricData)}
          />
        </ReadOnlyTooltip>
      )),
    !isCalculatedMetric && (
      <OptionsDropdown.Item
        key="metricSettings"
        label={<FormattedMessage id="metrics.metricSettings" />}
        icon={['fal', 'cog']}
        onSelectOption={() => onEditMetric(metricData)}
      />
    ),
    <OptionsDropdown.Item
      key="metricLogs"
      label={<FormattedMessage id="metrics.metricLog" />}
      icon={['fal', 'clipboard-list-check']}
      onSelectOption={redirectToLogs}
    />,
    !isCalculatedMetric && (
      <OptionsDropdown.Item
        label={<FormattedMessage id="metrics.removeMetric" />}
        color={theme.colors.red}
        icon={['fal', 'trash-alt']}
        onSelectOption={() => onRemoveMetric(metricData)}
      />
    ),
  ].filter(Boolean)
}

export const isMetricOutOfDate = (
  frequency,
  countDays,
  limitPercent = 0.25
) => {
  if (countDays === null) {
    return false
  }
  switch (frequency) {
    case MetricFrequency.ANNUALLY:
      return countDays <= Math.ceil(365 * limitPercent)
    case MetricFrequency.QUARTERLY:
      return countDays <= Math.ceil(90 * limitPercent)
    case MetricFrequency.MONTHLY:
      return countDays <= Math.ceil(30 * limitPercent)
    case MetricFrequency.WEEKLY:
      return countDays <= Math.ceil(7 * limitPercent)
    default:
      return false
  }
}

export const calculateMilestoneProgress = (lastDataPoint, milestoneValue) => {
  if (!lastDataPoint?.value || !milestoneValue) {
    return 0
  }

  if (lastDataPoint?.value >= milestoneValue) {
    return 100
  }

  return round((lastDataPoint.value * 100) / milestoneValue, 0)
}

export const getMetricCompaniesPortfoliosFormat = (companies, portfolios) => {
  return [
    {
      id: 'PORTFOLIOS',
      group: 'Funds or Portfolios',
      subOptions: portfolios,
    },
    {
      id: 'COMPANIES',
      group: 'Portfolio Holdings',
      subOptions: companies.map((company) => ({
        ...company,
        type: 'Company',
        logo: company.primaryLogo?.url,
      })),
    },
  ]
}

export const getMetricNames = (intl) => {
  return [
    {
      id: 'CUSTOMER_METRICS',
      group: 'Customer Metrics',
      subOptions: [
        {
          id: METRIC_NAMES.CUSTOMER_ACQUISITION_COST,
          label: intl.formatMessage({ id: 'metrics.customerAcquisitionCost' }),
        },
        {
          id: METRIC_NAMES.CUSTOMER_CHURN_RATE,
          label: intl.formatMessage({ id: 'metrics.customerChurnRate' }),
        },
        {
          id: METRIC_NAMES.CUSTOMER_LIFETIME_VALUE,
          label: intl.formatMessage({ id: 'metrics.customerLifetimeValue' }),
        },
        {
          id: METRIC_NAMES.CUSTOMER_SATISFACTION_RETENTION,
          label: intl.formatMessage({
            id: 'metrics.customerSatisfactionRetention',
          }),
        },
        {
          id: METRIC_NAMES.NUMBER_OF_CUSTOMERS,
          label: intl.formatMessage({ id: 'metrics.numberOfCustomers' }),
        },
      ],
    },
    {
      id: 'FINANCIAL_METRICS',
      group: 'Financial Metrics',
      subOptions: [
        {
          id: METRIC_NAMES.REVENUE,
          label: intl.formatMessage({ id: 'metrics.revenue' }),
        },
        {
          id: METRIC_NAMES.EXPENSES,
          label: intl.formatMessage({ id: 'metrics.expenses' }),
        },
        {
          id: METRIC_NAMES.OPERATING_CASH_FLOW,
          label: intl.formatMessage({ id: 'metrics.operatingCashFlow' }),
        },
        {
          id: METRIC_NAMES.NET_INCOME,
          label: intl.formatMessage({ id: 'metrics.netIncome' }),
        },
        {
          id: METRIC_NAMES.NET_PROFIT,
          label: intl.formatMessage({ id: 'metrics.netProfit' }),
        },
      ],
    },
    {
      id: 'SALES_AND_MARKETING_METRICS',
      group: 'Sales and marketing metrics',
      subOptions: [
        {
          id: METRIC_NAMES.SALES,
          label: intl.formatMessage({ id: 'metrics.sales' }),
        },
        {
          id: METRIC_NAMES.SALES_GROWTH,
          label: intl.formatMessage({ id: 'metrics.salesGrowth' }),
        },
        {
          id: METRIC_NAMES.NEW_LEADS_PROSPECTS,
          label: intl.formatMessage({ id: 'metrics.newLeadsProspects' }),
        },
        {
          id: METRIC_NAMES.SALE_CONVERSION_RATE,
          label: intl.formatMessage({ id: 'metrics.saleConversionRate' }),
        },
      ],
    },
  ]
}

export const getFrequencyNames = (intl) => {
  return [
    {
      id: MetricFrequency.WEEKLY,
      label: intl.formatMessage({ id: 'metrics.weekly' }),
    },
    {
      id: MetricFrequency.MONTHLY,
      label: intl.formatMessage({ id: 'metrics.monthly' }),
    },
    {
      id: MetricFrequency.QUARTERLY,
      label: intl.formatMessage({ id: 'metrics.quarterly' }),
    },
    {
      id: MetricFrequency.ANNUALLY,
      label: intl.formatMessage({ id: 'metrics.annually' }),
    },
    {
      id: MetricFrequency.NONE,
      label: intl.formatMessage({ id: 'metrics.none' }),
    },
  ]
}

const DELETE_METRIC_THRESHOLD_IN_DAYS = 2
export const isCreationDateOlderThanTwoDays = (createdAt) => {
  const now = dayjs()
  const updateDate = dayjs(createdAt)
  return now.diff(updateDate, 'day') >= DELETE_METRIC_THRESHOLD_IN_DAYS
}

export const isMetricEditable = (metric) => {
  return metric && !isCreationDateOlderThanTwoDays(metric.createdAt)
}

export const getShareValuesColumns = ({ intl, onRemoveValue }) => [
  {
    label: intl.messages['metrics.date'],
    align: 'left',
    flex: 1.2,
    cellRenderer: ({ rowData }) => {
      return dayjs(rowData.date).format('DD MMM YYYY')
    },
  },
  {
    label: intl.messages['metrics.value'],
    align: 'left',
    flex: 1.2,
    cellRenderer: ({ rowData }) => {
      return <ValueWrapper>{displayThousands(rowData.value)}</ValueWrapper>
    },
  },
  {
    align: 'left',
    flex: 0.5,
    cellRenderer: ({ rowData }) => {
      return (
        <TimesIconWrapper>
          <FontAwesomeIcon
            onClick={() => onRemoveValue(rowData)}
            icon={['fal', 'times']}
          />
        </TimesIconWrapper>
      )
    },
  },
]

export const getGrowthPercentage = (value, previousValue) => {
  if (previousValue === 0) {
    return 0
  }

  const percentage = ((value - previousValue) / previousValue) * 100
  const formattedPercentage = withDecimals(percentage, 0)

  return `${formattedPercentage}%`
}

export const getMetricsDataPointColumns = ({
  intl,
  currentGroupId,
  archiveDataPoints,
  unArchiveDataPoints,
  editDataPoint,
  isFounder,
  isClient,
  deleteDataPoints,
  isActiveTab,
  metric,
}) => {
  const isCalculatedMetric = metric.metricSource === MetricSources.System
  return [
    {
      label: intl.messages['showMetrics.date'],
      align: 'left',
      flex: 0.6,
      sortKey: 'date',
      padding: '0 0.6rem 0 0.2rem',
      cellRenderer: ({ rowData }) => {
        const date = dayjs(rowData.date).format('MMM DD, YYYY')
        if (currentGroupId !== rowData?.group?.id) {
          return (
            <>
              {isActiveTab && <TooltipGlobalStyles />}
              <Tooltip
                id={rowData.id}
                place="top"
                event="mouseover mouseenter"
                eventOff="mouseleave mouseout scroll mousewheel blur"
                leftOffset={146}
                maxWidth="32.9rem"
                backgroundColor={
                  isCalculatedMetric
                    ? theme.colors.purple
                    : theme.colors.primaryBlue
                }
                text={
                  <FormattedMessage
                    values={{
                      br: <br />,
                      header: (
                        <b>
                          {intl.formatMessage({
                            id: isCalculatedMetric
                              ? 'metrics.valueAddedBySystem'
                              : 'metrics.valueAddedByFounderHeader',
                          })}
                        </b>
                      ),
                    }}
                    id={
                      isCalculatedMetric
                        ? 'metrics.valueAddedBySystemDesc'
                        : 'metrics.valueAddedByFounderDesc'
                    }
                  />
                }
              >
                <div data-iscapture="true">
                  <Triangle
                    color={isCalculatedMetric ? 'purple' : 'primaryBlue'}
                  />
                </div>
              </Tooltip>

              <DateWrapper>{date}</DateWrapper>
            </>
          )
        }
        return <DateContainer>{date}</DateContainer>
      },
    },
    {
      label: intl.messages['showMetrics.value'],
      align: 'left',
      flex: 1,
      sortKey: 'value',
      padding: '0',
      cellRenderer: ({ rowData }) => {
        return (
          <FMVCell>
            <MetricValue
              color={color('darkGray')({ theme })}
              fontWeight={400}
              showInOneColumn={isFounder}
            >
              {parseInt(rowData.value, 10).toLocaleString()}
              {rowData.growth !== null && archiveDataPoints && (
                <GrowthIndicatorWrapper>
                  <GrowthIndicator
                    direction={rowData.growth ? 'up' : 'down'}
                    bold
                    padding="0"
                  />
                  <GrowthValue
                    growth={rowData.growth}
                    value={getGrowthPercentage(
                      rowData.value,
                      rowData.value - rowData.growthValue
                    )}
                    bold
                    padding="0 0 0 .1rem"
                  />
                </GrowthIndicatorWrapper>
              )}
            </MetricValue>
          </FMVCell>
        )
      },
    },
    {
      label: intl.messages['showMetrics.sharedWith'],
      align: 'left',
      flex: 0.7,
      hideColumnDataCells: !isFounder,
      hidden: !isFounder,
      padding: '0 0.6rem 0 0',
      sortKey: 'sharedGroups.length',
      cellRenderer: ({ rowData, rowIndex }) => {
        const sharedGroups = rowData.sharedGroups?.filter(
          (metricGroup) => metricGroup?.id !== currentGroupId
        )

        return (
          <GroupCell>
            <GroupTooltips
              small
              groups={sharedGroups}
              maxGroupsToDisplay={3}
              zIndexMultiplier={10 ** rowIndex}
              withArrowOffset
              direction="left"
              tooltipPlace="right"
            />
          </GroupCell>
        )
      },
    },
    {
      sortDisabled: true,
      key: 9,
      flex: 0,
      padding: '0',
      cellRenderer: ({ rowData }) => {
        const readOnlyMode = rowData?.sharedGroups?.length > 0

        const isDataPointFromFounder = rowData.group?.type === 'FounderTeam'
        const canEditValue =
          !rowData.archive && isFounder && !isCalculatedMetric
        const canRemoveValue =
          !isFounder && !isDataPointFromFounder && !isCalculatedMetric

        const isFounderOrClient = isFounder || isClient

        const removeValueComponent = (
          <OptionsDropdown.Item
            label={<FormattedMessage id="metrics.removeValue" />}
            icon={['fal', 'trash-alt']}
            color="#E75767"
            onSelectOption={() => {
              deleteDataPoints([rowData])
            }}
          />
        )

        return (
          <FloatColumnWrapper id={rowData.id}>
            <OptionsDropdown id={rowData.id} topOffset={-8}>
              {canEditValue ? (
                <OptionsDropdown.Item
                  label={<FormattedMessage id="metrics.editValue" />}
                  icon={['fal', 'pen']}
                  onSelectOption={() => {
                    editDataPoint({
                      readOnlyMode,
                      dataPoint: rowData,
                    })
                  }}
                />
              ) : null}

              {canRemoveValue ? (
                <>
                  <ReadOnlyTooltip
                    key="editDataPoint"
                    id={`editDataPointDropdown_${rowData.id}`}
                    text={intl.messages['readOnlyMode.editMetricValues']}
                    place="left"
                    leftOffset={-10}
                  >
                    <OptionsDropdown.Item
                      label={<FormattedMessage id="metrics.editValue" />}
                      icon={['fal', 'pen']}
                      onSelectOption={() => {
                        editDataPoint({
                          readOnlyMode,
                          dataPoint: rowData,
                        })
                      }}
                    />
                  </ReadOnlyTooltip>
                  {removeValueComponent}
                </>
              ) : null}

              {!isFounder && isDataPointFromFounder
                ? removeValueComponent
                : null}

              {isFounderOrClient || isCalculatedMetric ? (
                <OptionsDropdown.Item
                  label={
                    rowData.archive ? (
                      <FormattedMessage id="metrics.moveToMainList" />
                    ) : (
                      <FormattedMessage id="metrics.archiveValue" />
                    )
                  }
                  icon={['fal', rowData.archive ? 'arrow-alt-up' : 'archive']}
                  onSelectOption={() => {
                    if (rowData.archive) {
                      unArchiveDataPoints([rowData])
                    } else {
                      archiveDataPoints([rowData])
                    }
                  }}
                />
              ) : null}
            </OptionsDropdown>
          </FloatColumnWrapper>
        )
      },
    },
  ]
}

const getDateFormatForFrequency = (frequency) => {
  if (frequency === MetricFrequency.WEEKLY) {
    return 'DD MMM YYYY'
  }
  return 'MMM YYYY'
}

export const sortMetricDataPoints = (dataPoints) =>
  sortBy(
    dataPoints,
    (m) => new Date(m.date),
    (m) => new Date(m.createdAt)
  )

export const getMetricChartData = (dataPoints = [], frequency) => {
  return sortMetricDataPoints(dataPoints).map((dataPoint) => ({
    x: dayjs(dataPoint.date).format(getDateFormatForFrequency(frequency)),
    y: dataPoint.value,
  }))
}

export const groupMetricsByName = (metrics) => {
  return chain(metrics)
    .filter((metric) => metric.dataPoints?.length > 0)
    .groupBy((metric) => metric.name?.toLowerCase())
    .mapValues((metricsForName) => ({
      id: metricsForName[0].id,
      metric: metricsForName[0].name,
      data: metricsForName,
    }))
    .values()
    .value()
}

export const getPortfolioMetricsChartData = (companiesMetricData) => {
  return chain(companiesMetricData)
    .map((companyMetricData) =>
      companyMetricData.dataPoints.map((datapoint) => ({
        ...datapoint,
        metricId: companyMetricData.id,
        companyData: companyMetricData.companyData,
      }))
    )
    .reduce((res, metricDataPoints) => res.concat(metricDataPoints), [])
    .groupBy((data) => dayjs(data.date).format('YYYYMMDD'))
    .mapValues((datapointsForDate) => {
      return datapointsForDate.reduce(
        (res, datapoint) => {
          res[`${datapoint.companyData?.id}_${datapoint.metricId}`] =
            datapoint.value
          return res
        },
        { date: datapointsForDate[0].date }
      )
    })
    .values()
    .value()
}

export const groupMilestonesByState = ({
  milestones = [],
  investorsMilestones = [],
}) => {
  const reachedMilestones = []
  const expiredMilestones = []
  const activeMilestones = []

  milestones.forEach((milestone) => {
    const milestoneIsExpired = new Date() >= new Date(milestone.date)

    if (milestoneIsExpired) {
      if (milestone.percentage >= 100) {
        reachedMilestones.push(milestone)
      } else {
        expiredMilestones.push(milestone)
      }
    } else {
      activeMilestones.push(milestone)
    }
  })

  return {
    investorsMilestones,
    activeMilestones,
    reachedMilestones,
    expiredMilestones,
  }
}

export const getMetricTrackingSince = (metric, dataPoints) => {
  const firstDataPoint = dataPoints?.[0]

  if (firstDataPoint) {
    return firstDataPoint.updatedAt
  }
  return metric.createdAt
}

export const calculateDaysToNextUpdate = (frequency, nextDateToUpdate) => {
  if (!frequency || !nextDateToUpdate) {
    return Infinity
  }
  const now = dayjs()
  const limitDate = dayjs(nextDateToUpdate).endOf('day')
  const diffDays = dayjs(limitDate).diff(now, 'days', true)
  return diffDays
}

export const undoDeleteMilestone = ({ queryClient, metricId, milestone }) => {
  queryClient.setQueryData(['metric', metricId], (metric) => {
    return {
      ...metric,
      milestones: [...metric.milestones, milestone],
    }
  })
}

export const deleteMilestone = ({ queryClient, metricId, milestone }) => {
  queryClient.setQueryData(['metric', metricId], (metric) => {
    return {
      ...metric,
      milestones: metric.milestones.filter(
        (oldMilestone) => oldMilestone.id !== milestone.id
      ),
    }
  })
}

export const getActionDropdownOptions = (intl) => {
  return [
    { label: intl.messages['metrics.createMetric'], id: 'CREATE_METRIC' },
    {
      label: intl.messages['metrics.addToExistingMetric'],
      id: 'ADD_TO_EXISTING_METRIC',
    },
  ]
}

export const metricIsReceivingDataFromFounder = (metric) => {
  return (
    metric.senderLinkedMetric?.receiveData &&
    (metric.senderLinkedMetric?.state === MetricStates.SHARE_ACCEPTED ||
      metric.senderLinkedMetric?.state === MetricStates.REQUEST_ACCEPTED)
  )
}

export const populateMetricGrowth = (dataPoints) => {
  return dataPoints.map((dp, index) => {
    if (index === dataPoints.length - 1) {
      return {
        ...dp,
        growth: null,
        growthValue: null,
      }
    }

    const growthValue = dp.value - dataPoints[index + 1].value

    return growthValue === 0
      ? {
          ...dp,
          growth: null,
          growthValue: null,
        }
      : {
          ...dp,
          growth: growthValue > 0,
          growthValue,
        }
  })
}

export const populateDataPoints = (metricValues) => {
  const sortedDataPoints = sortMetricDataPoints(metricValues)
  return populateMetricGrowth(sortedDataPoints)
}

export const getIndexToInsertCompanyName = ({
  companyNames,
  companyNameToInsert,
  sortField,
  sortDirection,
}) => {
  const sortedWithNewItem = companyNames
    .concat(companyNameToInsert)
    .map((name) => name?.toLowerCase())
    .sort()

  if (sortField === MetricSortBy.NAME && sortDirection === SortDirection.DESC) {
    sortedWithNewItem.reverse()
  }
  return sortedWithNewItem.findIndex(
    (companyName) => companyName === companyNameToInsert.toLowerCase()
  )
}

export const getGroupedMetrics = ({ paginatedMetrics, removedMetricsIds }) => {
  const groupedMetrics =
    paginatedMetrics?.pages.reduce((acc, currPage) => {
      currPage?.data.forEach((metric) => {
        if (removedMetricsIds.includes(metric.id)) {
          return
        }
        const key = metric.companyData?.id || metric.metrizable
        if (!acc[key]) {
          const name = metric.companyData?.name || metric.metrizableName
          acc[key] = {
            companyData: metric.companyData ??
              metric.senderGroup?.companyData?.[0] ?? { id: key, name },
            metrics: [metric],
          }
        } else {
          acc[key].metrics.push(metric)
        }
      })
      return acc
    }, {}) ?? {}

  return Object.values(groupedMetrics)
}

export const getSharedMetricCompany = (sharedMetric) => {
  if (sharedMetric?.senderGroup?.type === GroupTypes.FUND_MANAGER) {
    const fundCompany = sharedMetric.senderGroup.companyData?.find(
      (company) => company.fund
    )
    return fundCompany
  }
  return sharedMetric?.senderGroup?.companyData?.[0]
}

export const groupMetricsByCompany = (
  metrics,
  sharedMetrics,
  removedMetricsIds,
  isLastPage,
  sortField,
  sortDirection
) => {
  if (!metrics?.pages && !sharedMetrics?.length) {
    return []
  }
  const metricsByCompany = getGroupedMetrics({
    paginatedMetrics: metrics,
    removedMetricsIds,
  }).filter((metric) => metric.companyData !== undefined)

  const findMetricsForCompanyId = (companyId) =>
    metricsByCompany.find((data) => data.companyData?.id === companyId)

  const groupedRequests = groupBy(sharedMetrics, (sharedMetric) => {
    const metricCompany = getSharedMetricCompany(sharedMetric)
    return metricCompany.id
  })

  Object.entries(groupedRequests).forEach(
    ([companyId, sharedMetricsForCompany]) => {
      const companyMetricsData = findMetricsForCompanyId(companyId)
      if (companyMetricsData) {
        companyMetricsData.metrics = [
          ...sharedMetricsForCompany,
          ...companyMetricsData.metrics,
        ]
      } else {
        const companySharedMetric = getSharedMetricCompany(
          sharedMetricsForCompany[0]
        )
        const newCompanyData = {
          companyData: companySharedMetric,
          metrics: sharedMetricsForCompany,
        }

        const indexToInsert = getIndexToInsertCompanyName({
          companyNames: metricsByCompany.map(
            (company) => company.companyData?.name
          ),
          companyNameToInsert: newCompanyData?.companyData?.name,
          sortField,
          sortDirection,
        })

        if (metricsByCompany.length === 0) {
          metricsByCompany.push(newCompanyData)
        } else if (indexToInsert < metricsByCompany.length || isLastPage) {
          metricsByCompany.splice(indexToInsert, 0, newCompanyData)
        }
      }
    }
  )
  return metricsByCompany
}

export const shouldCompanySupportMetrics = (company, currentGroupId) => {
  return (
    company?.groupId !== currentGroupId && company?.group?.id !== currentGroupId
  )
}

export const shouldHoldingSupportMetrics = (holding, currentGroupId) => {
  return (
    holding?.groupId !== currentGroupId &&
    holding?.group?.id !== currentGroupId &&
    holding.holdingType === HoldingType.COMPANY
  )
}
