import styled, { createGlobalStyle, css } from 'styled-components'
import { Link } from 'react-router-dom'
import { motion } from 'framer-motion'
import Button from 'ui/Button'
import { ExpandableRowContainer } from 'components/ExpandableTable/ExpandableRow/ExpandableRow.styles'
import { color } from 'utils/functions/colors'
import { maxSize } from 'utils/constants/breakpoint'

export const CreateMetricButton = styled(Button)`
  font-weight: bold;
  letter-spacing: 0.107rem;
  border-radius: 0.8rem;
  padding: 1rem 3rem;
  text-transform: uppercase;

  svg {
    margin-right: 1rem;
  }
`
export const MetricsHeader = styled.header`
  display: flex;
  justify-content: space-between;

  @media (max-width: 720px) {
    flex-direction: column-reverse;
    margin: 1rem;

    button[class*='Button_btn'] {
      width: 100%;
      margin-top: 1rem;
    }
  }

  button[id='createMetric'] {
    width: 16rem;
  }
`

export const MetricsContainer = styled.div`
  padding-top: 1rem;
  display: grid;
`

export const FounderMetricsContainer = styled.div`
  padding-top: 3.4rem;
  display: grid;
  grid-template-columns: 3fr 1fr;
  gap: 5rem;

  @media (max-width: 1200px) {
    grid-template-columns: 1fr;
  }
`

export const MetricsTitle = styled.h1`
  margin-bottom: 2.4rem;
  margin-top: 0;
  font-size: 2.4rem;
  color: #131313;
`

export const CountMetrics = styled.span`
  font-size: 1.6rem;
`

export const CompanyNameLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  &:hover {
    text-decoration: underline;
  }

  span {
    margin-left: 1rem;
  }

  object,
  img {
    border-radius: 10rem;
    width: 3rem;
  }
`

export const StyledLink = styled(Link)`
  display: block;
  color: #194590;

  &:hover {
    text-decoration: underline;
  }
`
export const CellLabel = styled.span`
  color: #787e8c;
  font-weight: 600;
  ${(props) =>
    props.upperCase &&
    css`
      text-transform: uppercase;
    `}
`

export const TableWrapper = styled.div`
  ${ExpandableRowContainer} {
    font-size: 1.4rem;
  }
`

export const OutOfDate = styled.div`
  color: #e75767;
  font-size: 1rem;
  line-height: 1.2rem;
`

export const DaysRemaining = styled.div`
  color: #194590;
  font-size: 1rem;
  line-height: 1.2rem;
`

export const CellSubLabel = styled.div`
  color: #787e8c;
  font-size: 1rem;
  line-height: 1.2rem;
`

export const MetricsGrid = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 25.6rem;
  grid-gap: 2.4rem;

  @media (min-width: 1680px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: 1023px) {
    grid-template-columns: 1fr;
    margin: 0 1rem;
  }
`

export const CheckButton = styled.button`
  width: 3.9rem;
  height: 3.9rem;
  box-shadow: 0.1rem 0.2rem 0.6rem 0 rgba(0, 0, 0, 0.1);
  border: solid 0.1rem #194590;
  border-radius: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #194590;
  color: #eff2f7;
  font-size: 1.7rem;

  &:focus {
    outline: none;
  }
`

export const LoaderWrapper = styled.div`
  height: 50vh;
`

export const NoResultsContainer = styled.div`
  position: relative;
  margin-top: 12rem;

  ${(props) =>
    props.margin
      ? css`
          margin: ${props.margin};
        `
      : ''}
`

export const Triangle = styled.div`
  width: 0;
  height: 0;
  border-top: 1.2rem solid
    ${(props) => (props.color ? color(props.color) : color('purple'))};
  border-right: 12px solid transparent;
  position: absolute;
  top: 0;
  left: 0;
`

export const TooltipGlobalStyles = createGlobalStyle`
  div[class^='__react_component_tooltip'] {
    margin-top: -10px;
    width: 331px;
    background: ${color('purple')};
    border-bottom-left-radius: 0px !important;

    &::before,
    &::after {
      display: none;
    }
  }
`

export const InputWrapper = styled.div`
  width: 27%;

  input {
    border-radius: 0.8rem;

    &:not(:placeholder-shown) {
      padding-right: 1rem;
    }
  }

  svg {
    color: ${color('darkBlue')};
  }
`

export const CompanyMetricsWrapper = styled.div``

export const RequestsMetricsWrapper = styled.div`
  display: none;

  @media (min-width: 1200px) {
    display: block;
  }
`

export const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2.2rem;
  min-width: 0;

  @media (max-width: 1200px) {
    flex-direction: column;
    justify-content: flex-start;
    align-items: baseline;
    gap: 1rem;
    padding: 0 1rem;

    > div {
      width: 100%;
      max-width: 100vw;
    }
  }
`

export const HeaderSubContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

export const Title = styled.h1`
  color: ${color('black')};
  font-size: 2.4rem;
  font-weight: 400;
  margin-bottom: 0;
`

export const SortBy = styled.div``

export const RequestsMetricsContainer = styled.div`
  display: flex;
  flex-direction: column;

  > div {
    padding: 0 3.2rem 0 0;
  }

  div[class^='Tabs_tabContainer'] {
    min-width: 32.7rem;
    justify-content: space-between;
    border-bottom: 1px solid rgba(120, 126, 140, 0.2);
    min-width: 0;
    width: 32.7rem;
  }

  div[class^='Tabs_tabItemContainer'] {
    text-align: center;
    width: 14rem;
  }
`

export const MobileRequestsMetricsWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: 5.8rem;
  background: #f4f6f7;

  ${(props) =>
    props.isRequestsExpanded &&
    css`
      height: 70rem;
    `}

  @media (min-width: 1200px) {
    display: none;
  }
`
export const ResponsiveTitle = styled.h1`
  color: ${color('black')};
  font-size: 1.8rem;
  font-weight: 400;
  margin-bottom: 0;
  margin-left: 3.2rem;
  cursor: pointer;

  > svg {
    margin-right: 1.5rem;
    transform: rotate(0);
  }

  ${(props) =>
    props.isRequestsExpanded &&
    css`
      > svg {
        transform: rotate(180deg);
      }
    `}

  @media (min-width: 1200px) {
    font-size: 2.4rem;
    display: block;
    margin-left: 0;
    cursor: none;
    > svg {
      display: none;
    }
  }
`

export const Table = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  min-width: 0;
`

export const CompanyTitleRow = styled.div`
  display: flex;
  justify-content: space-between;
  &:not(:first-child) {
    margin-top: 2.2rem;
  }

  @media ${maxSize.md} {
    max-width: 100vw;
  }
`

export const CompanyWrapper = styled(Link)`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-top: 2.4rem;

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

export const CompanyLogo = styled.img`
  width: 2.1rem;
  height: 2.1rem;
  border-radius: 10rem;
`

export const CompanyName = styled.p`
  font-weight: 700;
  font-size: 1.4rem;
  color: ${color('darkGray')};
`

export const DateContainer = styled.p`
  font-size: 1.4rem;
`

export const BulkContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
`

const commonBulkActionStyles = css`
  border: 0.1rem solid #c9cfe4;
  font-size: 1.4rem;
  font-weight: 700;
  width: 100%;
  padding: 1rem;
  border-radius: 0.8rem;
  &:focus {
    outline: ${color('primaryDarkBlue')};
  }
  ${(props) =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}
`

export const BtnApproveAll = styled.button`
  color: ${color('darkBlue')};
  ${commonBulkActionStyles}
`

export const BtnDenyAll = styled.button`
  color: ${color('red')};
  ${commonBulkActionStyles}
`

export const SortDirectionLabel = styled.span`
  color: ${color('lightGray')};
  font-size: 1.2rem;
  margin-left: 0.5rem;
`

export const SortContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 0.8rem;

  div[class*='SortBy_sortContainer'] {
    padding: 0.2rem;
  }

  @media ${maxSize.md} {
    flex-wrap: wrap;
  }
`

export const GroupCell = styled.div`
  margin-left: 0.8rem;
`

export const DateWrapper = styled.div`
  color: red;
  font-weight: 400;
  color: #4a4a68;
`

export const CellContainer = styled.div`
  color: red;
  font-weight: 400;
  font-size: 1.4rem;
`

export const ModalQuestion = styled.p`
  font-size: 1.8rem;
  color: ${color('darkBlue')};
`

export const ModalLegend = styled.p`
  font-size: 1.4rem;
  color: ${color('darkGray')};
  margin-top: 0.8rem;
`

export const DenyAllModalWrapper = styled.div`
  div[class^='Modal_modalWrapper'] {
    max-width: 47.5rem;
  }
`

export const Divider = styled.div`
  height: 0.1rem;
  background: rgba(233, 233, 233, 1);
  width: 100%;
`

export const DenyAllFooterWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  gap: 0.8rem;
`

export const InfiniteLoaderWrapper = styled.div`
  margin-top: 3rem;
`

export const ExportButton = styled(Button)`
  background: transparent;
  color: #2a2a2f;
  font-size: 1.4rem;
  font-weight: 600;
  text-transform: capitalize;
  margin-left: ${(props) => props.marginLeft || '1.2rem'};
  &:hover {
    box-shadow: none;
  }

  svg {
    font-size: 1.4rem;
    margin-right: 0.7rem;
  }

  @media ${maxSize.md} {
    width: fit-content;
  }
`

export const Separator = styled.div`
  width: 0.1rem;
  height: 3.3rem;
  background-color: rgba(120, 126, 140, 0.2);
`
export const PortfolioIconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${color('primaryBlue')};
  border-radius: 2.4rem;
  color: ${color('white')};
  font-size: 1.1rem;
  height: 2rem;
  width: 2rem;
`
