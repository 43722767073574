import { IFormContext } from 'components/Form/hooks'
import { compact } from 'lodash'
import { useEffect, useRef } from 'react'
import { useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { MetricFrequency } from 'utils/constants/metrics'
import { useCreateFounderMetricQuery } from 'utils/hooks/queries/useMetrics'
import useMetricGroups from 'utils/hooks/useMetricGroups'
import { getFounderMetricsSchema } from 'utils/schemas/metrics'
import { CompanyHoldingData } from 'utils/types/company'

type MyRefType = {
  clear: () => void
  close: () => void
}
export interface CreateFounderMetricFormType extends IFormContext {
  name: string
  frequency: string
  sharedGroups: {
    id: string
    name: string
  }[]
}

interface GetInitialValuesProps {
  linkedMetric?: any
}

const getInitialValues = ({ linkedMetric }: GetInitialValuesProps) => {
  const defaultValues = {
    name: '',
    frequency: MetricFrequency.NONE,
    sharedGroups: [],
  }

  let currentGroups

  if (linkedMetric) {
    const groupRequestingMetric = linkedMetric?.receiverGroup
    defaultValues.name = linkedMetric?.receiverMetric?.name
    defaultValues.frequency =
      linkedMetric?.receiverMetric?.frequency || MetricFrequency.NONE

    currentGroups = groupRequestingMetric
  }

  return { defaultValues, currentGroups }
}

interface Props {
  closeDrawer: () => void
}

const useCreateFounderMetric = ({ closeDrawer }: Props) => {
  const firstRender = useRef<boolean>(true)
  const dropdownRef = useRef<MyRefType>(null)
  const intl = useIntl()

  const { state } = useLocation<{
    linkedMetric?: any
    companyToAdd?: CompanyHoldingData
  }>()

  const clearDropdown = () => {
    dropdownRef.current?.clear()
    dropdownRef.current?.close()
  }

  const {
    setCurrentGroups,
    handleAddGroup,
    handleRemoveGroup,
    loadGroups,
    currentGroups,
    searchText: groupSearchText,

    itemLists,
    handleAddItemList,
    handleDeleteItemList,
    handleEditItemList,
  } = useMetricGroups()

  const { defaultValues: initialValues, currentGroups: initialCurrentGroups } =
    getInitialValues({
      linkedMetric: state?.linkedMetric,
    })

  const validationSchema = getFounderMetricsSchema(intl)

  const onSelectGroup = (...params) => {
    handleAddGroup(...params)
    setTimeout(clearDropdown)
  }

  useEffect(() => {
    if (!firstRender.current) return

    if (initialCurrentGroups) {
      setCurrentGroups([
        {
          id: initialCurrentGroups?.id,
          linkedMetricId: state.linkedMetric?.id,
          name: initialCurrentGroups?.name,
          logo: {
            url: initialCurrentGroups?.logo?.url,
          },
          canDelete: false,
        },
      ])
    } else {
      setCurrentGroups([])
    }

    firstRender.current = false
  }, [
    initialCurrentGroups,
    initialCurrentGroups?.id,
    initialCurrentGroups?.length,
    initialCurrentGroups?.logo?.url,
    initialCurrentGroups?.name,
    setCurrentGroups,
    state?.linkedMetric?.id,
  ])

  const { mutate: createMetricMutation, isLoading: isCreatingMetric } =
    useCreateFounderMetricQuery({ closeDrawer })

  const getSubOptionById = (id, options) =>
    compact<{ id: string; label: string }>(
      options.map((option) => {
        return option.subOptions.find((subOption) => subOption.id === id)
      })
    )?.[0]

  return {
    initialValues,
    validationSchema,
    isCreatingMetric,
    createMetricMutation,

    currentGroups,
    onSelectGroup,
    handleRemoveGroup,
    loadGroups,
    groupSearchText,

    itemLists,
    handleAddItemList,
    handleDeleteItemList,
    handleEditItemList,

    getSubOptionById,
  }
}

export default useCreateFounderMetric
