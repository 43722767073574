import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useIntl } from 'react-intl'
import classNames from 'classnames'
import { debounce } from 'lodash'

import useOutsideClick from 'utils/hooks/useOutsideClick'
import { randomId } from 'utils/functions/number'
import InputIcon from 'ui/InputIcon'
import Image from 'components/Image'

import styles from './Dropdown.module.scss'

const Dropdown = ({
  label,
  placeholder,
  error,
  warning,
  options,
  onSelectOption,
  disabled,
  register,
  name,
  value,
  showIconOnSelectedOption,
  showBorder,
  async,
  searchEnabled,
  loadOptions,
  getOption,
  searchPlaceholder,
  onAddOption,
  withInput,
  withGroupOptions,
  inputPlaceholder,
  selectedOption: selectedOptionProp,
  onChangeInput,
}) => {
  const intl = useIntl()
  const dropdownRef = useRef(null)
  const dropdownId = useRef(randomId())
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)
  const [search, setSearch] = useState('')
  const [dropdownOptions, setDropdownOptions] = useState(options.map(getOption))
  const [rawOptions, setRawOptions] = useState(options)

  const toggleDropdown = () => {
    setIsDropdownOpen((currState) => !currState)
  }

  useEffect(() => {
    setSelectedOption(selectedOptionProp)
  }, [selectedOptionProp])

  useEffect(() => {
    if (options) {
      setRawOptions(options)
      setDropdownOptions(options.map(getOption))
    }
  }, [options, getOption])

  useOutsideClick(dropdownRef, () => {
    setIsDropdownOpen(false)
  })

  useEffect(() => {
    register({ name })
  }, [name, register])

  const getErrorMessage = () => {
    if (error?.message) return error?.message
    if (error?.type) return intl.messages[`general.${error?.type}`]
    return ''
  }

  const getWarningMessage = () => {
    if (warning?.message) return warning?.message
    if (warning?.type) return intl.messages[`general.${warning?.type}`]
    return ''
  }

  const onSelectDropdownOption = useCallback(
    (dropdownOption) => {
      setSelectedOption(dropdownOption)
      const selectedRawOption = rawOptions.find(
        (rawOption) => getOption(rawOption)?.id === dropdownOption.id
      )

      onSelectOption(
        name,
        dropdownOption.id,
        selectedRawOption || dropdownOption.label
      )
      toggleDropdown()
    },
    [getOption, name, onSelectOption, rawOptions]
  )

  const renderIcon = (dropdownOption) => {
    if (!dropdownOption.icon) return null

    if (Array.isArray(dropdownOption.icon)) {
      return (
        <FontAwesomeIcon
          fixedWidth
          width="1.5rem"
          className={styles.optionIcon}
          icon={dropdownOption.icon}
        />
      )
    }

    if (typeof dropdownOption.icon === 'string') {
      return <Image className={styles.optionImage} src={dropdownOption.icon} />
    }

    return dropdownOption.icon
  }

  useEffect(() => {
    if (typeof value === 'object' && value !== null) {
      setSelectedOption(getOption(value))
    } else if (value !== undefined && dropdownOptions.length) {
      const newSelected = dropdownOptions.find((option) => option.id === value)
      setSelectedOption(newSelected)
    }
  }, [dropdownOptions, value, getOption])

  const onChange = useCallback(
    async (searchValue) => {
      const asyncOptions = await loadOptions(searchValue)
      if (!Array.isArray(asyncOptions)) {
        throw new Error('loadOptions prop must resolve to an array')
      }
      const newOptions = asyncOptions.map(getOption)
      setRawOptions(asyncOptions)
      setDropdownOptions(newOptions)
    },
    [getOption, loadOptions]
  )

  const debouncedOnChange = useMemo(() => debounce(onChange, 300), [onChange])

  useEffect(() => {
    if (async) {
      debouncedOnChange('')
    }
  }, [async, debouncedOnChange])

  const renderOption = useCallback((dropdownOption) => {
    if (dropdownOption.legend) {
      return (
        <div className={styles.optionRow}>
          <span>{dropdownOption.label}</span>
          <span className={styles.legend}>{dropdownOption.legend}</span>
        </div>
      )
    }
    return dropdownOption.label
  }, [])

  const handleSearch = useCallback(
    (event) => {
      const { value: searchValue } = event.target
      setSearch(searchValue)
      debouncedOnChange(searchValue)
    },
    [debouncedOnChange]
  )

  const onClickAddOption = useCallback(() => {
    onAddOption(search)
  }, [onAddOption, search])

  return (
    <div
      ref={dropdownRef}
      className={classNames(styles.container, {
        [styles.disabled]: disabled,
      })}
    >
      {label && <p className={styles.inputLabel}>{label}</p>}
      {!withInput && (
        <label
          htmlFor={dropdownId.current}
          className={classNames(styles.containerInput, {
            [styles.error]: !!getErrorMessage(),
            [styles.borderLabel]: !showBorder,
          })}
        >
          <button
            id={dropdownId.current}
            type="button"
            className={classNames(styles.dropdownButton, {
              [styles.dropdownPlaceholder]: !selectedOption && !value,
              [styles.disabledOpacity]: disabled,
            })}
            onClick={toggleDropdown}
            disabled={disabled}
          >
            {showIconOnSelectedOption && selectedOption
              ? renderIcon(selectedOption)
              : null}
            {selectedOption ? renderOption(selectedOption) : placeholder}

            <FontAwesomeIcon
              icon={['fal', isDropdownOpen ? 'angle-up' : 'angle-down']}
              className={classNames(styles.icon, {
                [styles.iconNoLabel]: !label,
              })}
            />
          </button>
        </label>
      )}
      {withInput && (
        <>
          <input
            name={name}
            ref={register}
            placeholder={inputPlaceholder}
            className={classNames(
              styles.containerInput,
              {
                [styles.error]: !!getErrorMessage(),
                [styles.borderLabel]: !showBorder,
              },
              styles.dropdownInput
            )}
            value={value}
            onChange={onChangeInput}
          />
          <button
            id={dropdownId.current}
            type="button"
            className={classNames(styles.dropdownButton, {
              [styles.dropdownPlaceholder]: !selectedOption && !value,
              [styles.disabledOpacity]: disabled,
            })}
            onClick={toggleDropdown}
            disabled={disabled}
          >
            <FontAwesomeIcon
              icon={['fal', isDropdownOpen ? 'angle-up' : 'angle-down']}
              className={classNames(styles.icon, {
                [styles.iconNoLabel]: !label,
              })}
            />
          </button>
        </>
      )}
      <div
        className={classNames(styles.fieldMessage, {
          [styles.fieldErrorMessage]: !!getErrorMessage(),
          [styles.fieldWarningMessage]: !!getWarningMessage(),
        })}
      >
        {getErrorMessage() || getWarningMessage()}
      </div>

      {isDropdownOpen && (
        <div
          className={classNames(styles.dropdown, {
            [styles.inputMargin]: withInput,
          })}
        >
          {searchEnabled && (
            <div className={styles.searchContainer}>
              <InputIcon
                placeholder={searchPlaceholder}
                value={search}
                handleChange={handleSearch}
                tabIndex={0}
              />
            </div>
          )}
          {!withGroupOptions ? (
            <ul className={styles.dropdownOptionsContainer}>
              {dropdownOptions?.map?.((dropdownOption, index) => (
                <li
                  key={dropdownOption.id}
                  className={classNames(styles.dropdownOption, {
                    [styles.dropdownOptionSelected]:
                      selectedOption === dropdownOption,
                  })}
                >
                  <button
                    className={classNames(styles.dropdownOptionButton, {
                      [styles.disabled]: dropdownOption.disabled,
                    })}
                    id={dropdownOption.id}
                    onClick={() => onSelectDropdownOption(dropdownOption)}
                    type="button"
                    disabled={dropdownOption.disabled}
                    tabIndex={index}
                  >
                    {renderIcon(dropdownOption)}
                    {renderOption(dropdownOption)}
                  </button>
                </li>
              ))}
            </ul>
          ) : (
            dropdownOptions?.map?.((groupOption) => {
              return (
                <>
                  <p className={styles.optionsTitle}>{groupOption.group}</p>

                  {groupOption?.subOptions?.map((dropdownOption, index) => {
                    return (
                      <li
                        key={dropdownOption.id}
                        className={classNames(styles.dropdownOption, {
                          [styles.dropdownOptionSelected]:
                            selectedOption === dropdownOption,
                        })}
                      >
                        <button
                          className={classNames(styles.dropdownOptionButton, {
                            [styles.disabled]: dropdownOption.disabled,
                          })}
                          id={dropdownOption.id}
                          onClick={() => onSelectDropdownOption(dropdownOption)}
                          type="button"
                          disabled={dropdownOption.disabled}
                          tabIndex={index}
                        >
                          {renderIcon(dropdownOption)}
                          {renderOption(dropdownOption)}
                        </button>
                      </li>
                    )
                  })}
                </>
              )
            })
          )}
          {searchEnabled && search && dropdownOptions.length === 0 && (
            <div>
              <button
                type="button"
                className={styles.addButton}
                onClick={onClickAddOption}
              >
                <FontAwesomeIcon
                  className={styles.addIcon}
                  icon={['fal', 'plus']}
                />
                <span className={styles.addOptionLabel}>
                  {intl.formatMessage(
                    { id: 'general.addOption' },
                    { value: search }
                  )}
                </span>
              </button>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

Dropdown.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.object,
  warning: PropTypes.object,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      legend: PropTypes.string,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
      icon: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.string,
        PropTypes.element,
      ]),
    })
  ),
  disabled: PropTypes.bool,
  showIconOnSelectedOption: PropTypes.bool,
  showBorder: PropTypes.bool,
  searchEnabled: PropTypes.bool,
  onSelectOption: PropTypes.func.isRequired,
  register: PropTypes.func,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  searchPlaceholder: PropTypes.string,
  async: PropTypes.bool,
  loadOptions: PropTypes.func,
  getOption: PropTypes.func,
  onAddOption: PropTypes.func,

  withInput: PropTypes.bool,
  withGroupOptions: PropTypes.bool,
  inputPlaceholder: PropTypes.string,
  selectedOption: PropTypes.object,
  onChangeInput: PropTypes.func,
}

Dropdown.defaultProps = {
  options: [],
  label: '',
  searchPlaceholder: '',
  placeholder: '',
  disabled: false,
  error: {},
  warning: {},
  register: () => {},
  value: null,
  showIconOnSelectedOption: false,
  showBorder: true,
  searchEnabled: false,
  async: false,
  loadOptions: () => [],
  onAddOption: () => {},
  getOption: (option) => ({
    id: option.id,
    label: option.label,
    icon: option.icon,
    disabled: option.disabled,
  }),
  withInput: false,
  withGroupOptions: false,
  inputPlaceholder: '',
  selectedOption: null,
  onChangeInput: () => {},
}
export default React.memo(Dropdown)
