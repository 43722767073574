import MetricService from 'api/MetricsService'
import { useQuery } from '@tanstack/react-query'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { isActingAsFounder } from 'selectors/auth'

export const useMetricMilestone = (metricId, milestoneId, queryOptions) => {
  const isFounder = useAppSelector(isActingAsFounder)

  const { data: milestone, status } = useQuery(
    ['milestones', metricId, milestoneId, isFounder],
    () => {
      if (isFounder) {
        return MetricService.getFounderMilestone(milestoneId)
      }
      return MetricService.getInvestorMilestone(milestoneId)
    },
    queryOptions
  )

  return {
    milestone,
    status,
    isLoading: status === 'loading',
  }
}
