/* eslint-disable react/style-prop-object */
import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FormattedMessage, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'

import Drawer from 'components/Drawer'
import Input from 'ui/Input'
import InputLabel from 'ui/InputLabel'
import SelectDatePicker from 'components/TimeSelect/SelectDatePicker'
import Button from 'ui/Button'
import CWLoader from 'components/CWLoader/CWLoader'
import Checkbox from 'components/Checkbox'
import Separator from 'ui/Separator'
import Toggle from 'components/Toggle'
import Hint from 'components/Hint'
import { buildFormError } from 'utils/functions/forms'

import useSetMetricMilestone from './useSetMetricMilestone'
import {
  ButtonsContainer,
  CheckboxContainer,
} from './SetMetricMilestone.styles'
import { MetricTitle } from '../ShowMetric/ShowMetric.styles'
import { Form, Legend, LoaderWrapper } from '../EditMetric/EditMetric.styles'

const SetMetricMilestone = ({ closeDrawer, isEditingMilestone, isOpen }) => {
  const {
    formik,
    onDeleteMilestone,
    metric,
    milestone,
    isLoadingMetric,
    isFounder,
    isSystemMetric,
  } = useSetMetricMilestone(closeDrawer, isEditingMilestone)

  const intl = useIntl()
  const history = useHistory()

  if (isLoadingMetric) {
    return (
      <LoaderWrapper>
        <CWLoader />
      </LoaderWrapper>
    )
  }

  return (
    <Drawer
      isOpen={isOpen}
      onCloseDrawer={() => history.goBack()}
      headerTitle={
        isEditingMilestone
          ? intl.formatMessage({ id: 'metrics.editGroupMilestone' })
          : intl.formatMessage({ id: 'metrics.setGroupMilestone' })
      }
    >
      <Form
        onSubmit={(e) => {
          e.preventDefault()
          formik.submitForm(e)
        }}
      >
        <Drawer.Content>
          <Legend>
            {intl.formatMessage({ id: 'metrics.milestoneLegend' })}
          </Legend>
          <Input
            type="number"
            name="value"
            error={buildFormError(
              formik.errors.value,
              'error',
              formik.touched.value
            )}
            value={formik.values.value}
            onChange={formik.handleChange}
            label={intl.messages['metrics.milestone']}
            placeholder={intl.messages['metrics.addValue']}
            onBlur={formik.handleBlur}
          />

          <Separator space="3rem" />

          <SelectDatePicker
            id="date"
            name="date"
            handleChange={(date) => formik.setFieldValue('date', date)}
            placeholder={intl.messages['metrics.selectDate']}
            label={intl.messages['metrics.whenShouldItBeReached']}
            onlyMonthYearPicker={false}
            error={buildFormError(
              formik.errors.date,
              'error',
              formik.touched.date
            )}
            dateFormat="dd MMM, yyyy"
            maxDate={null}
            minDate={new Date()}
            value={milestone?.date ?? null}
            onBlur={() => formik.setFieldTouched('date', true)}
          />
          <Separator space="1rem" />

          {!isFounder && !isSystemMetric && (
            <Toggle
              id="shared"
              name="shared"
              status={formik.values.shared}
              onChangeStatus={(status) =>
                formik.setFieldValue('shared', status)
              }
              blue
              legend={intl.formatMessage(
                { id: 'metrics.shareMilestoneWith' },
                { company: metric?.companyData?.name }
              )}
            />
          )}

          <Separator space="1.5rem" />

          <InputLabel>{intl.messages['metrics.alertMeWhen']}</InputLabel>

          <CheckboxContainer>
            <Checkbox
              id="notifyValueReached"
              name="notifyValueReached"
              label={intl.formatMessage({ id: 'metrics.hitAlert' })}
              checked={formik.values.notifyValueReached}
              onChange={formik.handleChange}
            />
            <Separator space="0.8rem" />
            <Checkbox
              id="notifyPercentageReached"
              name="notifyPercentageReached"
              label={intl.formatMessage({ id: 'metrics.percentAlert' })}
              checked={formik.values.notifyPercentageReached}
              onChange={formik.handleChange}
            />
          </CheckboxContainer>

          <Separator space="2rem" />

          {isFounder && (
            <Hint>
              <FormattedMessage id="metrics.milestonesOnlySeenByGroup" />
            </Hint>
          )}
        </Drawer.Content>

        <Drawer.Footer>
          <ButtonsContainer isEditingMilestone={isEditingMilestone}>
            {isEditingMilestone && (
              <Button type="button" danger onClick={onDeleteMilestone}>
                <FormattedMessage id="general.delete" />
              </Button>
            )}

            <ButtonsContainer>
              <Button secondary type="button" onClick={history.goBack}>
                <FormattedMessage id="general.cancel" />
              </Button>
              <Button type="submit" primary>
                <FormattedMessage id="general.save" />
              </Button>
            </ButtonsContainer>
          </ButtonsContainer>
        </Drawer.Footer>
      </Form>
    </Drawer>
  )
}

SetMetricMilestone.propTypes = {
  closeDrawer: PropTypes.func.isRequired,
  isEditingMilestone: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
}

SetMetricMilestone.defaultProps = {
  isEditingMilestone: false,
}

export default SetMetricMilestone
