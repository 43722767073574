import { useEffect, useMemo, useRef } from 'react'
import useInfiniteScroll from 'utils/hooks/useInfiniteScroll'
import useBulkImportMetricValuesModalHandlers from 'containers/Metrics/BulkImportMetricValuesModal/useBulkImportMetricValuesModalHandlers'
import { Metric } from 'utils/types/metrics'
import { SortDirectionType } from 'utils/types/common'
import { useMetricsQuery } from 'utils/hooks/queries/useMetrics'
import { MetricSortBy } from 'utils/constants/metrics'
import { DELETED_METRIC_EVENT } from 'utils/constants/events'
import { useHighlightText } from 'utils/hooks/useHighlightText'
import { useMetricsContext } from 'containers/Metrics/MetricsContext'
import { useMetricsSandbox } from 'containers/Metrics/SelectedMetrics/useMetricsSandbox'
import { useMetrics } from 'containers/Metrics/MetricsList/useMetrics'
import { useEventListener } from 'utils/hooks/useEventListener'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { isActingAsFounder } from 'selectors/auth'

export type MetricsTableColumn =
  | 'name'
  | 'portfolioHolding'
  | 'source'
  | 'currentValue'
  | 'previousValue'

const useAllMetricsTable = () => {
  const scrollRef = useRef<HTMLDivElement>(null)
  const { ref: infiniteScrollRef, onScreen } = useInfiniteScroll()
  const isFounder = useAppSelector(isActingAsFounder)

  const { metricBulkImportModal, onHideImportCsvModal, onImportCsv } =
    useBulkImportMetricValuesModalHandlers()

  const {
    isMetricInSandbox,
    toggleMetricInSandbox,
    isAddMetricToSandboxDisabled,
  } = useMetricsSandbox()

  const { debouncedSearch, sortCriteria, setSortCriteria } = useMetricsContext()

  const { metrics, isLoading, isFetchingNextPage, fetchNextMetricsPage } =
    useMetricsQuery({
      sortBy: sortCriteria?.field,
      sortDirection: sortCriteria?.direction,
      metricName: debouncedSearch,
    })

  const actions = useMetrics()

  const allMetrics: Metric[] = useMemo(
    () =>
      (metrics?.pages?.flatMap((page) => page.data) || []).filter(
        (metric) => !actions.removedMetricsIds.includes(metric.id)
      ),
    [actions.removedMetricsIds, metrics?.pages]
  )

  const visibleColumns: Record<MetricsTableColumn, boolean> = useMemo(() => {
    return {
      name: true,
      portfolioHolding: !isFounder,
      source: !isFounder,
      currentValue: true,
      previousValue: true,
    }
  }, [isFounder])

  useHighlightText(
    {
      elementClass: '.metric-name',
      text: debouncedSearch,
    },
    [debouncedSearch, metrics]
  )

  const onChangeSortBy = ({
    sortDirection,
    sortId,
  }: {
    sortDirection: SortDirectionType
    sortId: MetricSortBy
  }) => {
    setSortCriteria({ field: sortId, direction: sortDirection })
  }

  const onExportCsv = (metricData: Metric) => actions.exportMetric(metricData)

  useEffect(() => {
    if (onScreen) {
      fetchNextMetricsPage()
    }
  }, [fetchNextMetricsPage, onScreen])

  useEventListener<{ id: string; hidden: boolean }>(
    DELETED_METRIC_EVENT,
    ({ id, hidden }) => {
      if (hidden) {
        actions.setRemovedMetricsIds((currState) => [...currState, id])
      } else {
        actions.setRemovedMetricsIds((currState) =>
          currState.filter((currId) => currId !== id)
        )
      }
    }
  )

  return {
    allMetrics,
    debouncedSearch,
    isLoading,
    isFetching: isFetchingNextPage,
    onScreen,
    metricBulkImportModal,
    metricActions: {
      ...actions,
      onExportCsv,
      onImportCsv,
      onHideImportCsvModal,
    },
    sortCriteria,
    scrollRef,
    visibleColumns,
    infiniteScrollRef,
    isMetricInSandbox,
    toggleMetricInSandbox,
    isAddMetricToSandboxDisabled,
    onChangeSortBy,
    onHideImportCsvModal,
  }
}

export default useAllMetricsTable
