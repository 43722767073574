import GroupLogo from 'components/GroupLogo'
import { EmailLogo } from 'components/GroupsUsersAddInput/GroupsUsersAddInput.styles'
import styled, { css } from 'styled-components'
import { breakpoint } from 'utils/constants/breakpoint'
import { color } from 'utils/functions/colors'
import { Nullable } from 'utils/types/common'
import ContextMenuToStyle from 'ui/ContextMenu'

export const ChipContainer = styled.div<{
  hasError?: boolean
  isSmall?: boolean
  withBorder?: Nullable<boolean>
  canDelete?: boolean
  withCursorPointer?: boolean
  width?: string
  backgroundColor?: string
}>`
  align-items: center;
  background: ${(props) =>
    props.backgroundColor || color('veryLightBlue', 0.4)};
  border-radius: 1.5rem;
  cursor: default;
  color: ${color('gray')};
  display: flex;
  height: 2.5rem;
  padding: 0.2rem 0.6rem 0.2rem 0rem;
  box-sizing: border-box;
  width: ${(props) => props.width || 'auto'};
  user-select: none;

  background-color: ${(props) => props.hasError && color('red', 0.15)};

  ${(props) =>
    props.isSmall &&
    css`
      padding: 0.217rem 0.8rem 0.217rem 0.8rem;
      p {
        margin: 0;
      }
    `}

  ${(props) =>
    props.withBorder &&
    css`
      border: 0.1rem solid ${color('veryLightBlue40')};
    `}

  border: 0.2rem solid transparent;
  ${(props) =>
    props.canDelete &&
    css`
      &:hover {
        border: 0.2rem solid #c9cfe4;
      }
    `}

  ${(props) => props.withCursorPointer && `cursor: pointer;`};
`

export const IconWrapper = styled.div<{ hasError?: boolean }>`
  cursor: pointer;
  width: auto;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
  margin-left: auto;

  ${(props) =>
    props.hasError &&
    css`
      svg:first-child {
        cursor: auto;
        color: ${color('red')};
      }
    `}

  svg {
    font-size: 1.4rem;
    margin-left: 0.4rem;
  }
`

const generateMediaQuery = (breakpointKey: string, maxWidth: string) => {
  const minWidth = breakpoint[breakpointKey]
  return css`
    @media (min-width: ${minWidth}) {
      max-width: ${maxWidth};
    }
  `
}

export const Title = styled.p<{
  color?: string
  showHoverText?: boolean
  maxWidth?: string
  canDelete?: boolean
  breakpoints?: { [key: string]: string }
  isCapitalizedText?: boolean
}>`
  color: ${(props) => props.color || color('darkBlue')(props)};
  font-size: 1.4rem;
  margin: 0 0.8rem 0 0.5rem;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  ${(props) =>
    props.isCapitalizedText &&
    css`
      text-transform: capitalize;
    `}

  ${(props) =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
    `}

  ${(props) =>
    props.showHoverText
      ? css`
          visibility: hidden;
        `
      : ''}

  ${(props) =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth};
    `}

  ${(props) =>
    !props.canDelete &&
    css`
      margin: 0 0.5rem;
    `}

    ${(props) =>
    props.breakpoints &&
    Object.keys(props.breakpoints).map((breakpointKey) =>
      generateMediaQuery(breakpointKey, props.breakpoints![breakpointKey])
    )}
`

export const Image = styled.img`
  border-radius: 50%;
  height: 2.1rem;
  width: 2.1rem;
`

export const BackgroundImage = styled.div<{ src: string }>`
  border-radius: 50%;
  height: 2.6rem;
  width: 2.5rem;
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-position: center;
`

export const StyledSpan = styled.span`
  color: ${color('darkBlue')};
  font-size: 1.6rem;
`

export const StyledEmailLogo = styled(EmailLogo)`
  height: 2.6rem;
  width: 2.5rem;
`

export const StyledGroupLogo = styled(GroupLogo)`
  height: 2.6rem;
  width: 2.5rem;
`

export const PortfolioIcon = styled.div`
  height: 2.6rem;
  width: 2.5rem;
  border-radius: 100%;
  border: solid 0.1rem #e9e9e9;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ContextMenu = styled(ContextMenuToStyle)`
  max-width: 100%;
`
