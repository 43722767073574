import styled from 'styled-components/macro'
import { color } from 'utils/functions/colors'

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: ${(props) =>
    props.isEditingMilestone ? 'space-between' : 'flex-end'};

  button {
    text-transform: capitalize;
  }

  button:nth-child(1) {
    margin-right: 0.5rem;
    border: 0.1rem solid ${color('veryLightGray')};
  }

  button:nth-child(2) {
    background: ${color('primaryBlue')};
    color: ${color('white')};
  }
`

export const CheckboxContainer = styled.div`
  margin-left: 1rem;
`
