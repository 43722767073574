import { useIntl } from 'react-intl'
import MetricHoldingAvatar from 'containers/Metrics/MetricHoldingAvatar'
import Tooltip from 'components/Tooltip'
import Checkbox from 'ui/Checkbox'
import { useMetricsSandbox } from 'containers/Metrics/SelectedMetrics/useMetricsSandbox'
import { color } from 'utils/functions/colors'
import theme from 'utils/theme'
import { Metric } from 'utils/types/metrics'
import * as Styles from './MetricOption.styles'
import { useMetricOption } from './useMetricOption'

interface HoldingMetricOptionProps {
  metric: Metric
}

export const HoldingMetricOption = ({ metric }: HoldingMetricOptionProps) => {
  const intl = useIntl()
  const { onClick, metricName, checked } = useMetricOption(metric)
  const { isAddMetricToSandboxDisabled } = useMetricsSandbox()

  const reachedChartLimit = !checked && isAddMetricToSandboxDisabled()
  const companyName = metric.companyData?.name || metric.metrizableName

  const metricOptionContent = (
    <Styles.MetricOption key={metric.id}>
      <Checkbox
        id={metric.id}
        name={metric.id}
        label={
          <Styles.MetricOptionContainer>
            <MetricHoldingAvatar
              metricSource={metric.metricSource}
              portfolioType={metric.metrizableType}
              companyInfo={metric.companyData}
              size="small"
              hasPortfolioIconBorder={false}
            />
            <Styles.MetricOptionTextContainer>
              <Styles.MetricName>{metricName}</Styles.MetricName>
              <Styles.HoldingName>{companyName}</Styles.HoldingName>
            </Styles.MetricOptionTextContainer>
          </Styles.MetricOptionContainer>
        }
        onChange={onClick}
        checked={checked}
        disabled={reachedChartLimit}
      />
    </Styles.MetricOption>
  )

  const nameIsTooLong = !!companyName && companyName.length > 43
  const haveReachedChartLimit = !checked && isAddMetricToSandboxDisabled()
  const needsTooltip = nameIsTooLong || haveReachedChartLimit

  if (needsTooltip) {
    return (
      <Tooltip
        text={
          haveReachedChartLimit
            ? intl.formatMessage({
                id: 'metrics.showInSandboxDisabled',
              })
            : companyName
        }
        place="bottom"
        backgroundColor="white"
        color={color('gray')({ theme })}
        tooltipStyle={{ maxWidth: haveReachedChartLimit ? '28rem' : '35rem' }}
        parentTooltipStyles={{
          border: `1px solid ${theme.colors.veryLightGray}`,
          padding: '0.6rem 0.8rem !important',
          boxShadow: 'box-shadow: 0px 0px 15px 0px rgba(16, 21, 39, 0.10);',
          marginLeft: haveReachedChartLimit ? '4rem' : '8rem',
        }}
      >
        {metricOptionContent}
      </Tooltip>
    )
  }

  return metricOptionContent
}
