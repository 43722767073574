/* eslint-disable react/prop-types, react/destructuring-assignment, react/style-prop-object, consistent-return */
import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { FormattedMessage, useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Helmet } from 'components/Helmet'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { getIsReadOnlyMode, isActingAsClient } from 'selectors/auth'
import { getPortfolioIconByType } from 'utils/functions/renderers/renderPortfolioHelper'
import { MetricSources } from 'utils/types/metrics'
import humps from 'humps'

import OptionsDropdown from 'ui/OptionsDropdown'
import {
  getMetricChartData,
  getMetricsDataPointColumns,
} from 'utils/functions/metrics'
import AreaChart from 'components/Charts/AreaChart'
import { MetricStates, MetricStatus } from 'utils/constants/metrics'
import CWLoader from 'components/CWLoader'
import ShowPendingFounderMetric from 'containers/Metrics/ShowPendingMetric/ShowPendingFounderMetric'
import Toggle from 'components/Toggle'
import Separator from 'ui/Separator'
import IconButton from 'ui/IconButton'
import Tabs from 'components/Tabs'
import Table from 'components/ExpandableTable'
import DataPointRow from 'components/DataPointRow/DataPointRow'
import { ZeroState, ZeroStateType } from 'components/ZeroStateV2'
import Tooltip from 'components/Tooltip'
import Button from 'ui/Button'
import theme from 'utils/theme'

import Drawer from 'components/Drawer'
import usePortal from 'utils/hooks/usePortal.ts'
import GroupTooltips from 'components/GroupTooltips/GroupTooltips'
import { EventLogTypes } from 'utils/constants/eventLogs'
import ReadOnlyTooltip from 'components/ReadOnlyTooltip/ReadOnlyTooltip'
import Logs from 'components/Logs'
import { ProcessingDataLoading } from 'components/ProcessingDataLoading/ProcessingDataLoading.styles'
import useMetric from './useMetric'
import {
  MetricTitle,
  CompanyImage,
  CompanyName,
  CompanyChip,
  TabsContainer,
  Frequency,
  RowContainer,
  CTAWrapper,
  TableWrapper,
  Subtitle,
  CalculatedMetricContainer,
  CalculatedMetricText,
  LockIcon,
  PortfolioIconWrapper,
  SubheaderWrapper,
  ButtonsWrapper,
  CollapseHeader,
  Header,
  ArchivedValuesTitle,
  TitleWrapper,
  ReceiverGroupsWrapper,
  SendRequestContainer,
  FounderDataText,
  Column,
  RequestSent,
  WaitingForResponse,
  TooltipContainer,
  WaitingResponseContainer,
  LogsContainer,
  ProcessingTitle,
  SubtitleGeneratingMetrics,
  GeneratingMetricsWrapper,
  CompanyChipWrapper,
  AreaChartWrapper,
  Scroller,
  Padding,
} from './ShowMetric.styles'

import { LoaderWrapper } from '../EditMetric/EditMetric.styles'
import ShowMetricMilestones from '../ShowMetricMilestones'
import BulkImportMetricValuesModal from '../BulkImportMetricValuesModal'

const ShowMetric = ({ closeDrawer, urlPrefix = '', isOpen }) => {
  const {
    isFounder,
    metric,
    groupedMilestones,
    dataPoints,
    setDataPoints,
    isLoadingMetric,
    onSortByColumn,
    onRemoveMetric,
    onCancelDataPoint,
    onSaveDataPoint,
    currentGroupId,
    onChangeDataPointIndex,
    dataPoint,
    setDataPoint,
    editDataPointIndex,
    onDeleteDataPoint,
    isFounderDataToggled,
    onChangeFounderDataToggleValue,
    redirectToShareDataPoints,
    redirectToEdit,
    archiveDataPoints,
    unArchiveDataPoints,
    deleteDataPoints,
    editDataPoint,
    sendRequestToFounder,
    onAddNewValue,
    showBulkImport,
    setShowBulkImport,
    initialTabIndex,
    notArchivedDataPoints,
    archivedDataPoints,
    exportMetric,
    isGeneratingMetrics,
    isSystemMetric,
  } = useMetric(urlPrefix, closeDrawer)
  const isReadOnlyClient = useAppSelector(getIsReadOnlyMode)
  const isClient = useAppSelector(isActingAsClient)
  const [activeTab, setActiveTab] = useState(0)
  const { activeMilestones: milestones } = groupedMilestones
  const intl = useIntl()
  const [isArchivedValuesExpanded, setIsArchivedValuesExpanded] =
    React.useState(false)
  const isCalculatedMetric = metric?.metricSource === MetricSources.System

  const metricName = isCalculatedMetric
    ? intl.formatMessage({ id: `portfolios.${humps.camelize(metric?.name)}` })
    : metric?.name

  if (isLoadingMetric) {
    return (
      <LoaderWrapper>
        <CWLoader />
      </LoaderWrapper>
    )
  }

  if (isFounder && metric?.status === MetricStatus.PENDING) {
    return (
      <ShowPendingFounderMetric
        metric={metric}
        closeDrawer={closeDrawer}
        onRemoveMetric={onRemoveMetric}
      />
    )
  }

  const founderDataTooltipText = !isFounderDataToggled
    ? intl.formatMessage(
        { id: 'metrics.turnOnFounderData' },
        { companyName: metric?.companyData?.name }
      )
    : intl.formatMessage(
        { id: 'metrics.turnOffFounderData' },
        { companyName: metric?.companyData?.name }
      )

  const isAtLeastOneDataPointSelected = notArchivedDataPoints.some(
    (dp) => dp.isSelected
  )
  const isAtLeastOneArchivedValueSelected = archivedDataPoints.some(
    (dp) => dp.isSelected
  )

  const toggleHeader = () => {
    setIsArchivedValuesExpanded((currState) => !currState)
  }

  const displayFounderDataOptions = () => {
    if (isFounder || isCalculatedMetric) {
      return null
    }

    const sendDataComponent = (
      <SendRequestContainer>
        <FontAwesomeIcon icon={['far', 'minus-octagon']} />

        <FounderDataText>
          <FormattedMessage id="metrics.founderData" />
        </FounderDataText>

        <Button onClick={sendRequestToFounder} link>
          <FormattedMessage id="metrics.sendRequest" />
        </Button>
      </SendRequestContainer>
    )

    const requestDataComponent = (onClick) => {
      if (isReadOnlyClient) {
        return (
          <ReadOnlyTooltip
            id={`toggleFounderDataTooltip_${metric.id}`}
            text={intl.formatMessage({ id: 'readOnlyMode.toggleFounderData' })}
            place="bottom"
            maxWidth="26.3rem"
            leftOffset={-71}
          >
            <Toggle
              id="founderData"
              status={isFounderDataToggled}
              blue
              name="founder"
              onChangeStatus={onClick}
              legend={<FormattedMessage id="metrics.founderData" />}
            />
          </ReadOnlyTooltip>
        )
      }

      return (
        <Tooltip
          id="founder-data"
          text={founderDataTooltipText}
          place="bottom"
          leftOffset={-40}
        >
          <Toggle
            id="founderData"
            status={isFounderDataToggled}
            blue
            name="founder"
            onChangeStatus={onClick}
            legend={<FormattedMessage id="metrics.founderData" />}
          />
        </Tooltip>
      )
    }

    const hasRequestedMetricFromFounder = metric?.senderLinkedMetric

    if (!hasRequestedMetricFromFounder) {
      return requestDataComponent(sendRequestToFounder)
    }

    switch (metric?.senderLinkedMetric?.state) {
      case MetricStates.REQUEST_SENT:
        return (
          <WaitingResponseContainer>
            <FontAwesomeIcon icon={['far', 'check-circle']} />
            <Column>
              <RequestSent>
                <FormattedMessage id="metrics.requestSent" />
              </RequestSent>
              <WaitingForResponse>
                <FormattedMessage id="metrics.waitingForFounderResponse" />
              </WaitingForResponse>
            </Column>
          </WaitingResponseContainer>
        )
      case MetricStates.REQUEST_DENIED:
      case MetricStates.UNSHARED:
        return (
          <TooltipContainer width="300px">
            <Tooltip
              id="founder-data"
              text={intl.formatMessage(
                { id: 'metrics.founderDeniedMetric' },
                { companyName: metric?.companyData?.name }
              )}
              place="bottom"
              leftOffset={-60}
              topOffset={5}
            >
              {sendDataComponent}
            </Tooltip>
          </TooltipContainer>
        )
      default:
        return requestDataComponent(onChangeFounderDataToggleValue)
    }
  }

  return (
    <Drawer
      isOpen={isOpen}
      onCloseDrawer={closeDrawer}
      headerTitle={metricName}
    >
      <Helmet>
        <title>{`${intl.formatMessage(
          { id: 'metaTitles.metricDetail' },
          { name: metricName }
        )}`}</title>
      </Helmet>

      <Drawer.Content contentPadding="2rem 2.4rem 4rem 2.4rem">
        {!isFounder && !isCalculatedMetric && (
          <CompanyChipWrapper>
            <CompanyChip key={metric?.companyData?.id}>
              <CompanyImage src={metric?.companyData?.primaryLogo?.url} />
              <CompanyName>{metric?.companyData?.name}</CompanyName>
            </CompanyChip>
          </CompanyChipWrapper>
        )}
        <RowContainer>
          {displayFounderDataOptions()}
          {isCalculatedMetric && (
            <div>
              <CompanyChipWrapper>
                <CompanyChip key={metric?.id}>
                  <div>
                    <PortfolioIconWrapper>
                      {getPortfolioIconByType(metric?.type, '1x')}
                    </PortfolioIconWrapper>
                  </div>
                  <CompanyName>{metric?.metrizableName}</CompanyName>
                </CompanyChip>
              </CompanyChipWrapper>

              <CalculatedMetricContainer>
                <LockIcon icon={['far', 'lock-alt']} />
                <CalculatedMetricText>
                  {intl.formatMessage({ id: 'metrics.cwUniverseCalculated' })}
                </CalculatedMetricText>
              </CalculatedMetricContainer>
            </div>
          )}

          {isFounder && (
            <ReceiverGroupsWrapper>
              <GroupTooltips
                groups={metric?.receiverGroups}
                direction="left"
                small
                tooltipTopOffset={-5}
              />
            </ReceiverGroupsWrapper>
          )}

          <CTAWrapper>
            {!isCalculatedMetric && (
              <IconButton
                onClick={() => {
                  redirectToEdit(metric)
                }}
                iconFamily="far"
                icon="cog"
              />
            )}
            <OptionsDropdown buttonType="circle">
              {!isCalculatedMetric && (
                <ReadOnlyTooltip
                  key="addDataPoint"
                  id={`addDataPointDropdown_${metric?.id}`}
                  text={intl.formatMessage({
                    id: 'readOnlyMode.addMetricValues',
                  })}
                  place="left"
                  leftOffset={-10}
                >
                  <OptionsDropdown.Item
                    label={intl.formatMessage({ id: 'metrics.addValue' })}
                    icon={['far', 'plus-square']}
                    onSelectOption={() => onAddNewValue(metric)}
                  />
                </ReadOnlyTooltip>
              )}
              <ReadOnlyTooltip
                key="exportCsv"
                id={`exportCsvDropdown_${metric?.id}`}
                text={intl.formatMessage({
                  id: 'readOnlyMode.exportMetricCsv',
                })}
                place="left"
                leftOffset={-10}
                maxWidth="38.5rem"
              >
                <OptionsDropdown.Item
                  label={intl.formatMessage({ id: 'metrics.exportCsv' })}
                  icon={['far', 'file-export']}
                  onSelectOption={exportMetric}
                />
              </ReadOnlyTooltip>
              {!isCalculatedMetric && (
                <ReadOnlyTooltip
                  key="importCsv"
                  id={`importCsvDropdown_${metric?.id}`}
                  text={intl.formatMessage({
                    id: 'readOnlyMode.importMetricCsv',
                  })}
                  place="left"
                  leftOffset={-10}
                  maxWidth="38.5rem"
                >
                  <OptionsDropdown.Item
                    label={intl.formatMessage({
                      id: 'metrics.importCsv',
                    })}
                    icon={['far', 'file-import']}
                    onSelectOption={() => setShowBulkImport(true)}
                  />
                </ReadOnlyTooltip>
              )}
              {!isCalculatedMetric && (
                <OptionsDropdown.Item
                  label={intl.formatMessage({ id: 'metrics.removeMetric' })}
                  icon={['far', 'trash-alt']}
                  onSelectOption={() => onRemoveMetric(metric)}
                  color={theme.colors.red}
                />
              )}
            </OptionsDropdown>
          </CTAWrapper>
        </RowContainer>

        <Separator space="1.1rem" />

        <TabsContainer isArchivedValuesExpanded={isArchivedValuesExpanded}>
          <Tabs
            initialIndex={initialTabIndex}
            items={[
              {
                id: 'general',
                title: intl.formatMessage({ id: 'metrics.general' }),
              },
              {
                id: 'milestones',
                title: intl.formatMessage({ id: 'metrics.milestones' }),
              },
              { id: 'logs', title: intl.formatMessage({ id: 'metrics.logs' }) },
            ]}
            onClickTab={setActiveTab}
            tabOffset={24}
            withBorder
          >
            <Tabs.TabContent id="general">
              {dataPoints?.length === 0 ? (
                <ZeroState
                  callToActionText={intl.formatMessage({
                    id: 'metrics.addValue',
                  })}
                  type={ZeroStateType.METRICS_DATA}
                  callToAction={
                    isSystemMetric ? undefined : () => onAddNewValue(metric)
                  }
                  isCallToActionPrimaryStyle
                  padding="3rem"
                  disableActionIfReadOnly
                  readOnlyTooltipText={intl.formatMessage({
                    id: 'readOnlyMode.addMetricValues',
                  })}
                />
              ) : (
                <>
                  {!notArchivedDataPoints.length ? (
                    <ZeroState
                      callToActionText={intl.formatMessage({
                        id: 'metrics.addValue',
                      })}
                      type={ZeroStateType.METRICS_UNARCHIVED_DATA}
                      callToAction={
                        isSystemMetric ? undefined : () => onAddNewValue(metric)
                      }
                      isCallToActionPrimaryStyle
                      padding="3rem"
                      disableActionIfReadOnly
                      readOnlyTooltipText={intl.formatMessage({
                        id: 'readOnlyMode.addMetricValues',
                      })}
                      readOnlyTooltipPlace="bottom"
                    />
                  ) : (
                    <Scroller isFounder={isFounder} id="data-points-wrapper">
                      {isGeneratingMetrics && isCalculatedMetric ? (
                        <GeneratingMetricsWrapper>
                          <ProcessingTitle>
                            <ProcessingDataLoading />
                            {intl.formatMessage({
                              id: 'metrics.processingNewData',
                            })}
                          </ProcessingTitle>
                          <SubtitleGeneratingMetrics>
                            {intl.formatMessage({
                              id: 'metrics.thisMayTakeSeconds',
                            })}
                          </SubtitleGeneratingMetrics>
                        </GeneratingMetricsWrapper>
                      ) : (
                        <AreaChartWrapper>
                          <AreaChart
                            data={getMetricChartData(
                              notArchivedDataPoints,
                              metric?.frequency
                            )}
                            maxLegendItems={5}
                            lines={milestones?.map((milestone) => ({
                              id: milestone?.id,
                              value: milestone?.value,
                            }))}
                            stroke={theme.colors.softLightBlue}
                            fill={theme.colors.veryLightWhite}
                          />
                        </AreaChartWrapper>
                      )}

                      <SubheaderWrapper>
                        <Subtitle>
                          <FormattedMessage id="metrics.allMetricValues" />
                        </Subtitle>

                        <ButtonsWrapper>
                          {isAtLeastOneDataPointSelected && (
                            <>
                              <IconButton
                                iconFamily="far"
                                icon="share"
                                title={intl.formatMessage({
                                  id: 'metrics.shareDataPoints',
                                })}
                                onClick={() => {
                                  const selectedDataPoints = dataPoints.filter(
                                    (dp) => dp.isSelected
                                  )
                                  redirectToShareDataPoints(
                                    metric,
                                    selectedDataPoints
                                  )
                                }}
                              />
                              <IconButton
                                iconFamily="far"
                                icon="archive"
                                title={intl.formatMessage({
                                  id: 'metrics.archiveDataPoints',
                                })}
                                onClick={() => {
                                  const selectedDataPoints = dataPoints.filter(
                                    (dp) => dp.isSelected
                                  )
                                  archiveDataPoints(selectedDataPoints)
                                }}
                              />
                            </>
                          )}

                          {!isCalculatedMetric && (
                            <ReadOnlyTooltip
                              key="addDataPoint"
                              id={`addDataPointDropdown_${metric?.id}`}
                              text={intl.formatMessage({
                                id: 'readOnlyMode.addMetricValues',
                              })}
                              place="left"
                              leftOffset={-10}
                            >
                              <IconButton
                                iconFamily="far"
                                icon="plus"
                                title={intl.formatMessage({
                                  id: 'metrics.addDataPoints',
                                })}
                                onClick={() => onAddNewValue(metric)}
                              />
                            </ReadOnlyTooltip>
                          )}
                        </ButtonsWrapper>
                      </SubheaderWrapper>
                      <TableWrapper>
                        <Table
                          onChangeSortBy={onSortByColumn(false)}
                          initialSortId="date"
                          sortDirection="desc"
                          innerScroll={false}
                          scrollElementId="data-points-wrapper"
                          maxHeight={380}
                          onSelectRow={(selectedRow, isSelected) => {
                            if (selectedRow === 'selectAll') {
                              setDataPoints((currDataPoints) =>
                                currDataPoints.map((currDataPoint) => {
                                  if (!currDataPoint.archive) {
                                    return {
                                      ...currDataPoint,
                                      isSelected,
                                    }
                                  }
                                  return currDataPoint
                                })
                              )
                            } else {
                              setDataPoints((currDataPoints) => {
                                return currDataPoints.map((currDataPoint) => {
                                  if (currDataPoint.id === selectedRow.id) {
                                    return { ...currDataPoint, isSelected }
                                  }
                                  return currDataPoint
                                })
                              })
                            }
                          }}
                          selectableRows={isFounder}
                          rowHeight={40}
                          keyPath="id"
                          columns={getMetricsDataPointColumns({
                            intl,
                            archiveDataPoints,
                            currentGroupId,
                            editDataPoint,
                            isFounder,
                            isClient,
                            deleteDataPoints,
                            metric,
                            isActiveTab: activeTab === 0,
                          })}
                          data={notArchivedDataPoints || []}
                          loading={isLoadingMetric}
                          customRows={[
                            {
                              showCustomRow: editDataPointIndex !== null,
                              hideOriginalRow: true,
                              index: editDataPointIndex,
                              component: (
                                <DataPointRow
                                  dataPointDate={dataPoint?.date}
                                  setDataPointDate={(date) => {
                                    setDataPoint((prev) => ({
                                      ...prev,
                                      date,
                                    }))
                                  }}
                                  dataPointValue={dataPoint?.value}
                                  setDataPointValue={(value) => {
                                    setDataPoint((prev) => ({
                                      ...prev,
                                      value,
                                    }))
                                  }}
                                  onCancel={onCancelDataPoint}
                                  onSave={onSaveDataPoint}
                                  editDataPoint
                                />
                              ),
                            },
                          ]}
                        />
                      </TableWrapper>
                    </Scroller>
                  )}

                  {archivedDataPoints?.length > 0 && (
                    <CollapseHeader isExpanded={isArchivedValuesExpanded}>
                      <Header>
                        <TitleWrapper
                          isExpanded={isArchivedValuesExpanded}
                          onClick={toggleHeader}
                        >
                          <FontAwesomeIcon icon={['far', 'angle-down']} />

                          <ArchivedValuesTitle>
                            <FontAwesomeIcon icon={['far', 'archive']} />
                            <FormattedMessage
                              id="metrics.archivedValues"
                              values={{ count: archivedDataPoints.length }}
                            />
                          </ArchivedValuesTitle>
                        </TitleWrapper>

                        <ButtonsWrapper>
                          {isAtLeastOneArchivedValueSelected && (
                            <>
                              <IconButton
                                iconFamily="far"
                                icon="arrow-alt-up"
                                title={intl.formatMessage({
                                  id: 'metrics.unarchiveDataPoints',
                                })}
                                onClick={() => {
                                  const selectedDataPoints =
                                    archivedDataPoints.filter(
                                      (dp) => dp.isSelected
                                    )
                                  unArchiveDataPoints(selectedDataPoints)
                                }}
                              />
                              <IconButton
                                iconFamily="far"
                                icon="trash-alt"
                                color={theme.colors.red}
                                onClick={() => {
                                  const selectedDataPoints =
                                    archivedDataPoints.filter(
                                      (dp) => dp.isSelected
                                    )
                                  deleteDataPoints(selectedDataPoints)
                                }}
                              />
                            </>
                          )}
                        </ButtonsWrapper>
                      </Header>

                      <TableWrapper>
                        <Table
                          innerScroll={false}
                          onSelectRow={(selectedRow, isSelected) => {
                            if (selectedRow === 'selectAll') {
                              setDataPoints((currDataPoints) =>
                                currDataPoints.map((currDataPoint) => {
                                  if (currDataPoint.archive) {
                                    return {
                                      ...currDataPoint,
                                      isSelected,
                                    }
                                  }
                                  return currDataPoint
                                })
                              )
                            } else {
                              setDataPoints((currDataPoints) => {
                                return currDataPoints.map((currDataPoint) => {
                                  if (currDataPoint.id === selectedRow.id) {
                                    return { ...currDataPoint, isSelected }
                                  }
                                  return currDataPoint
                                })
                              })
                            }
                          }}
                          selectableRows
                          initialSortId="date"
                          sortDirection="desc"
                          rowHeight={40}
                          keyPath="id"
                          columns={getMetricsDataPointColumns({
                            intl,
                            setEditDataPointIndex: onChangeDataPointIndex,
                            currentGroupId,
                            onDeleteDataPoint,
                            unArchiveDataPoints,
                            isFounder,
                            isClient,
                            metric,
                            isActiveTab: activeTab === 0,
                          })}
                          data={archivedDataPoints || []}
                          onChangeSortBy={onSortByColumn(true)}
                          loading={isLoadingMetric}
                          customRows={[
                            {
                              showCustomRow: editDataPointIndex !== null,
                              hideOriginalRow: true,
                              index: editDataPointIndex,
                              component: (
                                <DataPointRow
                                  dataPointDate={dataPoint?.date}
                                  setDataPointDate={(date) => {
                                    setDataPoint((prev) => ({
                                      ...prev,
                                      date,
                                    }))
                                  }}
                                  dataPointValue={dataPoint?.value}
                                  setDataPointValue={(value) => {
                                    setDataPoint((prev) => ({
                                      ...prev,
                                      value,
                                    }))
                                  }}
                                  onCancel={onCancelDataPoint}
                                  onSave={onSaveDataPoint}
                                  editDataPoint
                                />
                              ),
                            },
                          ]}
                        />
                      </TableWrapper>
                    </CollapseHeader>
                  )}
                </>
              )}
            </Tabs.TabContent>

            <Tabs.TabContent id="milestones">
              <ShowMetricMilestones metric={metric} urlPrefix={urlPrefix} />
            </Tabs.TabContent>

            <Tabs.TabContent id="logs">
              <LogsContainer>
                <Logs
                  key={`${activeTab === 2}`}
                  noResultsMargin="15rem 0 0 0"
                  itemType={EventLogTypes.METRICS}
                  itemId={metric?.id}
                />
              </LogsContainer>
            </Tabs.TabContent>
          </Tabs>
        </TabsContainer>
      </Drawer.Content>

      <BulkImportMetricValuesModal
        show={showBulkImport}
        metric={metric}
        onHide={() => setShowBulkImport(false)}
      />
    </Drawer>
  )
}

export default ShowMetric
