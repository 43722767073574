export const QUERIES = {
  GROUP: 'group',
  STORAGE_USAGE: 'storageUsage',
  USER_GROUP: 'userGroup',
  TIER: 'tier',
} as const

export const groupKeys = {
  byId: (id: string) => [QUERIES.GROUP, id] as const,
  storageUsage: () => [QUERIES.GROUP, QUERIES.STORAGE_USAGE] as const,
  userGroup: (userId: string, groupId: string, includeMetrics?: boolean) =>
    [QUERIES.USER_GROUP, userId, groupId, includeMetrics] as const,
  allGroupsTier: () => [QUERIES.TIER] as const,
  tier: () => [QUERIES.TIER] as const,
}
