import sortBy from 'lodash/sortBy'
import humps from 'humps'
import styled from 'styled-components'
import {
  FormattedDate,
  FormattedMessage,
  FormattedNumber,
  type IntlShape,
} from 'react-intl'
import PulseIndicator from 'components/PulseIndicator'
import Checkbox from 'ui/Checkbox'
import { Column } from 'ui/Table/types'
import { Metric, MetricSources, Milestone } from 'utils/types/metrics'
import { MetricStates } from 'utils/constants/metrics'
import { MAX_SANDBOX_METRICS } from 'containers/Metrics/SelectedMetrics/useMetricsSandbox'
import Actions from 'containers/Metrics/AllMetrics/components/MetricActions'
import ChipList from 'containers/Files/components/ChipList'
import FloatColumnWrapper from 'ui/Table/FloatButtonColumn/FloatColumnWrapper'
import { ReactChildren } from 'utils/types/common'
import { MetricsTableColumn } from 'containers/Metrics/AllMetrics/useAllMetricsTable'
import Tooltip from 'components/Tooltip'
import theme from 'utils/theme'

import * as ProfileMetricStyles from 'components/ProfileMetrics/components/ProfileMetric/ProfileMetric.styles'
import { color } from '../colors'

const MetricName = styled.span`
  color: ${color('darkBlue')};
  font-size: 1.4rem;
  font-weight: 700;

  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

const TooltipContent = styled.div`
  align-items: center;
  color: ${color('darkGray')};
  display: flex;
  flex-direction: column;
  font-size: 1.4rem;
`

interface MetricActions {
  onRemoveMetric: (metric: Metric) => void
  onAddNewValue: (metric: Metric) => void
  onEditMetric: (metric: Metric) => void
  onSetMilestone: (metric: Metric) => void
  onEditMilestone: (metric: Metric, milestone: Milestone) => void
  onViewMetricDetail: (metricId?: string) => void
  onImportCsv: (metricData: any) => void
  onExportCsv: (metric: Metric) => Promise<void>
}

interface MetricsColumns {
  intl: IntlShape
  Cell: React.FC<{ children: ReactChildren; maxLines?: number }>
  metricActions: MetricActions
  visibleColumns: Record<MetricsTableColumn, boolean>
  isMetricInSandbox: (metric: Metric) => boolean
  toggleMetricInSandbox: (metric: Metric) => void
  isAddMetricToSandboxDisabled: () => boolean
}

export const getMetricsColumns = ({
  intl,
  Cell,
  metricActions,
  visibleColumns,
  isMetricInSandbox,
  toggleMetricInSandbox,
  isAddMetricToSandboxDisabled,
}: MetricsColumns): Column<Metric>[] =>
  [
    {
      id: 'checkbox',
      sortDisabled: true,
      flex: 0,
      minWidth: 30,
      cellRenderer: ({ rowData }) => {
        const isChecked = isMetricInSandbox(rowData)
        const isDisabled = !isChecked && isAddMetricToSandboxDisabled()

        const onToggleMetric = () => {
          if (isChecked) {
            toggleMetricInSandbox(rowData)
          } else if (!isAddMetricToSandboxDisabled()) {
            toggleMetricInSandbox(rowData)
          }
        }

        const DisabledSelectMetricCheckbox = (
          <Tooltip
            text={intl.formatMessage(
              {
                id: 'metrics.showInSandboxDisabled',
              },
              {
                count: MAX_SANDBOX_METRICS,
              }
            )}
            backgroundColor={color('white')({ theme })}
            color={color('darkGray')({ theme })}
            place="bottom"
            tooltipStyle={{ maxWidth: '28.5rem' }}
            parentTooltipStyles={{
              border: `1px solid ${theme.colors.veryLightGray}`,
              padding: '0.6rem 0.8rem !important',
              boxShadow: 'box-shadow: 0px 0px 15px 0px rgba(16, 21, 39, 0.10)',
            }}
          >
            <Checkbox
              id={rowData.id}
              name={rowData.id}
              label=""
              onChange={onToggleMetric}
              checked={isChecked}
              disabled={isDisabled}
            />
          </Tooltip>
        )

        if (isDisabled) {
          return DisabledSelectMetricCheckbox
        }

        return (
          <Checkbox
            id={rowData.id}
            name={rowData.id}
            label=""
            onChange={onToggleMetric}
            checked={isChecked}
            disabled={isDisabled}
          />
        )
      },
    },
    {
      id: 'name',
      sortKey: 'name',
      label: <FormattedMessage id="metrics.allMetrics.name" />,
      maxWidth: '26rem',
      minWidth: 260,
      flex: 1,
      hidden: !visibleColumns.name,
      cellRenderer: ({ rowData }) => {
        const isCalculatedMetric =
          rowData?.metricSource === MetricSources.System
        const metricName = isCalculatedMetric
          ? intl.formatMessage({
              id: `portfolios.${humps.camelize(rowData.name)}`,
            })
          : rowData?.name

        return (
          <Tooltip
            backgroundColor={color('white')({ theme })}
            boxShadow="0px 0px 15px 0px #1015271a"
            color={color('darkGray')({ theme })}
            id={`metric-name-${rowData.id}`}
            place="top"
            text={<TooltipContent>{metricName}</TooltipContent>}
          >
            <MetricName>{metricName}</MetricName>
          </Tooltip>
        )
      },
    },
    {
      id: 'portfolioHolding',
      sortKey: 'metrizable_name',
      label: <FormattedMessage id="metrics.allMetrics.portfolioHolding" />,
      maxWidth: '21.8rem',
      minWidth: 218,
      flex: 1,
      hidden: !visibleColumns.portfolioHolding,
      cellRenderer: ({ rowData }) => {
        return (
          <Cell>
            {rowData.companyData ? (
              <ChipList
                entities={[rowData.companyData]}
                tooltipContentType="list"
              />
            ) : (
              <ChipList
                entities={[
                  {
                    id: rowData.id,
                    name: rowData.metrizableName!,
                    type: rowData.metrizableType,
                  },
                ]}
                tooltipContentType="list"
              />
            )}
          </Cell>
        )
      },
    },
    {
      id: 'source',
      sortKey: 'metric_source',
      label: <FormattedMessage id="metrics.allMetrics.source" />,
      maxWidth: '20.8rem',
      minWidth: 208,
      flex: 1,
      hidden: !visibleColumns.source,
      cellRenderer: ({ rowData }) => {
        const isCalculatedMetric =
          rowData?.metricSource === MetricSources.System
        const showFounderDataPulse =
          rowData?.senderLinkedMetric?.receiveData &&
          (rowData?.senderLinkedMetric?.state === MetricStates.SHARE_ACCEPTED ||
            rowData?.senderLinkedMetric?.state ===
              MetricStates.REQUEST_ACCEPTED)

        if (showFounderDataPulse) {
          return (
            <Cell>
              <ProfileMetricStyles.ReceivingDataWrapper>
                <PulseIndicator color={theme.colors.primaryBlue} />
                <FormattedMessage id="metrics.receivingData" />
              </ProfileMetricStyles.ReceivingDataWrapper>
            </Cell>
          )
        }

        if (isCalculatedMetric) {
          return (
            <Cell>
              <ProfileMetricStyles.CalculatedMetricContainer>
                <ProfileMetricStyles.LockIcon icon={['fal', 'lock']} />
                <ProfileMetricStyles.Subtitle>
                  <FormattedMessage id="metrics.cwUniverseCalculated" />
                </ProfileMetricStyles.Subtitle>
              </ProfileMetricStyles.CalculatedMetricContainer>
            </Cell>
          )
        }

        return null
      },
    },
    {
      id: 'currentValue',
      sortKey: 'currentValue',
      label: <FormattedMessage id="metrics.allMetrics.currentValue" />,
      sortDisabled: true,
      maxWidth: '11.5rem',
      minWidth: 115,
      flex: 1,
      hidden: !visibleColumns.currentValue,
      cellRenderer: ({ rowData }) => {
        const sortedDataPoints = sortBy(rowData.dataPoints, (dp) => dp.date)
        const reversedDataPoints = sortedDataPoints.slice().reverse()
        const currentValue =
          reversedDataPoints.find((dp) => !dp.archive) ?? null

        return currentValue ? (
          <Cell>
            <Tooltip
              backgroundColor={color('white')({ theme })}
              boxShadow="0px 0px 15px 0px #1015271a"
              color={color('darkGray')({ theme })}
              id={`metric-current-value-date-${rowData.id}`}
              place="top"
              text={
                <TooltipContent>
                  <span>
                    <FormattedMessage id="metrics.allMetrics.latestValueDate" />
                  </span>
                  <FormattedDate
                    value={currentValue.date}
                    day="numeric"
                    month="short"
                    year="numeric"
                  />
                </TooltipContent>
              }
            >
              <FormattedNumber value={currentValue.value} />
            </Tooltip>
          </Cell>
        ) : (
          <Cell>-</Cell>
        )
      },
    },
    {
      id: 'previousValue',
      sortKey: 'previousValue',
      label: <FormattedMessage id="metrics.allMetrics.previousValue" />,
      sortDisabled: true,
      flex: 1,
      minWidth: 115,
      hidden: !visibleColumns.previousValue,
      cellRenderer: ({ rowData }) => {
        const sortedDataPoints = sortBy(rowData.dataPoints, (dp) => dp.date)
        const reversedDataPoints = sortedDataPoints.slice().reverse()
        const currentValueIndex =
          reversedDataPoints.findIndex((dp) => !dp.archive) ?? null

        const previousValue =
          reversedDataPoints
            .slice(currentValueIndex + 1)
            .find((dp) => !dp.archive) ?? null

        return previousValue ? (
          <Cell>
            <Tooltip
              backgroundColor={color('white')({ theme })}
              boxShadow="0px 0px 15px 0px #1015271a"
              color={color('darkGray')({ theme })}
              id={`metric-previous-value-date-${rowData.id}`}
              place="top"
              text={
                <TooltipContent>
                  <span>
                    <FormattedMessage id="metrics.allMetrics.previousValueDate" />
                  </span>
                  <FormattedDate
                    value={previousValue.date}
                    day="numeric"
                    month="short"
                    year="numeric"
                  />
                </TooltipContent>
              }
            >
              <FormattedNumber value={previousValue.value} />
            </Tooltip>
          </Cell>
        ) : (
          <Cell>-</Cell>
        )
      },
    },
    {
      id: 'actions-column',
      sortDisabled: true,
      flex: 0,
      cellRenderer: ({ rowData }) => {
        return (
          <FloatColumnWrapper id={rowData.id}>
            <Actions metric={rowData} {...metricActions} />
          </FloatColumnWrapper>
        )
      },
    },
  ].filter((column) => !column.hidden)
