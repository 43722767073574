import PropTypes from 'prop-types'

import classNames from 'classnames'
import Chip from 'components/Chip'
import ChipLoader from 'components/loaders/chipLoader'
import { isGroup } from 'utils/functions/groups'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { PortfolioIcon, StyledGroupLogo } from 'components/Chip/Chip.styles'
import styles from './BoxGroupsAndUsersChips.module.scss'

const BoxGroupsAndUsersChips = ({
  items,
  handleDelete,
  ownedByGroup,
  loading,
  canDelete,
}) => {
  const getItemText = ({
    type,
    email,
    firstName,
    lastName,
    name,
    fromMetric,
  }) => {
    if (isGroup(type) || fromMetric) {
      return name
    }

    if (name) {
      return name
    }

    if (!firstName || type === 'email') {
      return email
    }

    return `${firstName} ${lastName}`
  }

  const getImageComponent = ({ type, name, logo, primaryLogo, firstName }) => {
    const normalizedType = type?.toLowerCase()
    if (normalizedType === 'portfolio' || normalizedType === 'invest') {
      return (
        <PortfolioIcon>
          <FontAwesomeIcon icon={['far', 'dollar-sign']} />
        </PortfolioIcon>
      )
    }

    if (normalizedType === 'fund') {
      return (
        <PortfolioIcon>
          <FontAwesomeIcon icon={['far', 'sack-dollar']} />
        </PortfolioIcon>
      )
    }

    if (firstName || (name && normalizedType === 'user')) {
      // Is a user
      return <StyledGroupLogo forceInitials name={name} small />
    }

    if (normalizedType === 'group' || normalizedType === 'company') {
      const image = logo || primaryLogo
      return (
        <StyledGroupLogo imageUrl={image?.url ?? image} name={name} small />
      )
    }

    return null
  }

  if (loading) {
    return <ChipLoader />
  }

  return items.length > 0 ? (
    <ul className={classNames(styles.chipsList, 'fs-exclude')}>
      {items.map((item) => {
        return (
          <li key={item.id} className={styles.chipsListItem}>
            <Chip
              dataTestId={`group-user-chip-${item.id}`}
              canDelete={
                item.id !== ownedByGroup && canDelete && item.canDelete
              }
              imageComponent={getImageComponent(item)}
              handleDelete={() => {
                handleDelete(item.id)
              }}
              text={getItemText(item)}
              isUnreachableEmail={
                item?.unreachableEmailAddress ||
                item?.user?.unreachableEmailAddress
              }
            />
          </li>
        )
      })}
    </ul>
  ) : null
}

BoxGroupsAndUsersChips.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string,
      logo: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    })
  ),
  handleDelete: PropTypes.func,
  ownedByGroup: PropTypes.string,
  loading: PropTypes.bool,
  canDelete: PropTypes.bool,
}

BoxGroupsAndUsersChips.defaultProps = {
  items: [],
  handleDelete: () => {},
  ownedByGroup: '',
  loading: false,
  canDelete: true,
}

export default BoxGroupsAndUsersChips
