import styled from 'styled-components'
import { useIntl } from 'react-intl'

import { getDropdownOptionsForStatus } from 'utils/functions/metrics'
import { Metric, Milestone } from 'utils/types/metrics'
import { useProfileMetric } from 'components/ProfileMetrics/components/ProfileMetric/useProfileMetric'

import * as MetricStyles from 'components/ProfileMetrics/components/ProfileMetric/ProfileMetric.styles'

const ActionsWrapper = styled.div`
  margin-bottom: 0.4rem;
`

interface MetricActionsProps {
  metric: Metric
  onRemoveMetric: (metric: Metric) => void
  onAddNewValue: (metric: Metric) => void
  onEditMetric: (metric: Metric) => void
  onSetMilestone: (metric: Metric) => void
  onEditMilestone: (metric: Metric, milestone: Milestone) => void
  onViewMetricDetail: (metricId: string) => void
  onImportCsv: (metric: Metric) => void
  onExportCsv: (metric: Metric) => Promise<void>
  isContentMenuOpen?: boolean
  onCloseContentMenu?: () => void
  onOpenContentMenu?: () => void
  contextMenuPosition?: { x: number; y: number }
}

const MetricActions: React.FC<MetricActionsProps> = ({
  metric,
  onRemoveMetric,
  onAddNewValue,
  onEditMetric,
  onSetMilestone,
  onEditMilestone,
  onViewMetricDetail,
  onImportCsv,
  onExportCsv,
  isContentMenuOpen,
  onCloseContentMenu,
  onOpenContentMenu,
  contextMenuPosition,
}) => {
  const intl = useIntl()
  const linkTo = `all/${metric.id}`

  const { activeMilestones, redirectToLogs } = useProfileMetric(metric, linkTo)

  const onImportCsvHandler = () => onImportCsv(metric)

  return (
    <ActionsWrapper>
      <MetricStyles.OptionsDropdown
        buttonType="square"
        buttonSize="3.2rem"
        overridePosition={contextMenuPosition}
        isOpen={isContentMenuOpen}
        onCloseDropdown={onCloseContentMenu}
        onOpenDropdown={onOpenContentMenu}
      >
        {getDropdownOptionsForStatus({
          metricData: metric,
          onRemoveMetric,
          onAddNewValue,
          onEditMetric,
          activeMilestones,
          onSetMilestone,
          onEditMilestone,
          onViewMetricDetail,
          intl,
          redirectToLogs,
          onImportCsv: onImportCsvHandler,
          onExportCsv,
        })}
      </MetricStyles.OptionsDropdown>
    </ActionsWrapper>
  )
}

export default MetricActions
