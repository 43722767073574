import { useEffect, useState, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useInfiniteQuery } from '@tanstack/react-query'

import { METRICS_PAGE_SIZE } from 'utils/constants/metrics'
import { DELETED_METRIC_EVENT } from 'utils/constants/events'
import Toast from 'components/Toast'
import MetricsService from 'api/MetricsService'
import { useMetrics } from 'containers/Metrics/MetricsList/useMetrics'
import useBulkImportMetricValuesModalHandlers from 'containers/Metrics/BulkImportMetricValuesModal/useBulkImportMetricValuesModalHandlers'
import { getIsReadOnlyMode } from 'selectors/auth'
import { useEventListener } from 'utils/hooks/useEventListener'
import { getCurrentCompany } from 'selectors/company'
import { companyKeys, CompanyMetricsFilters } from 'utils/queries/companies'
import { useMetricsRoute } from 'utils/hooks/useMetricsRoute'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { useHighlightText } from 'utils/hooks/useHighlightText'
import { CompanyHoldingData } from 'utils/types/company'
import { useMetricsContext } from 'containers/Metrics/MetricsContext'
import useGroupCompany from 'utils/hooks/useGroupCompany'
import { useProfileMetricsActions } from './useProfileMetricsActions'

export const useProfileMetrics = ({
  companyInfo,
  requestFounderMetrics,
}: {
  companyInfo?: CompanyHoldingData
  requestFounderMetrics?: boolean
} = {}) => {
  const metricsRoute = useMetricsRoute()
  const {
    onAddMetric,
    onUpdateAllMetrics,
    getAddMetricReadOnlyTooltip,
    getUpdateAllMetricsReadOnlyTooltip,
  } = useProfileMetricsActions(companyInfo, requestFounderMetrics)
  const [currentPage, setCurrentPage] = useState(1)
  const [paginationEnabled, setPaginationEnabled] = useState(true)
  const isReadOnlyMode = useAppSelector(getIsReadOnlyMode)
  const currentCompany = useAppSelector(getCurrentCompany)
  const founderCompany = useGroupCompany()
  const { companyId = currentCompany.id } = useParams<{ companyId?: string }>()
  const { debouncedSearch: debouncedMetricSearch, sortCriteria } =
    useMetricsContext()

  const { metricBulkImportModal, onHideImportCsvModal, onImportCsv } =
    useBulkImportMetricValuesModalHandlers()

  const {
    onEditMetric,
    onRemoveMetric,
    onAddNewValue,
    onSetMilestone,
    onEditMilestone,
    onViewMetricDetail,
    removedMetricsIds,
    setRemovedMetricsIds,
    exportMetric,
  } = useMetrics()

  const fetchMetrics = async (page: number, filters: CompanyMetricsFilters) => {
    try {
      const metricsData = await MetricsService.getMetricsByCompanyId(
        filters.companyId,
        {
          page,
          text: filters.metricName,
          sortBy: filters.sortBy,
          sortDirection: filters.sortDirection,
          requestFounderMetrics,
        }
      )

      setPaginationEnabled(metricsData.length === METRICS_PAGE_SIZE)

      return { data: metricsData, page }
    } catch (err) {
      Toast.displayIntl('metrics.metricListError', 'error')
      return { data: [], page }
    }
  }

  const {
    data: metrics,
    isLoading,
    fetchNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery(
    companyKeys.companyMetricsWithFilters({
      companyId: requestFounderMetrics ? founderCompany?.id! : companyId,
      metricName: debouncedMetricSearch,
      sortBy: sortCriteria?.field,
      sortDirection: sortCriteria?.direction,
    }),
    ({ pageParam = 1, queryKey }) => {
      return fetchMetrics(pageParam, queryKey[2])
    },
    {
      getNextPageParam: (lastPage) => {
        if (lastPage?.data.length === 0) return lastPage.page
        return (lastPage?.page ?? 0) + 1
      },
    }
  )

  useEventListener<{ id: string; hidden: boolean }>(
    DELETED_METRIC_EVENT,
    ({ id, hidden }) => {
      if (hidden) {
        setRemovedMetricsIds((currState) => [...currState, id])
      } else {
        setRemovedMetricsIds((currState) =>
          currState.filter((currId) => currId !== id)
        )
      }
    }
  )

  useEffect(() => {
    setCurrentPage(1)
  }, [])

  const metricsVisible = useMemo(() => {
    return {
      ...metrics,
      pages: metrics?.pages?.map((page) => {
        return {
          ...page,
          data: page?.data?.filter?.(
            (metricData) => !removedMetricsIds.includes(metricData.id)
          ),
        }
      }),
    }
  }, [removedMetricsIds, metrics])

  useHighlightText(
    {
      elementClass: '.metric-name',
      text: debouncedMetricSearch,
    },
    [debouncedMetricSearch, metrics]
  )

  const onNameClick = () => {
    window.open(`/companies/${companyInfo?.id}`, '_blank')
  }

  return {
    currentPage,
    isLoading,
    removedMetricsIds,
    metrics: metricsVisible,
    onEditMetric,
    onRemoveMetric,
    onAddNewValue,
    paginationEnabled,
    fetchNextPage,
    isFetchingNextPage,
    onSetMilestone,
    onEditMilestone,
    isReadOnlyMode,
    metricBulkImportModal,
    onImportCsv,
    onHideImportCsvModal,
    onAddMetric,
    onUpdateAllMetrics,
    getAddMetricReadOnlyTooltip,
    getUpdateAllMetricsReadOnlyTooltip,
    showFounderMetricsZeroState:
      !isLoading && metricsVisible?.pages?.[0]?.data?.length === 0,
    onViewMetricDetail,
    exportMetric,
    showNoResults:
      !isLoading &&
      !metricsVisible?.pages?.[0]?.data?.length &&
      !!debouncedMetricSearch,
    metricsRoute,
    searchText: debouncedMetricSearch,
    onNameClick,
  }
}
