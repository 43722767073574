/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useIntl, FormattedMessage } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import UpdateService from 'api/UpdateService'
import { EventLogTypes } from 'utils/constants/eventLogs'
import useInfiniteScroll from 'utils/hooks/useInfiniteScroll'
import { setLogColors } from 'utils/functions/updates'
import NoResultsFound from 'components/NoResultsFound'
import InfiniteScroll from 'components/InfiniteScrollRef/InfiniteScroll'
import Timeline from 'components/Timeline'
import LogItem from 'components/Logs/LogItem'
import Toast from 'components/Toast'
import LogAvatar from './LogAvatar'

import { LogsContainer, NoResultsContainer } from './Logs.styles'

const LOG_PAGE_SIZE = 25

const Logs = ({ itemType, itemId, setLoading, noResultsMargin }) => {
  const intl = useIntl()
  const [logs, setLogs] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [isLoadingLogs, setIsLoadingLogs] = useState(true)

  const { infiniteScrollEnabled, setInfiniteScrollEnabled } =
    useInfiniteScroll()

  const getLogs = async (page = currentPage) => {
    if (itemType && itemId) {
      try {
        setIsLoadingLogs(true)
        const logsData = await UpdateService.getLogEvents(
          itemType,
          itemId,
          page,
          LOG_PAGE_SIZE
        )
        setCurrentPage(page + 1)
        if (logsData.length < LOG_PAGE_SIZE) {
          setInfiniteScrollEnabled(false)
        }
        if (page === 0) {
          setLogs(setLogColors(logsData))
        } else {
          setLogs((currentLogs) => setLogColors([...currentLogs, ...logsData]))
        }
        setIsLoadingLogs(false)
        setLoading(false)
      } catch (error) {
        Toast.display(intl.messages['updates.errorGetLogs'], 'error')
      }
    }
  }

  useEffect(() => {
    if (itemId) {
      getLogs(0)
    }
  }, [itemId])

  const areThereLogs = logs.length ? !!logs?.length : isLoadingLogs

  if (!areThereLogs && !isLoadingLogs) {
    return (
      <NoResultsContainer margin={noResultsMargin}>
        <NoResultsFound>
          <NoResultsFound.Icon>
            <FontAwesomeIcon icon={['far', 'file-alt']} />
          </NoResultsFound.Icon>
          <NoResultsFound.Title>
            <FormattedMessage id="logs.noLogsFound" />
          </NoResultsFound.Title>
          <NoResultsFound.Legend>
            <FormattedMessage id="logs.noLogsFoundLegend" />
          </NoResultsFound.Legend>
        </NoResultsFound>
      </NoResultsContainer>
    )
  }

  return (
    <LogsContainer>
      {!isLoadingLogs && (
        <div>
          {areThereLogs && (
            <Timeline renderChildrenDuration={0.1}>
              {logs.map((log) => {
                return (
                  <Timeline.Item
                    key={log.id}
                    icon={
                      <LogAvatar
                        logOperationType={log.key}
                        targetObjectType={log.targetObjectType}
                        text={log.text}
                        userName={log.user?.name}
                        color={log.color}
                      />
                    }
                    lineMargin={10}
                    updateType="log"
                    left="1.4rem"
                  >
                    <LogItem log={log} />
                  </Timeline.Item>
                )
              })}
            </Timeline>
          )}
        </div>
      )}
      <InfiniteScroll
        enabled={infiniteScrollEnabled}
        callback={({ page }) => getLogs(page)}
        pageSize={LOG_PAGE_SIZE}
      />
    </LogsContainer>
  )
}

Logs.propTypes = {
  itemType: PropTypes.oneOf(Object.values(EventLogTypes)).isRequired,
  itemId: PropTypes.string,
  setLoading: PropTypes.func,
  noResultsMargin: PropTypes.string,
}

Logs.defaultProps = {
  itemId: '',
  setLoading: () => {},
  noResultsMargin: '',
}

export default Logs
