import { Formik } from 'formik'
import Form from 'components/Form/Form'
import { FormattedMessage, useIntl } from 'react-intl'
import Drawer from 'components/Drawer'
import CWLoader from 'components/CWLoader/CWLoader'
import Button from 'ui/Button'
import { MetricStates } from 'utils/constants/metrics'
import useEditInvestorMetric, {
  EditInvestorMetricFormType,
} from './useEditInvestorMetric'
import { LoaderWrapper } from '../EditMetric.styles'
import EditInvestorMetricForm from './EditInvestorMetricForm'

interface Props {
  isOpen: boolean
  closeDrawer: () => void
}

const EditInvestorMetric = ({ closeDrawer, isOpen }: Props) => {
  const intl = useIntl()
  const {
    initialValues,
    isLoading,
    isEditingMetric,
    metric,
    validationSchema,
    editMetricMutation,
  } = useEditInvestorMetric({
    closeDrawer,
  })

  if (isLoading || !initialValues || !metric) {
    return (
      <LoaderWrapper>
        <CWLoader />
      </LoaderWrapper>
    )
  }

  const showFounderTimelineItem =
    metric.senderLinkedMetric?.state === MetricStates.REQUEST_ACCEPTED ||
    metric.senderLinkedMetric?.state === MetricStates.SHARE_ACCEPTED

  const showTimelineMessage = !!metric?.senderLinkedMetric?.message

  const showTimeline = showFounderTimelineItem || showTimelineMessage

  return (
    <Drawer
      isOpen={isOpen}
      headerTitle={`${metric?.name} ${intl.formatMessage({
        id: 'metrics.settings',
      })}`}
      onCloseDrawer={closeDrawer}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => editMetricMutation(values)}
        validationSchema={validationSchema}
        validateOnMount
      >
        {({ submitForm, isValid }) => (
          <Form<EditInvestorMetricFormType> {...initialValues!}>
            <Drawer.Content contentPadding="2rem 2.4rem">
              <EditInvestorMetricForm
                showFounderTimelineItem={showFounderTimelineItem}
                showTimelineMessage={showTimelineMessage}
                showTimeline={showTimeline}
                metric={metric}
              />
            </Drawer.Content>

            <Drawer.Footer>
              <Button
                secondary
                type="button"
                onClick={closeDrawer}
                disabled={isEditingMetric}
              >
                <FormattedMessage id="metrics.cancel" />
              </Button>

              <Button
                primary
                type="submit"
                disabled={!isValid || isEditingMetric}
                onClick={submitForm}
              >
                <FormattedMessage id="metrics.save" />
              </Button>
            </Drawer.Footer>
          </Form>
        )}
      </Formik>
    </Drawer>
  )
}

export default EditInvestorMetric
