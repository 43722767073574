import { emails } from 'utils/constants/emails'
import { UpdateTypes } from 'utils/constants/updates'
import investorManagement from './investorManagement'
import shareFundSettings from './shareFundSettings'
import fundProfile from './fundProfile'
import holdings from './holdings'
import logs from './logs'
import files from './files'
import updates from './updates'
import profiles from './profiles'
import chat from './chat'
import updatesView from './updatesView'
import inbounds from './inbounds'
import suggestedUpdates from './suggestedUpdates'
import investments from './investments'
import signup from './signUp'
import subscriptions from './subscriptions'
import spreadsheet from './spreadsheet'
import onboardingModal from './onboardingModal'
import pushNotifications from './notifications'
import createNewGroup from './createNewGroup'
import metrics from './metrics'
import breadcrumb from './breadcrumb'
import updateActions from './updateActions'

export const enUs = {
  login: {
    copyright:
      '© {year} Clockwork Universe, Inc. All rights reserved. Version {version}',
    email: 'Email',
    forgotPassword: 'Forgot your password?',
    googleSignIn: 'Sign in with Google',
    rememberMe: 'Keep me signed in for 2 weeks',
    password: 'Password',
    separatorMessage: 'Or, sign in with your email',
    signIn: 'Sign in',
    title: 'Welcome',
    twoFactorAuthentication: 'Two-Factor Authentication',
    requiredName: 'Name is a required field',
    requiredFirstName: 'First name is a required field',
    requiredLastName: 'Last name is a required field',
    invalidEmail: 'Invalid email format',
    requiredEmail: 'Email is a required field',
    requiredPassword: 'Password is a required field',
    updateAppError:
      'Before signing in, update to the latest version from the top right corner.',
    invalidLoginCredentials:
      'The user or password you have entered is incorrect. Try signing in with Google',
    userMustBelongToGroupError:
      'You must belong to a group in order to sign in',
    newToUniverse: 'New to Universe?',
    signup: 'Sign Up',
    hasNotAcceptedTermsOfUser: 'Terms of use not accepted',
    accountUnconfirmed: 'Your account is unconfirmed',
    error:
      'Failed to sign in with Google. Be sure to allow third-party cookies in order to login with your Google account',
    notLinkedAccount: 'The user is not linked with a google account',
    notLinkedAccountError: 'user not linked with google account',
    otpRequired: 'Otp attempt code required',
    launchingClockwork: 'Launching Clockwork Universe',
    clickOpenClockwork:
      'Click <b>"Open Clockwork Universe"</b> to launch the desktop app.',
    notWorking: 'Or <link>use it in your browser.</link>',
    signInError:
      'An error occurred when trying to sign in, please try again later.',
  },
  twoFactorAuthentication: {
    description: `Your account is protected with two-factor authentication. Add a code from your authenticator or a recovery code.`,
    dontAskAgain: 'Don’t ask again on this device',
    verifyCode: 'Verify Code',
  },
  forgotPassword: {
    description:
      'Enter your email and we’ll send you a link to reset your password.',
    emailPlaceholder: `e.g ${emails.USER_EMAIL_EXAMPLE}`,
    reset: 'Reset Password',
    successMessage:
      'An email has been sent to "{email}" with \npassword reset instructions',
    title: 'Forgot Password',
    userNotFound: `We didn't find a user with the email "{email}". \nMaybe you signed in with a Google account?`,
    invalidEmail: `That format doesn't look right. Make sure there aren't any typos.`,
  },
  emailConfirmation: {
    legend:
      'If you didn’t receive any email from us within 15 minutes, please check your SPAM folder. If there is still no email -',
    resendEmail: 'click here to resend the email',
    successMessage: 'A confirmation email has been sent to you',
    sendEmail: 'Send email',
    back: '< Back',
    error:
      'An error occurred when trying to send the confirmation email, try again later',
    information:
      'We have sent you an e-mail with a secure link attached. Click it to finish the account setup process.',
    yourEmail: 'Your email',
    title: 'Confirm your email',
    userAlreadyExists:
      'A user with that email already exists, please choose another email account.',
  },
  loginAndSecurity: {
    title: 'Login & Security',
    googleSignupMethod: 'GOOGLE SIGN-UP METHOD',
    email: 'Email',
    resetPassword: 'Change Password',
    login: 'Login',
    security: 'Security',
    twoFactorAuthentication: 'TWO-FACTOR AUTHENTICATION ',
    twoFactorEnableDescription: `Add an extra layer of security to your account by using a one-time
    security code. Each time you sign into your Clockwork account,
    you’ll need your password and security code.`,
    enableTwoFactor: 'Enable',
    twoFactorDisableDescription: `If disabled, you will need to set up your verification method to re-enable it.`,
    twoFactorHasBeenEnabled:
      'Two-Factor Authentification has been enabled for your account.',
    disableTwoFactor: 'Disable',
    recommended: '(Recommended)',
    password: 'Email & Password',
    connectedAccounts: 'Connected accounts',
    createdAt: 'Created at',
    lastUsed: 'Last used',
    active: 'Active',
    recoveryCodes: 'Recovery code',
    settings: 'Settings',
    activeSessions: 'Active sessions',
    signOutFromAllDevices: 'Sign out from all devices',
  },
  activeSessions: {
    description:
      'The following devices are currently logged into your Clockwork Universe account.\nRevoke access from sessions you don’t recognize.',
    signOutFromAllDevices: 'Sign out from all devices',
    revoke: 'Revoke',
    current: 'Current',
    deviceOnBrowser: 'Device on ',
    lastLogged: 'Last logged in ',
    errors: {
      fetchActiveSessions:
        'An error occurred fetching the active sessions, try again later.',
      revokeAccess:
        'An error occurred revoking the active session, try again later.',
      signOutFromAllDevices:
        'Something went wrong when trying to close the sessions. Please try again later.',
    },
    success: {
      revokeAccessSuccess: 'You have successfully revoked access.',
      signOutFromAllDevices:
        'You have successfully revoked access from all devices.',
    },
    modal: {
      title: 'Sign out from all devices',
      areYouSure: 'Are you sure you want to sign out from all devices?',
      description:
        "You will lose all work or edits made from other devices that aren't saved yet.",
      cancel: 'Cancel',
      confirm: 'Sign out of all devices',
    },
  },
  enableTwoFactorAuthenticationModal: {
    title: 'Configure Two-Factor Authentication',
    description:
      'When 2FA is configured, you will need to enter a verification code as well as your password to sign into Clockwork Universe',
    stepOne: '1. Go to your authentication app of choice',
    stepOneDescription:
      'Download an authentication app such as <bold>Authy</bold> or <bold>Google Authenticator.</bold>',
    stepTwo: '2. Scan this QRCODE',
    stepTwoDescriptionOne:
      'Open your authentication app and scan the QRcode to the left.',
    stepTwoDescriptionTwo:
      'If you can’t scan this code, enter this key into you authenticator app.',
    stepThree: '3. Enter your verification code',
    stepThreeDescription:
      'Once you scan the qrcode, enter the 6 digit code below',
    qrCodeCopied: 'QR code copied to clipboard',
    success: 'Two-Factor Authentication has been enabled for your account.',
    errorUserNotFound: 'An error has ocurred updating the user',
  },
  disableTwoFactorAuthenticationModal: {
    title: 'Disable Two-Factor Authentication',
    continue: 'Continue',
    question: 'Are you sure you want to disable this method?',
    description: `If disabled, you will need to set up your verification method to re-enable it.`,
    disable: 'Disable 2FA',
    disabling: 'Disabling 2FA...',
    success: 'Two-Factor Authentication has been disabled for your account.',
    error: 'An error occurred when trying to disable 2FA, try again later',
  },
  recoveryCodes: {
    description: `Recovery code is the only way to access your account if you lose your device and cannot receive two factor authentication codes.`,
    requestNewCodes: 'Request New Code',
  },
  recoveryCodesModal: {
    title: 'Your recovery code',
    description:
      'Keep this code somewhere secure. When 2FA is enabled, it’s the only way to sign in without your phone.',
    copyCode: 'Copy Code',
    codesCopied: 'Recovery code copied to clipboard',
  },
  requestNewCodesModal: {
    title: 'Request new codes',
    description: 'By doing this, you old codes will no longer work.',
    question: 'Are you sure you want to request new codes?',
  },
  validateViewRecoveryCodesModal: {
    title: 'View recovery code',
    description:
      'Account protected with two-factor authentication. \nAdd a code from your authenticator or a recovery code.',
  },
  validateConnectWithGoogleModal: {
    title: 'Connect with Google Account',
  },
  changePassword: {
    title: 'Reset password',
    passwordPlaceholder: 'Add password',
    newPassword: 'New password',
    confirmNewPassword: 'Confirm new password',
    confirmPasswordPlaceholder: 'Repeat password',
    resetAndSignIn: 'RESET PASSWORD & SIGN IN',
    description:
      'Enter a new password to reset the password on your account. We’ll ask for this password whenever you log in. ',
    error:
      'An error occurred when trying to change the password. Try again later.',
    youKnowYourPassword: 'You know your password? ',
    logIn: 'Log in',
    otpDescription:
      'Your account is protected with two-factor authentication. Add a code from your authenticator or a recovery code.',
  },
  noActiveGroup: {
    back: '< Back',
    title: 'No active group',
    legend:
      'Your user account is not a member of a group. Group membership is required to use Universe. Once you are added to a group you will be able to use Universe. ',
    contactSupport: 'If you feel this is an error, contact {supportEmail}',
    supportEmail: `${emails.SUPPORT}`,
  },
  accountSetup: {
    title: 'Finish account setup',
    firstName: 'First Name',
    lastName: 'Last Name',
    password: 'Password',
    confirmPassword: 'Confirm Password',
    save: 'save',
    onSetupAccountError:
      'An error occurred when trying to setup your account, try again later',
  },
  pageNotFound: {
    title: '404',
    legend: "It seems like the page you are looking for doesn't exist",
    goBackHome: 'Go back home',
  },
  companyProfile: {
    generalInformation: 'General Information',
    addTag: 'Add tag...',
    showInCompanySearchUnverifiedTooltip:
      'Your account is being verified. You can’t hide it from the Companies page search until your account is verified.',
    editCompanyProfile: 'Edit Company Profile',
    editProfile: 'Edit Profile',
    verifiedProfile1: 'Verified Profile – This indicates that Clockwork has',
    verifiedProfile2:
      ' verified the authenticity of the profile owner and content.',
    contactDetails: 'Contact details',
    imageFormat: 'Recommended dimensions are 300x300 pixels. ',
    imageFormatLegend:
      'We support JPEG, GIF, and PNG file formats. Maximum file size is 5MB.',
    title: 'Company Profile',
    description: 'Description',
    keyPeople: 'Key People',
    showMoreInfo: 'Show more info',
    showLessInfo: 'Show less info',
    cancel: 'Cancel',
    companyDescription: 'Description:',
    companyTeam: 'Company Team',
    countEmployees: '({count} Employees)',
    create: 'Add',
    edit: 'Edit ',
    editCompany: 'Edit Profile',
    editMessage: 'Edit company',
    followUnfollow: 'Follow/Unfollow company',
    addToTrack: 'Add to Track Portfolio',
    addToInvest: 'Add to Invest Portfolio',
    founded: 'Founded ',
    new: 'New ',
    noIndustries: 'No industries',
    noLocation: 'No location',
    notApply: 'N/A',
    noWebsite: 'No website',
    save: 'Save',
    teamMember: 'Team Member',
    teamSize: 'Team Size: ',
    today: 'Today',
    yesterday: 'Yesterday',
    portfolioError:
      'An error occurred when trying to add the company to your portfolio',
    getPortfolioError:
      'An error occurred when trying to get the company portfolios',
    getCompanyError:
      'An error occurred when trying to get the company data, try again later',
    noUpdatesFound: 'No updates found',
    noUpdatesFoundLegend: 'It seems that there are no updates to show',
    addCompanyToPortfolio: 'Add {company} to Track Portfolio',
    addCompanyToInvestPortfolio: 'Add {company} to Invest Portfolio',
    addTrackPortfolios: 'Add Track Portfolios',
    addInvestPortfolios: 'Add Invest Portfolios',
    onePortfolioRequired: 'At least one portfolio must be selected',
    selectPortfolio: 'Select {type} Portfolio',
    searchPortfolio: 'Search portfolio',
    addPortfolioError:
      'An error occurred when trying to update the company portfolios',
    addPortfolioSuccess: 'Company portfolios updated successfully',
    changeLogo: 'Change Logo',
    tag: 'tag',
    tags: 'Tags:',
    unknown: 'unknown',
    goToFund: 'Go to Fund Profile',
    addMember: 'Add Member',
  },
  common: {
    main: 'Main',
    apply: 'Apply',
    upgradeYourAccount: 'Upgrade your account',
    setAsMain: 'Set As Main',
    emptyList: 'This list is empty',
    companyDescription: 'Company Description:',
    companyTeam: 'Company Team:',
    founded: 'Founded',
    teamSize: 'Team Size: ',
    notApply: 'N/A',
    yes: 'Yes',
    yesDelete: 'Yes, delete',
    no: 'No',
    create: 'Create',
    creating: 'Creating...',
    cancel: 'Cancel',
    delete: 'Delete',
    save: 'Save',
    sortDirection: 'Sort direction',
    grid: 'Grid view',
    rows: 'List view',
    sortBy: 'Sort by',
    moreItems: 'and {count} more',
    and: 'and',
    noResults: 'No results found',
    more: 'More',
    learnMore: 'Learn more',
    upgrade: 'Upgrade',
    remove: 'Remove',
    from: 'From',
    undoDelete: 'Undo delete',
    requiredField: '*',
  },
  editCompany: {
    loadingMessage: 'Loading locations...',
    loadingCountries: 'Loading countries...',
    loadingStates: 'Loading states...',
    selectCountry: 'Search legal country',
    legalState: 'Search legal state',
    description: 'Description',
    writeDescription: 'Write your description here...',
    name: 'Name',
    locations: 'Location(s)',
    fundFounded: 'Fund Founded Date',
    links: 'Links',
    directories: 'Directories',
    keyPeople: 'Key People',
    socialMedia: 'Social Media',
    fundWebsite: 'Fund Website',
    fundWebsitePlaceholder: 'www.fund-site.com',
    companyWebsitePlaceholder: 'www.company-site.com',
    fundLinkedin: 'Fund LinkedIn URL',
    fundLinkedinPlaceholder: 'https://www.linkedin.com/company/',
    companyLinkedinPlaceholder: 'https://www.linkedin.com/company/',
    fundCrunchbase: 'Fund Crunchbase URL',
    fundCrunchbasePlaceholder: 'https://www.crunchbase.com/organization/',
    companyCrunchbasePlaceholder: 'https://www.crunchbase.com/organization/',
    fundAngellist: 'Fund Angellist URL',
    fundAngellistPlaceholder: 'https://angel.co/company/',
    companyAngellistPlaceholder: 'https://angel.co/company/',
    fundTargetIndustires: 'Target Industries',
    fundDescription: 'Fund Description',
    addIndustryPlaceholder: 'Add Industry',
    doingBusinessAs: 'Doing business as',
    selectCompany: 'Select a company',
    firstName: 'First Name',
    firstNamePlaceholder: 'e.g. Sarah',
    lastName: 'Last Name',
    lastNamePlaceholder: 'e.g. Smith',
    email: 'Email',
    title: 'Job Title',
    titlePlaceholder: 'e.g. CEO',
    linkedin: 'Linkedin URL',
    emailPlaceholder: 'e.g. sarah.smith@email.com',
    optional: 'Optional',
    founder: 'Business Founder',
    website: 'Website',
    crunchbase: 'Crunchbase URL',
    companyLinkedin: 'LinkedIn URL',
    companyX: 'X Profile',
    companyXPlaceholder: 'https://www.x.com/username',
    companyLinks: 'Company Links',
    angellist: 'Angellist URL',
    companyLogo: 'Company logo',
    addLocationPlaceholder: 'Add address',
    companyFounded: 'Founded Date',
    foundedPlaceholder: 'Select founded date',
    companyDecription: 'Company Description',
    companyTeam: 'Total Number of Employees',
    addIndustry: 'Add Industry',
    legalStructure: 'Legal Structure',
    legalEntityNamePlaceholder: 'Add Legal Entity Name here...',
    legalStructurePlaceholder: 'Add Legal Structure here...',
    legalCountryPlaceholder: 'Type and select country',
    legalStatePlaceholder: 'Type and select state',
    foundedDate: 'Founded date',
    companyDate: 'Company date',
    undoDelete: 'Undo Delete',
    errorDeleteEmployee:
      'There was an error deleting the employee, try again later.',
    showInCompanySearchTitle: 'Show in company search',
    showInCompanySearchLegend:
      'Unchecking hides the company profile in the in-app search. The profile is still accessible via the profile URL.',
    membersCount: 'Total number of employees',
    keyMembers: 'Company Key Members',
    addMember: 'Add',
    addNewMembers: 'ADD NEW MEMBER',
    editMembers: 'EDIT MEMBER',
    keyMemberDeleted: '{name} has been deleted from the key members list.',
    editTeamMembersCountError:
      'An error occurred when trying to edit the number of team members, try again later',
    addTeamMemberSuccess:
      '{name} has been successfully added to the key members list.',
    editTeamMemberSuccess: '{name} information has been successfully updated.',
    membersCountPlaceholder: 'Ex. 25',
    unverifiedEditUrl:
      'Your group is verified, and it is tight to this URL. In case you need to edit, please get in touch with the Clockwork Support team.',
    upgradeYourAccount: 'Upgrade your account',
    otherInformation: 'Other Information',
    team: 'Team',
    showInDiscover: 'Show in Discover page',
    showInDiscoverTooltip:
      'Toggle OFF to hide the profile from in-app search. The profile will still be accessible via its URL.',
  },
  holdingFilters: {
    allSector: 'All Sector',
    allSectors: 'All Sectors',
  },
  companyList: {
    showOpportunities: 'Show {totalHoldings} Opportunities',
    clearAll: 'Clear All',
    portfolioHoldings: 'Portfolio Holdings',
    portfolioHoldingsOnly: 'Portfolio Holdings Only',
    privateCompany: 'Private Profiles',
    privateCompanyOnly: 'Private Profiles Only',
    type: 'Type',
    company: 'Company',
    organization: 'Organization',
    fund: 'Fund',
    fundAndDeal: 'Fund / Deal',
    deal: 'Deal',
    sectorAndIndustry: 'Sector & Industry',
    location: 'Location',
    groupTags: 'Group Tags',
    filters: 'Filters',
    selectCountry: 'Select Country',
    selectStateProvince: 'Select State / Province',
    thisHoldingIsInNoPortfolio:
      'This holding is in no portfolio. {br} Click here to add it.',
    loadingMoreCompanies: 'Loading more companies...',
    addCompanyToPortfolio: 'Manage {company} on portfolios',
    pendingValidation: 'Pending Review',
    pendingValidationTooltip:
      'Pending Review – This profile is private to your group with editing permissions until Customer Success team validates information for accuracy and compliance.',
    errorFetchingPortfolioCompanies:
      'There was an error when trying to get the portfolio companies, please try again later.',
    allCompanies: 'All',
    portfolioCompanies: 'Portfolio Companies',
    addCompanyTooltip: 'Can’t find the profile you’re looking for?',
    addCompanyToCW: 'Add Company to CWUniverse.',
    clearFilters: 'Clear filters',
    filterBy: 'Filter by',
    filterByPlaceholder: 'Filter...',
    industries: 'industries',
    industry: 'industry',
    lastUpdate: 'Last Update: {date}',
    noResultsLegend: "Sorry, we couldn't find any company matching your search",
    noResultsTitle: 'No companies found',
    notAvailable: 'Not available',
    search: 'Search...',
    searchBy: 'Search by',
    sector: 'sector',
    sectors: 'sectors',
    portfolio: 'portfolio',
    portfolioType: 'portfolio type',
    nameSortAsc: 'Name (A-Z)',
    nameSortDesc: 'Name (Z-A)',
    lastUpdateSort: 'Last Update',
    portfolioTypeSortAsc: 'Portfolio Type (Invest-Track)',
    portfolioTypeSortDesc: 'Portfolio Type (Track-Invest)',
    tag: 'tag',
    countries: 'countries',
    country: 'country',
    provinces: 'provinces',
    province: 'province',
    legalStructures: 'legal structures',
    legalStructure: 'Legal Structure',
    addNewCompany: 'Add new',
    privateCompanyTooltip:
      "Private Profile – This profile is kept private for your group's exclusive access. The Customer Success team has validated that the information is accurate and considers it relevant only to this group.",
  },
  createEmail: {
    manageRecipientsLists: 'Manage recipients lists',
    resendRecipientTitle:
      'Add recipients by selecting from lists or by typing their email addresses or usernames.',
    byRecipientsList: 'By recipients list',
    back: 'Back',
    byEmail: 'By email & Users',
    addEmailsSeparated: 'Add emails and users each separated by comma...',
    sendEmailToRecipient: 'Send email to:',
    sendNow: 'Send Now',
    yourAccountIsBeingVerified: 'Your account is being verified',
    yourAccountIsBeingVerifiedLegend:
      'This generally takes 24 hours, in the meanwhile you won’t be able to send IUE,  but you can save it as draft and send it later.',
    saveAsDraft: 'Save as draft',
    email: 'Email',
    header: 'Header',
    addEmailAddress: 'Add email address',
    addMyself: 'Add myself as recipient',
    build: 'Build',
    recipients: 'Recipients',
    recipient: 'Recipient',
    send: 'Send',
    recipientsList: 'Recipients List',
    noRecipients: 'You have no email recipients added.',
    noGroups: 'You have no groups added.',
    users: 'Users: ',
    groups: 'Groups: ',
    errorCreatingRecipient:
      'There was a problem adding the new recipient, try again later.',
    errorRemovingRecipient:
      'There was a problem trying to remove the recipient, try again later.',
    errorGetRecipients:
      'There was a problem getting the email recipients, try again later.',
    dragAndDropIndicator: 'Drag & Drop the section for building your email.',
    addAttachments: 'Add attachments',
    customSection: 'Custom section',
    addCustomSection: 'Add custom section',
    textBlock: '+ Text block',
    singleImage: '+ Single image',
    deleteEntryTitle: 'Delete entry',
    deleteEntryBody: 'Are you sure you want to delete this entry?',
    deleteTextOrImageTitle: 'Delete {name}',
    deleteTextOrImageBody: 'Are you sure you want to delete this {name}?',
    deleteTitle: 'Delete title',
    deleteTitleText: 'Are you sure you want to delete the entry title?',
    editReportSectionError:
      'An error occurred when trying to save the entry, try again later',
    emailStructure: 'Email structure',
    getAttachmentsError:
      'An error occurred when trying to get the attachments, try again later',
    createNewReportSectionError:
      'An error occurred when trying to create a new email section, try again later',
    changeSectionPositionError:
      'An error occurred when trying to change the section position, try again later',
    deleteSectionError:
      'An error occurred when trying to delete the section, try again later',
    removeFileError:
      'An error occurred when trying to remove the attachment, try again later',
    getDefaultSectionsError:
      'An error occurred when trying to get the default sections, try again later',
    getReportSectionsError:
      'An error occurred when trying to get the email sections, try again later',
    onAddCustomsectionError:
      'An error occurred when trying to create a custom section, try again later',
    fromName: 'From',
    fromNamePlaceholder: 'Enter name',
    previewMode: 'Preview mode',
    subject: 'Subject',
    subjectPlaceholder: 'Enter the subject of the email',
    selectSections: 'Select Sections',
    replyTo: 'Reply-To',
    replyToPlaceholder: 'example@email.com',
    sendEmailTo: 'You will send it to',
    sendSuccessful: 'Email sent successfully',
    scheduleSuccessful: 'Email scheduled successfully',
    sendError: 'There was a problem sending the email, try again later.',
    editFieldError: 'There was a problem saving the field {name}.',
    fetchError: 'There was a problem fetching the email, try again later.',
    invalidEmails: 'Invalid emails',
    testEmailSent: 'Test email sent!',
    testEmailError:
      'There was a problem sending the test email, try again later',
    sendTestTo: 'Send a test to',
    addEmailsHere: 'Add emails here...',
    testEmailNote: 'Note: Use commas to separate multiple emails.',
    cancel: 'Cancel',
    sendTest: 'Send Test',
    testEmail: 'Test email',
    sendATest: 'Send a Test',
    previous: 'Previous',
    next: 'Next',
    preview: 'Preview draft',
    sendEmail: 'Send email',
    sendToMe: 'Send to me',
    onRemovingCustomSectionError:
      'An error occurred when trying to remove a custom section, try again later',
    undoAction: 'Undo Action',
    addNew: 'Add New',
    discardDraftSection: 'Discard',
    saveDraftSection: 'Save',
    onDiscardError: 'An error occurred when trying to discard the new section',
    onSaveDraftError: 'An error occurred when trying to save the new section',
    sectionNameExists: 'Section name already exists, please choose another one',
    sectionCantBeEmpty: 'Section name can not be empty',
    removeSection: 'Remove entry',
    addRecipients: 'Add Recipients',
    emailRecipients: 'Email Recipients ({count})',
    emailRecipientsEmpty: 'Email Recipients',
    sharedInClockwork: 'Shared in Clockwork Universe',
    selectLists: 'Select lists',
    inAppGroups: 'In-App Groups',
    inAppGroupsText:
      'Groups will see the email as they would see any Update stored in Universe. They will not receive the formatted email.',
    inAppGroupsText2:
      'To ensure an individual receives the formatted email, also add them as an Email Recipient above.',
    requiredEmail: 'You need to add at least one email address',
    unreachableEmailAddress: 'Unreachable email address',
    maxEmailsError:
      'Test emails can be sent to {maxRecipients} recipients at most',
    finish: 'Finish',
    schedule: 'Schedule',
    scheduleQuestion: 'Send Date',
    editTitle: 'Edit title',
    classicBuilder: 'Classic Builder',
    visualBuilder: 'Visual Builder',
    showTitle: 'Show title',
    loadingRecipients: 'Loading recipients...',

    errors: {
      mbExceeded:
        'You cannot upload all these files. Your attachments exceed 10 MB limit you have for emails.',
    },
  },
  emailList: {
    composeNewEmail: 'Compose new email',
    draft: 'draft',
    schedule: 'Schedule',
    getEmailsError:
      'An error occurred when trying to get the emails, try again later',
    sent: 'Sent emails',
    noResultsTitle: 'No emails found',
    deleteDraftTooltip: 'Delete draft',
    deleteDraftConfirmationTitle: 'Delete',
    deleteDraftConfirmationBody: 'Are you sure you want to delete this draft?',
    deleteMultipleDraftsConfirmationBody:
      'Are you sure you want to delete these drafts?',
    deleteDraftError:
      'An error occurred when trying to delete the IUE draft, try again later',
    deleteDraft: 'Delete draft',
    editDraft: 'Edit Draft',
    deleteDrafts: 'Delete drafts ({count})',
    selectAllDrafts: 'Select all',
    deselectAllDrafts: 'Deselect all',
    errorDeletingDrafts:
      'An error occurred when trying to delete the drafts, try again later',
    draftsDeleted: '{count} draft{plural} has been deleted',
  },
  showEmail: {
    duplicateEmailError:
      'An error occurred when trying to duplicate this email, try again later',
    makeADraft: 'Make a draft',
    makeACopy: 'Make a copy',
    sendToAdditionalrecipients: 'Send to Additional Recipients',
    resendEmailToRecipients: 'Send: Email Update to Additional Recipients?',
    resendEmailSuccess: 'Email sent successfully',
    resendEmailError:
      'An error occurred when sending the email, try again later.',
    totalDelivered: 'Total Delivered',
    openRate: 'Open Rate',
    recipients: 'Recipients',
    dateTime: 'Date & Time',
    state: 'State',
    noRecipients: 'It seems that there are no recipients to show',
    errorRecipients:
      'An error occurred when getting the recipients, try again later',
    recipientStatus: {
      queued:
        'Your message cannot immediately be sent, but it hasn’t been completely rejected.',
      sent: 'The server has accepted the message and attempted to deliver it to the recipient.',
      delivered: 'The message was delivered to the recipient.',
      opened: 'The recipient received the message and opened it.',
      clicked: 'The recipient opened and clicked on the message.',
      spam: 'The recipient marked your email as Spam with their email service provider.',
    },
    emailStatusError: 'Your message cannot or will not be delivered.',
  },
  general: {
    ok: 'OK',
    accept: 'Accept',
    continue: 'Continue',
    saveAndGoNext: 'Save & Go Next >',
    deal: 'Deal',
    byUser: 'By user or emails',
    add: 'Add',
    custom: 'Custom',
    searchEllipsis: 'Search...',
    noMatchesFound: 'No matches found.',
    byList: 'Select list',
    optional: '(optional)',
    edit: 'Edit',
    expand: 'Expand',
    createNewUpdate: 'Create update',
    requiredField: 'Required',
    youHaveExceededCharacterLimit:
      'You have exceeded the {limit} characters limit. ',
    notValidUrl: 'URL is not valid',
    invalidEmail: 'Invalid email format',
    invalidUrl:
      'Invalid url format. A proper URL format should start with either http:// or https://. For example: https://www.aviato.com',
    invalidCrunchbaseUrl: 'Invalid Crunchbase URL format',
    invalidAngellistUrl: 'Invalid AngelList URL format',
    invalidLinkedinUrl: 'Invalid LinkedIn URL format',
    invalidTwitterUrl: 'Invalid X URL format',
    invalidHandleUrl: 'Invalid handle format',
    invalidPassword:
      'Your password is not secure enough; it might be very easy to guess. Passwords must be a minimum of six characters and must contain at least one lower case, one upper case, and one symbol.',
    verification2FACodeError:
      'Please make sure you have entered a valid verification code and try again.',
    passwordDontMatch: 'The passwords entered don’t match. Please try again.',
    min2Chars: 'Value is too short (minimum is 2 characters)',
    company: 'Company',
    fund: 'Fund',
    fundCompany: 'Fund Company',
    organization: 'Organization',
    industry: 'Industry',
    employee: 'Employee',
    legalStructure: 'Legal Structure',
    mainLocation: 'Main Location',
    location: 'Location',
    cities: 'Cities',
    companyNames: 'Company Names',
    dropFiles: 'Drop your files here or ',
    browseFiles: 'Browse Files',
    addFiles: 'Add Files',
    browseMyComputer: 'Browse my computer',
    browse: 'Browse',
    maxFile: 'Max file {size}MB',
    pendingConfirmation: 'Pending confirmation',
    back: '< Back',
    goBack: 'Go Back',
    backText: 'Back',
    supportEmail: `${emails.SUPPORT}`,
    accountNotVerified:
      'Your account is still being setup. Please, try again later.',
    invalidToken: 'The token is lo longer valid, please request a new one.',
    tosNotAccepted: 'Terms of use not accepted',
    cancel: 'Cancel',
    prev: 'Prev',
    next: 'Next',
    update: 'update',
    createAnnouncement: 'Create Announcement',
    createNote: 'Create Note',
    createEmail: 'Create Email',
    createDocument: 'Create Document',
    createTransaction: 'Create Transaction',
    createAccounting: 'Create Accounting',
    minDate: 'Date must be greater than the current date',
    maxDate: 'Date must be lower than the current date',
    errorFindingCompany:
      'An error occurred when trying to get the company, try again later',
    doesNotRepeat: 'Does not repeat',
    daily: 'Daily',
    weeklyOnDay: 'Weekly on {dayOfWeek}',
    weeklyOnDate: '{dateDo} of each month',
    monthlyFirstDay: 'Monthly on the first {dayOfWeek}',
    monthlyLastDay: 'Monthly on the last {dayOfWeek}',
    anuallyOnDate: 'Annual on {date}',
    repetition: 'Repetition',
    select: 'Select',
    selectRepetition: 'Select repetition',
    accountingReport: 'Accounting Report',
    selectAccountingReport: 'Select accounting report',
    previewReport: 'Preview report demo',
    balanceDate: 'Balance Date',
    selectBalanceDate: 'Select balance date',
    comparePeriods: 'Compare Periods',
    selectComparePeriods: 'Select periods to compare',
    scheduledDate: 'Scheduled Date',
    today: 'Today',
    yesterday: 'Yesterday',
    endOfLastMonth: 'End of Last Month',
    endOfLastQuarter: 'End of Last Quarter',
    endOfLastFinancialYear: 'End of Last Financial Year',
    none: 'None',
    previous1Period: 'Previous 1 period',
    previous2Period: 'Previous 2 periods',
    previous3Period: 'Previous 3 periods',
    reportSettings: 'Report Settings',
    currentMonth: 'Current month',
    scheduledMonth: 'Scheduled month',
    currentQuarter: 'Current quarter',
    currentFinancialYear: 'Current financial year',
    previousMonth: 'Previous month',
    previousQuarter: 'Previous quarter',
    previousFinancialYear: 'Previous financial year',
    selectPeriod: 'Select period',
    paymentsOnly: 'Cash basis',
    standardLayout: 'Use Xero Standard Layout (Non customized)',
    standardLayoutDesc: 'Xero Report 2.0 is not supported.',
    moreInfo: 'More info',
    searchByName: 'Search by name',
    selectCompany: 'Select company',
    selectHolding: 'Select holding...',
    addOption: 'Add "{value}"',
    numberField: 'This field should be of type number',
    search: 'Search...',
    recentlyVisited: 'Recently visited',
    save: 'Save',
    share: 'Share',
    undo: 'Undo',
    undoRemove: 'Undo remove',
    delete: 'Delete',
    loadingUpdates: 'Loading updates...',
    loadingMoreUpdates: 'Loading more updates...',
    noUpdates: 'No updates.',
    loadingEmails: 'Loading emails...',
    loadingMetrics: 'Loading metrics...',
    founder: 'Founder',
    clockworkSupportTeam: 'Clockwork Support Team',
    goToClockwork: 'Go to clockwork.app',
    loading: 'Loading...',
    groupOptions: 'Group options',
    required: 'Required',
    selectedGroupsUsers: 'Selected groups/users',
    allPortfolios: 'All portfolios',
    allFunds: 'All funds',
    updates: 'Updates',
    emails: 'Emails',
    discard: 'Discard',
    name: 'Name',
    permissions: 'Permissions',
    verify: 'Verify',
    verifying: 'Verifying...',
    done: 'Done',
    newCodes: 'New Codes',
    viewCodes: 'View Codes',
    addCode: 'Add code',
    download: 'Download',
    page: 'Page',
    of: 'of',
    preview: 'Preview',
    files: 'Files',
    you: 'you',
    week: 'week',
    hours: 'hours',
    always: 'Always',
    title: 'Title',
    scheduledUpdates: 'Scheduled Updates',
    settings: 'Settings',
    readMore: 'Read more',
    showLess: 'Show less',
    create: 'Create',
    selected: 'Selected',
    reloadPage: 'Reload Page',
    months: {
      short: {
        1: 'Jan',
        2: 'Feb',
        3: 'Mar',
        4: 'Apr',
        5: 'May',
        6: 'Jun',
        7: 'Jul',
        8: 'Aug',
        9: 'Sep',
        10: 'Oct',
        11: 'Nov',
        12: 'Dec',
      },
    },
    here: 'here',
    updatesTypes: {
      note: 'Note',
      announcement: 'Announcement',
      document: 'Document',
      transaction: 'Transaction',
      accounting: 'Accounting',
      email: 'Email',
    },
    skipStep: 'Skip this step',
    finish: 'Finish',
    clockworkUniverse: 'Clockwork Universe',
    beta: 'Beta',
    close: 'Close',
  },
  errors: {
    connectionError: 'Connection Error',
    addItemError:
      'There was an error adding the {name} {item}, try again later.',
    editItemError:
      'There was an error editing the {name} {item}, try again later.',
    removeItemError:
      'There was an error removing the {name} {item}, try again later.',
    changeItemError:
      'There was an error when changing the {item}, try again later.',
    creatingItemError:
      'There was an error when creating the {item}, try again later.',
    updatingItemError:
      'There was an error when updating the {item}, try again later.',
    fetchError: 'There was a error fetching the {item}, try again later.',
    noAccessUpdate: 'You have no permission to create {name} for this company',
    fileUpload:
      'An error occurred when uploading the file, it may be because it exceeded 50MB',
    fileIsRequired:
      'You need to add a file to the document update in order to create it',
    findingGroupError:
      'There was an error finding that group, try again later.',
    addingUserToList:
      'There was an error when adding the user to the list, try again later.',
    createUserToAddInList:
      'The user you are trying to add does not exist, and there was an error creating it, try again later',
    getCompany: 'There was an error getting the company, try again later',
    noAccessUpdateEdit: 'You have no permission to edit the {name}',
    noAccessUpdateShow: 'You have no permission to see the {name}',
    groupNoAccessEdit:
      'You nor {groupName} does not have permissions to edit this {name}',
    groupNoAccessShow:
      'You nor {groupName} does not have permissions to see this {name}',
    errorGettingCompanies:
      'There was an error fetching the companies, try again later',
    bulkImportTransactionsError:
      'An error occurred when importing the transactions, try again later. ',
    bulkImportInvestorsTransactionsError:
      'An error occurred when importing the investors transactions, try again later. ',
    fetchTagsError: 'An error occurred when fetching the tags, try again later',
    locationAlreadyAdded: '"{location}" is already included',
  },
  success: {
    creatingItem: 'The {name} was successfully created',
    updatingItem: 'The {name} was successfully updated',
    schedulingItem: 'The {name} was successfully scheduled',
    bulkImportTransactions: 'All transactions were successfully created',
    bulkImportInvestors: 'All investors were successfully created',
    bulkImportInvestmentVehicles:
      'All investment vehicles were successfully created',
  },
  buttons: {
    cancel: 'Cancel',
    publish: 'Publish',
    schedule: 'Schedule',
    save: 'Save',
    ok: 'OK',
    confirm: 'Confirm',
    delete: 'Delete',
    add: 'Add',
    close: 'Close',
    discard: 'Discard',
    discardDraft: 'Discard draft',
  },
  updates: {
    holding: 'Holding',
    attachedFiles: 'Attached Files ({countFiles})',
    downloadAll: 'Download All ({filesSize})',
    downloadAllNotGeneratedYet: 'File not generated yet. Try again later',
    additionalInformation: 'Additional Information',
    editReshare: 'Edit reshare',
    moreReshares: '+ {count} more',
    reshareComments: '{count, plural, one {1 comment} other { # comments}}',
    resharedBy: 'Reshared by',
    resharedWith: 'Reshared with {names}',
    confidentialWarning:
      'CONFIDENTIAL UPDATE - This update is marked as confidential by the group that published it. This means it contains sensitive information, so you must be careful with whom you share it.',
    reshareWith: 'Reshare with',
    and: 'and',
    reshareBy: 'Reshare by',
    chooseLists:
      'Choose from specific lists, groups and users to view this reshare',
    reshare: 'Reshare',
    reshareMessage: 'Reshare message',
    reshareSettings: 'Reshare settings',
    confidential: 'Confidential',
    confidentialUpdate: 'Confidential update',
    blockedUpdate: 'Blocked update',
    reshareConfidentialUpdate: 'Reshare Confidential Update',
    reshareConfidentialUpdateConfirmation:
      'Are you sure you want to reshare this update?',
    reshareConfidentialUpdateDesc:
      'This update is marked as confidential by the group that published it. This means it contains sensitive information, so you have to be very careful with whom you share it.',
    confirmReshare: 'Yes, reshare it',
    blockedUpdateDesc:
      'By indicating that an update is blocked, you are preventing someone to reshare it.',
    reshareUpdate: 'Reshare',
    analytics: 'Analytics',
    recentlyUsedGroupsOrUsers: 'Recently used groups or users',
    recentlyUsedUsers: 'Recently used users',
    readOnlyPublic:
      'Your account is being verified. You can’t publish public updates until your account is verified.',
    readOnlyCustom:
      'Your account is being verified. You can’t select specific groups until your account is verified.',
    readOnlyMetrics:
      'Your account is being verified. You can’t share metrics until your account is verified.',
    updatesFeed: 'Updates Feed',
    transactionsFeed: 'Transactions Feed',
    updateFeed: 'Update Feed',
    undefinedUpdate:
      'The update you are looking for does not exist, try again later',
    addGroupsOrUser: 'By group / user',
    addMedia: 'Add Media',
    addUser: 'Add Users',
    aditionalMessage: 'Additional Message',
    userAlreadyAdded: 'User {name} was already added to the list',
    announcementCreateTitle: 'Create new announcement',
    announcementEditTitle: 'Edit announcement',
    accountingCreateTitle: 'Create new accounting',
    accountingEditTitle: 'Edit accounting',
    announcementTitlePlaceholder: `What's the announcement about?`,
    accountingTitlePlaceholder: `What's the accounting about?`,
    body: 'Body',
    bodyPlaceholder: 'Write your rationale here...',
    custom_groupDescription: 'Members of your group will see this update',
    customDescription:
      'Choose from specific lists, groups and users to view this updates.',
    customText: 'Custom',
    date: 'Date',
    discard: 'Discard Changes',
    documentCreateTitle: 'Create new document',
    documentEditTitle: 'Edit document',
    documentTitlePlaceholder: `What's the document about?`,
    editMessage: 'Edit update',
    fetchErrors: {
      note: 'An error occurred when getting the note, try again later',
      document: 'An error occurred when getting the document, try again later.',
      announcement:
        'An error occurred when getting the announcement, try again later',
      accounting:
        'An error occurred when getting the accounting, try again later',
      transaction:
        'An error occurred when getting the transaction, try again later',
      report: 'An error occurred when trying to get the email, try again later',
    },
    errorGetVisibility:
      'An error occurred when getting the update visibility, try again later.',
    errorIUECreate: 'There was an error creating a new IUE, try again later.',
    everyoneDescription: 'Everyone can view this update.',
    everyoneText: 'Public',
    founderGroups: 'My Founder Groups',
    groupAlreadyAdded: 'Group was already added to the list',
    groupManage: 'Allow anyone within my group to edit this update',
    invalidGroupUserFormat: 'Invalid user/group format',
    invalidUserFormat: 'Invalid email format',
    invalidBulkUserFormat: 'Invalid email format: {emails}',
    lookForUsers: 'Look for users',
    lookGroupsOrUser: 'Add groups or emails each separated by comma',
    lookForGroupsOrUsers: 'Look for groups or users',
    myClientGroups: 'My Investor groups',
    noteCreateTitle: 'Create new note',
    noteEditTitle: 'Edit note',
    noteTitlePlaceholder: `What's the note about?`,
    neitherGroupOrUserFound: 'Neither a group or user was found',
    only_meDescription: 'Only you are able to view this update.',
    only_meText: 'Only Me',
    permissions: 'Permissions',
    save: 'Save',
    specificGroupsAndUsers: 'Specific groups & users',
    specifilGroupsAndUsers: 'Specific groups & users',
    title: 'Title',
    viewGroupsAndUsers: 'The following groups & users can view this update',
    visibility: 'Update visibility',
    sentRecipients: 'IUE recipients',
    attachmentsNumber: 'Attachments',
    delete: 'Delete',
    removeNoteTitle: 'Delete note',
    removeNoteBody: 'Are you sure you want to delete the note?',
    noteDeleteSuccess: 'Note deleted successfully',
    noteDeleteError: 'An error occurred deleting the note, try again later',
    removeDocumentTitle: 'Delete document',
    removeDocumentBody: 'Are you sure you want to delete the document?',
    documentDeleteSuccess: 'Document deleted successfully',
    documentDeleteError:
      'An error occurred deleting the document, try again later',
    removeAnnouncementTitle: 'Delete announcement',
    removeAnnouncementBody: 'Are you sure you want to delete the announcement?',
    removeAccountingTitle: 'Delete accounting',
    removeAccountingBody: 'Are you sure you want to delete the accounting?',
    announcementDeleteSuccess: 'Announcement deleted successfully',
    accountingDeleteSuccess: 'Accounting report deleted successfully',
    announcementDeleteError:
      'An error occurred deleting the announcement, try again later',
    publishNow: 'Publish Now',
    selectSpecificDate: 'Select Date',
    selectDate: 'Select date',
    dateTime: 'Date & Time',
    selectDateTime: 'Select date and time',
    errorGetVisbility:
      'An error occurred when trying to get the update permissions, try again later',
    balanceSheetPreview: 'Balance Sheet - Demo Preview',
    profitAndLossPreview: 'Profit & Loss - Demo Preview',
    general: 'General',
    rawData: 'Raw data',
    recipients: 'Recipients',
    from: 'From',
    to: 'To',
    draft: 'Draft',
    scheduled: 'Scheduled for: {date}',
    compareWith: 'Compare With',
    comparePeriods: 'Compare Periods',
    cashBasis: 'Cash Basis',
    selectPeriod: 'Select period',
    filesMore: '{count, plural, one {1 file more} other { # files more}}',
    description: 'Description',
    descriptionPlaceholder: 'Write your rationale here...',
    company: 'Company',
    selectHolding: 'Select Holding',
    companyPlaceholder: 'e.g. Aviato',
    addPortfolio: 'Add portfolio or fund...',
    selectPortfolioFundDeal: 'Select portfolio, fund or deal',
    dealNotAssociatedWithHolding:
      "It looks like {dealsNames} isn't associated to {holdingName}. Please double-check if this is the right deal or verify the selected holding",
    fundPortfolio: 'Fund',
    selectFund: 'Select fund or deal',
    includeInPortfolio: 'Include in Portfolio/Fund',
    includeIn: 'Include in',
    transactionDeleteSuccess: 'Transaction deleted successfully',
    transactionDeleteError:
      'An error occurred when trying to delete the transaction, try again later',
    transactionDeleteErrorUnfundedCommitment:
      'You cannot delete this commitment. The total amount invested cannot exceed the total amount committed.',
    transactionEditErrorUnfundedCommitment:
      'Please check your investment amounts first if you wish to lower the amount committed. The total amount invested cannot exceed the total amount committed.',
    transactionEditErrorUnfundedCommitmentTransactionType:
      'Please check your investment amounts if you wish to change the transaction type. This action lowers the amount committed, and the total amount invested cannot exceed the total amount committed.',
    removeTransactionTitle: 'Delete Transaction',
    removeTransactionBody: 'Are you sure you want to delete the transaction?',
    publishDate: 'Publish date',

    reshareTitles: {
      note: 'Reshare Note',
      document: 'Reshare Document',
      announcement: 'Reshare Announcement',
      accounting: 'Reshare Accounting',
      transaction: 'Reshare Transaction',
      report: 'Reshare Email',
    },
    editTitles: {
      note: 'Edit Note',
      document: 'Edit Document',
      announcement: 'Edit Announcement',
      accounting: 'Edit Accounting',
      transaction: 'Edit Transaction',
      report: 'Edit Email',
    },
    deleteMessageSuccess: {
      note: 'Note deleted successfully',
      document: 'Document deleted successfully',
      announcement: 'Announcement deleted successfully',
      accounting: 'Accounting deleted successfully',
      transaction: 'Transaction deleted successfully',
      email: 'Email deleted successfully',
    },
    deleteMessageError: {
      note: 'An error occurred deleting the note, try again later',
      document: 'An error occurred deleting the document, try again later',
      announcement:
        'An error occurred deleting the announcement, try again later',
      accounting: 'An error occurred deleting the accounting, try again later',
      transaction:
        'An error occurred deleting the transaction, try again later',
      email: 'An error occurred deleting the email, try again later',
    },
    createTitles: {
      note: 'Add Note',
      document: 'Add Document',
      announcement: 'Create Announcement',
      accounting: 'Add Accounting Report',
      transaction: 'Add Transaction',
      email: 'Create Email',
      file: 'File Upload',
    },
    deleteTitles: {
      note: 'Delete note',
      document: 'Delete document',
      announcement: 'Delete announcement',
      accounting: 'Delete accounting',
      transaction: 'Delete transaction',
      email: 'Delete email',
      report: 'Delete report',
    },
    deleteMessages: {
      note: 'Are you sure you want to delete this note?',
      document: 'Are you sure you want to delete the document?',
      announcement: 'Are you sure you want to delete the announcement?',
      accounting: 'Are you sure you want to delete the accounting?',
      transaction: 'Are you sure you want to delete the transaction?',
      email: 'Are you sure you want to delete the email?',
      report: 'Are you sure you want to delete the report?',
      deleteSecondaryText: "Be aware this action can't be undone.",
    },
    deleteConfirmationModal: {
      secondaryText: 'Be aware this action can’t be undone.',
    },
    createUpdateToastMessage:
      'Please note: once you publish the update, you have <bold>48 hours to edit the content and its permissions.</bold>',
    editUpdateHoursContentToastMessage:
      'You have <bold>{countHours}</bold> hours to edit the update content. On the permissions, you can add and remove additional users or groups, or set it as public or visible for only you.',
    editUpdateHoursToastMessageGroupManage:
      'You have <bold>{countHours}</bold> hours to edit the update content. On the permissions, you can only edit to allow anyone within your group to edit this update. ',
    editUpdateHoursToastMessage:
      'You have <bold>{countHours}</bold> hours to edit the update content and its permissions.',
    editUpdateToastMessageExpired:
      'Your time to edit the content of the update has expired. You can only change the permissions to add additional users or groups or set it as a public update. ',
    editUpdateToastMessageGroupManage:
      'Your time to edit the content of the update has expired. You can only edit to allow anyone within your group to edit this update.',
    editExpiredTooltip:
      'Your time to edit the content or permissions of the update has expired.',
    ...updates,
  },
  transactions: {
    transactionType: 'Transaction Type',
    transactionTypePlaceholder: 'e.g. Investment',
    instrument: 'Instrument',
    investmentVehicle: 'Investment Vehicle',
    investor: 'Investor',
    instrumentPlaceholder: 'e.g. Equity',
    additionalInformation: 'Additional Information',
    commitmentDate: 'Commitment Date',
    amountCommited: 'Amount Committed',
    maxAmountError: 'The amount must be less than $2,000,000,000',
    investorHasNoInvestmentVehicle:
      'This investor has no investment vehicle. Please add one to continue.',
    investmentDate: 'Investment Date',
    amountInvested: 'Amount Invested',
    distributionDate: 'Distribution Date',
    amountDistributed: 'Amount Distributed',
    interestRate: 'Interest Rate',
    transactionDetails: 'Transaction Details',
    transactionBodyPlaceholder: 'Add transaction description',
    commitment: 'Commitment',
    distribution: 'Distribution',
    investment: 'Investment',
    includedInPortfolios: 'Included in Portfolios',
    includedIn: 'Included in',

    transactionTitlePlaceholder: 'Give this transaction a name',
    transactionDate: 'Transaction Date',
    transactionDatePlaceholder: 'Select date',

    transactionAmountInvested: 'Amount Invested',
    transactionAmountInvestedPlaceholder: 'Add Amount Invested',
    transactionAmountCommited: 'Amount Committed',
    transactionAmountCommitedPlaceholder: 'Add Amount Committed',
    transactionAmountDistributed: 'Amount Distributed',
    transactionAmountDistributedPlaceholder: 'Add Amount Distributed',

    valuationCap: 'Valuation Cap',
    valuationCapPlaceholder: 'Add Valuation Cap',
    discountRate: 'Discount Rate',
    discountRatePlaceholder: 'Add Discount Rate',
    interestRatePlaceholder: 'Add Interest Rate',
    interestCalculationBasis: 'Interest Calculation Basis',
    interestCalculationBasisPlaceholder: 'Add Interest Calculation Basis',
    maturityDate: 'Maturity Date',
    maturityDatePlaceholder: 'Add Maturity Date',
    purchasePricePerShare: 'Purchase Price per Share',
    purchasePricePerSharePlaceholder: 'Add Purchase Price per Share',
    sharesPurchased: 'Shares Purchased',
    sharesPurchasedPlaceholder: 'Add Shares Purchased',
    preMoneyValuation: 'Pre-Money Valuation',
    preMoneyValuationPlaceholder: 'Add Pre-Money Valuation',
    postMoneyValuation: 'Post-Money Valuation',
    postMoneyValuationPlaceholder: 'Add Post-Money Valuation',
    fundInvestmentPlaceholder: 'Add Fund Investment',
    drawdownAgainstCommitment: 'Drawdown Against Commitment?',
    drawdownAgainstCommitmentPlaceholder: 'Add Drawdown Against Commitment',
    investedExceedsCommitmentError:
      'This investment exceeds your outstanding commitments. Double check your input value or select “No” on Drawdown Against Commitment.',
    futureDateError:
      'Please select another date. You are not allowed to create transactions with future dates.',
    annualManagementFee: 'Annual Management Fee',
    annualManagementFeePlaceholder: 'Add Annual Management Fee',
    carry: 'Carry',
    carryPlaceholder: 'Add Carry',
    carryHurdleRate: 'Carry Hurdle Rate',
    carryHurdleRatePlaceholder: 'Add Carry Hurdle Rate',
    dividend: 'Dividend',
    dividendPlaceholder: 'Add Dividend',
    dividendCalculationBasis: 'Dividend Calculation Basis',
    dividendCalculationBasisPlaceholder: 'Add Dividend Calculation Basis',
    dividendType: 'Dividend Type',
    dividendTypePlaceholder: 'Add Dividend Type',
    na: 'N/A',
    naPlaceholder: 'Add N/A',
    cumulative: 'Cumulative',
    cumulativePlaceholder: 'Add Cumulative',
    noncumulative: 'Non-cumulative',
    noncumulativePlaceholder: 'Add Non-cumulative',
    warrants: 'Warrants',
    warrantsPlaceholder: 'Add Warrants',
    vestingCommencementDate: 'Vesting Commencement Date',
    vestingCommencementDatePlaceholder: 'Select Vesting Commencement Date',
    expirationDate: 'Expiration Date',
    expirationDatePlaceholder: 'Select Expiration Date',
    strikePrice: 'Strike Price',
    strikePricePlaceholder: 'Add Strike Price',
    numberOfShares: 'Number of Shares',
    numberOfSharesPlaceholder: 'Add Number of Shares',
    convertibleNote: 'Convertible Note',
    debtCredit: 'Debt / Credit',
    equity: 'Equity',
    preferredEquity: 'Preferred Equity',
    fundInvestment: 'Fund Investment',
    safe: 'SAFE',
    other: 'Other',
  },
  richTextEditor: {
    placeholder: 'Write your text here...',
  },
  visibility: {
    onlyMe: 'Only me',
  },
  sidebar: {
    holdings: 'Holdings',
    discover: 'Discover',
    newFeatures: 'New Features',
    upgradeYourAccount: 'Upgrade your account',
    groupSettings: 'Group Settings',
    userSettings: 'User Settings',
    notification: 'Notification',
    needHelp: 'Need Help?',
    groups: 'Groups',
    fundReporting: 'Fund Reporting',
    portfolioManagement: 'Portfolio Monitoring',
    recent: 'Recent',
    allGroups: 'All Groups',
    generalInformation: 'General Information',
    groupManagement: 'Group Management',
    storage: 'Storage',
    listManagement: 'List Management',
    fundProfile: 'Organization Profile',
    companyProfile: 'Company Profile',
    privateProfiles: 'Private Profiles',
    integrations: 'Integrations',
    userInformation: 'User Information',
    loginAndSecurity: 'Login & Security',
    privacy: 'Privacy',
    notifications: 'Notifications',
    logOut: 'Log Out',
    customLogin: 'Log In Customization',
    dashboard: 'Dashboard',
    organizationProfile: 'Organization Profile',
    investorRelations: 'Investor Relations',
    emails: 'Emails',
    updates: 'Updates',
    investorManagement: 'Investor Management',
    fundPortfolios: 'Funds & Portfolios',
    portfolios: 'Portfolios',
    metrics: 'Metrics',
    yourMetrics: 'Your Metrics',
    holdingMetrics: 'Holding Metrics',
    investorProfile: 'My Investor Profile',
    fundHoldings: 'Fund Holdings',
    funds: 'Funds',
    havePrivateInvestments: 'Have private Investments?',
    accountManagedBy: 'Account managed by',
    investmentVehicles: 'Investment Vehicles',
    additionalInformation: 'Additional Information',
    groupMembers: 'Group Members',
    updateSettings: 'File & Update Settings',
    files: 'Files & Contents',
    fundFiles: 'Fund Files',
    chat: 'Chat',
    inbounds: 'Inbounds',
    investments: 'Investments',
    subscriptionsAndBilling: 'Subscriptions and Billing',
  },
  updateSettings: {
    title: 'Update Settings',
    action: 'Default my new updates to be group owned',
    description:
      'If you have this setting turned ON, every time you create an Update it will be group editable/owned by default',
    errorTryingToUpdateSettings:
      'An error occurred when trying to update the settings, please try again later',
    settingsSuccessfullyUpdated: 'Settings successfully updated',
  },
  signOutFromAllDevices: {
    description:
      'Logged in on a shared device but forgot to sign out? End all sessions by signing out from all devices.',
    action: 'Sign out from all devices',
  },
  fileSettings: {
    title: 'File Settings',
    action: 'Default new unreconciled files to be group managed',
    description:
      'If you have this setting turned ON, every time you upload a new file, it will be group managed. This means all your group members can see and use the file. And if you set it OFF, you will be the only one to see your uploaded unreconciled files.',
    errorTryingToUpdateSettings:
      'An error occurred when trying to update the settings, please try again later',
    settingsSuccessfullyUpdated: 'Settings successfully updated',
  },
  storageSettings: {
    you: '(you)',
    title: 'Storage',
    tableTitle: 'Storage by member',
    tableHeader: {
      name: 'Name',
      storage: 'Storage',
      email: 'Email',
    },
    shareAttachedFiles: 'Shared attached files',
    unreconciledFiles: 'Unreconciled files',
    usedSpace: 'Used Space',
    totalSpace: 'Total Space',
  },
  privacySettings: {
    title: 'Privacy Settings',
    helpCWBySharingDataTitle:
      'Help improve Clockwork Universe by sharing usage data',
    helpCWBySharingDataDescription1:
      'We collect usage data to improve the Clockwork experience. For more information see the ',
    termsAndConditions: 'Terms and Conditions',
    helpCWBySharingDataDescription2:
      'This information helps us improve our products, personalize the Clockwork.app experience, and more. All the information about your session is anonymous, and about the behaivor and interaction with the platform. ',
    readMore: 'Read more',
  },
  userSettings: {
    emailVerified: 'Email verified',
    emailAddressVerified: 'Your email address ({email}) has been verified.',
    displayedSecondary:
      'Now it will be displayed in list of emails as a secondary email.',
    setPasswordModalHeader:
      'In order to set {email} as your primary email you will need to add a password to login.',
    setPasswordModalTitle: 'Set as Primary Email',
    setAsPrimaryAndSave: 'Set as Primary & Save Password',
    firstName: 'First Name',
    lastName: 'Last Name',
    handle: 'Handle',
    userInfoUpdatedSuccess: 'User info updated successfully',
    userInfoUpdatedError:
      'An error occurred when updating the user info, try again later',
    emails: 'Emails',
    addEmails: 'Add Emails...',
    emailValidationError: 'The email you are trying to add is not valid',
    setAsPrimary: 'Set as Primary',
    successSetPrimaryEmail:
      'The email address {email} was set as your primary email successfully!',
    emailError: 'An error occurred adding email, try again later',
    mergeModalTitle: 'Merge accounts',
    sendConfirmationEmail: 'Send confirmation email',
    mergeAccountError: 'An error occurred sending confirmation email',
    mergeAccountSuccess: 'Merge account confirmation email send successfully',
    emailAlreadyLinked:
      'The email address you’re trying to add is already linked to an existing account.',
    mergeAccountsQuestion: 'Would you like to merge both accounts?',
    mergeAccountsQuestion2:
      'If you agree, we’ll send you a confirmation email to validate the action. ',
    mergeAccountsDescription:
      'The account under {email} was successfully merged to this account',
    confirmEmailTitle: 'Confirmation Successful',
    confirmEmailDescription:
      'The email {email} was successfully added as a Secondary Email to this account',
    invalidLinkDescription:
      'The confirmation link you used is no longer valid.',
    invalidLinkTitle: 'Confirmation Failed: Invalid Link',
    tryAgain: 'Please re-add the email and try again.',
    changePhoto: 'Change Photo',
    errorTryingToGetSettings:
      'An error occurred when trying to get the settings, please try again later',
    confirmPrimaryEmail: 'Set email as primary',
    confirmPrimaryEmailTextLine1:
      "After clicking continue, you'll need to input your password and verify your email. If two-factor authentication was enabled, you'll need to set it up again.",
    confirmPrimaryEmailTextLine2: 'Are you sure you want to continue?',
    confirmationLinkNotValid:
      'The confirmation link you used is no longer valid.',
  },
  groupSettings: {
    optional: '(optional)',
    groupDescription: 'Group Description',
    description: 'Description',
    email: 'Group Email',
    getGroupError:
      'An error occurred when trying to get the group information, try again later',
    handle: 'Group Handle',
    handleTakenError: 'This group handle is already taken. Try another.',
    handleFormatError:
      'You are using characters that are not allowed. Use only lowercase letters, numbers, or "-" and "_".',
    infoUpdatedError:
      'An error occurred when updating the group info, try again later',
    infoUpdatedSuccess: 'Group info updated successfully',
    name: 'Group Name',
    changeLogo: 'Change Logo',
    title: 'General Information',
    imageHelpDimensions: 'Recommended dimensions are 300x300 pixels. ',
    imageHelpFormat:
      'We support JPEG, GIF, and PNG file formats. Maximum file size is 5MB.',
    changeImage: 'Change image',
    investmentVehicles: {
      title: 'Investment Vehicles',
      subtitle:
        'You have permissions to edit the General Information of <b>{quantity} investment vehicles.</b>',
    },
    groupMembers: {
      title: 'Group Members',
      pendingCWUniverseUser: 'Pending CWUniverse User',
      awaitingSignUp: 'Awaiting Signup',
    },

    additionalInfo: {
      title: 'Additional Information',
      investorLinksTitle: 'Investor Links',
      investorTeamTitle: 'Investor Team',
      otherInformationTitle: 'Other Information',

      types: {
        title: 'TYPE',
        individual: 'Individual',
        organization: 'Organization',
      },

      emails: {
        title: 'EMAILS',
      },

      website: {
        title: 'WEBSITE',
      },

      phone: {
        title: 'PHONE',
      },

      foundedDate: {
        title: 'FOUNDED DATE',
      },

      address: {
        title: 'ADDRESS',
      },

      team: {
        title: 'TOTAL NUMBER OF EMPLOYEES',
        keyPeople: 'KEY PEOPLE',
      },
    },
  },
  changePasswordSettings: {
    modalTitle: 'Change your password',
    passwordUpdatedSuccess: 'Password updated successfully',
    passwordUpdatedError:
      'An error occurred updating password, try again later',
    currentPassword: 'Current Password',
    currentPasswordPlaceholder: 'Add your current password',
    newPassword: 'New Password',
    newPasswordPlaceholder: 'Add new password',
    confirmNewPassword: 'Repeat New Password',
  },
  lists: {
    noRecipientsLists: 'You have no recipients lists yet.',
    goToListsMessage: 'Want to manage lists?',
    goToListsMessageEmpty: 'Want to manage lists?',
    linkToListsText: 'Manage lists',
    createLists:
      'Create one to organize the groups and users who will receive your updates.',
    addRecipientList: 'Add Recipient List',
    manageRecipientsLists: 'Manage recipients lists',
    selectLists: 'Select lists',
    getErrorList:
      'There was an error trying to get the lists, try again later.',
  },
  listsMgmt: {
    title: 'List Management',
    loadingLists: 'Loading lists...',
    getListsError:
      'An error occurred when trying to get the lists, try again later',
    importRecipientsError:
      'An error occurred when trying to import the recipients, try again later',
    createListError:
      'An error occurred when trying to create the list, try again later',
    listNameAlreadyTaken:
      'The name {name} has already been taken, please choose another one',
    editListError: 'An error occurred when editing a list, try again later',
    deleteListError:
      'An error occurred when trying to delete the list, try again later',
    createList: 'Create list',
    recipients: 'Recipients',
    noRecipients: 'No recipients',
    groups: 'Groups',
    users: 'Users',
    emails: 'Emails',
    addRecipients: 'Add Recipients',
    addListName: 'Add List Name',
    viewList: 'View List',
    editList: 'Edit List',
    deleteList: 'Delete List',
    copyList: 'Copy List',
    exportRecipients: 'Export Recipients <csv>(.csv)</csv>',
    copyListToClipboardSuccess:
      'All recipients from "{listName}" have been copied to your clipboard.',
    countImported: 'Successfully imported {count} recipients',
    importRecipients: 'Import Recipients <csv>(.csv)</csv>',
    importRecipientsTitle: 'Import Recipients',
    helpCsv: 'If you need a sample of our .CSV bulk template you can {here}',
    deleteMessage: 'Delete List',
    deleteMessageConfirmation: 'Are you sure you want to delete “{listName}”?',
    deleteMessageConfirmationSecondary:
      'This action will remove the list to all group members. Be aware that you cannot undo this action.',
    deleteToastMessage: '"{listName}" has been deleted successfully.',
  },
  groupManagement: {
    you: ' (you)',
    ownerLegend:
      'Full access to all group and updates management. You can assign the owner role to any group member. However, you can only have 1 member in this role.',
    managerLegend: 'Full access to all group and updates management.',
    memberLegend: 'Access only to updates management.',
    title: 'Group Management',
    addToGroup: 'Add to group',
    groupMembers: 'Group Members',
    emailExample: `${emails.EXAMPLE_EMAIL}`,
    chooseRole: 'Choose role',
    role: 'Role',
    userEmail: 'User Email',
    noName: 'No name found',
    transferOwnershipTitle: 'Transfer ownership',
    transferOwnershipDescription:
      'To delete {username} you need to transfer any associated data to another member.',
    transferTo: 'Select the member where you wish to transfer {username} data.',
    transferData: 'Transfer Data',
    selectUser: 'Select a user',
    optionsTitle: 'Group name members',
    changeGroupOwnership: 'Switch Group Owner',
    changeGroupOwnershipQuestion:
      'Are you sure you want {username} to be the owner of this group?',
    changeGroupOwnershipDescription:
      'By doing this you will become a group manager. But don’t worry you’ll still have full access to all Group and Updates management.',
    name: 'Name',
    changeOwner: 'Change owner',
    email: 'Email',
    getGroupsError:
      'An error occurred when trying to get the group members, try again later',
    addMemberError:
      'An error occurred when trying to add the group member, try again later',
    deleteMemberError:
      'An error occurred when trying to delete the group member, try again later',
    memberDeletedSuccess:
      'The user has been removed from the group succesfully',
    changeRoleError:
      'An error occurred when trying to update the role, try again later',
    memberExistError: 'The user {email} is already a member of the group',
    addMemberSuccess: 'The user has been added successfully to the group.',
    editMemberRoleSuccess: 'We have changed {user} role to {role} succesfully.',
    errorResendingInvite:
      'An error occurred when resending the invite, try again later',
    successResendingInvite: 'We have sent {email} invite successfully.',
    resendInvite: 'Re-Send Invite',
    switchToMember: 'Switch to Member',
    downgradePermissionsQuestion:
      'Are you sure want to downgrade your permissions? ',
    downgradePermissionsDescription:
      'By doing this you will become a regular group member and you will only have access to Updates management.',
    changeRole: 'Change Role',
  },
  portfolios: {
    filesSummary: 'Files Summary',
    updatesSummary: 'Updates Summary',
    totalFiles: 'Files',
    totalPdfs: 'PDFs',
    totalImages: 'Images',
    totalDocuments: 'Documents',
    totalSpreadsheets: 'Spreadsheets',
    totalPresentations: 'Presentations',
    totalAudios: 'Audios',
    totalEmailContents: 'Email Content',
    totalVideos: 'Videos',
    totalCompressedFiles: 'Compressed Files',
    totalOtherTypes: 'Other type Files',
    announcement: 'Announcement',
    announcements: 'Announcements',
    totalUpdates: 'Updates',
    totalNotes: 'Notes',
    totalAnnouncements: 'Announcements',
    totalEmails: 'Emails',
    totalSuggestedUpdates: 'Suggested Updates',
    totalDraftUpdates: 'Draft Updates',
    totalCreatedUpdates: 'Created Updates',
    notes: 'Notes',
    emails: 'Emails',
    document: 'Document',
    pdfs: 'PDFs',
    images: 'Images',
    documents: 'Documents',
    spreadsheets: 'Spreadsheets',
    presentations: 'Presentations',
    audios: 'Audios',
    videos: 'Videos',
    compressedFiles: 'Compressed Files',
    emailContent: 'Email Content',
    otherTypes: 'Other Types',
    counted: {
      announcements:
        '{count, plural, one {Announcement} other {Announcements}}',
      notes: '{count, plural, one {Note} other {Notes}}',
      emails: '{count, plural, one {Email} other {Emails}}',
      documents: '{count, plural, one {Document} other {Documents}}',
      pdfs: '{count, plural, one {PDF} other {PDFs}}',
      images: '{count, plural, one {Image} other {Images}}',
      spreadsheets: '{count, plural, one {Spreadsheet} other {Spreadsheets}}',
      presentations:
        '{count, plural, one {Presentation} other {Presentations}}',
      audios: '{count, plural, one {Audio} other {Audios}}',
      videos: '{count, plural, one {Video} other {Videos}}',
      compressedFiles: '{count, plural, one {Compressed} other {Compressed}}',
      emailContent:
        '{count, plural, one {Email Content} other {Email Contents}}',
      otherTypes: 'Other {count, plural, one {File} other {Files}}',
    },
    sortOptions: {
      lastUpdate: 'Last Update',
      name: 'Name',
    },
    type: {
      track: 'Pipeline',
      invest: 'Invest',
      fund: 'Fund',
      deal: 'Deal',
    },
    holdings: 'Holdings',
    holding: 'Holding',
    funds: 'Funds',
    fetchPortfolioError:
      'An error occurred when fetching the portfolio, try again later',
    loadingMorePortfolios: 'Loading more portfolios...',
    loadingTransactions: 'Loading Transactions...',
    searchMetric: 'Search metric',
    investorHasbeenAddedTo: '{investorName} has been added to {fundName}',
    errorFetchingTransactions:
      'An error occurred when getting the transactions, try again later',
    readOnlyTooltip: `You have reached the limit of portfolios you can create. If you are interested in upgrading your account, get in touch at ${emails.UPGRADE}. `,
    createPortfolio: 'Create Portfolio',
    createNew: 'Create New',
    downloadBulkTemplate: 'Download Bulk Template',
    downloadImportTemplate: 'Download Import Template',
    noCompanies: 'No companies match that value',
    noPortfolios: 'You don’t have any portfolio to show yet.',
    addingCompanyToPorfolioSuccess:
      'Companies successfully added to the portfolio',
    createdAt: 'Portfolio created on {date}',
    countCompanies:
      '{countCompanies, plural, one {# holding} other { # holdings}}',
    morePortfolios: '+{count} more',
    capitalCalled: 'Total Capital Called',
    totalCommitted: 'Total Committed',
    portfolioListError:
      'An error occurred showing the portfolios, try again later',
    addNewPortfolio: 'Add new portfolio',
    newPortfolio: 'New Portfolio',
    totalInvested: 'Total Invested',
    followedHoldings: '# Followed Holdings',
    activeHoldings: 'Active Holdings',
    industryCount: 'Industry Count',
    geographicDiversity: 'Geographic Diversity',
    averageAge: 'Average Age',
    averageSize: 'Average Size',
    industryConcentration: 'Industry Concentration',
    mainLocation: 'Main Location',
    foundingRange: 'Founding Range',
    websitePresence: 'Website Presence',
    socialMediaPresence: 'Social Media Presence',
    totalValue: 'Total Value',
    totalAmountInvested: 'Total Amount Invested',
    totalHoldings: 'Total Holdings',
    distributionToPaidIn: 'DPI',
    multipleOnInvestedCapital: 'MOIC',
    totalUnfundedCommitment: 'Total Unfunded Commitment',
    uncalledCommitment: 'Uncalled Commitment',
    totalDistributed: 'Total Distributed',
    totalFairMarketValue: 'Total Carrying Value',
    totalInvestments: 'Total Investments',
    averageHoldingSize: 'Avg Holding Size',
    averageInvestmentPerHolding: 'Avg. Investment per Holding',
    irr: 'IRR',
    averageInvestmentPerCompany: 'Average Investment per Company',
    otherCompanies: 'Other Companies',
    otherPortfolios: 'Other Portfolios',
    others: 'Others',
    followedHoldingsSummary: 'Followed Holdings Summary',
    otherInvestors: 'Other Investors',
    totalCompanies: 'Total: {length} Companies',
    addNewCompanies: 'Add new companies to portfolio',
    addCompanies: 'Add companies',
    lookForCompanies: 'Look for companies',
    add: 'Add',
    cancel: 'Cancel',
    alreadyAddedCompany: 'Company already exists in portfolio',
    addingCompanyToPorfolio:
      'An error occurred when adding a company, try again later',
    firstInvestment: 'First Investment',
    exportCSV: 'Export Transactions',
    importCSV: 'Import Transactions',
    importTransactions: 'Import Transactions',
    exportTransactions: 'Export Transactions',
    exportFmv: 'Export Holdings Carrying Values',
    logs: 'Portfolio Log',
    investmentHoldings: 'Investment holdings',
    errorExportCSV:
      'An error occurred when trying to export to csv, try again later',
    noFairMarketValue: 'There is no carrying value to show yet.',
    noFairMarketValueLabel: 'No carrying values',
    noFairMarketValueLegend:
      'It seems that there are no carrying values on this company.',
    fairMarketValue: 'Carrying Value',
    portfolioTotalUnfundedCommitment: 'Total Unfunded Commitment',
    fmvHistory: 'Carrying Value history - {companyName}',
    totalUnrealizedValue: 'Total Unrealized Value',
    totalUnealizedValueDescription:
      'Do you need help calculating your investment portfolio performance? Write us at {email}',
    portfolioMetrics: 'Portfolio Metrics',
    addNewMetric: 'Add Metric',
    addMetricToPortfolio: 'Add Metric to All Portfolio Holdings',
    emailExcluded: 'Excluded from Weekly Digest Email',
    emailIncluded: 'Included in Weekly Digest Email',
    fetchPortfolioEmailsettingsError:
      'An error occurred fetching the email settings, try again later',
    editPortfolioEmailsettingsError:
      'An error occurred updating the portfolio email settings, try again later',
    fmvCommentPlaceholder: 'Leave a comment (optional)',
  },
  createPortfolio: {
    errorGettingPortfolioByName:
      'An error occurred trying to get the portfolio by name, try again later',
    portfolioAlreadyExists:
      'You already have a portfolio or fund with this name. Try adding another one.',
    trackPortfolio: 'Pipeline',
    trackCompanies: 'Track holdings.',
    manageInvestments: 'Manage investments.',
    selectTypeLabel: 'Select type you want to create',
    manageInvestors: 'Manage investors.',
    investPortfolio: 'Invest Portfolio',
    dealPortfolio: 'Deal',
    trackHoldings: 'Track holding.',
    addHolding: 'Add holding',
    name: 'Name',
    portfolioName: 'Name',
    addHoldings: 'Add holdings',
    fundPortfolioNamePlaceholder: 'Give your fund or portfolio a name',
    portfolioNamePlaceholder: 'Give your portfolio a name',
    addCompanies: 'Add Companies',
    optional: '(optional)',
    lookHoldings: 'Search for the holdings you would like to add',
    oneCompanyRequired: 'At least one company must be selected',
    createPortfolioError:
      'An error occurred when trying to create the portfolio, try again later',
    createDealHoldingRequiredError: '',
    createDealMaxHoldingExceededError:
      'Deals can only have a single holding. Please remove one to continue.',
    portfolioCreatedSuccess: 'Portfolio created successfully',
    track: 'Track Portfolio',
    invest: 'Invest Portfolio',
    fundPortfolio: 'Fund',
    transactions: 'Transactions',
    amountInvested: 'Amount Invested',
    fairMarketValue: 'Carrying Value',
    amountDistributed: 'Amount Distributed',
    firstInvestment: 'First Investment',
    selectPortfolioType: 'Select portfolio type',
    selectType: 'Select type',
    readOnly: `For creating invest portfolios, upgrade you account. For more information, get in touch at ${emails.UPGRADE}.`,
    readOnlyForPipelines: `For creating more pipeline portfolios, upgrade you account. For more information, get in touch at ${emails.UPGRADE}.`,
  },
  upgradeYourAccountModal: {
    title: 'Upgrade your account',
    subtitle:
      'Gain efficiency and peace of mind by streamlining your private investing.',
    item1: 'Centralized and secure portal for all of your private investments',
    item2:
      'Customized investment and pipeline portfolios with dynamic update feeds',
    item3: 'Track investment metrics - including funds and directs',
    item4:
      'Manage distribution lists for better communications with stakeholders',
    item5: 'Direct access to our investment team support if desired',
    item6: 'And more...',
  },
  portfolioDetail: {
    files: 'Files',
    chat: 'Chat',
    metrics: 'Metrics',
    updates: 'Updates',
    pipeline: 'Pipeline',
    followedHoldings: 'Followed Holdings',
    editColumns: 'Edit Columns',
    firstInvestmentDate: 'First Investment Date',
    loadingTransactions: 'Loading Transactions...',
    unfundedCommitment: 'Unfunded Commitment',
    editValue: 'Edit value',
    deleteValue: 'Delete value',
    amount: 'Amount',
    transactionType: 'Transaction type',
    instrument: 'Instrument',
    errorDeletingFMV:
      'An error occurred when trying to delete the carrying value, try again later',
    title: '{portfolioName} companies',
    summary: 'Portfolio Summary',
    investmentSummary: 'Investment Summary',
    fundsAndDeals: 'Funds & Deals',
    fundSummary: 'Fund Summary',
    individualDealSummary: 'Individual Deal Summary',
    investments: 'Investments',
    portfolioRemoved: 'Portfolio removed successfully',
    addHolding: 'Add Holding',
    addFairMarketValuePlus: 'Update Carrying Value',
    addTransactionalUpdate: 'Create Transaction',
    amountDistributed: 'Amount Distributed',
    amountInvested: 'Amount Invested',
    companies: 'Companies',
    distributionToPaidIn: 'Distribution to Paid In',
    editPortfolioError:
      'An error occurred when trying to remove a company from the portfolio, try again later',
    editPortfolioNameError:
      'An error occurred when trying to edit the portfolio name. You might already have a portfolio with the same name.',
    pinnedPortfolioError:
      'An error occurred when trying to pin the portfolio, try again later',
    fairMarketValue: 'Carrying Value',
    totalUnrealizedValue: 'Total Unrealized Value',
    carryingValueTooltip:
      ' Do you need help calculating your investment portfolio performance? Write us at {email}',
    getPortfolioError:
      'An error occurred when trying to get the portfolio, try again later',
    getUpdatesByPortfolioError:
      "An error occurred when trying to get the updates from the portfolio's companies, try again later",
    holdings: 'Holdings',
    multipleOnInvestedCapital: 'Multiple on Invested Capital',
    moic: 'MOIC',
    noCompanies: 'No companies found',
    noCompaniesLegend:
      'It seems that there are no companies added to this portfolio yet',
    noCompaniesTitle: 'No companies found',
    percentageOfInvested: '% of Invested',
    portfolioCreation: 'Portfolio created on {creationDate}',
    removeCompanyBody:
      'Are you sure you want to remove {companyName} from your portfolio?',
    removeCompanyConfirm: 'Remove',
    removeCompanyFromInvestPortfolioError:
      'An error occurred when trying to remove the company from the portfolio, try again later',
    removeCompanyTitle: 'Remove company from portfolio',
    removeHoldingTitle: 'Remove holding from portfolio',
    totalValue: 'Total Value',
    totalInvestments: 'Total Investments',
    irr: 'IRR',
    editCompanyDataError:
      'An error occurred when trying to edit the company data, try again later',
    deletePortfolioTitle: 'Delete portfolio',
    deletePortfolioConfirmation:
      'Are you sure you want to delete the portfolio?',
    deletePortfolioQuestion: 'To delete the portfolio, please type its name.',
    portfolioName: 'Portfolio name',
    errorRemoving: 'An error occurred deleting the portfolio, try again later',
    logs: 'Logs',
    portfolioLog: 'Portfolio Log',
    remove: 'Delete Portfolio',
    rename: 'Rename',
    previewAs: 'Preview as',
    deletePortfolioWarning:
      '<bold>Warning!</bold> This action can’t be undone.',
    dateFirstInvestment: 'First investment date',
    noPortfolios: 'There are no companies in your portfolio',
    addTransactional: 'Add Transaction',
    addFairMarketValue: 'Update Carrying Value',
    companyFmvHistory: 'Carrying Value History',
    deleteCompany: 'Delete Holding',
    currentFmv: 'Current Carrying Value',
    lastFmv: 'Last Carrying Value',
    transactionName: 'Transaction',
    transactionDate: 'Date',
    type: 'Type',
    deleteTransation: 'Delete transaction',
    description: 'Description',
    noTransactions: 'You have no transactions.',
    investmentVehicle: 'Investment Vehicle',
    addTransaction: 'Add transaction',
    turnIntoInvest: 'Turn into Invest Portfolio',
    turnIntoFund: 'Turn into a Fund',
    turnIntoDeal: 'Turn into a Deal',
    turnPortfolioSuccessfully:
      '{name} was successfully turned into {type} portfolio.',
    turnPortfolioError:
      'An error occurred when trying to turn the portfolio {name} into {type}, please try again later',
    turnPortfolioConfirmation: {
      title: 'Turn {type} Portfolio into {newType}',
      areYouSureInvest:
        'Are you sure you want to turn this {type} portfolio into a {newType}?',
      areYouSureTrack:
        'Are you sure you want to turn this {type} portfolio into an {newType}?',
      typePortfolioName: 'Please type the portfolio name.',
      typeDealName: 'Please give the deal name.',
      typePortfolioNamePlaceholder: 'Type portfolio name...',
      typeDealNamePlaceholder: 'Type deal name...',
      confirm: 'Turn into {type}',
    },
    transactionTypes: {
      amendment: 'Amendment',
      note_conversion: 'Note Conversion',
      valuation_adjustment: 'Valuation Adjustment',
      distribution: 'Distribution',
      follow_on_instrument: 'Follow on Instrument',
      follow_on_investment: 'Follow on Investment',
      new_company_investment: 'New Company Investment',
    },
    instruments: {
      safe: 'SAFE',
      preferred_equity: 'Preferred Equity',
      equity: 'Equity',
      debt_credit: 'Debt / Credit',
      convertible_debt: 'Convertible Debt',
      other: 'Other',
    },
    loadMoreTransactions: 'Load More Transactions',
    duplicatedTransactionTitle:
      'Are you sure you want to upload this transaction?',
    duplicatedTransactionSubTitle: 'It seems it’s duplicated.',
    companyWarning: 'Please double check this is the correct company',
    lastCarryingValueUpdate: 'Last Carrying Value Update',
    companyNotIncludedInDeal:
      'It looks like "{holdingName}" isn\'t associated to {dealsNames}. Please double-check if this is the right holding or verify the added deal(s).',
    firstInvestmentTooltip:
      'The month and year in which the first investment was made.',
    totalInvestmentTooltip:
      'The total number of unique investments made into the holding.',
    unfundedCommitmentTooltip:
      'The aggregate value of any outstanding prior commitments made by the fund to the holding',
    amountInvestedTooltip:
      'The aggregate cost of all investments made to date.',
    fairMarketValueTooltip:
      'The aggregate holding (unrealized) value of actively held investments.',
    amountDistributedTooltip:
      'The total capital returned (realized) by investments to date.',
    totalValueTooltip:
      'The aggregate value generated by all investments made to date. The Total Value is equal to Carrying Value + Amount Distributed.',
    moicTooltip:
      'Multiple on Invested Capital (MOIC) represents the ratio of value to capital invested. It is calculated by dividing the sum of Amount Distributed and Carrying Value by Amount Invested.',
    irrTooltip:
      'Internal rate of return (IRR) is an annualized time-weighted return metric used to show the profitability of potential investments.',
    percentageOfInvestedTooltip:
      'The ratio of Amount Invested into each entity relative to the Total Invested.',
    tooltips: {
      followedHoldings: 'Total number of added holdings.',
      activeHoldings:
        'Number of holdings which have published updates this month.',
      industryCount: 'Number of unique industries.',
      geographicDiversity:
        'Number of different countries the holdings are based in.',
      averageAge: 'The average age of the holdings since their founding.',
      averageSize: 'Average employee count across all companies.',
      industryConcentration: 'The most common industry.',
      mainLocation: 'The country where most companies are headquartered.',
      foundingRange: 'Range in which holdings were founded.',
      websitePresence: 'Range in which holdings were founded.',
      socialMediaPresence: 'Percentage of companies with social media.',
      totalInvested: 'The aggregate cost of all investments made to date.',
      totalInvestedInvestor:
        'The aggregate of personal invested capital to date.',
      totalFairMarketValue:
        'The aggregate carrying (unrealized) value of actively held investments.',
      totalDistributed:
        'The total capital returned (realized) to the fund by investments to date.',
      totalDistributedInvestor:
        'The total capital returned (realized) to you to date.',
      totalValue:
        'The aggregate value generated to the fund by all investments made to date. The Total Value is equal to Total Carrying Value + Total Distributed.',
      totalValueInvestor:
        'The aggregate of personal realized and unrealized investment gains to date. The Total Value is equal to Total Carrying Value + Total Distributed.',
      totalUnfundedCommitment:
        'The aggregate value of any outstanding prior commitments made by the fund to portfolio holdings.',
      totalUnfundedCommitmentInvestor:
        'The remaining capital committed that has yet to be called.',
      totalHoldings:
        'The aggregate number of investments made into unique companies, funds, or other holdings.',
      totalHoldingsInvestor:
        'The aggregate number of managed funds or SPVs invested in.',
      totalInvestments:
        'The aggregate number of unique investments made into companies, funds, or other holdings.',
      totalInvestmentsInvestor:
        'The aggregate number of unique investments made into managed funds or SPVs.',
      averageHoldingSize:
        'The average invested capital into each unique company, fund, or other holding.',
      averageHoldingSizeInvestor:
        'The average invested capital into each managed fund and SPV invested in.',
      firstInvestment:
        'The year in which the portfolio’s first investment was made. Typically referred to as the “Vintage Year” of the portfolio.',
      firstInvestmentInvestor:
        'The year in which the first investment was made.',
      distributionToPaidIn:
        'Distributions to Paid-In Capital (DPI) represents the ratio of capital returned to capital invested. It is calculated by dividing Total Distributions by Total Invested.',
      multipleOnInvestedCapital:
        'Multiple on Invested Capital (MOIC) represents the ratio of value to capital invested. It is calculated by dividing Total Value by Total Invested.',
      irr: 'Internal Rate of Return (IRR) is an annualized time-weighted return measure used to estimate the profitability of investment activity.',
    },
    lastUpdated: 'Last updated',
    portfolio: 'Portfolio',
    investors: 'Investors',
    shareSettings: 'Share Settings',

    investorSummary: {
      title: 'Investor summary',
      totalCommittedCapital: 'Total Committed Capital',
      totalCapitalCalled: 'Total Capital Called',
      totalInvestors: 'Total Investors',
      totalUnfundedCommitment: 'Total Unfunded Commitment',
      totalDistributions: 'Total Distributions',
      totalHoldingValue: 'Total Holding Value',
      tooltips: {
        totalCommittedCapital:
          'The aggregate of all investor commitments made to date.',
        totalUncalledCommitment:
          'The aggregate value of any outstanding prior commitments made by investors to the fund.',
        totalCapitalCalled:
          'The aggregate value of all capital calls made to and received by investors.',
        totalDistributions:
          'The total capital returned (realized) to investors by the fund to date.',
        totalInvestors: 'The aggregate number of unique investors in the fund.',
        totalHoldingValue:
          'The aggregate fair market (unrealized) value of actively held investments.',
        totalCarryingValue:
          'The aggregate carrying (unrealized) value of actively held investments.',
      },
      chart: {
        totalCapitalCalled: 'Total Capital Called',
        otherInvestors: 'Other Investors',
        others: 'Others',
      },
    },
    fundInvestors: {
      title: 'investors ({quantity})',
      addInvestor: 'Add Investor',
      createTransaction: 'Create Transaction',
      viewInvestorProfile: 'View Investor Profile',
      remove: 'Remove',
      selectInvestor: 'Select Investor',
      search: 'Search...',
    },
    fetchFundPortfolioInvestorsError:
      'An error occurred when trying to get the investors, please try again later',
    fundPortfolio: {
      summary: 'Fund Summary',
      remove: 'Delete Fund',
      previewAsTitle: 'Preview as',
      cancel: 'Cancel',
      preview: 'Preview',
      selectInvestor: 'Select Investor',
      previewingAs: 'Previewing as {investorName}',
      goBack: 'Go back',
      shareSettingsMsg:
        'Do you want to customize the information you share with your investors? Click on the <share>Share Settings</share> option on the fund and select the data you want to share with them.',
    },
    performanceMetrics: {
      chartCriteria: {
        all: 'All',
        quarter: 'Quarter',
        year: 'Year',
      },
      processingNewData: 'Processing new data',
      takeAFewSeconds: 'This may take a few seconds',
    },
    filesTable: {
      title: 'Files',
      name: 'Portfolio/Holding',
      columnTitle: 'Total # of',
      totalFiles: 'Files',
      totalPDFs: 'PDFs',
      totalImages: 'Images',
      totalDocuments: 'Documents',
      totalSpredsheets: 'Spreadsheets',
      totalPresentations: 'Presentations',
      totalAudios: 'Audios',
      totalVideos: 'Videos',
      totalCompressedFiles: 'Compressed Files',
      noFiles: 'You have no files.',
      loadingFiles: 'Loading files...',
      loadMoreFiles: 'Load More Files',
    },
  },
  pdfPreviewer: {
    noAvailablePreview: 'There is no preview available for this file.',
    downloadLink: 'Download',
  },
  logs: {
    update: 'update',
    stage: 'Stage',
    title: 'Log',
    note: 'note',
    document: 'document',
    announcement: 'announcement',
    metric: 'metric',
    attachment: 'attachment',
    milestone: 'a milestone',
    portfolio: 'portfolio',
    tag: 'tag "{tagName}"',
    accounting: 'accounting',
    email: 'email',
    created: 'has created',
    updated: 'has edited',
    updatedCompany: 'edited company {companyName}',
    added: 'has added',
    deleted: 'has deleted',
    removed: 'has removed',
    companyAdded: 'company {companyName}',
    addedToPortfolio: 'added company {companyName} to portfolio',
    removedFromPortfolio: 'removed company {companyName} from portfolio',
    errorGetLogs: 'An error occurred when trying to get the update logs',
    noLogsFound: 'No logs found',
    noLogsFoundLegend: 'It seems that there are no logs to show',
    exportCSV: 'exported transactions to CSV',
    transaction: 'transaction',
    addedDataPoint: 'has added a new value',
    editedDataPoint: 'has edited a value',
    sharedWith: 'has shared metric with {groupName}',
    deleteDataPoint: 'deleted data point',
    sharedMetric: 'has shared metric with {groupName}',
    deniedMetric: 'has denied metric from {groupName}',
    unsharedMetric: 'has unshared metric with {groupName}',
    metricAccepted: 'has accepted metric from {groupName}',
    informationLogTitle: '{name} Information Log',
    ...logs,
  },
  notifications: {
    customize: 'Customize',
    customizeUpdatesNotifications: 'Customize individual email notifications',
    customizeUpdatesNotificationsDesc:
      'Select or deselect the update types for which you want to receive individual email notifications.',
    atLeastOneUpdateRequired:
      'You need to have one update type selected in order to save.',
    title: 'Notifications',
    getNotificationsBy: 'Get notifications by:',
    pickNotifications: 'Pick notifications to receive by email',
    emailNotifications: 'Email notifications',
    pushNotifications: 'Push notifications',
    inAppNotifications: 'In-App notifications',
    enableAllUpdates: 'Receive all notifications',
    receiveUpdatesNotifications: 'Send me a dedicated email for new Updates',
    sendMeDailyRecap: 'Send me a daily recap of new Updates',
    updates: 'Updates',
    weekly: 'Weekly Digest',
    digestTime: '(Fridays at 10am Eastern)',
    portfolio: 'Portfolio',
    companyAdded: 'When a company is added to a portfolio',
    companyRemoved: 'When a company is removed from a portfolio',
    metrics: 'Metrics',
    /* Updates notifications */
    newDocumentShared: 'New Document is shared',
    newAnnouncementShared: 'New Announcement is shared',
    newNoteShared: 'New Note is shared',
    newTransactionShared: 'New Transaction is shared',
    newIueShared: 'New Investor Update is shared',
    newAccountingShared: 'New Accounting Report is shared',
    updateTypes: {
      loggingDocumentShared: 'Documents',
      loggingAnnouncementShared: 'Announcements',
      loggingNoteShared: 'Notes',
      loggingTransactionShared: 'Transactions',
      reportingReportShared: 'Investor Update Emails',
      accountingReports: 'Accounting Reports',
    },
    /* Metrics Founder */
    whenSomeoneRequestsMetric: 'When someone requests a metric',
    whenMetricIsAboutToBeOutdated: 'When a metric is about to be outdated',
    whenMetricIsOutdated: 'When a metric is outdated',
    whenMetricIsDeleted: 'When a metric is deleted',
    /* Metrics Investor */
    whenMetricHasBeenShared: 'When someone shares a metric with your group',
    whenMetricHasBeenDenied: 'When a metric request has been denied',
    whenFounderTeamRemovesYourGroup:
      'When a founder team removes your group from a metric',
    /* Subscriptions */
    subscriptions: 'Subscriptions',
    clockworkOrbit: 'Clockwork Orbit',
    companyAnnouncements: 'Company Announcements',
    productAndFeatures: 'Product and Feature Updates',
    marketingInformation: 'Marketing Information',
    webinarAndTrainings: 'Webinars and Trainings',
    include: 'Include',
    allPortfolios: 'All portfolios',
    customizePortfolios: 'Customize Portfolios',
    and: 'and',
    andMore: '{count} more {count, plural, one {portfolio} other {portfolios}}',
    includedPortfolios: 'Included portfolios',
    modalDescription:
      'Select and deselect Portfolios you would like to include or exclude from the weekly portfolios digest email.',
    zeroPortfolio: '0 portfolios',
  },
  serviceWorker: {
    newVersionAvailable: 'A new version of Clockwork Universe is available.',
    update: 'Update',
    saveAndReload: 'Save your changes and {link}',
    reload: 'reload',
  },
  emailPreview: {
    investorUpdate: 'Investor Update - {date}',
    investorUpdateSent: 'Investor update sent via Clockwork Universe.',
    privateInteligence: 'A private intelligence platform.',
    unsuscribe:
      "If you'd no longer like the receive these emails, please message {email}.",
    disclaimer:
      'This message is intended only for the use of the individual or entity to whom it is addressed, and may contain information that is PROPRIETARY and CONFIDENTIAL. If you are not the intended recipient or the employee or agent responsible for delivering the message to the intended recipient, you are hereby notified that any dissemination, distribution, or copying of this communication is strictly prohibited. If you have received this communication in error, please notify the above-named sender by e-mail.',
  },
  tags: {
    tagsTitle: 'Tags',
    addTagPlaceholder: 'Add Tags',
    tagAlreadyAddedToList: 'Tag name is already added to list',
    getTagsByGroupError:
      'An error occurred when importing the transactions, try again later. when trying to get the tags',
    errorValidatingTag:
      'Tags must have a mininum of two characters and only contain numbers or letters',
    errorAddingTag: 'There was an error trying to add the tag, try again later',
    errorRemovingTag:
      'There was an error trying to remove the tag, try again later',
  },
  termsOfUse: {
    checkboxLegend:
      'I have read and agree to the Clockwork {termsOfUseLink} and {privacyPolicyLink}',
    legend: 'Terms of Use',
    mustAcceptTermsOfUse: 'You must accept the terms of use before continuing.',
    errorAcceptingTermsOfUse:
      'An error occurred when trying to accept the terms of use, try again later',
    title: 'Terms Of Use',
    agreeAndContinue: 'Agree and continue',
  },
  privacyPolicy: {
    legend: 'Privacy Policy',
  },
  footer: {
    newFeatures: 'New Features',
    home: 'Home',
    website: 'Website',
    privacy: 'Privacy',
    terms: 'Terms',
    help: 'Help',
    status: 'Status',
    contact: 'Contact',
    copyright: '© {year} Clockwork Universe, Inc.',
    version: 'Version {version}',
    supportEmail: `${emails.SUPPORT}`,
  },
  aboutCompany: {
    requiredField: 'This is a required field',
    legend: 'Tell us about your company',
    companyName: 'Company name',
    companyNamePlaceholder: 'Add company name',
    companyWebsite: 'Company website',
    companyWebsitePlaceholder: 'Add company website',
    legalEntityName: 'Legal entity name',
    legalEntityNamePlaceholder: 'Add legal entity name',
    errorCollectingCompanyInformation:
      'There was an error when trying to collect information about your company',
    companyLogo: 'Company Logo',
    createCompanyError:
      'A company with that legal name already exists, please choose another name',
    createCompanyWebsiteError:
      'A company with that website already exists, please choose another',
    createCompanyLegalEntityNameError:
      'A company with that legal entity name already exists, please choose another',
    noInfoCollectedPart1:
      'Hmm… We weren’t able to extract any information from the company website you provided.',
    noInfoCollectedPart2:
      ' Please take some time to fill in some additional information so you can begin storing updates in the new company profile.',
    infoCollectedPart1:
      'Take a look! We used the company website you provided and tried to populate some more information.',
    infoCollectedPart2:
      ' Please double check what we’ve populated to make any edits and/or add any additional information.',
    pointOfContact: 'Company’s Point of Contact',
    pointOfContactPlaceholder: `e.g. ${emails.INFO}`,
    pointOfContactHelper:
      "If you add the founder's email or company point of contact responsible for investor reporting, we'll send them an invite to {freeSignup} ",
    freeSignup:
      'sign up for free to send their updates through Clockwork Universe. ',
    yourRole: 'Your Role',
    yourRolePlaceholder: 'Add your role',
    areYouFounder: 'Are you the founder?',
    yesFounder: 'Yes, I am the founder',
    claimGroup: 'Claim this Group?',
    claimGroupDescription:
      'We found this group without an owner, and we think it could belong to you.',
  },
  aboutYou: {
    requiredField: 'This is a required field',
    errorMessage:
      'An error occurred when trying to save your information, try again later',
    firstNameLabel: 'First Name',
    firstNamePlaceholder: 'What is your first name?',
    lastNameLabel: 'Last Name',
    lastNamePlaceholder: 'What is your last name?',
    roleLabel: 'Your Role',
    rolePlaceholder: 'What is your company role?',
    linkedinProfileLabel: 'Linkedin Profile',
    linkedinProfilePlaceholder: 'What is your linkedin profile?',
    businessFounder: "I'm the {founder}",
    errorSavingInformation:
      'An error occurred when saving your information, try again later',
  },
  additionalInformation: {
    errorMessage:
      'An error occurred when editing the company profile, try again later',
    initialDataError:
      'An error occurred when trying to fetch the initial data, try again later',
    generalInformation: 'General Information',
    optional: '(optional)',
    headquarterLocation: 'Headquarter Location',
    legalEntityName: 'Legal Entity Name',
    legalStructure: 'Legal Structure',
    legalCountry: 'Legal Country',
    legalState: 'Legal State',
    descriptionLabel: 'Description',
    descriptionPlaceholder: 'Add a description of your company',
    socialMedia: 'Social Media',
    industry: 'Industry',
    industryPlacholder: 'Select one or more industries',
    linkedinProfile: 'Linkedin Profile',
    linkedinProfilePlaceholder: 'Add your Linkedin profile',
    crunchbaseProfile: 'Crunchbase Profile',
    crunchbaseProfilePlaceholder: 'Add your Crunchbase profile',
    angellistProfile: 'Angellist Profile',
    angellistProfilePlaceholder: 'Add your Angellist profile',
    skipStep: 'Skip this step',
    title: 'We need some additional information',
  },
  setPassword: {
    title: 'Set your password',
    passwordLabel: 'Your password',
    passwordPlaceholder: 'Type a secure password',
    confirmPasswordLabel: 'Repeat your password',
    strength: 'Strength',
    errorMessage:
      'An error occurred when trying to save the password, try again later',
  },
  signupProgress: {
    aboutYourCompany: 'About your company',
    aboutYou: 'About you',
    setYourPassword: 'Set your password',
    startSetingYourAccount: 'Start setting up your account.',
    subtitle: 'A few clicks away from creating your new group.',
    accountAlmostDone: 'Your account is almost done!',
    typeformTitle: 'Please complete our request access form',
    welcome: 'Welcome to Clockwork Universe',
    welcomeLegend:
      'Before you get started using the app, we’ll need some more information to set up your account.',
    welcomeSubLegend:
      'Please sign up with Google or add your email in order to start with the process',
    selectGroupType: 'Select group type',
    signupWithGoogle: 'Sign up with Google',
    setupYourAccount: 'set up your account',
    letsGetStarted: "Let's get started!",
    enterEmail: 'Your email address',
    setupAccount: 'Set up your account',
    thankYouMessage: 'Thank you for verifying your email address',
    thankYouLegend:
      'Verifying your email is a simple way to prove that you’re a real user and also, makes your account more secure. ',
    thankYouLegendTwo:
      'We still need some other info in order to to create your account, {next} to continue.  ',
    plaseClickNext: 'please click next',
  },
  dashboard: {
    hi: '<icon></icon> Hi {userName}',
    morning: 'Good morning',
    evening: 'Good evening',
    afternoon: 'Good afternoon',
    portfolioCreatedAt: 'Created at: {date}',
    lastUpdatedAt: 'Last updated {date}',
    portfolioSummary: 'Portfolio Summary',
    topActiveCompanies: 'Top Active Companies',
    amountInvestedVsTotalValue: 'Amount Invested vs Total Value',
    amountInvested: 'Amount Invested',
    totalValue: 'Total Value',
    updates: 'Updates',
    lastMonthUpdates: 'Last Month Updates',
    totalUpdates: 'Total # Updates',
    zeroState: {
      addFirstCompany:
        'Add a company to the portfolio to start viewing information on the dashboard',
      addCompany: 'Add company',
      addPortfolioTitle:
        'Add an invest portfolio and start viewing information on the dashboard',
      generalInfoTitle: 'You don’t have any General Info. to show yet.',
      topAtiveCompaniesTitle: 'You don’t have any company to show yet.',
      amountVsTotalTitle: 'You don’t have any data to show yet.',
      totalUpdatesTitle: "You don't have any data to show yet",
      updatesTitle: "You don't have any Updates to view.",
      addInvest: 'Add Invest Portfolio',
    },
    dropdownOptions: {
      investPortfolio: 'Invest Portfolio',
      fundPortfolio: 'Fund Portfolio',
      pipeline: 'Pipeline',
      deal: 'Deal',
    },
  },
  fundDashboard: {
    commitedCapital: 'Committed Capital',
    capitalCalled: 'Capital Called',
    aggregateFundSummary: 'Aggregate Fund Summary',
    aggregateIndividualSummary: 'Aggregate Individual Summary',
    fundSummary: 'Fund Summary',
    individualFundSummary: 'Individual Fund Summary',
    unfundedCommitment: 'Unfunded Commitment',
    distributions: 'Distributions',
    proRataHoldingValue: 'Pro-Rata Holding Value',
    chart: {
      totalCommitedVsCapitalCalled: 'Total committed vs. capital called',
      amountInvestedVsTotalValue: 'Amount invested vs. total value',
      amountInvested: 'Amount Invested',
      totalCommitted: 'Total Committed',
      capitalCalled: 'Capital Called',
      totalValue: 'Total Value',
    },
    totalCommittedCapital: 'Total Committed Capital',
    totalCapitalCalled: 'Total Capital Called',
    tooltips: {
      commitedCapital:
        'The total capital that investors have committed to the fund.',
      capitalCalled:
        'The total capital invested into the fund by the investors.',
      unfundedCommitment:
        'The remaining capital committed but not yet called from the investors.',
      distributions:
        'The total capital returned from the fund to the investors.',
      proRataHoldingValue:
        'The proportionate holding value of the fund to each investor, defined as the aggregate holding value of the fund multiplied by the percentage of total capital contributed by an individual investor. Note: This value represents an estimate and could exclude performance fees, applicable taxes, and other adjustments by the fund manager.',
    },
  },
  connectedAccounts: {
    title: 'Connected Accounts',
    google: 'Connect with Google',
    differentAccounts:
      "The account you are trying to connect doesn't match your email account",
    legendNoAccounts: 'You have no accounts connected.',
    wouldYouLike: 'Would you like to:',
    disconnectAccount: 'Disconnect Account',
    setPasswordBeforeDisconnect:
      'Before we can disconnect your account, please make sure you have set a password.',
    confirmationTitle: 'Disconnect Google Account',
    confirmationHeader:
      'Before we can disconnect your account, please set a password for login.',
    confirmationBody:
      'You will need to use your email address {email} and password next time you sign in to Clockwork.',
    disconnect: 'Disconnect',
    password: 'Password',
    repeatPassword: 'Repeat Password',
    connectPassword: 'Connect Password',
    success: 'You have successfully disconnected your Google Account!',
    error:
      'An error occurred when trying to disconnect your google account, try again later',
    description:
      'Account protected with two-factor authentication. Add a code from your authenticator or a recovery code.',
  },
  dashboardTips: {
    tip0: '"An investment in knowledge pays the best interest" - Benjamin Franklin',
    tip1: '"Whatever you think, be sure it is what you think" - T.S. Eliot',
    tip2: '"Success is walking from failure to failure with no loss of enthusiasm" - Winston Churchill',

    tip3: '"A wise man will make more opportunities than he finds" - Sir Francis Bacon',
    tip4: '"Beware lest you lose the substance by grasping at the shadow" - Aesop',
    tip5: '"When in doubt, lean against the wind" - David Swensen',
    tip6: '"The expectations of life depend upon diligence; the mechanic that would perfect his work must first sharpen his tools" - Confucius',
    tip7: 'Tip: Keep track of individual investment details by uploading transaction documents to a portfolio company profile. Try adding brief detail to summarize the terms for easy access when you need it.	',
    tip8: 'Tip: Have you tried adding a note to a portfolio company profile for a founder call? It’s a great way to keep track of the key points of a conversation (metrics, key asks, etc.).	',
    tip9: 'Tip: Are you seeing your weekly digest? Every Friday at 10am eastern you receive an email with any updates made across the portfolio for the week.	',
    tip10:
      'Tip: Do you need to segment your investments? You can always add a separate portfolio (ex. Fund I, Fund II), on the Portfolios page!',
  },
  errorBoundary: {
    reloadPage: 'Reload Page',
    reportFeedback: 'Report feedback',
    title: 'We are having some problems trying to load this page.',
    legend:
      'Sorry about that. Please try refreshing and contact us if the problem persists.',
    details: 'Click for error details',
  },
  companyWebsite: {
    title:
      'The process to add a company profile and start storing updates is quick and easy. ',
    legend:
      'Simply provide the company’s website and if a profile does not already exist we’ll begin the process of creating one.',
    info: 'Don’t worry If the company doesn’t have a website, we’ll be adding functionality soon to support you with setting that up on your own. For now please contact us and we’ll help you with what you need.',
    label: 'Website URL',
    placeholder: 'https://www.companywebsite.com',
  },
  companyUpdates: {
    errorFetchingUpdates:
      'An error occurred when fetching the updates, try again later',
    today: 'Today',
    yesterday: 'Yesterday',
    noResultsTitle: 'No updates found for that date',
  },
  claimCompany: {
    title: 'Claim this company profile?',
    no: 'No',
    yes: 'Yes',
  },
  integrations: {
    title: 'Integrations',
    thirdPartyIntegrationsConnections: 'Third Party Integrations - Connections',
    connections: 'Connections',
    selectApplication: 'Select Application to connect with',
    xero: 'Xero',
    quickbooks: 'Quickbooks',
    connection: 'Connection',
    activeConnections: 'Active connections',
    connected: 'active',
    error: 'error',
    deleteIntegrationTitle: 'Remove {type} connection',
    deleteIntegrationText:
      'Are you sure you want to remove the {type} connection?',
    deleteIntegrationDescription: {
      xero: 'By doing so, you won’t be able to create new accounting updates or edit existing reports. Also all scheduled accounting updates will be removed from your calendar.',
      quickbooks:
        'By doing so, you won’t be able to create new accounting updates or edit existing reports. Also all scheduled accounting updates will be removed from your calendar.',
    },
    deleteDraftError:
      'An error occurred when trying to remove the {type} connection, try again later',
    xeroMessages: {
      connectionError: 'An error occurred connecting to Xero, try again later.',
      success: 'Your Xero account has been connected successfully.',
      deleteSuccess: 'Xero connection removed succesfully',
      access_denied: 'You must grant access to your Xero Account.',
    },
    quickbooksMessages: {
      connectionError:
        'An error occurred connecting to Quickbooks, try again later.',
      success: 'Your Quickbooks account has been connected successfully.',
      deleteSuccess: 'Quickbooks connection removed succesfully',
      access_denied: 'You must grant access to your Quickbooks Account.',
    },
    noResultsTitle: 'You have no active connections',
    statusError: 'An error occurred fetching connections, try again later.',
    status: {
      connected: 'Connected',
      pending: 'Pending',
      disconnected: 'Disconnected',
    },
    removeConnection: 'Remove connection',
    removeConnectionConfirmation: 'Yes, remove connection',
  },
  bulkImportModal: {
    title: 'Import CSV',
    instructionsOne: 'Drop your .CSV file',
    instructionsTwo: 'Or use the "Browse" button',
    instructionsTwoAlt: 'Or browse the file on your computer',
    help: 'If you need a sample, {downloadLink}',
    downloadSampleLink: 'download blank .CSV template here',
    uploadFile: 'Upload your file',
    downloadHere: 'download here',
    browse: 'Browse',
    importingCSVFile: 'Importing file {fileName} ',
    importingCSVFileLegend:
      'Sit back while we process your data. This will only take a couple of minutes. You can cancel this process at any time.',
    errorUploadingFile:
      'An error occurred when trying to upload the file, try again later',
    fileTypeError:
      'File extension is not .csv, please upload a csv file and try again',
    csvError:
      'An error occurred reading the CSV file, please double check if the CSV format is correct.',
    metricsCsvLoadError:
      'An error occurred importing the data points, please try again later.',
    investorsCsvLoadError:
      'An error occurred importing the investors, please try again later.',
    csvDateError:
      'There’s an issue with one or multiple dates added in this file. We recommend checking that it doesn’t contain any space or that the date format is the expected (MM/DD/YYYY).',
    fileEmptyError: 'The file is empty',
    atLeastOneTransaction: 'The CSV file is empty',
  },
  bulkImportDataPointsModal: {
    title: 'Import from CSV',
    instructionsOne: 'Drop your .CSV file to upload values to {metricName}',
    instructionsTwo:
      'Or you can also browse the file on your computer by clicking the button below',
    help: 'If you need a sample of our .CSV bulk template you can {here}',
    uploadFile: 'Upload your file',
    importingCSVFile: 'Importing file {fileName} ',
    importingCSVFileLegend:
      'Sit back while we process your data. This will only take a couple of minutes. You can cancel this process at any time.',
    errorUploadingFile:
      'An error occurred when trying to upload the file, try again later',
    fileTypeError:
      'File extension is not .csv, please upload a csv file and try again',
    csvError:
      'An error occurred reading the CSV file, please double check if the CSV format is correct.',
    addValues: 'Add Values',
    countImported: 'Successfully imported {count} values',
    metricValue: 'Metric value',
    metricDate: 'Metric date',
  },
  bulkImportInvestorsModal: {
    title: 'Import Investors',
    instructionsOne: 'Drop your .CSV file here to import investors',
    instructionsTwo: 'Or use the "Browse" button',
    browse: 'Browse',
    help: 'If you need a sample {sample}',
    downloadSampleLink: 'download blank .CSV template here',
    importingCSVFile: 'Importing file {fileName} ',
    importingCSVFileLegend:
      'Sit back while we process your data. This will only take a couple of minutes. You can cancel this process at any time.',
    countImported: 'Successfully imported {count} investors',
    addInvestors: 'Add Investors',
  },
  bulkImportInvestmentVehiclesModal: {
    title: 'Import Investment Vehicles',
    instructionsOne: 'Drop your .CSV file here to import investment vehicles',
    instructionsTwo: 'Or use the "Browse" button',
    browse: 'Browse',
    help: 'If you need a sample {sample}',
    downloadSampleLink: 'download blank .CSV template here',
    importingCSVFile: 'Importing file {fileName} ',
    importingCSVFileLegend:
      'Sit back while we process your data. This will only take a couple of minutes. You can cancel this process at any time.',
    countImported: 'Successfully imported {count} investment vehicles',
    addInvestmentVehicles: 'Add Investment Vehicles',
    investmentVehiclesCsvLoadError:
      'An error occurred importing the investement vehicles, please try again later.',
  },
  bulkImportInvestorsTransactionsModal: {
    help: 'If you need a sample {sample}',
    countImported: 'Successfully imported {count} transaction(s)',
  },
  importInvestorsTransactions: {
    info: 'These transactions will be added to this fund portfolio only.\nEveryone in your group will be able to view them',
  },
  importTransactions: {
    successMessage: '·  Successfully imported {count} transactions',
    infoMessage:
      'These transactions will be added to this portfolio only.{br} Everyone in your group will be able to view them.',
    groupView: 'my group will view',
    onlyPortfolio: 'only into this Portfolio',
    transactionTitle: 'Transaction Title',
  },
  metrics: {
    cwUniverseCalculated: 'CW Universe Calculated',
    thisMayTakeSeconds: 'This may take a few seconds',
    processingNewData: 'Processing new data..',
    noGroupFound: 'Group handle not found',
    errorDenyingAllMetrics:
      'An error occurred when trying to deny the metrics, try again later',
    errorApproveAllMetrics:
      'An error occurred when trying to approve the metrics, try again later',
    yesDenyAll: 'Yes, Deny All {count}',
    createMetric: 'Create Metric',
    denyAllRequests: 'Deny all requests',
    denyAllQuestion:
      'Are you sure you want to deny all these groups access to your metric data?',
    denyAllLegend:
      'By performing this action, you will deny access to the requested metrics, but you cannot prevent them from asking for the data again.',
    addToExistingMetric: 'Add to existing metric',
    approveAllCount: 'Approve All',
    addTo: 'Add to',
    action: 'Action',
    metricName: 'Metric Name',
    requestedBy: 'Requested by',
    approveAll: 'Approve All',
    denyAll: 'Deny All',
    approveSelected: 'Approve Selected ({count})',
    denySelected: 'Deny Selected ({count})',
    exportAll: 'Export All',
    exportMetricsError:
      'An error occurred when trying to export the metrics, try again later',
    exportMetricError:
      'An error occurred when trying to export the metric, try again later',
    loadingMetrics: 'Loading more metrics...',
    metricNameSort: 'Metric Name',
    metricUpdateSort: 'Metric Update',
    recentlyUsedGroups: 'Recently used groups',
    company: 'Company',
    founderInformation: 'Founder Information',
    messageSent: 'Message sent ({date})',
    receiveDataFrom: 'Receive data from',
    since: 'Since',
    frequencyValue: '({frequency})',
    archiveDataPoints: 'Archive values',
    unarchiveDataPoints: 'Unarchive values',
    shareDataPoints: 'Share values',
    addDataPoints: 'Add value',
    importCsv: 'Import CSV',
    exportCsv: 'Export CSV',
    none: 'None',
    enterAtLeastOneGroup: 'Enter at least one group',
    shareValues: 'Share Values ({count}) - ',
    founderDeniedMetric:
      '{companyName} denied access to the metric data. If you think they should give your group access, please re-send your request.',
    requestToFounderFailed:
      'An error occurred when sending the request to the founder team, try again later',
    sendRequest: 'Send request',
    requestSent: 'Request sent:',
    waitingForFounderResponse: 'Waiting for founder response',
    errorFetchingGroups:
      'An error occurred when trying to get the groups, try again later',
    errorDeletingDataPoints:
      'An error occurred when trying to delete the data point, try again later',
    archivedValues: 'Archived Values ({count})',
    archiveValue: 'Archive Value',
    editValue: 'Edit value',
    shareWith: 'Share with',
    verifyValues: 'Verify these are the values you would like to share:',
    date: 'Date',
    value: 'Value',
    sharedWithPlaceholder: 'Add group',
    moveToMainList: 'Move to main list',
    search: 'Search...',
    allMetricValues: 'All metric values',
    turnOnFounderData: 'Turn ON to receive data from {companyName}. ',
    turnOffFounderData: 'Turn OFF to stop receiving data from {companyName} ',
    errorChangingReceivingData:
      'An error occurred when toggling the founder data option, try again later',
    addMilestone: 'Add Milestone',
    new: 'New',
    addAsNewMetric: 'Add as new metric',
    addMetricToExistingMetric: 'Add {metricName} data to Existing Metric',
    selectMetric: 'Select metric',
    general: 'General',
    milestones: 'Milestones',
    settings: ' Settings',
    from: 'From',
    founderData: 'Founder Data',
    trackingSince: 'Tracking since',
    lastValue: 'Last Value',
    requestDataFromCompanies: 'Request data from companies',
    includeHistoryData: 'Include historical metric data',
    errorLinkingMetric:
      'An error occurred trying to add group to existing metric, try again later',
    metricLinkedSuccess: 'Group added to existing metric successfully',
    addGroupNameTo: 'Add {groupName} to...',
    approveMetricGroup: 'Approve {groupName} Access',
    selectSharedData: 'Select how you want to share the data',
    createNewMetric: 'Create a new metric',
    createNewMetricDesc:
      'Create a new metric with the information the group requests.',
    addGroupToExistingMetric: 'Add group to existing metric',
    addGroupToExistingMetricDesc:
      'Add the group to an existing metric. You can choose to share your historical data or from today.',
    requestMetricsError:
      'An error occurred when trying to fetch the requested metrics, try again later',
    valuesSharedSuccessfully: 'Values have been shared successfully',
    requestingAccess: 'is requesting access to {metricName}',
    requests: 'Requests',
    pendingRequests: 'Pending ({count})',
    oldRequests: 'Old Requests',
    viewMetricDetails: 'View Metric Details',
    addCompanyMetric: 'Add Metric',
    deleteGroupMilestone: 'Delete milestone',
    save: 'Save',
    metric: 'Metric',
    metrics: 'Metrics',
    milestoneTitle: 'Milestone',
    milestone: 'Milestone',
    setMilestone: 'Set Milestone',
    editMilestone: 'Edit Milestone',
    companyMetrics: 'Company Metrics',
    groupMilestone: 'Group Milestone',
    setGroupMilestone: 'Set Group Milestone',
    sharedMetric: 'Shared Metric',
    groupFounderGroups:
      '(This metric can be updated by your Group and by the Founder Group)',
    yourGroupOnlyParenthesis: '(This metric can only be updated by your Group)',
    valueAddedByFounderHeader: 'This value was added by the founder group.',
    valueAddedBySystem: 'This value is managed by Clockwork Universe.',
    valueAddedByFounderDesc:
      '{header} {br} You cannot edit the value, but you can remove it from your list in case you need it.',
    valueAddedBySystemDesc:
      '{header} {br} You cannot edit the value, but you can archive it from your list in case you need it.',
    deleteValue: 'Delete Value',
    removeValue: 'Remove Value',
    requestedTo: 'Requested to:',
    logs: 'Log',
    addNewMetric: 'Add New Metric',
    addMetric: 'Add Metric',
    receivingDataFromCompany: 'Receiving data from:',
    portfolios: 'Portfolio(s)',
    frequency: 'Frequency',
    requestedDate: 'Date',
    lastUpdate: 'Last Update',
    goal: 'Goal',
    state: 'Status',
    editGroupGoal: 'Edit Group Goal',
    addValue: 'Add Value',
    metricValueDate: 'Metric Value Date',
    whenShouldItBeReached: 'When should it be reached',
    shareMilestoneWith: 'Share milestone with {company}',
    editMetric: 'Edit metric settings',
    metricLog: 'Metric Log',
    goToCompanyPage: 'Go to Company Page',
    removeMetric: 'Remove Metric',
    outOfDate: 'Out of date!',
    deleteMetricError:
      'An error occurred when trying to delete the metric, try again later',
    updateMetricError:
      'An error occurred when trying to update the metric, try again later',
    undo: 'Undo',
    title: 'Metric',
    titlePlaceholder: 'Write or select the metric you need',
    cancel: 'Cancel',
    add: 'Add',
    companiesOrPortfolios: 'Companies or Portfolios',
    holdingsFundsOrPortfolios: 'Holdings, Funds or Portfolios',
    weekly: 'Weekly',
    monthly: 'Monthly',
    frequencyPlaceholder: 'Select...',
    quarterly: 'Quarterly',
    annually: 'Annual',
    metricType: 'Metric Type:',
    privateMetric: 'Internal Metric',
    ofMonth: ' of the Month',
    firstFifteenDays: 'First 15 days of the next Year',
    lastFifteenDays: 'Last 15 days of the current Year',
    firstSevenDays: 'First 7 days of the next Quarter',
    lastSevenDays: 'Last 7 days of the current Quarter',
    customerAcquisitionCost: 'Customer Acquisition Cost (CAC)',
    customerChurnRate: 'Customer Churn Rate',
    customerLifetimeValue: 'Customer Lifetime Value (CLV)',
    customerSatisfactionRetention: 'Customer Satisfaction & Retention',
    numberOfCustomers: 'Number of Customers',
    revenue: 'Revenue',
    expenses: 'Expenses',
    operatingCashFlow: 'Operating Cash Flow',
    netIncome: 'Net Income',
    netProfit: 'Net Profit',
    sales: 'Sales',
    salesGrowth: 'Sales Growth',
    newLeadsProspects: 'New Leads/Prospects',
    saleConversionRate: 'Sale Conversion Rate',
    companiesFounderGroup: 'Companies Founder Group',
    myGroup: 'My Group',
    message: 'Message',
    requestedMetrics: 'Requested Metrics',
    metricsAndRequests: 'Metrics & Requests',
    approve: 'Approve',
    approveAndLink: 'Approve & Link',
    deny: 'Deny',
    daysLeft: 'You have {countDays} to {action}',
    dueToday: 'Update is due today - {action}',
    dueTomorrow: 'Update is due tomorrow - {action}',
    days: 'days',
    updateNewValue: 'update new value',
    metricOutOfDate: 'Metric out of date - {action}',
    updateValue: 'Update Value',
    metricDetails: 'Metric details',
    metricValue: 'Metric value',
    metricDate: 'Metric date',
    selectDate: 'Select date',
    addGroups: 'Add Group',
    setNewMetricValue: ' New value',
    metricDenied: '{groupName}’s access to {metricName} has been denied.',
    sharedWith: 'Shared with',
    noValues: 'No values yet.',
    noMilestone: 'No milestone yet.',
    errorCreatingMetric:
      'The metric could not be created, please try again later.',
    errorRemovingMetric:
      'The metric could not be deleted, please try again later.',
    errorGettingMetric:
      'There was an error fetching the metric, please try again later.',
    metricListError: 'The metrics could not be fetched, please try again later',
    yourGroupOnly: 'Your group only',
    loadMoreMetrics: 'Load more metrics',
    noResultsTitle: 'Sorry we couldn’t find any matches for “{searchText}”',
    noResultsLegend:
      'Please try searching with another Holding, Portfolio or Metric',
    metricSettings: 'Metric Settings',
    companiesMetrics: 'Companies Metrics',
    holdingMetrics: 'Holdings Metrics',
    companyPage: 'Company Page',
    deleteMetric: 'Delete',
    name: 'Metric Name',
    namePlaceholder: 'Enter the name of the metric',
    approveMetric: 'Approve',
    denyMetric: 'Deny',
    requestedOn: 'Requested on',
    daysRemaining: '{countDays, plural, one {# day} other {# days}} remaining',
    editGroupMilestone: 'Edit Group Milestone',
    milestoneLegend:
      'Without clear objectives, your metrics are useless. Setting group milestones will help to develop tactics to meet your target.',
    milestoneValue: 'Milestone Value',
    addYourMilestone: 'Add your metric group milestone',
    milestoneDate: 'Date',
    alertMeWhen: 'Alert me when',
    hitAlert: 'Milestone has been hit',
    percentAlert: 'Milestone hits 70%',
    seenByGroup: 'Group Milestones will be only seen by your group.',
    milestoneLabel: 'Milestone: {value}',
    before: 'Before {value}',
    addMessagePlaceholder: 'Add a message to founders (200 characters)',
    selectHoldingFundOrPortfolio: 'Select holding, fund or portfolio',
    activeMilestones: 'Active',
    createdByInvestorMilestones: 'Created by investors',
    reachedMilestones: 'Reached',
    expiredMilestones: 'Expired',
    milestonesOnlySeenByGroup:
      'Group Milestones will be only seen by your group.',
    milestoneCannotBeZero: 'Milestone value can not be 0',
    noMetrics: 'No metrics.',
    receivingData: 'Receiving data',
    update: {
      updateAll: 'Update All',
      updateAllMetrics: 'Update all',
      updateCompanyMetricsDescription:
        'Add your values manually or paste them from your spreadsheet',
      addMultipleDates: 'Add Multiple Dates',
      addValue: 'Add value',
      selectMetric: 'Select metric',
      selectDate: 'Select date',
      groupSharingDisclaimer:
        'Updating all at once shares each data point with selected parties added to the “share with” metric setting.',
      dismissMessage: 'Dismiss message',
      noFutureDatesAllowed: 'Data points with future dates are not allowed.',
    },
    create: {
      addMetricsTitle: 'Add Metrics',
      addMetrics: 'Add Metrics',
      save: 'Add Metrics',
      ok: 'Ok',
    },
    ...metrics,
  },
  zeroState: {
    dismissMessage: 'Dismiss Message',
    chatWithSupport: 'Chat with support',
    readFeatureGuide: 'Read feature guide',
    readGuide: 'Read guide',
    needHelp: 'Need help?',
    metrics_data: {
      title: 'No values yet',
      text: ' ',
    },
    followed_holdings: {
      title: 'You have no holdings added to this Pipeline',
      text: 'To start following holdings click on the “Add Holding” button above.',
    },
    metrics_unarchived_data: {
      title: 'No values',
      text: ' ',
    },
    lists: {
      title: 'Create a List!',
      text: 'Click on the button "Create List" above to organize the groups and individuals who will receive your updates. You can create as many lists as you like (for example, advisors, angels, seed investors, etc.).',
    },
    integrations: {
      title: 'Connect with a Third Party App',
      text: 'Connecting your business systems (e.g., accounting and finance tools) opens new possibilities for effortless information sharing in Clockwork Universe. Choose from the available apps in the "Connections" dropdown above.',
    },
    milestones: {
      title: 'Your group has no milestones for this metric.',
      text: ' ',
    },
    active_milestones: {
      title: 'Your group has no active milestone for this metric.',
      text: ' ',
    },
    pending_requests: {
      title: 'You don’t have any pending invitation',
      text: ' ',
    },
    client_metrics: {
      title: 'No metrics yet',
      text: 'Use the searchbar above to add metrics to your sandbox and compare them easily.',
    },
    founder_metrics: {
      title: 'Start tracking and sharing your metrics',
      text: 'Metrics provide concise, clear, and relevant information that is reachable and actionable. Create and share a new metric by clicking on “Add Metrics”.',
    },
    portfolio_metrics: {
      title: 'There are no metrics to show for this portfolio',
      text: 'Metrics provide concise, clear, and relevant information that is reachable and actionable. Create and request a new metric by clicking on "Add Metric”. ',
    },
    founder_metrics_no_tracking: {
      title: 'You are not tracking any metric yet',
      text: 'You will need to create a new metric or approve metric requests from your investors. Please click on "Create new metric" or go to the "Requested Metrics" tab and approve your requests. ',
    },
    updates_feed: {
      title: 'No updates to show',
      text: 'This group may not have content to share or may be private.',
    },

    company_updates_feed: {
      title: 'Publish an Update',
      text: 'Click on the button "Create Update" and select the option that best suits what you\'d like to share with your investor audience. One step closer to smoother investor communications.',
    },
    dashboard: {
      title: 'Add an Invest Portfolio',
      text: 'Welcome to your investor dashboard. To start viewing investment information here, click on the Portfolios tab to add your first Portfolio. You are one step closer to smoother portfolio monitoring.',
    },
    fund_dashboard: {
      title: 'No data yet.',
      text: 'Come back later or, in the meanwhile, check out your group information and verify everything is correct.',
    },
    readonly_dashboard: {
      title: 'Add a Track Portfolio',
      text: 'Welcome to your investor dashboard. To start viewing investment information here, click on the Portfolios tab to add your free Track Portfolio. You are one step closer to smoother portfolio monitoring.',
    },
    empty_portfolio: {
      title: 'Add some data to your Portfolio',
      text: 'To start viewing investment information here, go to your Portofio page and add some data. You are one step closer to smoother portfolio monitoring.',
    },
    portfolios: {
      title: 'Create your first Portfollio',
      text: 'Create an Invest Portfolio to organize your active investments, or a Track Portfolio to monitor companies in your pipeline. Click on the button "Create Portfolio" above, and select the option that best suits you.',
    },
    company_profile_metrics: {
      title: 'No metrics to show',
      text: 'Metrics provide valuable insights. Create or request a new metric by clicking on “Add Metric” button.',
    },
    emails: {
      title: 'Create an Email!',
      text: 'Click on the button "Compose Email" and follow the simple step-by-step tool.',
    },
    draft_emails: {
      title: 'You have not saved any Email Drafts.',
      text: 'Saving a draft allows you to keep an email that is not yet ready to be sent.',
    },
    scheduled_emails: {
      title: 'You have no Scheduled Emails.',
      text: 'Emails in “Scheduled Emails” will be sent at the scheduled date and time.',
    },
    scheduled_updates: {
      title: 'You don’t have any updates scheduled yet',
    },
    portfolio_holdings: {
      title: 'You have no holdings added to this Portfolio',
      text: 'To start tracking your Portfolio data, please add your portfolio holdings. Add a holding by clicking on the “Add Holding" button above.',
    },
    fund_holdings: {
      title: 'No holdings have been added to this fund.',
      text: 'To start tracking fund data, please add fund holdings. Add a holding by clicking on the "Add Holding" button above.',
    },
    investor_fund_holdings: {
      title: 'No holdings have been added to this fund.',
    },
    all_investments: {
      title: 'You have no holdings added to this Portfolio',
      text: 'To start tracking your Portfolio data, please add holdings to your portfolios',
    },
    fund_investors: {
      title: 'You have not added any investors to this fund',
      text: 'To start tracking investor data, add investors by clicking on the "Add Investor" button or import your investor data as a .CSV.',
    },
    investor_members: {
      title: 'No members yet.',
      text: 'Click on the “Add Member” button to invite a new member to this investor group. You are one step closer to smoother investor communications.',
    },
    investor_funds_deals: {
      title: 'No funds or deals yet.',
      text: 'Come back later or, in the meanwhile, check your group information and verify everything is correct.',
    },
    companiesFounderGroup: 'Companies Founder Group',
    myGroup: 'My Group',
    message: 'Message',
    investor_funds: {
      title: 'This investor has not been included in a fund yet.',
      text: 'To start viewing data, you need to add this investor to a fund.',
    },
    vehicle_funds: {
      title: 'This investment vehicle has not been included in a fund yet.',
      text: 'To start viewing data, you need to add this investment vehicle to a fund.',
    },
    vehicles: {
      title: 'No investment vehicles yet.',
      text: 'To start viewing data, you need to add this investor investment vehicles.',
    },
    no_vehicles: {
      title: 'You have no investment vehicles yet!',
      text: 'Make sure to include at least one investment vehicle per investor. To add a new investment vehicle click on “Add New” or import from a CSV.',
    },

    no_investors: {
      title: 'You have no investors yet!',
      text: 'Add all your investors and streamline your fund porfolio information. To add a new investor click on  “Add New” or import from a CSV.',
    },
    no_investors_found: {
      title: 'No investors found.',
      text: 'The active filters are hiding all the investors.',
      clearFilters: 'Clear filters',
    },
    no_vehicles_found: {
      title: 'No investment vehicles found.',
      text: 'The active filters are hiding all investment vehicles.',
      clearFilters: 'Clear filters',
    },
    no_fund_holding_results: {
      title: 'Sorry we couldn’t find any matches for "{search}"',
      text: 'Please try searching with another Company or Fund',
    },
    fund_portfolios: {
      title: 'No funds yet.',
      text: 'Come back later or, in the meanwhile, check out your group information and verify everything is correct.',
    },
    no_investor_fund_list_results: {
      title: 'Sorry we couldn’t find any matches for "{search}"',
      text: 'Please try searching with another Fund name',
    },
    no_portfolio_list_results: {
      title: 'Sorry we couldn’t find any matches for "{search}"',
      text: 'Please try searching with another Portfolio name',
    },
    no_holdings_results: {
      title: 'Sorry we couldn’t find any matches for "{search}"',
      text: 'Make sure the spelling is correct or try using another name.',
    },
    no_holdings_found: {
      title: 'No holdings found.',
      text: 'The active filters are hiding all holdings.',
      clear_filters: 'Clear filters',
    },
    no_fund_holdings: {
      title: 'No holdings yet.',
      text: 'Come back later or, in the meanwhile, check out your group information and verify everything is correct. ',
    },
    no_fmv_history: {
      text: 'Your group has no Carrying Value',
    },
    files: {
      title: 'No files yet.',
      text: 'Drop files anywhere to upload or wait until someone shares an update with you to view content here.',
    },
    no_files_found: {
      title: 'Sorry we couldn’t find any matches for “{search}”',
      text: 'Make sure the spelling is correct or try using another word.',
      clear: 'Clear filters',
    },
    no_unreconciled_found: {
      title: 'No files found.',
      text: 'The active filters are hiding all files.',
      clear: 'Clear filters',
    },
    modal_files: {
      title: 'No files yet.',
      text: 'To add files from your computer, click on “Browse my computer”.',
    },
    no_files_modal_found: {
      title: 'Sorry we couldn’t find any matches for “{search}”',
      text: 'Make sure the spelling is correct or try using another word.',
    },
    no_files_modal_unreconciled_found: {
      title: 'No unreconciled files found.',
      text: 'The active filters are hiding all files.',
      clear: 'Clear filters',
    },
    holdings: {
      ...holdings.zeroState,
    },
    no_lists_results: {
      title: 'Sorry we couldn’t find any matches for "{search}"',
      text: 'Make sure the spelling is correct or try using another name.',
    },
    no_chats: {
      title: 'Welcome, {userName}',
      text: 'Select a group member or user and\nstart a new chat to get the conversation going.',
    },
    chat_search: {
      title: 'Search for messages, chats or participants',
    },
    chat_search_results: {
      title: 'Sorry we couldn’t find any matches for “{search}”',
      text: 'Want to try a different search?',
    },
    no_investments: {
      title: 'There are no portfolios yet.',
      text: 'Add a portfolio to access powerful tools and features for tracking and analyzing your investments.',
    },
    publish_update: {
      title: 'Publish an Update!',
      text: 'Explore all your created updates about holdings, pipelines, and your invest portfolio. Create updates in profiles or portfolios, and find them all in one place.',
    },
    empty_investment_filter: {
      title: 'Empty Filter',
      text: 'Criteria have been removed, leaving this filter empty.',
    },
    updates_table: {
      title: 'You have no updates yet',
      text: 'Discover all updates for this portfolio here',
    },
    no_search_results: {
      title: 'Sorry we couldn’t find any matches for “{searchText}”',
      text: 'Please try searching with another Holding, Portfolio or Metric',
    },
    selected_metrics: {
      title: 'No selected metrics yet',
      text: 'Use the searchbar above to add metrics to your sandbox and compare them easily.',
    },
    sandboxed_metrics: {
      title: "Select the metrics to compare from the 'All Metrics' tab",
      text: 'You can add up to 8 metrics.',
    },
    sandboxed_metrics_from_investments: {
      title: 'Add the metrics to compare from the searchbar below',
      text: 'You can add up to 8 metrics.',
    },
  },
  showMetrics: {
    date: 'Date',
    value: 'Value',
    requested: 'Requested:',
    trackSince: 'Tracking since:',
    approve: 'Approve',
    deny: 'Deny',
    sharedWith: 'Shared with',
    yourGroupOnly: 'Your group only',
  },
  editMetric: {
    error: 'An error occurred when trying to save the metric, try again later.',
    milestoneUpdatedSuccess: 'Metric milestone updated successfully',
    milestoneCreatedSuccess: 'Metric milestone created successfully',
    milestoneDeletedSuccess: 'Metric milestone deleted successfully',
    milestoneDeletedError:
      'An error occurred when trying to delete the milestone, try again later.',
  },
  shareUpdate: {
    title: 'Share Update',
    copyLink: 'Copy link',
    linkCopied: 'Link copied to your clipboard',
  },
  university: {
    title: 'Getting Started with Clockwork Universe',
    subTitle: 'Follow these steps to streamline your portfolio operations.',
    completedByYou: 'Completed by you',
    completedByGroup: 'Completed by someone in your group',
    progress: 'Setup Progress',
    progressCompleted: 'Setup completed!',
    start: 'Run tour',
    skip: 'Set as Completed',
    redo: 'Redo tour',
    readGuide: 'Read guide',
    tryItMyself: 'Try it myself',
    investorPortfolioRequired: 'You need to create an Invest Portfolio first.',
    actionTitles: {
      create_portfolio: 'Create a Portfolio',
      company_index_page: 'Find Holdings',
      find_Holdings: 'Find Companies',
      create_update: 'Create an Update',
      create_transaction_in_portfolio: 'Monitor Your Investments',
      create_metric: 'Track Company Metrics',
      user_settings_page: 'Explore Your Settings',
      need_help: 'Need More Help?',
    },
    actionDescriptions: {
      create_portfolio:
        'Go to the Portfolios page create an Invest or Track portfolio.',
      company_index_page: 'Go to the Holdings page and search for a holding',
      create_update:
        'Go to a holding page and create an update on their profile.',
      create_transaction_in_portfolio:
        'Add a transaction on your Invest portfolio.',
      create_metric:
        'Go to the Metrics page and create or request a new metric.',

      user_settings_page: 'Visit your Group and User Settings',
      need_help:
        'Leave us feedback or contact support by clicking on the main menu “Need help?”.',
    },
  },
  investorSignup: {
    welcome: 'Welcome to Clockwork Universe',
    getStarted: 'Get started with Clockwork Universe',
    alreadyHaveAccount: 'Already have an account? {link}',
    signin: 'Sign in',
    workEmailAddress: 'Email Address',
    addYourWorkEmail: 'Add your email...',
    orSignupWith: 'Or sign up with',
    continue: 'Continue',
    yourName: 'Your name',
    addYourName: 'Add your name',
    tellUsAboutYourOrganization: 'Tell us about your investment organization.',
    groupPhoto: 'Group photo',
    companyPhoto: 'Company Logo',
    groupNamePlaceholder: 'Add your group name',
    createGroupError: 'An error occurred creating your group, try again later.',
    firstName: 'First name',
    lastName: 'Last name',
    addYourFirstName: 'Add your first name',
    addYourLastName: 'Add your last name',
    password: 'Password',
    addPassword: 'Add password',
    repeatPassword: 'Repeat Password',
    signup: 'Sign up',
    tos: 'By signing up, I agree to Clockwork’s {termsOfService} and {privacyPolicy}',
    termsOfService: 'Terms of Service',
    privacyPolicy: 'Privacy Policy',
    almostDone: "You're almost done!",
    almostDoneLegend:
      'An email has been sent to “{email}” to verify your email address. Click the button or link in the email to complete the account setup.',
    done: 'Done',
    almostDoneHelp1:
      'If you didn’t receive an email from us within 15 minutes, please check your SPAM folder.',
    almostDoneHelp2: 'If there is still no email - {link}',
    clickHere: 'click here to resend the email',
    emailHasBeenTaken:
      'The email has already been taken, please provide another one',
    groupHasBeenTaken:
      'The group name has already been taken, please provide another one',
    buyNow: 'Buy Clockwork Universe Now',
    startTrial: 'Start 14-day Free Trial',
    fourteenDayTrial: '14-day Free Trial',
    emailAlreadyInUse:
      'Looks like this email is already in use. Please <link>sign in</link> or use a different one.',
  },
  readOnlyMode: {
    toggleFounderData: `For turning ON or OFF your founder data, you will need to upgrade your account. For more information, get in touch at ${emails.UPGRADE}.`,
    addUpdates: `For adding updates, upgrade your account. \nFor more information, get in touch at ${emails.UPGRADE}.`,
    addMetrics: `For adding or requesting metrics, upgrade your account. For more information, get in touch at ${emails.UPGRADE}.`,
    addMetricValues: `For adding a metric value, upgrade your account. \nFor more information, get in touch at ${emails.UPGRADE}.`,
    updateAllMetrics: `For updating all metrics, upgrade your account. \nFor more information, get in touch at ${emails.UPGRADE}.`,
    importMetricCsv: `For importing metric data, upgrade your account. \nFor more information, get in touch at ${emails.UPGRADE}.`,
    exportMetricCsv: `For exporting metric data, upgrade your account. \nFor more information, get in touch at ${emails.UPGRADE}.`,
    editMetricValues: `For editing a metric value, upgrade your account. \nFor more information, get in touch at ${emails.UPGRADE}.`,
    setMilestone: `For adding milestones, upgrade your account. For more information, get in touch at ${emails.UPGRADE}.`,
    editMilestone: `For editing milestones, upgrade your account. For more information, get in touch at ${emails.UPGRADE}.`,
    deleteMetric: `For deleting a metric, upgrade your account. For more information, get in touch at ${emails.UPGRADE}.`,
    viewMetricDetails: `For viewing metric details, upgrade your account. For more information, get in touch at ${emails.UPGRADE}.`,
    upgradeYourPlan: 'Upgrade your plan',
    here: 'here',
    turnIntoInvest:
      '{upgradeYourPlan} to take portfolios to the next level. More information {here}.',
    reshare: '{upgradeYourPlan} to reshare updates. More information {here}.',
    bannerTitle:
      'Clockwork Universe is so much more than what you’ve seen so far',
    bannerUpgrade:
      'Upgrade your account to take your portfolio operations to the next level.',
    bannerGetInTouch: 'Get in touch to learn more at {link}',
    upgradeLink: `${emails.UPGRADE}`,
    universityDisabled: `To access this feature, you need to upgrade your account. For more information, get in touch at ${emails.UPGRADE}.`,
  },
  unverifiedWidget: {
    title: 'Your account is being verified',
    description:
      'Until we verify you are a member of {groupName}, your functionality will be limited. Verification typically takes 24 hours.',
  },
  imagePicker: {
    changeImage: 'Change Image',
    removeImage: 'Remove Image',
    cancel: 'Cancel',
    save: 'Save',
    browseImage: 'Browse Image',
    errorSavingImage:
      'An error occurred when trying to save the image, please try again later',
    errorRemovingImage:
      'An error occurred when trying to remove the image, please try again later',
  },
  metaTitles: {
    signIn: 'Sign In | Clockwork Universe',
    signUp: 'Sign Up | Clockwork Universe',
    dashboard: 'Dashboard | Clockwork Universe',
    update: {
      accounting: 'Accounting',
      announcement: 'Announcement',
      document: 'Document',
      note: 'Note',
      transaction: 'Transaction',
      report: 'Email',
    },
    storage: 'Storage | Clockwork Universe',
    additionalInformation: 'Additional Information | Clockwork Universe',
    updates: 'Updates | Clockwork Universe',
    editTransaction: 'Edit Transaction | Clockwork Universe',
    editAccounting: 'Edit Accounting | Clockwork Universe',
    createAnnouncement: 'Create Announcement | Clockwork Universe',
    createEmail: 'Create Email | Clockwork Universe',
    createDocument: 'Create Document | Clockwork Universe',
    createNote: 'Create Note | Clockwork Universe',
    updateNote: 'Update Note | Clockwork Universe',
    createAccounting: 'Create Accounting | Clockwork Universe',
    createTransaction: 'Create Transaction | Clockwork Universe',
    updateTitle: '{title} | {entityName} | Clockwork Universe',
    editUpdateTitle: 'Edit {title} | {entityName} | Clockwork Universe',
    reshareUpdateTitle: 'Reshare {title} | {entityName} | Clockwork Universe',
    emails: 'Emails | Clockwork Universe',
    metrics: 'Metrics | Clockwork Universe',
    createMetric: 'Add Metric | Clockwork Universe',
    metricDetail: '{name} | Clockwork Universe',
    generalInformation: 'General Information | Clockwork Universe',
    groupManagement: 'Group Management | Clockwork Universe',
    listManagement: 'List Management | Clockwork Universe',
    companyProfile: 'Company Profile | Clockwork Universe',
    integrations: 'Integrations | Clockwork Universe',
    userInformation: 'User Information | Clockwork Universe',
    connectedAccounts: 'Connected Accounts | Clockwork Universe',
    notifications: 'Notifications | Clockwork Universe',
    loginAndSecurity: 'Login & Security | Clockwork Universe',
    company: '{name} | Clockwork Universe',
    discover: 'Discover | Clockwork Universe',
    addNew: 'Add New | Clockwork Universe',
    portfolios: 'Portfolios | Clockwork Universe',
    portfolioDetail: '{name} | Clockwork Universe',
    addCompany: 'Add Company | Clockwork Universe',
    privateProfile: 'Private Profiles | Clockwork Universe',
    portfolio: 'Portfolio | Clockwork Universe',
    fundProfile: 'Fund Profile | Clockwork Universe',
    investorManagement: 'Investor Management | Clockwork Universe',
    createInvestmentVehicle: 'Create Investment Vehicle | Clockwork Universe',
    editInvestmentVehicle: 'Edit Investment Vehicle | Clockwork Universe',
    createInvestor: 'Create Investor | Clockwork Universe',
    editInvestor: 'Edit Investor | Clockwork Universe',
    investorDetails: 'Investor Details  | Clockwork Universe',
    investmentVehicleDetails:
      'Investment Vehicle Details  | Clockwork Universe',
    fundHoldings: 'Fund Holdings | Clockwork Universe',
    funds: 'Funds | Clockwork Universe',
    forgotPassword: 'Forgot Password | Clockwork Universe',
    resetPassword: 'Reset Password | Clockwork Universe',
    investorProfile: 'My Investor Profile | Clockwork Universe',
    fundFiles: 'Fund Files | Clockwork Universe',
    files: 'Files | Clockwork Universe',
    chat: 'Chat | Clockwork Universe',
    profile: '{profileName} | Clockwork Universe',
    organizationProfile: 'Organization Profile | Clockwork Universe',
    investments: 'Investments | Clockwork Universe',
    subscriptionsAndBilling: 'Subscriptions and Billing | Clockwork Universe',
    pipelines: 'All Following Holdings | Clockwork Universe',
    createNewGroup: 'Create New Group | Clockwork Universe',
  },
  analytics: {
    loadingAnalytics: 'Loading analytics...',
    user: 'User',
    userGroups: 'User Group(s)',
    date: 'Date',
    lastActivity: 'Last Activity',
    directShareTooltip: 'Access by Direct Share',
    publicShareTooltip: 'Access by Public Update',
    reshareTooltip: 'Access by Reshare',
    viewedUpdate: 'Viewed update',
    viewedViaReshare: 'Viewed via Reshare',
    resharedUpdate: 'Reshared update',
    resharedUpdateWith: 'Reshared update with {groups}',
    uniqueViews: 'Unique Views',
    totalViews: 'Total Views',
    loadMoreAnalytics: 'Load more analytics',
    viewDetails: 'View Details',
    downloadFile: 'Download File',
    added: 'Added',
    attachedFiles: 'Attached Files ({countAttachments})',
    noResultsLabel: 'There are no views on this update yet.',
    noResultsLegend: 'Came back later for details about your update views.',
    fileDetails: 'File Details',
    today: 'Today',
    yesterday: 'Yesterday',
    totalDownloads: 'Total Downloads: {count}',
    lastDownloadDate: 'Last download date',
    downloads: 'Downloads',
    attachmentAnalyticZeroStateTitle: 'This file has no activity',
    attachmentAnalyticZeroStateDescription:
      'Check this file later for changes on its views and downloads.',
  },
  customLogin: {
    title: 'Log In Customization',
    customLoginUrl: 'Custom log in URL',
    mainLogo: 'Main Logo',
    browseImage: 'Browse Image',
    changeImage: 'Change Image',
    mainLogoDesription:
      'Upload your logo without any margin; this will help to position it correctly. The image should be no larger than 600 x 600 pixels.',
    accentColor: 'Accent Color',
    bigImage: 'Big image',
    bigImageDescription:
      'You can leave this section empty and use your accent color with the Clockwork Universe logo, or add an image here and use it as background. The image should be at least 1440 x 2048 px.',
    default: 'default',
    purple: 'purple',
    green: 'green',
    red: 'red',
    yellow: 'yellow',
    custom: 'custom',
  },
  investorManagement,
  fundProfile,
  shareFundSettings,
  holdings,
  profiles,
  files,
  chat,
  investments,
  signup,
  subscriptions,
  spreadsheet,
  onboardingModal,
  pushNotifications,
  celeste: {
    welcome: 'Hi! I’m Celeste, your \nClockwork Universe Assistant',
    help: 'How can I help you?',
  },
  addHolding: {
    company: 'Company',
    fund: 'Fund',
    addHolding: 'Add Holding',
    completeForm: 'Complete the following form',
    logo: 'Logo',
    legalEntityName: 'Legal entity name',
    name: 'Name',
    website: 'Website',
    pointOfContact: 'Point of Contact',
    invitationInfo:
      'Add the founder or company point of contact email and we will send them an invitation to sign up Clockwork Universe for free.',
    addFundName: 'Add fund name',
    addFund: 'Add fund',
    includeFundManager: 'I want to include info about the Fund Manager',
    fundManagerName: 'Fund Manager Name',
    companyCreated: 'The company has been added successfully',
    fundsCreated:
      'The {count, plural, one {fund has} other {funds have}} been added successfully',
    countImported: 'Successfully imported {count} holdings',
    nameUsed:
      'This name is already being used. Would you like to visit <link>{name}</link> profile?',
    dropCSV: 'Or drop your .CSV file to upload multiple holdings at once',
    browseYourFile: 'Browse your file',
    downloadBulkTemplate: 'Download .CSV Bulk Template',
    bulkImportSuccess: 'All holdings were successfully created',
    bulkImportError:
      'An error occurred importing the holdings, please try again later.',
    completeTheForm: 'Complete the form',
    errors: {
      genericCompany:
        'There was an error when trying to create the company profile, try again later',
      genericFund:
        'There was an error when trying to create the fund profile, try again later',
      nameUsed: 'This name is already being used.',
      websiteUsed: 'The website URL is already being used.',
      wouldYouLikeToVisit: 'Would you like to visit',
      profileLink: '<link>{name}</link>',
      or: ' or ',
      profile: 'profile?',
      readingCSV: 'An error occurred reading the CSV file: {error}',
      invalidHoldingType: 'Invalid holding type "{type}"',
      companyAlreadyVisibleAddCompany:
        'This URL is already being used by one of the companies on your list. Would you like to add {link}?',
      fundAlreadyVisibleAddCompany:
        'This name is already being used by one or more funds on your list. Would you like to add {link}?',
      repeatedFundName: 'This name is already being used. Please try another.',
      repeatedWebsite:
        'This website is already being used. Please try another.',
    },
  },
  storageUsage: {
    label: 'Storage Usage: ',
    storageUsage: '{label} {used} {percentage} / {total}',
  },
  runOutOfStorage: {
    message:
      'Your group has run out of storage space. You will be able to upload or attach new files once you clean up some space or <link>upgrade to access to more storage.</link>',
  },
  previewFiles: {
    noPreviewAvailable: 'There is no preview available for "{fileName}".',
  },
  createFundPortfolioModal: {
    title: 'CREATE FUND',
  },
  holdingsAndPortfoliosDropdown: {
    placeholder: 'Select from...',
    portfolioTypes: {
      track: 'Pipeline',
      invest: 'Invest Portfolio',
      fund: 'Fund Portfolio',
      deal: 'Deal Portfolio',
    },
  },
  updatesView,
  inbounds,
  suggestedUpdates,
  createNewGroup,
  breadcrumb,
  updateActions,
}
