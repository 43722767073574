import styled from 'styled-components'
import { color } from 'utils/functions/colors'

export const MetricName = styled.span<{ size?: string }>`
  color: ${color('darkGray')};
  font-family: 'Lato', sans-serif;
  font-size: ${({ size }) => size || '1.4rem'};
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`
