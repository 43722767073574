import React, { useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'

import { Helmet } from 'components/Helmet'
import Tabs from 'components/Tabs'
import { useSelectedMetricsCount } from 'utils/hooks/queries/useMetrics'
import { usePendingMetricsInvitationsCount } from 'utils/hooks/metrics/useMetricsInvitationsQueries'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import { maxSize } from 'utils/constants/breakpoint'

import CelesteMetrics from './CelesteMetrics'
import Invitations from './Invitations'
import AllMetricsTable from './AllMetrics/AllMetricsTable'
import { useMetricsContext } from './MetricsContext'

import SelectedMetrics from './SelectedMetrics'
import { SelectedMetricsContextProvider } from './SelectedMetrics/useSelectedMetricsContext'
import MetricsSandbox from './MetricsSandbox'

import * as Styles from './Metrics.styles'

type Tab = {
  id: string
  title: string
  url: string
  urlPath: string
}

const TABS = {
  SELECTED_METRICS: {
    id: 'SELECTED_METRICS',
    url: '/metrics/selected',
  },
  ALL_METRICS: {
    id: 'ALL_METRICS',
    url: '/metrics/all',
  },
  INVITATIONS: {
    id: 'INVITATIONS',
    url: '/metrics/invitations',
  },
} as const

const Metrics: React.FC = () => {
  const intl = useIntl()
  const { matches: isMobile } = useMediaQuery(maxSize.mobile)
  const pendingInvitationsCount = usePendingMetricsInvitationsCount()
  const selectedMetricsCount = useSelectedMetricsCount({})
  const { search, onChange } = useMetricsContext()

  const tabs = useMemo(() => {
    const currentTabs: Tab[] = [
      {
        id: TABS.SELECTED_METRICS.id,
        title: `${intl.formatMessage({ id: 'metrics.tabs.selectedMetrics' })}${
          selectedMetricsCount ? ` (${selectedMetricsCount})` : ''
        }`,
        url: TABS.SELECTED_METRICS.url,
        urlPath: TABS.SELECTED_METRICS.url,
      },
      {
        id: TABS.ALL_METRICS.id,
        title: intl.formatMessage({ id: 'metrics.tabs.allMetrics' }),
        url: TABS.ALL_METRICS.url,
        urlPath: TABS.ALL_METRICS.url,
      },
      {
        id: TABS.INVITATIONS.id,
        title: `${intl.formatMessage({
          id: 'metrics.tabs.invitations',
        })}${pendingInvitationsCount ? ` (${pendingInvitationsCount})` : ''}`,
        url: TABS.INVITATIONS.url,
        urlPath: TABS.INVITATIONS.url,
      },
    ]

    return currentTabs
  }, [intl, pendingInvitationsCount, selectedMetricsCount])

  return (
    <>
      <Helmet>
        <title>{`${intl.formatMessage({ id: 'metaTitles.metrics' })}`}</title>
      </Helmet>
      <Styles.Container>
        <Styles.Header>
          <FormattedMessage id="metrics.metrics" />
        </Styles.Header>

        <SelectedMetricsContextProvider>
          <MetricsSandbox />
          <Tabs
            withBorder
            marginBottom={isMobile ? '1.6rem' : '2.4rem'}
            items={tabs}
            actions={[
              {
                id: 'search-metric',
                tabId: TABS.ALL_METRICS.id,
                component: (
                  <Styles.Search>
                    <Styles.SearchInput
                      value={search}
                      placeholder={intl.formatMessage({
                        id: 'general.search',
                      })}
                      handleChange={onChange}
                    />
                  </Styles.Search>
                ),
              },
            ]}
            className="metrics-tabs"
          >
            <Tabs.RouteContent path={TABS.SELECTED_METRICS.url}>
              <SelectedMetrics />
            </Tabs.RouteContent>

            <Tabs.RouteContent path={TABS.ALL_METRICS.url}>
              <AllMetricsTable />
            </Tabs.RouteContent>

            <Tabs.RouteContent path={TABS.INVITATIONS.url}>
              <Invitations />
            </Tabs.RouteContent>
          </Tabs>
        </SelectedMetricsContextProvider>
      </Styles.Container>
      <CelesteMetrics />
    </>
  )
}

export default Metrics
