import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'

import styles from './InfiniteScrollRef.module.scss'

const InfiniteScrollRef = forwardRef(({ offset }, ref) => {
  return (
    <div id="end-of-page" className={styles.relative}>
      <div
        ref={ref}
        className={styles.absolute}
        style={{
          bottom: offset,
        }}
      />
    </div>
  )
})

InfiniteScrollRef.propTypes = {
  offset: PropTypes.string,
}
InfiniteScrollRef.defaultProps = {
  offset: '50rem',
}
export default InfiniteScrollRef
