import styled from 'styled-components'
import { FormattedDate, FormattedNumber } from 'react-intl'
import dayjs from 'dayjs'
import type { IntlShape } from 'react-intl/src/types'
import { Column } from 'ui/Table/types'
import { PortfolioCompany } from 'utils/types/portfolios'
import ExpandArrow from 'ui/Table/Row/ExpandArrow'
import { emails } from 'utils/constants/emails'
import { HoldingType } from 'utils/hooks/useOnClickRedirect'
import Image from 'components/Image'
import Tooltip from 'components/Tooltip'
import { color } from 'utils/functions/colors'
import IconCell from './components/IconCell/IconCell'
import {
  TooltipContent,
  TooltipParagraph,
  TooltipTitle,
} from './components/IconCell/styles'

const DUMMY_DATE = '0000-01-01'

export enum DataType {
  CURRENCY = 'CURRENCY',
  DATE = 'DATE',
  NUMBER = 'NUMBER',
  MULTIPLIER = 'MULTIPLIER',
  STRING = 'STRING',
  PERCENT = 'PERCENT',
}

export interface VisibleFundHoldingsColumns {
  firstInvestmentDate: boolean
  totalTransactions: boolean
  unfundedCommitment: boolean
  totalAmountInvested: boolean
  totalFairMarketValue: boolean
  lastCarryingValueUpdate: boolean
  hideNullCarryingValueHoldings: boolean
  totalAmountDistributed: boolean
  totalValue: boolean
  multipleOnInvestedCapital: boolean
  irr: boolean
  percentageOfInvested: boolean
}

export const getFieldValue = (
  value?: number | string,
  type: DataType = DataType.CURRENCY,
  countDecimals: number = 0
) => {
  if (!value && value !== 0) return '-'

  if (type === DataType.CURRENCY && typeof value === 'number')
    return (
      <FormattedNumber
        value={value}
        // eslint-disable-next-line react/style-prop-object
        style="currency"
        currency="USD"
        currencyDisplay="narrowSymbol"
        minimumFractionDigits={countDecimals}
        maximumFractionDigits={countDecimals}
      />
    )

  if (type === DataType.DATE)
    return <FormattedDate year="numeric" value={value} />

  if (type === DataType.MULTIPLIER) return `${Number(value).toFixed(2)}x`

  if (type === DataType.NUMBER)
    return (
      <FormattedNumber
        value={Number(value || 0)}
        minimumFractionDigits={0}
        maximumFractionDigits={2}
      />
    )

  if (type === DataType.PERCENT)
    return (
      <FormattedNumber
        value={Number(value) / 100 || 0}
        // eslint-disable-next-line react/style-prop-object
        style="percent"
        minimumFractionDigits={countDecimals}
        maximumFractionDigits={countDecimals}
      />
    )

  return String(value)
}

export const Company = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  margin-top: 2.4rem;

  &:first-child {
    margin-top: 0;
  }

  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`

export const Cell = styled.div`
  color: ${color('darkGray')};
  font-size: 1.4rem;
`

export const CompanyAvatar = styled(Image)`
  border-radius: 10rem;
  border: 0.1rem solid ${color('veryLightGray')};
  width: 3rem;
  height: 3rem;
`

export const CompanyName = styled.span<{ isNormalFontWeight?: boolean }>`
  font-weight: ${({ isNormalFontWeight }) => (isNormalFontWeight ? 400 : 700)};
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
`

export const DescriptionText = styled(CompanyName)`
  -webkit-line-clamp: 1;
`

export const UpdateTitle = styled(CompanyName)`
  -webkit-line-clamp: 1;
`

export const InvestorNameCell = styled(Cell)`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 0.5rem;
`

export const IconContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  background-color: ${color('primaryBlue')};
  border: 0.1rem solid ${color('veryLightGray')};
  border-radius: 50%;
  height: 1.8rem;
  justify-content: center;
  padding: 1.3rem;
  width: 1.8rem;

  svg {
    color: ${color('white')};
  }
`

export const Investor = styled(Company)`
  margin-top: 0;
`

export const InvestorName = styled(CompanyName)`
  max-width: 15rem;
`

export type HoldingsColumnsConfig = {
  fmvWithTooltip: boolean
}

interface HoldingColumnsProps {
  intl: IntlShape
  visibleColumns?: VisibleFundHoldingsColumns
  config: HoldingsColumnsConfig
  isMobile: boolean
  handleOnHoldingClick: (holding: HoldingType) => void
}

export interface HoldingsColumnStatus {
  id: string
  status: boolean
  label: string
}

export const getHoldingColumns = ({
  intl,
  visibleColumns,
  config,
  isMobile,
  handleOnHoldingClick,
}: HoldingColumnsProps): Column<PortfolioCompany>[] => [
  {
    id: 'expand',
    sortDisabled: true,
    fixed: !isMobile,
    minWidth: 13,
    cellRenderer: ExpandArrow,
  },
  {
    id: 'name',
    label: intl.formatMessage({
      id: 'investments.aggregateInvestmentHoldingTable.holdings',
    }),
    flex: 1,
    minWidth: 215,
    fixed: !isMobile,
    sortKey: 'name',
    cellRenderer: ({ rowData }) => {
      return (
        <Cell>
          <Company
            onClick={() => handleOnHoldingClick(rowData as PortfolioCompany)}
          >
            <CompanyAvatar src={rowData?.logo} title={rowData?.name} />
            <Tooltip
              id={`company-name-${rowData?.name}`}
              place="bottom"
              text={rowData?.name}
              delayShow={500}
            >
              <CompanyName>{rowData?.name}</CompanyName>
            </Tooltip>
          </Company>
        </Cell>
      )
    },
  },
  {
    id: 'firstInvestmentDate',
    hidden: !visibleColumns?.firstInvestmentDate,
    label: intl.formatMessage({
      id: 'investments.aggregateInvestmentHoldingTable.firstInvestmentDate',
    }),
    flex: 1,
    minWidth: 110,
    sortKey: 'firstInvestmentDate',
    cellRenderer: ({ rowData }) => {
      return (
        <Cell>
          {rowData.firstInvestmentDate &&
          rowData.firstInvestmentDate !== DUMMY_DATE ? (
            <FormattedDate
              year="numeric"
              month="short"
              value={rowData.firstInvestmentDate}
            />
          ) : (
            '-'
          )}
        </Cell>
      )
    },
  },
  {
    id: 'totalTransactions',
    hidden: !visibleColumns?.totalTransactions,
    label: intl.formatMessage({
      id: 'investments.aggregateInvestmentHoldingTable.totalInvestments',
    }),
    flex: 1,
    minWidth: 90,
    sortKey: 'totalTransactions',
    cellRenderer: ({ rowData }) => {
      return (
        <Cell>
          {getFieldValue(rowData?.totalTransactions, DataType.NUMBER)}
        </Cell>
      )
    },
  },
  {
    id: 'unfundedCommitment',
    hidden: !visibleColumns?.unfundedCommitment,
    label: intl.formatMessage({
      id: 'investments.aggregateInvestmentHoldingTable.unfundedCommitment',
    }),
    flex: 1,
    minWidth: 100,
    sortKey: 'unfundedCommitment',
    cellRenderer: ({ rowData }) => {
      return (
        <Cell>
          {getFieldValue(rowData?.unfundedCommitment, DataType.CURRENCY)}
        </Cell>
      )
    },
  },
  {
    id: 'totalAmountInvested',
    hidden: !visibleColumns?.totalAmountInvested,
    label: intl.formatMessage({
      id: 'investments.aggregateInvestmentHoldingTable.amountInvested',
    }),
    flex: 1,
    minWidth: 90,
    sortKey: 'totalAmountInvested',
    cellRenderer: ({ rowData }) => {
      return (
        <Cell>
          {getFieldValue(rowData?.totalAmountInvested, DataType.CURRENCY)}
        </Cell>
      )
    },
  },
  {
    id: 'totalFairMarketValue',
    hidden: !visibleColumns?.totalFairMarketValue,
    label: config.fmvWithTooltip ? (
      <IconCell
        cellNameId="portfolioDetail.fairMarketValue"
        iconName="question-circle"
        tooltipId="fmv-tooltip"
        tooltipText={
          <TooltipContent>
            <TooltipTitle>
              {intl.formatMessage({
                id: 'portfolioDetail.totalUnrealizedValue',
              })}
            </TooltipTitle>
            <TooltipParagraph>
              {intl.formatMessage(
                { id: 'portfolioDetail.carryingValueTooltip' },
                {
                  email: (
                    <a
                      href={emails.MAILTO_SUPPORT}
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      support@clockwork.app
                    </a>
                  ),
                }
              )}
            </TooltipParagraph>
          </TooltipContent>
        }
      />
    ) : (
      intl.formatMessage({
        id: 'investments.aggregateInvestmentHoldingTable.fairMarketValue',
      })
    ),
    flex: 1,
    minWidth: 90,
    sortKey: 'totalFairMarketValue',
    cellRenderer: ({ rowData }) => {
      return (
        <Cell>
          {getFieldValue(rowData?.totalFairMarketValue, DataType.CURRENCY)}
        </Cell>
      )
    },
  },
  {
    id: 'lastCarryingValueUpdate',
    sortKey: 'lastCarryingValueUpdate',
    hidden: !visibleColumns?.lastCarryingValueUpdate,
    label: intl.formatMessage({
      id: 'investments.aggregateInvestmentHoldingTable.lastCarryingValueUpdate',
    }),
    minWidth: 100,
    flex: 1,
    cellRenderer: ({ rowData }) => {
      return (
        <Cell>
          {dayjs(rowData.lastCarryingValueUpdate).isValid()
            ? dayjs(rowData.lastCarryingValueUpdate).format('MMM DD, YYYY')
            : '-'}
        </Cell>
      )
    },
  },
  {
    id: 'totalAmountDistributed',
    hidden: !visibleColumns?.totalAmountDistributed,
    label: intl.formatMessage({
      id: 'investments.aggregateInvestmentHoldingTable.amountDistributed',
    }),
    flex: 1,
    minWidth: 100,
    sortKey: 'totalAmountDistributed',
    cellRenderer: ({ rowData }) => {
      return (
        <Cell>
          {getFieldValue(rowData?.totalAmountDistributed, DataType.CURRENCY)}
        </Cell>
      )
    },
  },
  {
    id: 'totalValue',
    hidden: !visibleColumns?.totalValue,
    label: intl.formatMessage({
      id: 'investments.aggregateInvestmentHoldingTable.totalValue',
    }),
    flex: 1,
    minWidth: 100,
    sortKey: 'totalValue',
    cellRenderer: ({ rowData }) => {
      return (
        <Cell>{getFieldValue(rowData?.totalValue, DataType.CURRENCY)}</Cell>
      )
    },
  },
  {
    id: 'multipleOnInvestedCapital',
    hidden: !visibleColumns?.multipleOnInvestedCapital,
    label: intl.formatMessage({
      id: 'portfolioDetail.moic',
    }),
    flex: 1,
    minWidth: 100,
    sortKey: 'multipleOnInvestedCapital',
    cellRenderer: ({ rowData }) => {
      return (
        <Cell>
          {getFieldValue(
            rowData?.multipleOnInvestedCapital,
            DataType.MULTIPLIER
          )}
        </Cell>
      )
    },
  },
  {
    id: 'irr',
    hidden: !visibleColumns?.irr,
    label: intl.formatMessage({
      id: 'investments.aggregateInvestmentHoldingTable.irr',
    }),
    tooltip: intl.formatMessage({
      id: 'portfolioDetail.moicTooltip',
    }),
    flex: 1,
    minWidth: 80,
    sortKey: 'irr',
    cellRenderer: ({ rowData }) => {
      return <Cell>{getFieldValue(rowData?.irr, DataType.PERCENT, 2)}</Cell>
    },
  },
  {
    id: 'percentageOfInvested',
    hidden: !visibleColumns?.percentageOfInvested,
    label: intl.formatMessage({
      id: 'portfolioDetail.percentageOfInvested',
    }),
    tooltip: intl.formatMessage({
      id: 'portfolioDetail.percentageOfInvestedTooltip',
    }),
    flex: 1,
    minWidth: 100,
    sortKey: 'percentageOfInvested',
    cellRenderer: ({ rowData }) => {
      return (
        <Cell>
          {getFieldValue(rowData?.percentageOfInvested, DataType.PERCENT, 2)}
        </Cell>
      )
    },
  },
]
