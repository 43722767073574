export enum EventLogTypes {
  NOTES = 'notes',
  DOCUMENTS = 'documents',
  ANNOUNCEMENTS = 'announcements',
  PORTFOLIOS = 'portfolios',
  XERO_REPORTS = 'xero_reports',
  QUICKBOOKS_REPORTS = 'quickbooks_reports',
  TRANSACTIONS = 'transactions',
  METRICS = 'metrics',
}

export const LoggingType = {
  NOTES: 'Logging::Note',
  DOCUMENTS: 'Logging::Document',
  ANNOUNCEMENTS: 'Logging::Announcement',
  ATTACHMENT: 'Attachment',
  PORTFOLIO: 'Portfolios::PortfolioCompany',
  TAG: 'Tags::Tagging',
  XERO_REPORTS: 'Logging::XeroReport',
  QUICKBOOKS_REPORTS: 'Logging::QuickbooksReport',
  TRANSACTION: 'Logging::Transaction',
  // METRIC_GROUP: 'Metric::Group',
  METRIC_ENTITY: 'Metrics::Metric',
  METRIC_LINKED: 'Metrics::LinkedMetric',
  METRIC_MILESTONE: 'Metrics::Milestone',
  METRIC_DATAPOINT: 'Metrics::DataPoint',
} as const
