import { useCallback } from 'react'
import type { IconProp } from '@fortawesome/fontawesome-svg-core'
import { FormattedMessage, useIntl } from 'react-intl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ContextMenuItem } from 'ui/ContextMenu'
import { Metric } from 'utils/types/metrics'
import theme, { DataVisualizationColor } from 'utils/theme'
import { useMetricsSandbox } from 'containers/Metrics/SelectedMetrics/useMetricsSandbox'
import { CHART_MODE, ChartConfig, ChartModeIcons } from '../../types'
import MetricName from '../MetricName'

import * as Styles from './MetricsList.styles'

interface MetricsListProps {
  colors: Record<string, DataVisualizationColor>
  chartMode: Record<string, ChartConfig>
  metrics: Metric[]
  isRemoveMetricDisabled?: boolean
  onRemove?: (metric: Metric) => void
  onChangeChartMode?: (metricId: string, mode: CHART_MODE) => void
  onChangeVisibilityMode?: (metricId: string) => void
  onClearAll?: () => void
}

const MetricsList: React.FC<MetricsListProps> = ({
  chartMode,
  colors,
  isRemoveMetricDisabled,
  metrics,
  onChangeChartMode,
  onChangeVisibilityMode,
  onRemove,
  onClearAll,
}) => {
  const intl = useIntl()
  const { isMetricVisible } = useMetricsSandbox()

  const getIcon = useCallback((chartModeValue: CHART_MODE): IconProp => {
    if (chartModeValue === CHART_MODE.BAR) {
      return ['fal', 'chart-column']
    }

    return ['fal', 'chart-line-up']
  }, [])

  const getContextualOptions = useCallback(
    (metric: Metric) => {
      if (!chartMode[metric.id]) return []

      const { mode } = chartMode[metric.id]

      const options: JSX.Element[] = Object.values(CHART_MODE)
        .filter((chartModeValue) => chartModeValue !== mode)
        .map((chartModeValue) => (
          <ContextMenuItem
            onClick={() => onChangeChartMode?.(metric.id, chartModeValue)}
            icon={ChartModeIcons[chartModeValue]}
            label={intl.formatMessage({
              id: `metrics.sandbox.${chartModeValue}`,
            })}
          />
        ))

      return [
        <ContextMenuItem
          onClick={() => onChangeVisibilityMode?.(metric.id)}
          icon={
            isMetricVisible(metric.id) ? ['fal', 'eye'] : ['fal', 'eye-slash']
          }
          iconColor={
            isMetricVisible(metric.id) ? undefined : theme.colors.lightGray
          }
          label={intl.formatMessage({ id: 'metrics.visibleOnChart' })}
        />,
        ...options,
      ]
    },
    [
      chartMode,
      intl,
      isMetricVisible,
      onChangeChartMode,
      onChangeVisibilityMode,
    ]
  )

  return (
    <Styles.Container>
      <Styles.List>
        {metrics.map((metric) => {
          return (
            <Styles.CustomChip
              canDelete={!isRemoveMetricDisabled}
              contextualOptions={getContextualOptions(metric)}
              handleDelete={() => onRemove?.(metric)}
              key={metric.id}
              text={<MetricName metric={metric} />}
              imageComponent={
                <Styles.MetricAvatar
                  isVisible={isMetricVisible(metric.id)}
                  color={colors[metric.id]}
                  onClick={() => onChangeVisibilityMode?.(metric.id)}
                >
                  <FontAwesomeIcon icon={getIcon(chartMode[metric.id]?.mode)} />
                </Styles.MetricAvatar>
              }
            />
          )
        })}
      </Styles.List>
      {!isRemoveMetricDisabled && (
        <Styles.Clear onClick={onClearAll} secondaryDanger>
          <FormattedMessage id="metrics.sandbox.clear" />
        </Styles.Clear>
      )}
    </Styles.Container>
  )
}

export default MetricsList
