/* eslint-disable react/prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { FieldArray } from 'formik'
import { useAppSelector } from 'utils/hooks/reduxToolkit'
import { AutoSizer, List } from 'react-virtualized'

import { isActingAsFounder, isActingAsFundManager } from 'selectors/auth'
import BoxGroupsAndUsersChips from 'components/BoxGroupsAndUsersChips'
import { FormattedMessage, useIntl } from 'react-intl'
import Dropdown from 'ui/Dropdown'
import ListsSelectionBox from 'components/ListsSelectionBox'
import FileBanner from 'components/FileBanner'
import ReadOnlyTooltip from 'components/ReadOnlyTooltip/ReadOnlyTooltip'
import { getIsFounderVerified } from 'selectors/company'
import useGroupCompany from 'utils/hooks/useGroupCompany'
import {
  AutoSuggestPortfolioCompanies,
  ListsSelectionBoxWrapper,
} from '../CreateMetric/CreateMetric.styles'
import {
  ImportMetricValuesContainer,
  TableWrapper,
  DataPointsContainer,
  TableHeader,
  TableHeaderItem,
  OptionalLabel,
  TableContent,
  ShareGroupsContainer,
  MetricValuesContainer,
} from './BulkImportMetricValuesModal.styles'
import ImportMetricValueRow from './ImportMetricValueRow'

const ImportMetricValues = ({
  formik,
  handleAddGroup,
  handleAddGroupByHandle,
  handleRemoveGroup,
  loadGroups,
  currentGroups,
  countImported,
  fileName,
  handleDeleteItemList,
  handleEditItemList,
  handleAddItemList,
  itemLists,
  metric,
}) => {
  const intl = useIntl()
  const isFounder = useAppSelector(isActingAsFounder)
  const isFounderVerified = useAppSelector(getIsFounderVerified)

  const isFundManager = useAppSelector(isActingAsFundManager)
  const groupCompany = useGroupCompany()
  const isFundCompanyMetric = metric.companyData?.id === groupCompany?.id

  const showShareWithOption =
    isFounder || (isFundManager && isFundCompanyMetric)

  const rowRenderer =
    (remove) =>
    ({ index, key, style }) => {
      return (
        <div key={key} style={style}>
          <ImportMetricValueRow
            name={`metricValues.${index}`}
            index={index}
            remove={remove}
            values={formik.values.metricValues[index]}
            errors={formik.errors?.metricValues?.[index]}
            touched={formik.touched?.metricValues?.[index]}
            showDelete={formik.values.metricValues.length > 1}
            handleChange={formik.handleChange}
            handleBlur={formik.handleBlur}
            setFieldValue={formik.setFieldValue}
          />
        </div>
      )
    }

  return (
    <MetricValuesContainer>
      <FileBanner fileName={fileName}>
        <FormattedMessage
          id="bulkImportDataPointsModal.countImported"
          values={{ count: countImported }}
        />
      </FileBanner>

      <ImportMetricValuesContainer>
        <DataPointsContainer withCustomWidth={showShareWithOption}>
          <TableWrapper>
            <TableHeader>
              <TableHeaderItem flex={0.1} />
              <TableHeaderItem flex={1}>
                <FormattedMessage id="bulkImportDataPointsModal.metricValue" />
              </TableHeaderItem>
              <TableHeaderItem flex={0.6} minWidth="16rem">
                <FormattedMessage id="bulkImportDataPointsModal.metricDate" />
              </TableHeaderItem>
              <TableHeaderItem flex={0.1} />
            </TableHeader>

            <TableContent withoutScroll={formik.values.metricValues.length < 7}>
              <FieldArray name="metricValues">
                {({ remove }) => (
                  <AutoSizer disableHeight>
                    {({ width }) => (
                      <List
                        height={Math.min(
                          showShareWithOption ? 450 : 320,
                          50 * formik.values.metricValues.length
                        )}
                        overscanRowCount={10}
                        rowCount={formik.values.metricValues.length}
                        rowHeight={50}
                        rowRenderer={rowRenderer(remove)}
                        width={width}
                        style={{ marginTop: '1rem' }}
                      />
                    )}
                  </AutoSizer>
                )}
              </FieldArray>
            </TableContent>
          </TableWrapper>
        </DataPointsContainer>
        {showShareWithOption && (
          <ReadOnlyTooltip
            place="left"
            text={intl.messages['updates.readOnlyMetrics']}
            disabled={!isFounderVerified}
            topOffset={-10}
            leftOffset={-50}
          >
            {({ disabled }) => (
              <ShareGroupsContainer>
                <TableHeaderItem disabled={disabled}>
                  <FontAwesomeIcon icon={['far', 'share']} />{' '}
                  <FormattedMessage id="metrics.shareWith" />{' '}
                  <OptionalLabel>(optional)</OptionalLabel>
                </TableHeaderItem>

                <ListsSelectionBoxWrapper>
                  <ListsSelectionBox
                    showLabel={false}
                    items={itemLists}
                    handleAddGroup={handleAddItemList}
                    handleAddUser={() => {}}
                    handleDelete={handleDeleteItemList}
                    handleEditItemList={handleEditItemList}
                    onlyGroups
                  />
                </ListsSelectionBoxWrapper>

                <AutoSuggestPortfolioCompanies>
                  <Dropdown
                    name="companiesOrPortfolios"
                    onSelectOption={handleAddGroup}
                    placeholder={intl.messages['metrics.addGroups']}
                    id="companies-portfolios"
                    loadOptions={loadGroups}
                    async
                    onPressEnter={handleAddGroupByHandle}
                    type="input"
                    icon={['far', 'plus']}
                    iconFontSize="1.8rem"
                    getOption={(entity) => ({
                      label: entity.name,
                      id: entity.id,
                    })}
                    clearOnSelect
                    disabled={disabled}
                  />

                  <BoxGroupsAndUsersChips
                    items={currentGroups?.map((item) => ({
                      ...item,
                      fromMetric: true,
                    }))}
                    handleDelete={handleRemoveGroup}
                  />
                </AutoSuggestPortfolioCompanies>
              </ShareGroupsContainer>
            )}
          </ReadOnlyTooltip>
        )}
      </ImportMetricValuesContainer>
    </MetricValuesContainer>
  )
}

ImportMetricValues.propTypes = {
  formik: PropTypes.object.isRequired,
  handleAddGroup: PropTypes.func.isRequired,
  handleAddGroupByHandle: PropTypes.func.isRequired,
  handleRemoveGroup: PropTypes.func.isRequired,
  loadGroups: PropTypes.func.isRequired,
  currentGroups: PropTypes.array.isRequired,
  countImported: PropTypes.number.isRequired,
  fileName: PropTypes.string.isRequired,
  handleDeleteItemList: PropTypes.func.isRequired,
  handleEditItemList: PropTypes.func.isRequired,
  handleAddItemList: PropTypes.func.isRequired,
  itemLists: PropTypes.array.isRequired,
  metric: PropTypes.object.isRequired,
}

export default ImportMetricValues
