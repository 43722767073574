import {
  SkeletonAvatarWithAnimation,
  SkeletonHorizontalSeparator,
  SkeletonItem,
  SkeletonItemWrapper,
} from 'components/Skeletons/Skeletons.styles'
import { maxSize } from 'utils/constants/breakpoint'
import { useMediaQuery } from 'utils/hooks/useMediaQuery'
import {
  SkeletonInvitationsMetricsCardWrapper,
  Wrapper,
} from './SkeletonInvitationsCard.styles'

const SkeletonInvitationsItem = () => {
  const { matches: isMobileXsm } = useMediaQuery(maxSize.xsm) // 468
  const { matches: isMobileXss } = useMediaQuery(maxSize.xss) // 380

  const getFirstItemWidth = () => {
    if (isMobileXss) {
      return '12rem'
    }
    if (isMobileXsm) {
      return '16rem'
    }
    return '28rem'
  }

  const getSecondItemWidth = () => {
    if (isMobileXss) {
      return '6rem'
    }
    if (isMobileXsm) {
      return '10rem'
    }
    return '18rem'
  }

  return (
    <SkeletonInvitationsMetricsCardWrapper>
      <SkeletonItemWrapper gap="1rem" alignItems="center">
        <SkeletonAvatarWithAnimation />
        <SkeletonItemWrapper
          direction="column"
          gap="1rem"
          alignItems="flex-start"
        >
          <SkeletonItem width={getFirstItemWidth()} height="1.4rem" />
          <SkeletonItem width={getSecondItemWidth()} height="1rem" />
        </SkeletonItemWrapper>
      </SkeletonItemWrapper>
    </SkeletonInvitationsMetricsCardWrapper>
  )
}

const SkeletonInvitationsCard = () => {
  return (
    <Wrapper>
      <SkeletonInvitationsItem />
      <SkeletonHorizontalSeparator />
      <SkeletonInvitationsItem />
      <SkeletonHorizontalSeparator />
      <SkeletonInvitationsItem />
      <div />
    </Wrapper>
  )
}

export default SkeletonInvitationsCard
